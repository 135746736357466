import { createContext } from 'react'; 
import { QueryState, initialStateReducer } from '../reducers/CatalogoReducer';

//definimos las variables globales del state
type catalogoContextType = {
    query: QueryState,
    dispatch: React.Dispatch<any>,
}

const ContextDefaultValues: catalogoContextType = {
    query: initialStateReducer,
    dispatch: () => null
};


//creamos el Context
//use context en un higher order component es decir es un componente que contiene componentes hijos
export const CatalogoContext = createContext<catalogoContextType>(ContextDefaultValues);