/*********************************************************************************************
Titulo:  NAVBAR
Tipo:    Componente complemento
Nivel:   COMPONENTE Nivel 2  Basico, Redux, Modal
Dependencias Componentes:
         principal
         sidebar
Dependencias Redux:
         darkModeReducer.tsx
         sidebarReducer.tsx
         navbarReducer.tsx
         modalReducer.tsx
Creador: Emmanuel Ordaz Alvarez 
Dazteck Todos los derechos reservados, prohibida su copia, distribucion o uso no autorizado
*********************************************************************************************/ 

import { useDispatch, useSelector } from 'react-redux';
import { useContext } from 'react';
import SVG from 'react-inlinesvg';
import { AppDispatch, RootState } from '../../../../store/store';
import { Logo } from './Logo'; 
import { modalIds, showModal, modalStyles } from '../../../../reducers/modalReducer';
import { startModoOscuro } from '../../../../reducers/darkModeReducer';
import { startLogout } from '../../../../reducers/authReducer';
import { FormControlLabel, Tooltip, Switch, Typography } from '@mui/material';
import { SocketContext } from '../../../../context/SocketContext';

import "./styles/navbar.scss"

//Configuracion del estilo del componente Modal
const customStyles: modalStyles = {
    overlay: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(21, 34, 56, 0.4)'
    },
    content : {
      top : '50px',
      left : 'auto',
      right : '10px',
      bottom : '10px',
      padding : '0px',
      marginRight: '',
      transform: '' 
    }
};

//creamos el componente JSX
export const Navbar = () => {
    
    // console.log('Se renderizo Navbar');

    //LEER EL STORE DE REDUX
    const storeDarkMode = (state: RootState ) => state.darkmode;
    const { darkMode } = useSelector(storeDarkMode);
    const storeSidebar = (state: RootState ) => state.sidebar;
    const { sidebarWidth } = useSelector(storeSidebar);
    const storeNavbar = (state: RootState ) => state.navbar;
    const { title } = useSelector(storeNavbar);
    const { alert } = useSelector(storeNavbar);
    const { message } = useSelector(storeNavbar);
    const { notification } = useSelector(storeNavbar);
    // const { smallLogo } = useSelector(storeNavbar);
    const storeAuth = (state: RootState ) => state.auth;
    const { username } = useSelector(storeAuth);
  
    //LEER EL CONTEXT DE SOCKETS PARA DESCONECTARLO 
    const { desconectarSocket } = useContext( SocketContext );

    //ESCRIBIR EN EL STORE DE REDUX
    const dispatchRedux = useDispatch<AppDispatch>();

    //ONCHANGE DE MODO OSCURO
    const handleChange = (checked: boolean) => {
        dispatchRedux<any>(startModoOscuro({ darkmode: checked })); 
    }

    //ONCLICK BOTONES DE NOTIFICACION
    const handleButtonClick = (id: string) => {
        switch (id) {
            case modalIds.Alert:
                dispatchRedux(showModal({ modal: id, style: customStyles })); 
            break;
            case modalIds.Message:
                dispatchRedux(showModal({ modal: id, style: customStyles }));
            break;
            case modalIds.Notification:
                dispatchRedux(showModal({ modal: id, style: customStyles }));
            break;
            case 'Logout':
                desconectarSocket();
                dispatchRedux<any>(startLogout());                
            break;
        }
    }

    //ESTILO DE SIDEBAR
    const logoStyles = {
        "--logowidth": `${sidebarWidth + 10}px`,
    } as React.CSSProperties;

    return (
        <div className={ 
            (darkMode) 
                ? 'navbar_main navbar_dark_theme' 
                : 'navbar_main navbar_light_theme'
            } 
        >
            <div className="navbar_row">
                <div className="navbar_left">
                    {/* <div className={ (smallLogo) ? 'navbar_logo_small' : 'navbar_logo' }
                        style={ logoStyles }
                    >
                        <div className={ (smallLogo) ? 'navbar_icon_small' : 'navbar_icon'}>
                            <Logo />
                        </div>
                    </div> */}
                    <div className="navbar_logo_small"
                        style={ logoStyles }
                    >
                        <div className="navbar_icon_small">
                            <Logo />
                        </div>
                    </div>
                    <div className="navbar_title">
                        { title }
                    </div>
                </div>
            </div>
            <div className="navbar_row">
                <div className="navbar_right">
                    <div className="navbar_user">
                        <div className="navbar_usericon navbar_modal_icon_purple">
                            <SVG src={process.env.REACT_APP_ICONSERVER+'dz-user-check'} height={18} fill="currentColor"/>
                        </div>
                        <div className="navbar_usertext">
                            { username }
                        </div>
                    </div>
                    <div className="navbar_buttons">
                        <Tooltip title="Alertas" placement="bottom" arrow>
                            <div className="navbar_alert">
                                <div className="navbar_button navbar_modal_icon_red"
                                    title={ modalIds.Alert }
                                    id={ modalIds.Alert }
                                    onClick={ (e) => ( handleButtonClick(e.currentTarget.id) ) }
                                >
                                    {/* <i className="fas fa-exclamation"></i> */}
                                    <SVG src={process.env.REACT_APP_ICONSERVER+'dz-alert-circle'} height={18} fill="currentColor"/>
                                    <span
                                        className={ (alert > 0) ? 'navbar_number_show' : 'navbar_number_hide' }
                                    >{ (alert > 0 ) ? ((alert > 9) ? '9+' : alert) : '' }
                                    </span>
                                </div>            
                            </div>
                        </Tooltip>
                        <Tooltip title="Mensajes" placement="bottom" arrow>
                            <div className="navbar_msgs">
                                <div className="navbar_button navbar_modal_icon_blue"
                                    title={ modalIds.Message }
                                    id={ modalIds.Message }                             
                                    onClick={ (e) => ( handleButtonClick(e.currentTarget.id) ) }
                                >
                                    {/* <i className="fas fa-envelope"></i> */}
                                    <SVG src={process.env.REACT_APP_ICONSERVER+'dz-message'} height={18} fill="currentColor"/>
                                    <span
                                        className={ (message > 0) ? 'navbar_number_show' : 'navbar_number_hide' }
                                    >{ (message > 0 ) ? ((message > 9) ? '9+' : message) : '' }
                                    </span>
                                </div>
                            </div>
                        </Tooltip>
                        <Tooltip title="Notificaciones" placement="bottom" arrow>
                            <div className="navbar_notifications">
                                <div className="navbar_button navbar_modal_icon_green" 
                                    title={ modalIds.Notification }
                                    id={ modalIds.Notification }                               
                                    onClick={ (e) => ( handleButtonClick(e.currentTarget.id) ) }
                                >
                                    {/* <i className="fas fa-bell"></i> */}
                                    <SVG src={process.env.REACT_APP_ICONSERVER+'dz-bell'} height={18} fill="currentColor"/>
                                    <span
                                        className={ (notification > 0) ? 'navbar_number_show' : 'navbar_number_hide' }  
                                    >{ (notification > 0 ) ? ((notification > 9) ? '9+' : notification) : '' }
                                    </span>
                                </div>
                            </div>    
                        </Tooltip>                
                    </div>
                    <Tooltip title="Cambiar diseño" placement="bottom" arrow>
                        <div className="navbar_darkmode">
                        <FormControlLabel 
                            labelPlacement="start"
                            className="navbar_darkmode_label"
                            control={
                            <Switch 
                                onChange = { (e) => { handleChange(e.target.checked) }}
                                size="small" 
                                checked={ darkMode }
                            />
                            } 
                            label={ <Typography style={ { fontSize: '12px', color: 'primary', fontStyle: 'bold'} }>{'Modo Oscuro'}</Typography> }
                        />
                        </div>
                    </Tooltip>
                    <Tooltip title="Cerrar Sesion" placement="bottom" arrow>
                        <div className="navbar_logout">
                            <div className="navbar_button navbar_modal_icon_orange"
                                title="Salir"
                                id="Logout"                             
                                onClick={ (e) => ( handleButtonClick(e.currentTarget.id) ) }
                            >
                                {/* <i className="fas fa-bell"></i> */}
                                <SVG src={process.env.REACT_APP_ICONSERVER+'dz-door-close'} height={18} fill="currentColor"/>
                            </div>                        
                        </div>
                    </Tooltip>
                </div>
            </div>            
        </div>
    )
}
