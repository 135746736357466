import { useDispatch, useSelector } from "react-redux";
import SVG from 'react-inlinesvg';
import { RootState, AppDispatch } from '../../../../store/store';
import { enableMessage } from '../../../../reducers/navbarReducer';
import { showSubmodal, modalStyles, submodalIds } from '../../../../reducers/modalReducer';
import dayjs from 'dayjs';

//Configuracion del estilo del componente Modal
const customStyles: modalStyles = {
    overlay: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(21, 34, 56, 0.4)'
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: '',
        marginRight: '-50%',
        transform: 'translate(-50%, -60%)'
    }
};


export const Messages = () => {

    // console.log('Se renderizo Messages');

    //LEER VARIABLES DE REDUX
    const storeDarkMode = (state: RootState ) => state.darkmode;
    const { darkMode } = useSelector(storeDarkMode);
    const storeNavbar = (state: RootState) => state.navbar;
    const { messages } = useSelector(storeNavbar);

    //ESCRIBIR EN EL STORE DE REDUX
    const dispatchRedux = useDispatch<AppDispatch>(); 

    //ONCLICK DE ITEM
    const handleItemClick = async (id: string) => {
        await dispatchRedux<any>(enableMessage({id: id}));
        await dispatchRedux<any>(showSubmodal({modal: submodalIds.Message, style: customStyles}));
    } 
    
    return (
        <>
        {
            (messages[0].key === 0)
                ? (
                    <div className="navbar_modal"></div>
                )
                : ( 
                messages.map((message) => (
                    <div 
                        key={ message.key }
                        id={ message.id }
                        className={ 
                            (darkMode) 
                            ? 'navbar_modal_grid navbar_modal_grid_dark' 
                            : 'navbar_modal_grid navbar_modal_grid_light'
                        }
                        onClick = { (e) => { handleItemClick(e.currentTarget.id) } }
                    >
                        <div className={ (message.active) ? 'navbar_modal_icon navbar_modal_icon_blue' : 'navbar_modal_icon' }>
                            {
                                (message.active) 
                                ? <SVG src={process.env.REACT_APP_ICONSERVER+'dz-message'} height={23} fill="currentColor"/>
                                : <SVG src={process.env.REACT_APP_ICONSERVER+'dz-message-open'} height={23} fill="currentColor"/>
                            }
                            {/* <i className={ (message.active) ? 'fas fa-envelope' : 'fas fa-envelope-open' } /> */}
                        </div>
                        <div className="navbar_modal_title">{ message.title }</div>
                        <div className="navbar_modal_text_msg">{ message.text }</div>
                        <div className={ (message.active) ? 'navbar_modal_active navbar_modal_active_blue' : 'navbar_modal_active' }/> 
                        <div
                            className={(darkMode) 
                                ? 'navbar_modal_date navbar_modal_date_dark' 
                                : 'navbar_modal_date navbar_modal_date_light'
                            } 
                        >
                            { dayjs(message.date).fromNow() } 
                        </div>
                    </div>                                      
                ))
            )
        }
        </>
    )
}
