
import { Box, Dialog, IconButton, Link, Typography, useMediaQuery } from "@mui/material";
import SVG from 'react-inlinesvg';
import { useDispatch, useSelector } from "react-redux";
import { cerrarTerminosCondicionesSaleModal } from "../reducers/registerReducer";
import { AppDispatch, RootState } from "../store/store";

export const TerminosCondicionesSale = () => {

    //LEER INFORMACION DE REDUX
    const storeRegister = (state: RootState ) => state.register;
    const { terminosCondicionesSale } = useSelector(storeRegister);
    const dispatchRedux = useDispatch<AppDispatch>();

    const responsive = useMediaQuery('(max-width:700px)');
    const responsive2 = useMediaQuery('(max-width:400px)');

  return (
    <Dialog
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      open={terminosCondicionesSale}
      onClose={() => {dispatchRedux(cerrarTerminosCondicionesSaleModal())}}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            maxWidth: "1200px",
          },
        },
      }}
    >
      <div className="aviso_privacidad_titulo">
        <Typography variant={( responsive ? "h4" : "h3" )} sx={{ fontWeight: 500, color: "rgb(0, 93, 199)" }}>Términos y Condiciones</Typography>
        <IconButton
          aria-label="close"
          onClick={() => {dispatchRedux(cerrarTerminosCondicionesSaleModal())}}
          sx={{
            position: 'absolute',
            right: (responsive ? 30 : 70)
          }}
        >
          <SVG src={process.env.REACT_APP_ICONSERVER+'dz-error'} width={30} />
        </IconButton>
      </div>

      <Box sx={{ p: "0px 60px 60px 60px" }}>
        <div className="aviso_privacidad_contenedores">
          <div className="aviso_privacidad_subtitulos">DECLARACIONES</div>
          <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
            Declara <b>AUTOSXPARTESPUNTOCOM S.A de C.V.</b>, ser un prestador de servicio que en los
            párrafos siguientes también se le podrá denominar el “<b>prestador</b>”, quien manifiesta ser
            una persona moral de nacionalidad mexicana registrada, que tiene como objeto la
            enajenación de servicios de internet mediante plataformas tecnológicas y aplicaciones,
            enajenación de publicidad digital y no digital, compra venta de autopartes usadas, servicio
            de desarme de vehículos, servicio de reparación de vehículos, importación y exportación
            de vehículos usados, compra venta de chatarra, compra venta de toda clase de vehículos
            para reciclaje, entre otros, por lo que la moral puede ejecutar toda clase de actos de
            comercio, pudiendo adquirir, enajenar, importar, y exportar toda clase de artículos y
            mercancías relacionadas con el objeto anterior, y en general, la realización de toda clase
            de actos, operaciones, convenios, contratos y títulos permitidos por la legislación
            mexicana que sean necesarios o convenientes para el objeto social, ofrece servicios de
            plataformas tecnológicas vinculadas principalmente al comercio electrónico de
            autopartes.
          </Typography>
          <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
            Declara el usuario que es una persona física o moral con capacidad legal, por lo que en
            líneas siguientes quien también se le podrá denominar el “<b>usuario</b>” y que ingresa y
            aceptará por derecho propio, para tal efecto lo acredita con los documentos enviados y/o
            cargados a través de la plataforma, así como ser de nacionalidad mexicana o en su caso de
            la nacionalidad que acredita su documentación y estar en el pleno ejercicio de sus
            derechos civiles y políticos.
          </Typography>
          <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
            Declara el prestador que los servicios que ofrece al usuario en el presente sitio de
            internet, están diseñados para permitir la búsqueda de productos o servicios, así como
            comprar, vender, pagar, contactar vendedores o compradores, cotizar envíos, solo
            relacionados con bienes y servicios del ramo automotriz y que serán sujetos bajo los
            siguientes <b>TÉRMINOS Y CONDICIONES</b>.
          </Typography><br/>
          <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
            Los términos y condiciones son aceptados de forma enunciativa mas no limitativa por lo
            que, de esta forma las prohibiciones que quedarán reguladas dentro del presente
            contrato tal efecto tendrá dicho carácter.
          </Typography>
        </div>

        <div className="aviso_privacidad_contenedores">
          <div className="aviso_privacidad_subtitulos">REGISTRO E INFORMACIÓN PERSONAL</div>
          <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
            Cuando visitas o te registras en esta o cualquiera de nuestras plataformas digitales, que
            sean administradas y/o propiedad del prestador autosxpartespuntocom S. A de C.V.,
            aceptas y acreditas que eres mayor de edad con capacidad legal para contratar. En caso
            de que uses la plataforma en representación de una persona moral, deberás tener
            capacidad legal para contratar en nombre de ella.
          </Typography>
          <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
            El usuario acepta que su información sea administrada de acuerdo al aviso de privacidad
            aceptado que se encuentra anexo a los presentes términos y condiciones, que para tal
            efecto puede volver a consultar a través de la siguiente liga:
          </Typography><br/>
          <Link href="/aviso-de-privacidad" target="_blank" underline="hover">autosxpartes.com/aviso-de-privacidad</Link>
        </div>

        <div className="aviso_privacidad_contenedores">
          <div className="aviso_privacidad_subtitulos">CONDICIONES DE USO</div>
          <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
            El usuario se compromete a dar un uso adecuado a la presente plataforma, por lo que será
            utilizada únicamente con los fines manifestados por el prestador, y que, de ninguna forma
            podrá ser diverso.
          </Typography><br/>
          <div>
            <ul>
              <li>
                <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
                  Queda prohibido que se use la plataforma para alguna actividad ilegal, como
                  venta de auto partes provenientes de autos de procedencia ilícita, producto de
                  robo o cualquier otra modalidad delictiva contemplada por la legislación
                  mexicana aplicable, así como para el comercio de mercancías prohibidas e
                  indebidas, la publicación de contenido sexual o denigrante que afecte los
                  derechos de terceros.
                </Typography>
              </li><br/>
              <li>
                <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
                  Queda prohibida la publicación de contenido que pueda ser delicado o sensible
                  para terceros o menores de edad, tales como sexualidad explicita, violencia o
                  cualquier contenido explicito relacionado con muerte, violencia o sangre.
                </Typography>
              </li><br/>
              <li>
                <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
                  Queda prohibido la publicación de contendió que sea sujeto a cualquiera de las
                  formas de protección de propiedad intelectual y/o derechos de autor mismo que
                  puede ser en video, imágenes, grafico o fonético, la excepción a esta condición es
                  que el usuario que pretenda subir dicho contenido acredite tener la autorización
                  debida que le permita hacerlo.
                </Typography>
              </li><br/>
              <li>
                <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
                  Queda prohibido el lenguaje ofensivo a través de preguntas, imágenes, videos o
                  cualquier otra modalidad que pueda ser subida a la plataforma, así como también
                  se prohíbe el uso de cualquier forma de acoso y/u ofensa a cualquiera de los
                  usuarios o administradores.
                </Typography>
              </li><br/>
              <li>
                <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
                  Queda prohibido cualquier tipo de comentario, pregunta o expresión que afecte la
                  sensibilidad de los usuarios por raza, sexo, preferencia sexual, religión, creencia,
                  etc. Así como en contra de la comunidad LGBTTTIQ+.
                </Typography>
              </li><br/>
              <li>
                <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
                  Queda prohibido la carga de contenido que presente imágenes de menores, y que
                  afecte los derechos de las personas vulnerables.
                </Typography>
              </li><br/>
              <li>
                <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
                  Queda prohibido el uso de documentos de identidad falsificados, alterados o que
                  carezcan de vigencia oficial de acuerdo a las normas y leyes mexicanas.
                </Typography>
              </li><br/>
              <li>
                <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
                  Queda prohibido el registro de dos o más cuentas con la finalidad de engañar,
                  defraudar, propiciar el error o cualquiera que pretenda crear la falsa percepción de
                  la realidad, a excepción de que exista la autorización de la administración de la
                  plataforma o sus representantes legales y para tal caso será únicamente con fines
                  comerciales, o algún otro que sea necesario para el mejoramiento de la atención al
                  cliente y los servicios que se prestan.
                </Typography>
              </li><br/>
              <li>
                <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
                  Queda prohibido reproducir, duplicar, copiar, vender, revender o explotar alguna
                  porción de nuestros servidores digitales o servicios, sin permiso expreso por parte
                  del sitio a través de alguno de sus departamentos, así como queda prohibido el uso
                  de la marca de forma total o parcial en cualquiera de sus modalidades sin previo
                  consentimiento del sitio o cualquiera de sus departamentos.
                </Typography>
              </li>
            </ul>
          </div><br/>
          <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
            El usuario podrá difundir, compartir, promocionar sus productos y servicios fuera del sitio
            con la imagen de autosxpartes.com siempre y cuando dichos productos o servicios se
            encuentren dentro del mismo y cumplan con los términos de usos y condiciones, por lo
            que si dichos productos o servicios se promocionan con el respaldo de la plataforma sin
            estar dentro del mismo será sujeto a la responsabilidad por el uso indebido de la marca,
            así como podrá ser sujeto a cualquier procedimiento contemplado en materia de
            propiedad intelectual y/o civil o moral.
          </Typography>
        </div>

        <div className="aviso_privacidad_contenedores">
          <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
            Es obligación del usuario registrarse en la plataforma para lo que deberá completar todos
            los campos obligatorios, siempre con información verdadera, exacta, completa y
            actualizada, para lo que, a través del presente, manifiesta bajo protesta de decir verdad
            que los datos registrados son verdaderos, reales y exactos.
          </Typography><br/>
          <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
            Acepta el usuario que la cuenta es para su uso y de carácter propio e intransferible, queda
            prohibido venderla o cederla a un tercero, por lo que si dicha cuenta es utilizada por un
            tercero con el consentimiento del usuario titular este mismo se hace responsable por el
            mal uso de su cuenta, así mismo acepta y queda sujeto a las condiciones y penalidades
            que conlleva la aceptación de los términos y condiciones, por lo que la contraseña que se
            genere para el usuario quedará bajo su único resguardo y responsabilidad.
          </Typography><br/>
          <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
            Es obligación del usuario reportar cualquier mal uso de su cuenta personal a la plataforma
            en un plazo no mayor a 24 horas mediante los medios de enlace establecidos dentro de la
            misma, debiendo acreditar ser el propietario y tener el carácter de usuario.
          </Typography><br/>
          <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
            Es obligación del usuario acreditar dicho carácter en caso de que requiera recuperar el
            acceso a su cuenta o solicite hacer alguna modificación en sus datos registrados, para tal
            efecto deberá cumplir con los requisitos formales que le sean solicitados por la
            administración de sitio, y/o cualquier departamento de atención del sitio mismo.
          </Typography><br/>
          <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
            Es obligación del usuario cumplir con los lineamientos establecidos por el sitio para el
            efecto de solicitar una venta /o compra denominada segura o garantizada, mismos que
            serán requeridos con el objetivo de poder resolver cualquier controversia entre
            comprador y vendedor, y que el incumplimiento de dichos lineamientos producirá como
            efecto aceptar la responsabilidad.
          </Typography><br/>
          <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
            El usuario acepta que el sitio pueda transferir información o documentación aportada por
            el mismo si es requerida por las autoridades a través de orden y/u oficio expreso, donde
            se indique dicha solicitud y siempre y cuando sea de una autoridad competente.
          </Typography>
          <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
            El sitio se obliga a mantener de manera confidencial la información del usuario de acuerdo
            al contrato aceptado de uso aviso de privacidad y uso de cookies.
          </Typography><br/>
          <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
            El sitio se obliga a ofrecer el servicio de difusión, promoción, acercamiento a compradores
            en línea, y demás acciones enfocadas a la venta de productos o servicios del ramo
            automotriz, de forma onerosa o gratuita según sea el caso y acuerdo con el vendedor.
          </Typography><br/>
          <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
            El usuario acuerda con el sitio que en caso de que se realice la venta de algún artículo de
            procedencia ilícita será responsable el usuario en calidad de vendedor, para lo cual el sitio
            no se hace responsable y se comunicará a las autoridades correspondientes dicha
            operación, así mismo facilitará los medios y datos para la investigación de dichas
            operaciones y/o comercializaciones.
          </Typography><br/>
          <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
            En caso de compras y ventas a través del sitio por medio de algún motor de cobro-pago, el
            sitio no será responsable por el manejo de datos de acuerdo a los contratos establecidos
            con el banco y/o bancos y/o motores de pago/cobro.
          </Typography><br/>
          <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
            En caso de compras y ventas a través del sitio por medio de algún motor de cobro-pago en
            el que el sitio sea intermediario, este se hará responsable de transferir o hacer llegar los
            recursos producto de la venta al usuario vendedor, para lo cual el usuario vendedor
            acepta que es obligación del sitio realizar las retenciones obligatorias de impuestos, así
            como las comisiones acordadas por dichas transacciones con <b>autosxpartespuntocom S.A.
            de C.V.</b>
          </Typography><br/>
          <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
            El usuario está de acuerdo con actualizar cualquier información, como direcciones de
            correo electrónico, número de tarjeta o fecha de expiración, domicilio, etc., de forma que
            podamos completar las transacciones y servicios necesarios. <b>La información personal que
            nos proporcionas está amparada bajo nuestra política de privacidad de acuerdo al aviso
            de privacidad y uso de cookies.</b>
          </Typography><br/>
          <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
            El usuario acepta que Los precios de nuestros servicios pueden ser modificados en
            cualquier momento, sin previo aviso, para tal efecto las tarifas o comisiones por el uso de
            la plataforma o servicio de <b>autosXpartespuntocom S.A. de C.V.</b> serán incrementadas o
            disminuidas según sea el caso y aparecerán dentro de la cuenta personal de cada usuario.
          </Typography><br/>
          <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
            La plataforma enviará estado de cuenta y comprobante fiscal correspondiente al usuario
            según sea el caso si es que hubiera alguna comisión y/o retención por transacción compra-
            venta de bienes o servicios.
          </Typography><br/>
          <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
            El usuario vendedor ofrecerá de forma personal y directa al usuario comprador sus
            términos de garantía y/o devoluciones sobre artículos nuevos o usados que comercialice a
            través del sitio.
          </Typography><br/>
          <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
            <b>Autosporpartespuntocom S.A. de C.V.</b> no se hace responsable por artículos comprados a
            través del sitio que estén en mal estado, o con defectos de fábrica, así como cualquier
            otra forma que impida o afecte su correcto funcionamiento, para tal efecto el comprador
            deberá informar al vendedor.
          </Typography><br/>
          <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
            <b>Autosporpartespuntocom S.A. de C.V.</b> solo hace responsable por artículos comprados a
            través del sitio que estén en mal estado, o con defectos de fábrica, así como cualquier
            otra forma que impida o afecte su correcto funcionamiento y que sean única y
            exclusivamente propiedad de <b>autosporpartespuntocom S.A. de C.V.</b>
          </Typography><br/>
          <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
            El usuario acepta que en caso de ser vendedor y requiera intermediación de operaciones
            de compra venta por parte de la empresa prestadora <b>autosporpartespuntocom S.A. de
            C.V.</b> en donde algún recurso financiero le sea depositado a través de las cuentas de la
            empresa prestadora del servicio, este accede a que le sean descontados y/o retenidos
            impuestos y comisiones legales y pactados, de igual modo el usuario acepta ser el único
            responsable por el producto o servicio vendido, eximiendo de toda responsabilidad al sitio
            y empresa intermediaria.
          </Typography><br/>
          <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
            El prestador se reserva el derecho de denegar nuestros servicios a cualquier persona, por
            cualquier razón y en cualquier momento, sin tener la obligación de documentar, acreditar
            o informar de manera detallada dicha determinación.
          </Typography><br/>
          <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
            El usuarios acepta que tu contenido, puede ser transmitido en diferentes redes con la
            finalidad de realizar promoción y acercamiento masivo a posibles clientes, y siempre
            cumpliendo con lo establecido en el aviso de privacidad que protege los datos sensibles
            del usuario.
          </Typography><br/>
          <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
            El sitio en calidad de intermediario de las transacciones y/o operaciones de compra-venta
            de artículos podrá ser intermediario y determinar responsabilidades de compradores y
            vendedores para poder resolver controversias de manera efectiva y para tales efectos será
            necesario que compradores y vendedores cumplan las condiciones establecidas por el
            prestador.
          </Typography><br/>
          <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
            El prestador se reserva el derecho de modificar o dejar de ofrecer cualquier servicio, en
            cualquier momento, sin previo aviso si el servicio se está ofreciendo de forma gratuita,
            para el caso de que el servicio que está ofreciendo el prestador sea onerosa, se les
            notificaran las modificaciones a los servicios al usuario con por lo menos 24 horas de
            anticipación.
          </Typography><br/>
          <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
            En caso de que el usuario sea comprador el sitio no garantiza que los productos o servicios
            publicados en esta plataforma cumplan con tus expectativas, es responsabilidad del
            usuario que el monitor de tu computadora o móvil muestre los productos con otros
            colores o rasgos incluyendo dimensiones de los productos.
          </Typography><br/>
          <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
            El sitio no garantiza estar exento de fallos ocasionados por las comunicaciones con los
            servidores o servicios contratados o subcontratados, así como fallos por los dispositivos de
            los usuarios o sus aparatos electrónicos o redes de comunicación.
            En ningún caso nos hacemos responsables, por la suspensión o modificación de un
            servicio.
          </Typography><br/>
          <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
            En caso de no aceptar los presente términos y condiciones o cualquier otro contrato o
            aviso emitidos por el prestador, este se reserva el derecho de limitar el servicio a cualquier
            persona, área geográfica o jurisdicción, así como la posibilidad de usar este derecho en
            cada caso en particular.
          </Typography><br/>
          <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
            El prestador se reserva el derecho de denegar o limitar cualquier servicio que solicite
            pudiendo, bajo el propio criterio, limitar o cancelar servicios. En caso de cancelación se
            notificará al usuario a través de tu dirección de correo electrónico o cualquier dato de
            contacto proporcionados.
          </Typography><br/>
          <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
            Será responsabilidad del usuario mantener sus datos actualizados, así como cualquier
            medio de contacto que sea necesario para notificar, avisar o contactar a cada usuario, sin
            que esto sea una limitante para que el sitio requiera sin previo aviso la actualización de
            dichos datos.
          </Typography><br/>
          <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
            La empresa <b>autosporpartespuntocom S.A. de C.V.</b> o su plataforma no tiene ninguna
            responsabilidad derivada del uso que el usuario le dé a los bienes aquí publicados. El uso
            de los bienes es opcional, la compra de los bienes ofrecidos en esta plataforma es bajo tu
            propio riesgo y discreción. Por lo tanto, el usuario se asegurará de que esta familiarizado
            con las características en que le son ofrecidas, así como con el ramo y utilización de los
            bienes.
          </Typography><br/>
          <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
            El prestador podrá monitorear, editar o eliminar comentarios o publicaciones que, bajo su
            propio criterio, sean de contenido ilegítimo, ofensivo, amenazante, difamatorio,
            pornográfico, obsceno o que viole derechos de terceros en forma específica o general, así
            como por cualquier falta a los términos del presente contrato y el usuario será
            responsable de los comentarios y publicaciones que haga y de su veracidad.
          </Typography><br/>
          <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
            Se autoriza al prestador que los contenidos tales como comentarios, sugerencias, ideas
            creativas, u otros materiales, ya sea en línea, vía correo electrónico o en espacios de
            comentarios. Sin restricción se otorga el derecho para editar, copiar, publicar, distribuir,
            traducir sin ninguna obligación para el prestador ninguna obligación de mantener
            comentarios en confidencial, pagar cualquier clase de compensación.
          </Typography><br/>
          <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
            El usuario acepta que, en caso de ir en contra de alguna de las prohibiciones establecidas
            en el presente, así como de encontrarse en alguno de estos supuestos que son
            enunciativos más lo limitativos, o mal uso de la plataforma, será sancionado con la
            cancelación de su cuenta, así como la imposibilidad de que pueda ser registrado
            nuevamente por si o por interpósita persona, apoderado o representante.
          </Typography><br/>
          <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
            El prestador, así como <b>autosporpartespuntocom S.A. de C.V.</b> actuará conforme a las leyes
            vigentes del territorio nacional, por lo que de ser necesario está facultado para promover
            denuncias o juicios de carácter civil, penal laboral en contra de cualquier persona y/o
            usuario que sea sujeto de alguna responsabilidad, así como puede colaborar con
            autoridades en actos de investigación fiscal, penal o de cualquier índole judicial.
          </Typography><br/>
          <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
            El usuario acepta que cuando falte a las obligaciones contenidas dentro del presente o
            cualquier falta legal que afecte a la plataforma digital y al sitio, este será cancelado de
            forma definitiva, perdiendo todos sus derechos, en caso de encontrarse bajo un servicio
            oneroso la diferencia económica por la temporalidad restante al momento de ser dado de
            baja no será reembolsada.
          </Typography><br/>
          <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
            <b>Autosporpartespuntocom S.A. de C.V.</b> no se hace responsable con el usuario por la
            cancelación y/o terminación del sitio debido a alguna afectación tecnológica, desastre
            natural, invasión, guerra, expropiación o cualquier forma inevitable de terminación.
          </Typography><br/>
          <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
            <b>Autosporpartespuntocom S.A. de C.V.</b> realizará el fomento del comercio en el ramo
            automotriz, así como fomentar el cuidado al medio ambiente, por lo que podrá
            condicionar a los usuarios y/o productos o servicios para que cumplan ciertos
            requerimientos extras.
          </Typography><br/>
          <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
            En caso de que exista contenido en el sitio o en el servicio que contenga errores
            tipográficos, imprecisiones u omisiones que afecten a productos, descripciones,
            información, ofertas, cargos de envío, precio, promociones, tiempos de entrega y
            disponibilidad, nos reservamos el derecho de corregir dichos errores, imprecisiones y
            omisiones en cualquier momento sin previo aviso.
          </Typography><br/>
          <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
            EL usuario acepta todos y cada uno de los términos y condiciones del presente, generando
            su consentimiento, mismo que se ejerce sin vicio alguno y sin que obre error.
            Para efecto de tener actualizado el software de <b>autosxpartes.com</b> se ofrecerán y serán
            necesarias actualizaciones en cualquier momento sin el deber de notificar previamente.
            Los términos y condiciones podrán ser ampliados y/o modificados poniéndolos a la vista
            de los usuarios que los aceptarán.
          </Typography>
        </div>
      </Box>
    </Dialog>
  )
}