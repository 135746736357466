import Modal from 'react-modal';
import { RootState, AppDispatch } from '../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { submodalIds, closeSubmodal } from '../../reducers/modalReducer';
import { Message } from './components/navbar/Message';
import { startDesactivationMessage } from '../../reducers/navbarReducer';
import { useContext } from 'react';
import { SocketContext } from '../../context/SocketContext';
import { socketTypes } from '../../hooks/useSocket';

//enviamos el id del documento principal html 
Modal.setAppElement('#root');

export const SubModal = () => {

    // console.log('Se renderizo Submodal');

    //LEER EL CONTEXT DE SOCKETS PARA NOTIFICARLOS
    const { enviarSocket } = useContext( SocketContext );
   
    //LEER VARIABLES DE REDUX
    const storeDarkMode = (state: RootState ) => state.darkmode;
    const { darkMode } = useSelector(storeDarkMode);
    const storeModal = (state: RootState) => state.modal;
    const { showSubmodal } = useSelector(storeModal);
    const { submodal } = useSelector(storeModal);
    const { styleSubmodal } = useSelector(storeModal);

    //ESCRIBIR EN EL STORE DE REDUX
    const dispatchRedux = useDispatch<AppDispatch>();

    //EVALUAMOS EL TIPO DE MODAL A MOSTRAR
    let componentJSX: JSX.Element = <></>;
    switch (submodal) {
        case submodalIds.Message:
            componentJSX = <Message />
        break;
        default:
            componentJSX =
                <h1 className="modal_main">
                    Sin contenido
                </h1>
    }

    //ONCLOSE DEL MODAL
    const handleClose = async () => {
        //EJECUTAMOS FUNCIONES ANTES DE CERRAR
        switch (submodal) {
            case submodalIds.Message:
                const active = await dispatchRedux<any>(startDesactivationMessage());
                if (active) {
                    //notificar el cambio de estado del mensaje
                    const type: socketTypes = 'Message';
                    enviarSocket(type);
                } 
            break;    
        }
        //cerramos el modal
        dispatchRedux(closeSubmodal());
    }
    
    return (
        <>
            < Modal
                //closeTimeoutMS={200}
                isOpen={ showSubmodal }          
                onRequestClose={ handleClose }
                style={ styleSubmodal }
            > 
                <div className={ 
                (darkMode) 
                    ? 'modal_main modal_dark_theme' 
                    : 'modal_main modal_light_theme'
                }>
                    <div className="modal_title ">
                        <div>
                                <p>{ submodal }</p>
                        </div>
                        <div>
                            <button 
                                type="button" 
                                className={ 
                                    (darkMode) 
                                    ? 'btn-close btn-close-white' 
                                    : 'btn-close'
                                }
                                aria-label="Close"
                                onClick={ handleClose }
                            />    
                        </div>
                    </div>
                    { componentJSX }  
                </div>    
            </Modal>
        </>
    )
}