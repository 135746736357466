import './styles/modals.scss';
import { useContext, useEffect, useState } from 'react';
import { Box, Button, Card, CardMedia, CircularProgress, Dialog, IconButton, Typography, useMediaQuery } from '@mui/material';
import { SalesContext } from './context/SalesContext';
import { Car, closeModalSales, editarPublicacionBandera, openActivarPubModal, openEliminarPubModal, openPausarPubModal, showModalPub } from './reducers/SalesReducer';

import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PauseIcon from '@mui/icons-material/Pause';
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import InfoIcon from '@mui/icons-material/Info';
import SVG from 'react-inlinesvg';

import Slider from "react-slick";
import { EditarPublicaModal } from './modals/EditarPublicaModal';
import { PausarPublicaModal } from './modals/PausarPublicaModal';
import { EliminarPublicaModal } from './modals/EliminarPublicaModal';
import { ActivarPublicaModal } from './modals/ActivarPublicaModal';

import { showModalCar, Publication } from './reducers/SalesReducer';
import { useHistory } from 'react-router-dom';

export const SalesModal = () => {

    const history = useHistory();

    const responsive = useMediaQuery('(max-width:300px)');

    //LEER EL REDUCER LOCAL
    const { salesReducerState, dispatchSales } = useContext(SalesContext); 
    const { modal, publicationInfo } = salesReducerState;

    const settings = {
      dots: true,
      dotsClass: "slick-dots custom-indicator",
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      //variableWidth: true,
      responsive: [
        {
          breakpoint: 1000,
          settings: {
            dots: false
          }
        }
      ]
    };

    //EVALUANDO LA CANTIDAD DE FOTOS DE LA PUBLICACION
    const [imagenes, setImagenes] = useState<string[]>([]);
    useEffect(() => {
      let fotos: string[] = [];
      if (publicationInfo) {
        publicationInfo.fotoPublicacion && fotos.push(publicationInfo.fotoPublicacion);
        publicationInfo.fotoIzquierda && fotos.push(publicationInfo.fotoIzquierda);
        publicationInfo.fotoDerecha && fotos.push(publicationInfo.fotoDerecha);
        publicationInfo.fotoFrontal && fotos.push(publicationInfo.fotoFrontal);
        publicationInfo.fotoPosterior && fotos.push(publicationInfo.fotoPosterior);
        publicationInfo.fotoMotor && fotos.push(publicationInfo.fotoMotor);
        publicationInfo.fotoInterior && fotos.push(publicationInfo.fotoInterior);
        setImagenes(fotos);
      }
    }, [publicationInfo]);

    const SlickArrowLeft = ({ currentSlide, slideCount, ...props }:any) => (
      <SVG
        src={process.env.REACT_APP_ICONSERVER+'dz-angle-left'}
        className={ "slick-prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")}
        aria-disabled={currentSlide === 0 ? true : false}
        {...props}
      />
    );

    const SlickArrowRight = ({ currentSlide, slideCount, ...props }: any) => (
      <SVG
        src={process.env.REACT_APP_ICONSERVER+'dz-angle-right'}
        className={ "slick-next slick-arrow" + (currentSlide === slideCount - 1 ? "slick-disabled" : "" )} 
        aria-disabled={currentSlide === slideCount - 1 ? true : false}
        {...props}
      />
    );

    const handleNewCar = () => {
      // dispatchSales(closeModalNewPub())
      const pub : Publication = { id: 'a' }
      dispatchSales(showModalCar(pub))
      dispatchSales(editarPublicacionBandera())
    }
    const handleNewPub = () => {
      // dispatchSales(closeModalNewPub())
      const car : Car = { id: 'a' };
      dispatchSales(showModalPub(car));
      dispatchSales(editarPublicacionBandera())
    }

    const handleHrefPublicacion = (publicacion: any) => {
        history.push(`/findUser/publication/${publicacion}`);
    }

    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    });

    return (
      <div>
        <Dialog
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          open={modal}
          //onClose={()=>{dispatchSales(closeModalSales())}}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                maxHeight: '95vh',
                maxWidth: '90vw',
              },
            },
          }}
        >
          <Box sx={{ backgroundColor: "black", color: "white", p: 3, display: "flex" }}>
            <Typography variant={(responsive ? 'h6' : 'h5')}>Detalles de tu publicación</Typography>
            <IconButton
              aria-label="close"
              onClick={()=>{dispatchSales(closeModalSales())}}
              sx={{
                position: 'absolute',
                right: 18
              }}
            >
              <SVG src={process.env.REACT_APP_ICONSERVER+'dz-error'} width={20} fill="white" />
            </IconButton>
          </Box>
          <div className="sale_modal_container">
            { 
              publicationInfo?.estado === "Rechazada" &&
              <div className="publicacion_rechazada_format">
                <div className="publicacion_rechazada_icono">
                  <InfoIcon sx={{ fontSize: 40 }}/>
                </div>
                <div className="publicacion_rechazada_mensaje">
                  <Box>
                    <Typography variant='h6'><b>Esta publicación fue rechazada</b></Typography>
                    <b>Motivo:</b> {publicationInfo?.motivoRechazo}
                  </Box>
                </div>
                <div className="publicacion_rechazada_boton">
                  <Button
                    variant="contained" fullWidth startIcon={<EditIcon />} sx={{ textTransform: 'none' }}
                    onClick={ handleNewCar }
                  >
                    Editar
                  </Button>
                </div>
              </div>
            }

            { publicationInfo ?
              <div className="sale_modal_format">

                <div className="publicacion_info_imagenes">
                  <div className="publicacion_info_images_format">
                    {
                      imagenes.length === 0 ?
                      <Box sx={{ height: "500px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <CircularProgress size={30} sx={{ mr: 1 }} /><Typography variant="h5">Cargando</Typography>
                      </Box>
                      :
                      <Slider
                        {...settings}
                        customPaging = {(i) => {
                          return <div>
                            <img src={process.env.REACT_APP_PHOTOSERVER+imagenes[i]} alt="" className="slider-dots" />
                          </div>
                        }}
                        prevArrow={ <SlickArrowLeft /> }
                        nextArrow={ <SlickArrowRight /> }
                        className="publicacion_info_slider"
                      >
                        {
                          imagenes.map((imagen, i) => (
                            <div key={i}>
                              <Card variant="outlined" sx={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}>
                                <Box>
                                  <CardMedia
                                    component="img"
                                    image={process.env.REACT_APP_PHOTOSERVER+imagen}
                                    alt=""
                                    className="publicacion_images_carrusel"
                                  />
                                </Box>
                              </Card>
                            </div>
                          ))
                        }
                      </Slider>
                    }
                  </div>
                </div>

                <div className="sales_modal_titulo_principal">
                  <div style={{ width: "100%" }}>
                    <Typography sx={{ fontSize: 14, mb: 3 }} color="text.secondary">
                      Autopartes
                    </Typography>
                    <Box sx={{ display: "flex", mb: 1 }}>
                      <div className="publicacion_info_container_icon">
                        <SVG src={publicationInfo.icon ? process.env.REACT_APP_ICONSERVER+publicationInfo.icon : '' } fill="currentColor" className="publicacion_info_icon"/>
                      </div>
                      <p className="sales_modal_titulo_style"><b>{ publicationInfo?.marca } { publicationInfo?.modelo } { publicationInfo?.anio }</b></p>
                    </Box>
                    {
                      publicationInfo.precio ?
                      <Box sx={{ mb: 2 }}>
                        <Typography variant='h4'><b>Precio:</b> {formatter.format(parseFloat(publicationInfo.precio))}</Typography>
                      </Box>
                      :
                      null
                    }
                    <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        { ( function() {
                          switch ( publicationInfo?.estado ) {
                            case 'Activa':
                              return <div style={{ width: "25px", height: "25px", borderRadius: "50%", background: "#00c853" }}/>
                            case 'Pausada':
                              return <div style={{ width: "25px", height: "25px", borderRadius: "50%", border: "3px solid black" }}/>
                            case 'Pendiente':
                              return <div style={{ width: "25px", height: "25px", borderRadius: "50%", background: "#fca311" }}/>
                            case 'Rechazada':
                              return <div style={{ width: "25px", height: "25px", borderRadius: "50%", background: "red" }}/>
                            case 'Incompleta':
                              return <div style={{ width: "25px", height: "25px", borderRadius: "50%", border: "3px solid red" }}/>
                            }
                          })()
                        }
                      </Box>
                      <Box sx={{ ml: 2 }}>
                        <p><b>Publicacion:</b> { publicationInfo?.estado }</p>
                      </Box>
                    </Box>
                        
                    {
                      publicationInfo?.estado === 'Incompleta' || publicationInfo?.estado === 'Pendiente' || publicationInfo?.estado === 'Rechazada' ?
                      null
                      :
                      <Button
                        variant="contained"
                        color="success"
                        fullWidth startIcon={<ZoomOutMapIcon />}
                        sx={{ textTransform: 'none', mb: 3 }}
                        onClick={() => handleHrefPublicacion(publicationInfo.idPub)}
                      >
                        Ver Publicación
                      </Button>
                    }
                    
                    {
                      publicationInfo?.estado === 'Incompleta' ? 
                      null
                      :
                      <Button
                        variant="contained" fullWidth startIcon={<EditIcon />} sx={{ textTransform: 'none', mb: 3 }}
                        onClick={ publicationInfo.tipoPublicacion === "Auto" ? handleNewCar : handleNewPub}
                      >
                        Editar
                      </Button>
                    }

                    { ( function() {
                      switch ( publicationInfo?.estado ) {
                        case 'Activa':
                          return null
                        case 'Incompleta':
                          return null
                        case 'Pendiente':
                          return null
                        case 'Pausada':
                          return <Button
                                variant="contained" fullWidth color="warning"
                                startIcon={<ReplayCircleFilledIcon/>} sx={{ textTransform: 'none', mb: 3 }}
                                onClick={()=>{dispatchSales(openActivarPubModal())}}
                              >
                                Activar
                              </Button>
                        }
                      })()
                    }
                  
                    { publicationInfo?.estado ==='Activa' &&
                      <Button
                        variant="contained" fullWidth color="warning"
                        startIcon={<PauseIcon/>} sx={{ textTransform: 'none', mb: 3 }}
                        onClick={()=>{dispatchSales(openPausarPubModal())}}
                      >
                        Pausar
                      </Button>
                    }
                    
                    <Button
                      variant="outlined" fullWidth color="error"
                      startIcon={<DeleteForeverIcon/>} sx={{ textTransform: 'none' }}
                      onClick={()=>{dispatchSales(openEliminarPubModal())}}
                    >
                      Eliminar
                    </Button>

                  </div>
                </div>

                <div className="sales_modal_caracteristicas">
                  <div className="publicacion_info_subtitulo">
                    Características principales
                  </div>
                  <div className="publicacion_info_caracteristicas_format">
                    <div>Marca:</div>
                    <div>{publicationInfo?.marca}</div>
                    <div>Modelo:</div>
                    <div>{publicationInfo?.modelo}</div>
                    <div>Año:</div>
                    <div>{publicationInfo?.anio}</div>
                    <div>Compatibilidad:</div>
                    <div>{publicationInfo?.anioInicial ? `Del ${publicationInfo.anioInicial} al ${publicationInfo.anioFinal}` : 'Sin dato'}</div>
                    <div>Transmisión:</div>
                    <div>{publicationInfo?.transmision ? publicationInfo.transmision : 'Sin dato'}</div>
                    <div>Motor:</div>
                    <div>{publicationInfo?.motor ? publicationInfo.motor : 'Sin dato'}</div>
                    <div>Version:</div>
                    <div>{publicationInfo?.version ? publicationInfo.version : 'Sin dato'}</div>
                  </div>
                </div>

                <div className="sales_modal_descripcion">
                  <div className="publicacion_info_subtitulo">
                    Descripción
                  </div>
                  <div className="publicacion_info_descripcion_size">
                    {publicationInfo?.descripcion ? publicationInfo.descripcion : 'El vendedor no incluyo una descripción'}
                  </div>
                </div>
              </div>
              :
              <Box sx={{ width: "900px", height: "500px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <CircularProgress size={30} sx={{ mr: 1 }} /><Typography variant="h5">Cargando</Typography>
              </Box>
            }
          </div>
        </Dialog>

        <EditarPublicaModal/>
        <ActivarPublicaModal/>
        <PausarPublicaModal/>
        <EliminarPublicaModal/>
      </div>
    );
}