
import { fetchSinToken } from '../../../helpers/fetch';

//DEFINIMOS LA ESTRUCTURA DEL REDUCER
export type RegisterState = {
    emailSend: boolean,
    emailSendMsg: string | null,
    emailValid: boolean
    emailValidMsg: string | null,
    smsSend: boolean,
    smsSendMsg: string | null,
    smsValid: boolean,
    smsValidMsg: string | null,
    whatsAppSending: boolean,
    userCreate: boolean,
    userCreateMsg: string | null,
    telefono: string | null,
    terminosCondiciones: boolean,
    avisoPrivacidad: boolean
};

//DEFINIMONS EL VALOR INICIAL
export const initialRegisterState: RegisterState = {
    emailSend: false,
    emailSendMsg: null,
    emailValid: false,
    emailValidMsg: null,
    smsSend: false,
    smsSendMsg: null,
    smsValid: false,
    smsValidMsg: null,
    whatsAppSending: false,
    userCreate: false,
    userCreateMsg: null,
    telefono: null,
    terminosCondiciones: false,
    avisoPrivacidad: false
};

//DEFINICION LOS TYPES DEL REDUCER
type SendEmailPayload = { email: string, dispatch: React.Dispatch<ReducerActions> };
type VerifyEmailPayload = { email: string, codigo: string, dispatch: React.Dispatch<ReducerActions> };
type SendSmsPayload = { email: string, telefono: string, dispatch: React.Dispatch<ReducerActions> };

type SendWhatsAppCodePayload = { parametro: string, dispatch: React.Dispatch<ReducerActions> };

type VerifySmsPayload = { email: string, telefono: number, codigo: string, dispatch: React.Dispatch<ReducerActions> };
type SendCreateUserPayload = { email: string, nombre: string, apellidos: string, telefono: number, password: string, dispatch: React.Dispatch<ReducerActions> };

type ChangePasswordPayload = { token :string, password: string, dispatch: React.Dispatch<ReducerActions> };

//DEFINIMOS LOS ACTIONS DEL REDUCER
type EmailPayload = { emailSend: boolean, emailSendMsg: string };
type EmailSendPayload = { emailValid: boolean, emailValidMsg: string };
type SmsPayload = { smsSend: boolean, smsSendMsg: string };
type SmsSendPayload = { smsValid: boolean, smsValidMsg: string };
type CreateUserPayload = { userCreate: boolean, userCreateMsg: string };
type ReducerActions = { type: 'email', payload: EmailPayload } | 
                    { type: 'emailSend', payload: EmailSendPayload } |
                    { type: 'sms', payload: SmsPayload } |
                    { type: 'smsSend', payload: SmsSendPayload } |
                    { type: 'createUser', payload: CreateUserPayload } |

                    { type: 'WhatsAppCodeSend' } |

                    { type: 'AbrirTerminosCondiciones' } |
                    { type: 'CerrarTerminosCondiciones' } |

                    { type: 'AbrirAvisoPrivacidad' } |
                    { type: 'CerrarAvisoPrivacidad' } ;

//DEFINIMOS EL REDUCER
export const registerReducer = (state: RegisterState = initialRegisterState, action: ReducerActions) : RegisterState => {
    switch (action.type) {
        // case 'cleanChecks' : return { ...state, rowsList: state.rowsList.map ((row,index) => (index>action.payload.rowsVisible) ? {...row, check: false} : {...row}) } 
        case 'email':
            return {
                ...state,
                ...action.payload
            }
        case 'emailSend':
            return {
                ...state,
                ...action.payload
            }
        case 'sms':
            return {
                ...state,
                ...action.payload
            }
        case 'smsSend':
            return {
                ...state,
                ...action.payload
            }
        case 'createUser':
            return {
                ...state,
                ...action.payload
            }

        case 'AbrirTerminosCondiciones':
            return {
                ...state,
                terminosCondiciones: true
            } 
        case 'CerrarTerminosCondiciones':
            return {
                ...state,
                terminosCondiciones: false
            } 
        case 'AbrirAvisoPrivacidad':
            return {
                ...state,
                avisoPrivacidad: true
            } 
        case 'CerrarAvisoPrivacidad':
            return {
                ...state,
                avisoPrivacidad: false
            }
        
        case 'WhatsAppCodeSend':
            return {
                ...state,
                whatsAppSending: true
            }
        default:
            return state;
    }
};

//DEFINICION DE LAS ACCIONES

//ENVIO DE EMAIL
export const startSendEmail = async (sendEmailData: SendEmailPayload) => {

    const {email, dispatch} = sendEmailData;
    
    dispatch (clearEmailMessage());
    const body = await fetchSinToken('auth/sendEmail', { email }, 'POST');
    if (body.ok){
        dispatch (sendEmail({
            emailSend: true,
            emailSendMsg: '',
        }));
    } else {
        dispatch (sendEmail({
            emailSend: false, 
            emailSendMsg: body.msg
        }));
    }
}
const sendEmail = (emailData: EmailPayload): ReducerActions  => {
    return {
        type: 'email',
        payload: emailData
    }    
}
const clearEmailMessage = (): ReducerActions  => {
    return {
        type: 'email',
        payload: {
            emailSend: false,
            emailSendMsg: ''
        }
    }    
}

//VERIFICANDO CODIGO DE EMAIL
export const startVerifyEmail = async (verifyEmailData: VerifyEmailPayload) => {
    
    const {email, codigo, dispatch} = verifyEmailData;

    dispatch(clearEmailSendMessage());
    const body = await fetchSinToken('auth/validateEmail', { email, codigo }, 'POST');
    if (body.ok){
        dispatch(verifyEmail({
            emailValid: true,
            emailValidMsg: ''
        }));
    } else {
        dispatch(verifyEmail({
            emailValid: false,
            emailValidMsg: body.msg
        }));
    }
    
}
const verifyEmail = (emailData: EmailSendPayload): ReducerActions  => {
    return {
        type: 'emailSend',
        payload: emailData
    }    
}
const clearEmailSendMessage = (): ReducerActions  => {
    return {
        type: 'emailSend',
        payload: {
            emailValid: false,
            emailValidMsg: ''
        }
    }    
}
//ENVIO DE SMS
export const startSendSms = async (sendSmsData: SendSmsPayload) => {
   
    const {email, telefono, dispatch} = sendSmsData;

    dispatch(clearSmsMessage());
    const body = await fetchSinToken('auth/sendWhatsapp', { email, telefono }, 'POST');
    //console.log(body);
    if (body.ok){
        dispatch(sendSms({
            smsSend: true,
            smsSendMsg: '',
        }));
        /*setTimeout(() => {
            dispatch(whatsAppCode());
          }, 10000);*/
    } else {
        dispatch(sendSms({
            smsSend: false,
            smsSendMsg: body.msg
        }));
    }        
}
const sendSms = (smsData: SmsPayload): ReducerActions  => {
    return {
        type: 'sms',
        payload: smsData
    }    
}
const clearSmsMessage = (): ReducerActions  => {
    return {
        type: 'sms',
        payload: {
            smsSend: false,
            smsSendMsg: ''
        }
    }    
}

//ENVIO DE EMAIL
export const startSendWhatsAppCode = async (sendWhatsAppCodeData: SendWhatsAppCodePayload) => {

    const {parametro, dispatch} = sendWhatsAppCodeData;
    
    dispatch (clearEmailMessage());
    const body = await fetchSinToken(`test/pruebaOk`, { 
        parametro: parametro
    }, 'POST');

    //console.log(body)

    if (body.ok){
        dispatch(verifySms({
            smsValid: false,
            smsValidMsg: body.msg
        }));
    } else {
        dispatch(verifySms({
            smsValid: false,
            smsValidMsg: body.msg
        }));
    }
}
/*const whatsAppCode = (): ReducerActions  => {
    return {
        type: 'WhatsAppCodeSend'
    }    
}*/

//VERIFICANDO CODIGO DE SMS
export const startVerifySms = async (verifySmsData: VerifySmsPayload) => {

    const {email, telefono, codigo, dispatch} = verifySmsData;

    dispatch(clearSmsSendMessage());
    const body = await fetchSinToken('auth/validatePhone', {email, telefono, codigo}, 'POST');
    if (body.ok){
        dispatch(verifySms({
            smsValid: true,
            smsValidMsg: ''
        }));
    } else {
        dispatch(verifySms({
            smsValid: false,
            smsValidMsg: body.msg
        }));
    }        
    
}
const verifySms = (smsData: SmsSendPayload): ReducerActions  => {
    return {
        type: 'smsSend',
        payload: smsData
    }    
}
const clearSmsSendMessage = (): ReducerActions  => {
    return {
        type: 'smsSend',
        payload: {
            smsValid: false,
            smsValidMsg: ''
        }
    }    
}
//CREANDO USARIO
export const startCreateUser = async (sendSmsData: SendCreateUserPayload) => {
   
    const {email, nombre, apellidos, telefono, password, dispatch} = sendSmsData;

    dispatch(clearCreateUserMessage());
    const body = await fetchSinToken('auth/createUser', { email, telefono, nombre, apellidos, password }, 'POST');
    if (body.ok){
        dispatch(sendCreateUser({
            userCreate: true,
            userCreateMsg: '',
        }));
    } else {
        dispatch(sendCreateUser({
            userCreate: false,
            userCreateMsg: body.msg
        }));
    }        
    
}
const sendCreateUser = (userData: CreateUserPayload): ReducerActions  => {
    return {
        type: 'createUser',
        payload: userData
    }    
}
const clearCreateUserMessage = (): ReducerActions  => {
    return {
        type: 'createUser',
        payload: {
            userCreate: false,
            userCreateMsg: ''
        }
    }    
}

export const changePasswordUser = async (changePasswordData: ChangePasswordPayload) => {
   
    const {token, password, dispatch} = changePasswordData;

    dispatch(clearCreateUserMessage());
    const body = await fetchSinToken('auth/changePassword', { token, password }, 'POST');
    if (body.ok){
        dispatch(sendCreateUser({
            userCreate: true,
            userCreateMsg: 'Contraseña actualizada correctamente',
        }));
        
    } else {
        dispatch(sendCreateUser({
            userCreate: false,
            userCreateMsg: body.msg
        }));
    }        
    
}

export const startPasswordChange = async (sendEmailData: SendEmailPayload) => {

    const {email, dispatch} = sendEmailData;
    
    dispatch (clearEmailMessage());
    const body = await fetchSinToken('auth/generateChangePassword', { email }, 'POST');
    //console.log(body);
    if (body.ok){
        dispatch (sendEmail({
            emailSend: true,
            emailSendMsg: body.msg,
        }));
    } else {
        dispatch (sendEmail({
            emailSend: false, 
            emailSendMsg: body.msg
        }));
    }
}

export const startVerifyCodeCHangePassword = async (verifySmsData: VerifySmsPayload) => {

    const {email, codigo, dispatch} = verifySmsData;

    dispatch(clearSmsSendMessage());
    //console.log( email , codigo)
    const body = await fetchSinToken('auth/validateChangePassword', {email, codigo}, 'POST');
    if (body.ok){
        dispatch(verifySms({
            smsValid: true,
            smsValidMsg: ''
        }));
    } else {
        dispatch(verifySms({
            smsValid: false,
            smsValidMsg: body.msg
        }));
    }        
    
}

export const abrirTerminosCondicionesModal = (): ReducerActions => {
    return {
        type: 'AbrirTerminosCondiciones',
    }
}
export const cerrarTerminosCondicionesModal = (): ReducerActions => {
    return {
        type: 'CerrarTerminosCondiciones',
    }
}
export const abrirAvisoPrivacidadModal = (): ReducerActions => {
    return {
        type: 'AbrirAvisoPrivacidad'
    }
}
export const cerrarAvisoPrivacidadModal = (): ReducerActions => {
    return {
        type: 'CerrarAvisoPrivacidad'
    }
}