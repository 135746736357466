// COMPONENETE PARA EL NAVBAR PRINCIAPL DE LA PAGINA

import React from 'react';
import { AppBar, Badge, Box, Button, Container, IconButton, Menu, MenuItem, Toolbar, Tooltip, Typography, ListItemIcon, Drawer, Divider, Card, useMediaQuery } from '@mui/material';
import { RootState, AppDispatch } from './store/store';
import { useSelector, useDispatch } from 'react-redux';
import { startLogout } from './reducers/authReducer';
import { useState, useEffect } from 'react';

import SVG from 'react-inlinesvg';
import { Link, useHistory } from 'react-router-dom';
import { useSocket } from './hooks/useSocket';
import { SocketContext } from './context/SocketContext';
import { startDesactiveNotifications } from './reducers/navbarReducer';
import dayjs from 'dayjs';
//import { MenuContext } from './components/principalUsers/components/misPreguntas/context/QuestionsContext';


export const AppNavbar = () => {

  const history = useHistory();

  const responsive = useMediaQuery('(max-width:600px)');
  const responsive2 = useMediaQuery('(max-width:300px)');

  //HACER CONEXION DE SOCKET
  const serverURL = process.env.REACT_APP_SOCKET_SERVER|| '';
  //console.log(process.env.REACT_APP_SOCKET_SERVER); 
  const { socket, online, conectarSocket, desconectarSocket, enviarSocket } = useSocket(serverURL);

  const [hrefIcon, setHrefIcon] = useState('/');

  //LEER INFORMACION DE REDUX
  const storeAuth = (state: RootState ) => state.auth;
  const { login, rol, checking, name } = useSelector(storeAuth);
  const storeNavbar = (state: RootState) => state.navbar;
  const { notification, notifications } = useSelector(storeNavbar);
  //ESCRIBIR INFORMACION EN REDUX
  const dispatchRedux = useDispatch<AppDispatch>();

  //REVISAR LOGIN PARA CONECTAR SOCKET
  useEffect(() => {
    if (login) {
      if (!online) {
        //console.log('Conectar Socket');
        conectarSocket();
      }
    } else {
      if (online) {
        //console.log('Desconectar Socket');
        desconectarSocket();
      }
    } 
  }, [login, online, conectarSocket])  

  //FUNCION PARA HACER LOGOUT
  const handleLogout = async () => {
    setHrefIcon('/');
    handleCloseUserMenu();
    dispatchRedux<any>(startLogout());
  }

  /*const handlePasswordChange= () => {
    setHrefIcon('/passwordChange');
    handleCloseUserMenu();
  }*/

  /*const handleNotifications = async () => {
    handleCloseUserMenu();
    // await dispatchRedux<any>(startDesactiveNotifications());
    // //notificar el cambio de estado del mensaje
    // const type: socketTypes = 'Notification';
    // enviarSocket(type);
    // console.log('Notificando cambio')
  }*/

    //MENUS DE USUARIOS
    type menuItem = {
      key: string,
      title: string,
      href: string,
      icon: string,
    };
    const menuInvitado : menuItem[] = [{key: 'm1', title: 'Buscar', href: '/find', icon: 'dz-search'},
                                       {key: 'm2', title: 'Nosotros', href: '/us', icon: 'dz-paperclip'},
                                       {key: 'm3', title: 'Contacto', href: '/contact', icon: 'dz-send'}];
    const menuUsuario : menuItem[] = [{key: 'm1', title: 'Mi cuenta', href: '/account', icon: 'dz-user-2'},
                                      {key: 'm2', title: 'Buscar', href: '/findUser', icon: 'dz-search'},
                                      /*{key: 'm3', title: 'Compras', href: '/shopping', icon: 'dz-shopping-cart'},*/
                                      {key: 'm3', title: 'Ventas', href: '/sales/1', icon: 'dz-dollar'},
                                      {key: 'm4', title: 'Preguntas', href: '/questions/1', icon: 'dz-question'},
                                      {key: 'm5', title: 'Contacto', href: '/contactUser', icon: 'dz-send'}];

    // COMPONENTE DE MATERIAL UI
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    
    /*const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElNav(event.currentTarget);
    };*/
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElUser(event.currentTarget);
    };
    
    const handleCloseNavMenu = () => {
      setAnchorElNav(null);
    };
    
    const handleCloseUserMenu = () => {
      setAnchorElUser(null);
    };

    const [mobileOpen, setMobileOpen] = useState(false);

    const [notificationsOpen, setNotificationsOpen] = useState(false);

    const handleDrawerToggle = () => {
      setMobileOpen(!mobileOpen);
    };

    const handleOpenNotifications = async () => {
      setNotificationsOpen(!notificationsOpen);
      handleCloseUserMenu();
      await dispatchRedux<any>(startDesactiveNotifications());
    };

    //LEER EL CONTEXT PARA CAMBIAR EL COMPONENTE DEL MENU
    //const { setIndexComponent } = useContext( MenuContext );

    const handleHrefNotification = (flag: any) => {
      if ( flag === 'Publicacion' ) {
        history.push('/sales/1');
        setNotificationsOpen(false);
      } else {
        if ( flag === 'Respuesta' ) {
          history.push('/questions/1');
          setNotificationsOpen(false);
        } else {
          history.push('/response');
          setNotificationsOpen(false);
        }
      }
    }

    return (
      <SocketContext.Provider value={{ socket, online, desconectarSocket, enviarSocket }}>
      <AppBar component="nav" color="inherit">
        <Container maxWidth="xl">
          <div className='app_header_navbar'>
            <Toolbar disableGutters>
              <Typography
                variant="h6"
                noWrap
                component="a"
                href="/"
                sx={{
                  mr: 2,
                  display: { xs: 'none', md: 'flex' },
                  fontFamily: 'monospace',
                  fontWeight: 700,
                  letterSpacing: '.3rem',
                  color: 'inherit',
                  textDecoration: 'none',
                }}
              >
                <Tooltip title="Pagina Principal">
                  <svg className='app_header_navbar_logo' viewBox="0 0 1400 330">
                    <path className='app_header_navbar_logo_letra_x' d="M787.5 68 613.4 241.4a71.024 71.024 0 0 1-50 20.6h-41.9L694.8 88.8C708.1 75.5 726.1 68 744.9 68h42.6z"/>
                    <path d="m640.2 114.7-26-26C601 75.5 582.9 68 564.1 68h-42.6l82.9 82.5 35.8-35.8zM668.8 214.7l26.8 26.7c13.3 13.2 31.3 20.6 50 20.6h41.9l-82.9-82.9-35.8 35.6z"/>
                    <g>
                      <path d="M101.3 130.8H121c.4.4 4.5 7.8 12.2 22.4 15.6 29.3 23.8 44.6 24.4 45.9v.1h-22.8c-.5-.6-7.6-13.6-21.1-39.1-1.7-3.1-2.5-4.6-2.6-4.6-15.2 28.5-23 43.1-23.5 43.7H64.8v-.3c23.4-43.9 35.6-66.6 36.5-68.1zM163.7 130.8h19.2c.2 0 .3.1.3.3v39.8c0 5.7 2.3 9.6 6.9 11.5.9.4 2.3.6 4 .6H218c5 0 8.4-2.6 10.2-7.8.3-1.3.4-2.7.4-4.4v-39.7c0-.2.1-.3.3-.3h19.3c.2 0 .3.1.3.3v49.6c0 6.6-3.3 11.9-10 16.1-3.1 1.6-6.2 2.4-9.3 2.4h-47c-5.6 0-10.7-2.6-15.1-7.8-2.5-3.4-3.8-6.9-3.8-10.7v-49.6c.1-.2.2-.3.4-.3zM254.7 130.8h84.6c.2 0 .3.1.3.3v15.6c0 .2-.1.3-.3.3H307v51.8c0 .2-.1.3-.3.3h-19.3c-.2 0-.3-.1-.3-.3V147h-32.4c-.2 0-.3-.1-.3-.3v-15.6c0-.2.1-.3.3-.3zM364.5 130.8h46.1c6.9 0 12.6 3 16.9 8.9 2 3.1 3.1 6.5 3.1 9.9v30.9c0 6.9-3.5 12.4-10.6 16.4-3 1.5-6.1 2.3-9.4 2.3h-45.5c-6.7 0-12.4-3-16.8-9.1-1.9-3-2.9-6.2-2.9-9.4v-31.2c0-7.3 3.9-12.9 11.7-17 3-1.1 5.5-1.7 7.4-1.7zm.7 27.4v13.5c0 5.5 2.6 9.2 7.8 11 .7.2 1.5.3 2.3.3h25.4c4.5 0 7.7-2.7 9.5-8 .3-.8.4-1.8.4-3.1v-13c0-5.8-2.4-9.7-7.3-11.7-1.4-.2-2.7-.3-3.8-.3h-24.3c-4.7 0-7.9 2.6-9.7 7.9-.2 1.2-.3 2.3-.3 3.4zM455.6 130.8h65.6c.2 0 .3.1.3.3v15.6c0 .2-.1.3-.3.3h-60.1c-3.4.5-5 2.3-5 5.4 0 2.1 1 3.7 3.1 4.7.6.3 1.3.4 2.2.4h40.8c5.7 0 10.8 2.5 15.3 7.5 2.7 3.4 4 7.1 4 11v4.7c0 6.7-3.5 12.1-10.6 16.2-2.9 1.4-5.9 2.2-9 2.2h-65.3c-.2 0-.3-.1-.3-.3v-15.6c0-.2.1-.3.3-.3h59.8c2.4 0 4.1-1.2 5-3.6.2-1 .2-1.6.2-1.8 0-2.1-1-3.7-3-4.8-.6-.3-1.4-.4-2.3-.4h-40.2c-6.6 0-12.2-2.8-16.6-8.5-2.1-3.1-3.2-6.4-3.2-9.8v-4.7c0-6.2 3.1-11.4 9.4-15.5 3.6-1.8 6.9-2.8 9.9-3z"/>
                    </g>
                    <g>
                      <path d="M787.8 130.8H853c6.8 0 12.4 3.1 16.9 9.2 1.8 3.2 2.8 6.3 2.8 9.2v12.7c0 6.2-3 11.4-9 15.6-3.4 2-6.8 2.9-10.2 2.9h-46.2v18.5c0 .2-.1.3-.3.3h-19.2c-.2 0-.3-.1-.3-.3v-67.8c0-.2.1-.3.3-.3zm19.5 16.3v17.1h37.6c4 0 6.6-1.9 7.6-5.8.2-.9.2-1.8.2-2.6 0-4.1-1.6-6.9-4.7-8.2-1-.3-1.9-.4-2.9-.4h-37.8zM914.9 130.8h19.7c.4.4 4.5 7.8 12.2 22.4 15.6 29.3 23.8 44.6 24.4 45.9v.1h-22.8c-.5-.6-7.6-13.6-21.1-39.1-1.7-3.1-2.5-4.6-2.6-4.6-15.2 28.5-23 43.1-23.5 43.7h-22.9v-.3c23.6-43.9 35.8-66.6 36.6-68.1zM977.4 130.8h65.3c5.9 0 11.1 2.5 15.5 7.5 2.7 3.4 4 7.2 4 11.2v12.4c0 6.4-3.2 11.7-9.6 15.9l-2.5 1.2 12.1 20.1-.3.1h-23.5c-.6-.7-4.4-7-11.5-18.8h-30.1v18.5c0 .2-.1.3-.3.3h-19.2c-.2 0-.3-.1-.3-.3v-67.8c.1-.2.2-.3.4-.3zm19.5 16.3v17.1h37.6c4 0 6.6-1.9 7.6-5.8.2-.9.2-1.8.2-2.6 0-4.1-1.6-6.9-4.7-8.2-1-.3-1.9-.4-2.9-.4h-37.8zM1068.3 130.8h84.6c.2 0 .3.1.3.3v15.6c0 .2-.1.3-.3.3h-32.3v51.8c0 .2-.1.3-.3.3H1101c-.2 0-.3-.1-.3-.3V147h-32.4c-.2 0-.3-.1-.3-.3v-15.6c.1-.2.2-.3.3-.3zM1159.3 130.8h84.6c.2 0 .3.1.3.3v15.6c0 .2-.1.3-.3.3h-84.6c-.2 0-.3-.1-.3-.3v-15.6c.1-.2.2-.3.3-.3zm0 26.8h84.6c.2 0 .3.1.3.3v14.2c0 .2-.1.3-.3.3h-84.6c-.2 0-.3-.1-.3-.3v-14.2c.1-.2.2-.3.3-.3zm0 25.4h84.6c.2 0 .3.1.3.3v15.6c0 .2-.1.3-.3.3h-84.6c-.2 0-.3-.1-.3-.3v-15.6c.1-.2.2-.3.3-.3zM1269.3 130.8h65.6c.2 0 .3.1.3.3v15.6c0 .2-.1.3-.3.3h-60.1c-3.4.5-5 2.3-5 5.4 0 2.1 1 3.7 3.1 4.7.6.3 1.3.4 2.2.4h40.8c5.7 0 10.8 2.5 15.3 7.5 2.7 3.4 4 7.1 4 11v4.7c0 6.7-3.5 12.1-10.6 16.2-2.9 1.4-5.9 2.2-9 2.2h-65.3c-.2 0-.3-.1-.3-.3v-15.6c0-.2.1-.3.3-.3h59.8c2.4 0 4.1-1.2 5-3.6.2-1 .2-1.6.2-1.8 0-2.1-1-3.7-3-4.8-.6-.3-1.4-.4-2.3-.4h-40.2c-6.6 0-12.2-2.8-16.6-8.5-2.1-3.1-3.2-6.4-3.2-9.8v-4.7c0-6.2 3.1-11.4 9.4-15.5 3.6-1.8 6.9-2.8 9.9-3z"/>
                    </g>
                  </svg>
                </Tooltip>
              </Typography>

              <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  //onClick={handleOpenNavMenu}
                  onClick={handleDrawerToggle}
                  color="inherit"
                  sx={{ width: "60px" }}
                >
                  <SVG src={process.env.REACT_APP_ICONSERVER+'dz-menu'} width={30}/>
                </IconButton>

                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: 'block' },
                  }}
                >
                  <Drawer
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    sx={{
                      '& .MuiDrawer-paper': { width: (responsive2 ? 200 : 250) },
                    }}
                  >
                    <Typography sx={{margin: 2}} variant="h6">Menú Principal</Typography>                           
                    { checking ? <div></div>
                      : ( function() {
                        switch (rol) {
                          case 'COMPRADOR':
                          case 'VENDEDOR': 
                            return menuUsuario.map((item) => (
                            <div key={item.key}>                              
                              <MenuItem 
                                component={Link} to={item.href}
                                onClick={() => (setMobileOpen(false))}
                                sx={{
                                  height: "45px",
                                  fill: "rgb(0, 93, 199)",
                                  '&:hover': {
                                    backgroundColor: 'rgb(0, 93, 199)',
                                    color: 'white',
                                    fill: 'white'
                                  },
                                }}
                              >
                                <ListItemIcon>
                                  <SVG src={process.env.REACT_APP_ICONSERVER+item.icon} width={30}/> 
                                </ListItemIcon>                                                
                                <Typography textAlign="center">                        
                                  <b>{item.title}</b>                        
                                </Typography>
                              </MenuItem>                              
                              <Divider/>
                            </div>
                            ));
                          default : 
                            return menuInvitado.map((item) => (
                            <div key={item.key}>
                              <MenuItem
                                component={Link} to={item.href}
                                onClick={() => (setMobileOpen(false))}
                                sx={{
                                  height: "45px",
                                  fill: "rgb(0, 93, 199)",
                                  '&:hover': {
                                    backgroundColor: 'rgb(0, 93, 199)',
                                    color: 'white',
                                    fill: 'white'
                                  },
                                }}
                              >
                                <ListItemIcon>
                                  <SVG src={process.env.REACT_APP_ICONSERVER+item.icon} width={30}/>
                                </ListItemIcon>
                                <Typography textAlign="center">
                                  <b>{item.title}</b>
                                </Typography>
                              </MenuItem>
                              <Divider/>
                            </div>
                          ));
                        }                                    
                      }() )
                    }
                  </Drawer>   
                </Menu>
              </Box>

              <Typography
                variant="h5"
                noWrap
                component="a"
                href="/"
                sx={{
                  mr: 2,
                  display: { xs: 'flex', md: 'none' },
                  flexGrow: 1,
                  fontFamily: 'monospace',
                  fontWeight: 700,
                  letterSpacing: '.3rem',
                  color: 'inherit',
                  textDecoration: 'none',
                }}
              >
                <Tooltip title="Pagina Principal">
                  <svg className='app_header_navbar_logo' viewBox="0 0 1400 330">
                    <path className='app_header_navbar_logo_letra_x' d="M787.5 68 613.4 241.4a71.024 71.024 0 0 1-50 20.6h-41.9L694.8 88.8C708.1 75.5 726.1 68 744.9 68h42.6z"/>
                    <path d="m640.2 114.7-26-26C601 75.5 582.9 68 564.1 68h-42.6l82.9 82.5 35.8-35.8zM668.8 214.7l26.8 26.7c13.3 13.2 31.3 20.6 50 20.6h41.9l-82.9-82.9-35.8 35.6z"/>
                    <g>
                      <path d="M101.3 130.8H121c.4.4 4.5 7.8 12.2 22.4 15.6 29.3 23.8 44.6 24.4 45.9v.1h-22.8c-.5-.6-7.6-13.6-21.1-39.1-1.7-3.1-2.5-4.6-2.6-4.6-15.2 28.5-23 43.1-23.5 43.7H64.8v-.3c23.4-43.9 35.6-66.6 36.5-68.1zM163.7 130.8h19.2c.2 0 .3.1.3.3v39.8c0 5.7 2.3 9.6 6.9 11.5.9.4 2.3.6 4 .6H218c5 0 8.4-2.6 10.2-7.8.3-1.3.4-2.7.4-4.4v-39.7c0-.2.1-.3.3-.3h19.3c.2 0 .3.1.3.3v49.6c0 6.6-3.3 11.9-10 16.1-3.1 1.6-6.2 2.4-9.3 2.4h-47c-5.6 0-10.7-2.6-15.1-7.8-2.5-3.4-3.8-6.9-3.8-10.7v-49.6c.1-.2.2-.3.4-.3zM254.7 130.8h84.6c.2 0 .3.1.3.3v15.6c0 .2-.1.3-.3.3H307v51.8c0 .2-.1.3-.3.3h-19.3c-.2 0-.3-.1-.3-.3V147h-32.4c-.2 0-.3-.1-.3-.3v-15.6c0-.2.1-.3.3-.3zM364.5 130.8h46.1c6.9 0 12.6 3 16.9 8.9 2 3.1 3.1 6.5 3.1 9.9v30.9c0 6.9-3.5 12.4-10.6 16.4-3 1.5-6.1 2.3-9.4 2.3h-45.5c-6.7 0-12.4-3-16.8-9.1-1.9-3-2.9-6.2-2.9-9.4v-31.2c0-7.3 3.9-12.9 11.7-17 3-1.1 5.5-1.7 7.4-1.7zm.7 27.4v13.5c0 5.5 2.6 9.2 7.8 11 .7.2 1.5.3 2.3.3h25.4c4.5 0 7.7-2.7 9.5-8 .3-.8.4-1.8.4-3.1v-13c0-5.8-2.4-9.7-7.3-11.7-1.4-.2-2.7-.3-3.8-.3h-24.3c-4.7 0-7.9 2.6-9.7 7.9-.2 1.2-.3 2.3-.3 3.4zM455.6 130.8h65.6c.2 0 .3.1.3.3v15.6c0 .2-.1.3-.3.3h-60.1c-3.4.5-5 2.3-5 5.4 0 2.1 1 3.7 3.1 4.7.6.3 1.3.4 2.2.4h40.8c5.7 0 10.8 2.5 15.3 7.5 2.7 3.4 4 7.1 4 11v4.7c0 6.7-3.5 12.1-10.6 16.2-2.9 1.4-5.9 2.2-9 2.2h-65.3c-.2 0-.3-.1-.3-.3v-15.6c0-.2.1-.3.3-.3h59.8c2.4 0 4.1-1.2 5-3.6.2-1 .2-1.6.2-1.8 0-2.1-1-3.7-3-4.8-.6-.3-1.4-.4-2.3-.4h-40.2c-6.6 0-12.2-2.8-16.6-8.5-2.1-3.1-3.2-6.4-3.2-9.8v-4.7c0-6.2 3.1-11.4 9.4-15.5 3.6-1.8 6.9-2.8 9.9-3z"/>
                    </g>
                    <g>
                      <path d="M787.8 130.8H853c6.8 0 12.4 3.1 16.9 9.2 1.8 3.2 2.8 6.3 2.8 9.2v12.7c0 6.2-3 11.4-9 15.6-3.4 2-6.8 2.9-10.2 2.9h-46.2v18.5c0 .2-.1.3-.3.3h-19.2c-.2 0-.3-.1-.3-.3v-67.8c0-.2.1-.3.3-.3zm19.5 16.3v17.1h37.6c4 0 6.6-1.9 7.6-5.8.2-.9.2-1.8.2-2.6 0-4.1-1.6-6.9-4.7-8.2-1-.3-1.9-.4-2.9-.4h-37.8zM914.9 130.8h19.7c.4.4 4.5 7.8 12.2 22.4 15.6 29.3 23.8 44.6 24.4 45.9v.1h-22.8c-.5-.6-7.6-13.6-21.1-39.1-1.7-3.1-2.5-4.6-2.6-4.6-15.2 28.5-23 43.1-23.5 43.7h-22.9v-.3c23.6-43.9 35.8-66.6 36.6-68.1zM977.4 130.8h65.3c5.9 0 11.1 2.5 15.5 7.5 2.7 3.4 4 7.2 4 11.2v12.4c0 6.4-3.2 11.7-9.6 15.9l-2.5 1.2 12.1 20.1-.3.1h-23.5c-.6-.7-4.4-7-11.5-18.8h-30.1v18.5c0 .2-.1.3-.3.3h-19.2c-.2 0-.3-.1-.3-.3v-67.8c.1-.2.2-.3.4-.3zm19.5 16.3v17.1h37.6c4 0 6.6-1.9 7.6-5.8.2-.9.2-1.8.2-2.6 0-4.1-1.6-6.9-4.7-8.2-1-.3-1.9-.4-2.9-.4h-37.8zM1068.3 130.8h84.6c.2 0 .3.1.3.3v15.6c0 .2-.1.3-.3.3h-32.3v51.8c0 .2-.1.3-.3.3H1101c-.2 0-.3-.1-.3-.3V147h-32.4c-.2 0-.3-.1-.3-.3v-15.6c.1-.2.2-.3.3-.3zM1159.3 130.8h84.6c.2 0 .3.1.3.3v15.6c0 .2-.1.3-.3.3h-84.6c-.2 0-.3-.1-.3-.3v-15.6c.1-.2.2-.3.3-.3zm0 26.8h84.6c.2 0 .3.1.3.3v14.2c0 .2-.1.3-.3.3h-84.6c-.2 0-.3-.1-.3-.3v-14.2c.1-.2.2-.3.3-.3zm0 25.4h84.6c.2 0 .3.1.3.3v15.6c0 .2-.1.3-.3.3h-84.6c-.2 0-.3-.1-.3-.3v-15.6c.1-.2.2-.3.3-.3zM1269.3 130.8h65.6c.2 0 .3.1.3.3v15.6c0 .2-.1.3-.3.3h-60.1c-3.4.5-5 2.3-5 5.4 0 2.1 1 3.7 3.1 4.7.6.3 1.3.4 2.2.4h40.8c5.7 0 10.8 2.5 15.3 7.5 2.7 3.4 4 7.1 4 11v4.7c0 6.7-3.5 12.1-10.6 16.2-2.9 1.4-5.9 2.2-9 2.2h-65.3c-.2 0-.3-.1-.3-.3v-15.6c0-.2.1-.3.3-.3h59.8c2.4 0 4.1-1.2 5-3.6.2-1 .2-1.6.2-1.8 0-2.1-1-3.7-3-4.8-.6-.3-1.4-.4-2.3-.4h-40.2c-6.6 0-12.2-2.8-16.6-8.5-2.1-3.1-3.2-6.4-3.2-9.8v-4.7c0-6.2 3.1-11.4 9.4-15.5 3.6-1.8 6.9-2.8 9.9-3z"/>
                    </g>
                  </svg>
                </Tooltip>
              </Typography>

              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                { checking ? <div></div>
                  : ( function() {
                    switch (rol) {
                        case 'COMPRADOR':
                        case 'VENDEDOR': 
                        return menuUsuario.map((item) => (                          
                          <Button
                            key={ item.key }
                            component={Link} to={item.href}
                            onClick={handleCloseNavMenu}
                            sx={{ my: 2, color: 'black', display: 'block', textTransform: 'none', fontSize: "16px",
                              '&:hover': {
                                color: 'rgb(0, 93, 199)'
                              }
                            }}
                          > 
                            {item.title}                    
                          </Button>
                          
                        ));
                      default : 
                        return menuInvitado.map((item) => (
                          <Button
                            key={ item.key }
                            component={Link} to={item.href}
                            onClick={handleCloseNavMenu}
                            sx={{ my: 2, color: 'black', display: 'block', textTransform: 'none', fontSize: "16px",
                              '&:hover': {
                                color: 'rgb(0, 93, 199)'
                              }
                            }}
                          >                            
                            {item.title}                            
                          </Button>
                          
                        ));
                      }                                    
                  }() )
                }
              </Box>

              {/* MENU DE SESION DE USUARIO */}
              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Usuario">
                  {(login)
                    ?
                    <>
                      <Badge badgeContent={ notification } color="warning" anchorOrigin={{ vertical: 'bottom',horizontal: 'right' }}>
                        <IconButton onClick={ handleOpenUserMenu } sx={{ p: 0, width: "50px" }}>                                                                                       
                          <SVG src={process.env.REACT_APP_ICONSERVER+'dz-user-check'} fill="rgb(0, 93, 199)" width={35}/>                                          
                        </IconButton>
                      </Badge>
                    </>
                    :
                    <>
                      <IconButton onClick={ handleOpenUserMenu } sx={{ p: 0, width: "50px" }}>                                                                                       
                        <SVG src={process.env.REACT_APP_ICONSERVER+'dz-user-2'} width={35}/>                                          
                      </IconButton>                                    
                    </>
                  }
                </Tooltip>

                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  { (login) ?
                    <div>
                      <Typography sx={{ margin: "0px 20px 0px 20px", color: "rgb(0, 93, 199)", fontWeight: 500 }}>{ name }</Typography>
                      <MenuItem onClick={ handleOpenNotifications }
                        sx={{
                          '&:hover': {
                            backgroundColor: '#ff6700',
                            color: 'white',
                            fill: 'white'
                          },
                        }}
                      >
                        <ListItemIcon>
                          <SVG src={process.env.REACT_APP_ICONSERVER+'dz-bell'} width={30}/>   
                        </ListItemIcon>
                        <Typography textAlign="center">Notificaciones</Typography>
                      </MenuItem>
                      <MenuItem component="a" href={ hrefIcon } onClick={ handleLogout }
                        sx={{
                          '&:hover': {
                            backgroundColor: '#ff6700',
                            color: 'white',
                            fill: 'white'
                          },
                        }}
                      >
                        <ListItemIcon>
                          <SVG src={process.env.REACT_APP_ICONSERVER+'dz-logout'} width={30}/>
                        </ListItemIcon>
                        <Typography textAlign="center">Cerrar Sesión</Typography>
                      </MenuItem>

                      <Drawer
                        open={notificationsOpen}
                        onClose={handleOpenNotifications}
                        sx={{
                          '& .MuiDrawer-paper': { width: ( !responsive ? 350 : responsive2 ? 210 : 290 ) },
                        }}
                        anchor="right"
                      >
                        <Typography sx={{m: "20px 20px 0px 20px"}} variant="h6">Notificaciones</Typography>
                        {
                          notifications.map((notificacion) => (
                            <Box sx={{ p: 2 }} key={notificacion.key} onClick={() => handleHrefNotification(notificacion.flag)}>
                              <Card sx={{ p: 2 }} className="app_header_notification_cards">
                                <Box sx={{ display: "flex" }}>
                                  <SVG src={process.env.REACT_APP_ICONSERVER+'dz-bell'} width={30} fill="rgb(0, 93, 199)"/>  
                                  <Typography sx={{ mb: .5 }} color="rgb(0, 93, 199)">
                                    Notificacion
                                  </Typography>
                                </Box>
                                <Typography sx={{ fontSize: "17px" }}>
                                  {notificacion.text}
                                </Typography>
                                <Typography sx={{ fontSize: 14, mt: 1 }} color="text.secondary">
                                  { dayjs(notificacion.date).fromNow() }
                                </Typography>
                              </Card>
                            </Box>
                          ))
                        }
                      </Drawer>

                    </div>
                    : 
                    <div>
                      <MenuItem component={Link} to="/login" onClick={ handleCloseUserMenu }
                        sx={{
                          '&:hover': {
                            backgroundColor: '#ff6700',
                            color: 'white',
                            fill: 'white'
                          },
                        }}
                      >
                        <ListItemIcon>
                          <SVG src={process.env.REACT_APP_ICONSERVER+'dz-login'} width={30}/>
                        </ListItemIcon>
                        <Typography textAlign="center">Iniciar Sesión</Typography>
                      </MenuItem>
                      <MenuItem component={Link} to="/register" onClick={ handleCloseUserMenu }
                        sx={{
                          '&:hover': {
                            backgroundColor: '#ff6700',
                            color: 'white',
                            fill: 'white'
                          },
                        }}
                      >
                        <ListItemIcon>
                          <SVG src={process.env.REACT_APP_ICONSERVER+'dz-edit'} width={30}/>   
                        </ListItemIcon>
                        <Typography textAlign="center">Registrarse</Typography>
                      </MenuItem>
                    </div>
                  }
                </Menu>
              </Box>
            </Toolbar>
          </div>
        </Container>
      </AppBar>
      </SocketContext.Provider>
    )
}
