
import { Box, Button, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";

const InstallPWA = () => {
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState<any>(null);

  const responsive = useMediaQuery('(max-width:1000px)');

  useEffect(() => {
    const handler = (e: any) => {
      e.preventDefault();
      console.log("we are being triggered :D");
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener("beforeinstallprompt", handler);

    return () => window.removeEventListener("transitionend", handler);
  }, []);

  const onClick = (e: React.FormEvent) => {
    e.preventDefault();
    if (!promptInstall) {
      return;
    }
    promptInstall?.prompt();
  };
  if (!supportsPWA) {
    return null;
  }
  return (
    <Box sx={{ marginTop: ( responsive ? "0px" : "20px" ) }}>
        <Button
            variant="contained"
            className="link-button"
            id="setup_button"
            aria-label="Install app"
            title="Install app"
            onClick={(e) => (onClick(e)) }
            startIcon={
                <svg x="0px" y="0px" viewBox="0 0 50 40" height="20px">
                    <g>
                        <path fill="#ff6700" d="M48.1,3.1L17.8,33.3c-2.3,2.3-5.4,3.6-8.7,3.6H1.9L32,6.7c2.3-2.3,5.4-3.6,8.7-3.6H48.1z"/>
                        <g>
                            <path fill="#000000" d="M22.5,11.3L18,6.7c-2.3-2.3-5.4-3.6-8.7-3.6H1.9l14.4,14.4L22.5,11.3z"/>
                            <path fill="#000000" d="M27.5,28.6l4.7,4.6c2.3,2.3,5.4,3.6,8.7,3.6h7.3L33.7,22.4L27.5,28.6z"/>
                        </g>
                    </g>
                </svg>
            }
        >
            Instalar App
        </Button>
    </Box>
  );
};

export default InstallPWA;