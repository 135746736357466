
import { fetchSinToken, fetchToken } from '../../../../../helpers/fetch';

//DEFINIMOS LOS TIPOS PARA DEL REDUCER
type QuestionsType = {
    idPregunta: string | null,
    pregunta: string | null,
    respuesta: string | null
    estadoPregunta: string | null,
    idPublicacion: string | null,
    fotoPublicacion: string | null,
    marca: string | null,
    modelo: string | null,
    anio: string | null,
    //nombreUsuarioPublic: string | null,
    //idUsuarioPublic: string | null
}
type AnswerType = {
    idPregunta: string | null,
    pregunta: string | null,
    respuesta: string | null,
    estadoPregunta: string | null,
    idPublicacion: string | null,
    fotoPublicacion: string | null,
    marca: string | null,
    modelo: string | null,
    anio: string | null,
    nombreUsuarioPublic: string | null,
    idUsuarioPublic: string | null
}
type PublicationInfoType = {
    idPublicacion: string | null,
    icon: string | null,
    marca: string | null,
    modelo: string | null,
    anio: number | null,
    anioInicial: number | null,
    anioFinal: number | null,
    version: string | null,
    motor: string | null,
    transmision: string | null,
    descripcion: string | null,
    estado: string | null,
    fotoPublicacion: string | null,
    fotoIzquierda: string | null,
    fotoDerecha: string | null,
    fotoFrontal: string | null,
    fotoPosterior: string | null,
    fotoMotor: string | null,
    fotoInterior: string | null
}

//DEFINIMOS LA ESTRUCTURA DEL REDUCER
export interface QuestionsReducerType {
    questions: QuestionsType[] | undefined,
    loading: boolean,
    questionsMessage: string | null,
    updateQuestions: boolean,
    countQuestions: number | undefined,

    answers: QuestionsType[] | undefined,

    respuesta: string,
    respuestaError: string | null,
    answerMessage: string | null,
    publicationInfo: PublicationInfoType | undefined,

    modalQuestionPublic: boolean,
    modalReportarPregunta: boolean,
    modalEliminarPregunta: boolean,
    mensajesModal: string | undefined,

    reloadQuestions: boolean,
    reloadAnswers: boolean,

    idPregunta: string | null
}
//DEFINIMOS EL VALOR INICIAL DEL REDUCER
export const initialQuestionsState: QuestionsReducerType = {
    questions: undefined,
    loading: true,
    questionsMessage: '',
    updateQuestions: true,
    countQuestions: undefined,

    answers: undefined,

    respuesta: '',
    respuestaError: null,
    answerMessage: '',
    publicationInfo: undefined,

    modalQuestionPublic: false,
    modalReportarPregunta: false,
    modalEliminarPregunta: false,
    mensajesModal: undefined,

    reloadQuestions: false,
    reloadAnswers: false,

    idPregunta: null
}

//DEFINIMOS LOS PAYLOAD DE LOS ACTIONS
type LoadQuestionsPayload = { preguntas: QuestionsType[] };

type LoadAnswerPayload = { respuestas: AnswerType[] };

type CountQuestionsPayload = { count: number | undefined };
type NoQuestionsDataPayload = { messageQuestion: string };
type NoAnswerDataPayload = { messageAnswer: string };

type OnChangePayload = { field: keyof QuestionsReducerType, value: any };
type OnErrorPayload = { errorField: keyof QuestionsReducerType, label: string };
type OnCleanErrorPayload = { errorField: keyof QuestionsReducerType };
type NoAnswerPayload = { messageAnswer: string };

type PublicationInfoPayload = { infoPublicacion: PublicationInfoType };
type ReportQuestionPayload = { messageReportQuestion: string };
type DeleteQuestionPayload = { messageDeleteQuestion: string };

type IdQuestionPayload = { idPregunta: string };

//DEFINIMOS LOS ACTIONS DEL REDUCER
type QuestionActions = { type: 'ClearQuestions' } |

                       { type: 'ClearAnswers' } |

                       { type: 'LoadQuestions', payload: LoadQuestionsPayload } |

                       { type: 'LoadAnswer', payload: LoadAnswerPayload } |

                       { type: 'CountQuestions', payload: CountQuestionsPayload } |
                       { type: 'NoQuestionsData', payload: NoQuestionsDataPayload } |
                       { type: 'NoAnswerData', payload: NoAnswerDataPayload } |

                       { type: 'OnChange', payload: OnChangePayload } |
                       { type: 'OnError', payload: OnErrorPayload } |
                       { type: 'OnCleanError', payload: OnCleanErrorPayload } |
                       { type: 'ClearAnswerError' } |
                       { type: 'NoAnswer', payload: NoAnswerPayload } |

                       { type: 'LoadPublicationInfo', payload: PublicationInfoPayload } |
                       { type: 'MessageReportQuestionModal', payload: ReportQuestionPayload } |
                       { type: 'MessageDeleteQuestionModal', payload: DeleteQuestionPayload } |

                       { type: 'ShowModalQuestionPublic' } | 
                       { type: 'CloseModalQuestionPublic' } |
                       { type: 'ShowModalReportarPregunta', payload: IdQuestionPayload } |
                       { type: 'CloseModalReportarPregunta' } |
                       { type: 'ShowModalEliminarPregunta', payload: IdQuestionPayload } |
                       { type: 'CloseModalEliminarPregunta' } |

                       { type: 'ActualizarPreguntas' };
                       /*{ type: 'ShowModalR', payload: QuestionsAnswer } | 
                       { type: 'CloseModalR' };*/

//DEFINIMOS LAS ACCIONES DEL REDUCER
export const questionsReducer = (state: QuestionsReducerType = initialQuestionsState, action: QuestionActions) : QuestionsReducerType => {
    switch (action.type) {
        case 'ClearQuestions' : return {
            ...state,
            ...initialQuestionsState,
        }

        case 'ClearAnswers' : return {
            ...state,
            ...initialQuestionsState
        }

        case 'LoadQuestions' : return { 
            ...state, 
            questions: action.payload.preguntas,
            loading: true,
            updateQuestions: false,
            reloadQuestions: false
        }

        case 'LoadAnswer' : return { 
            ...state, 
            answers: action.payload.respuestas,
            //loading: true,
            //updateQuestions: false,
            reloadAnswers: false
        }

        case 'CountQuestions' : return {
            ...state,
            countQuestions: action.payload.count
        }
        case 'NoQuestionsData' : return { 
            ...state, 
            questionsMessage: action.payload.messageQuestion,
        }

        case 'NoAnswerData' : return { 
            ...state, 
            questionsMessage: action.payload.messageAnswer,
        }

        case 'OnChange' : return {
            ...state,
            [action.payload.field]: action.payload.value
        }
        case 'OnError' : return {
            ...state,
            [action.payload.errorField]: action.payload.label
        }
        case 'OnCleanError' : return {
            ...state,
            [action.payload.errorField]: null
        }
        case 'ClearAnswerError' : return {
            ...state,
            respuesta: '',
            respuestaError: null,
            updateQuestions: true
        }
        case 'NoAnswer' : return { 
            ...state, 
            answerMessage: action.payload.messageAnswer,
        }

        case 'LoadPublicationInfo' : return {
            ...state,
            publicationInfo: action.payload.infoPublicacion,
        }
        case 'MessageReportQuestionModal' : return {
            ...state,
            mensajesModal: action.payload.messageReportQuestion,
        }
        case 'MessageDeleteQuestionModal' : return {
            ...state,
            mensajesModal: action.payload.messageDeleteQuestion,
        }

        case 'ShowModalQuestionPublic' : return {
            ...state,
            modalQuestionPublic: true
        }
        case 'CloseModalQuestionPublic' : return {
            ...state,
            modalQuestionPublic: false,
            publicationInfo: undefined
        }
        case 'ShowModalReportarPregunta' : return {
            ...state,
            modalReportarPregunta: true,
            idPregunta: action.payload.idPregunta
        }
        case 'CloseModalReportarPregunta' : return {
            ...state,
            modalReportarPregunta: false,
            mensajesModal: undefined,
            idPregunta: null
        }
        case 'ShowModalEliminarPregunta' : return {
            ...state,
            modalEliminarPregunta: true,
            idPregunta: action.payload.idPregunta
        }
        case 'CloseModalEliminarPregunta' : return {
            ...state,
            modalEliminarPregunta: false,
            mensajesModal: undefined,
            idPregunta: null
        }

        case 'ActualizarPreguntas' : return {
            ...state,
            reloadQuestions: true
        }
        /*case 'ShowModalR' : return {
            ...state,
            //modalR: true,
            //answerModal: action.payload
        }
        case 'CloseModalR' : return {
            ...state,
            //modalR: false,
            //answerModal: undefined
        }*/
        default: return state;
    }
}

//CREAMOS LAS ACCIONES DEL REDUCER
//Cargar datos de la cuenta
type updateIdUserParameter = { dispatchLocalQuestions: React.Dispatch<QuestionActions>,
                               idUser: number | null };
export const startLoadQuestions = async (loadQuestionData: updateIdUserParameter) => {

    const { dispatchLocalQuestions, idUser } = loadQuestionData;
    
    dispatchLocalQuestions (clearQuestions());

    const body = await fetchToken(`preguntas/questions/${idUser}`,{},'GET');
    if (body.ok) {

        //console.log(body);

        let questionsUser: QuestionsType[] = [];
        for (let question of body.res){
            questionsUser.push({
                idPregunta: question._id,
                pregunta: question.pregunta,
                respuesta: question.respuesta ? question.respuesta : '',
                estadoPregunta: question.estado,
                idPublicacion: question.publicacionId._id,
                fotoPublicacion: question.publicacionId.fotoPublicacion,
                marca: question.marca,
                modelo: question.modelo,
                anio: question.anio,
                //nombreUsuarioPublic: question.userId.nombre,
                //idUsuarioPublic: question.userId._id
            });
        }
        dispatchLocalQuestions(loadQuestions({ preguntas: questionsUser }));
        dispatchLocalQuestions(countQuestions({ count: body.count }));
    } else {
        dispatchLocalQuestions(noQuestionsData({ messageQuestion: body.msg }))
    }
}
const clearQuestions = (): QuestionActions  => {
    return {
        type: 'ClearQuestions'
    }    
}
const loadQuestions = (questionData: LoadQuestionsPayload): QuestionActions  => {
    return {
        type: 'LoadQuestions',
        payload: questionData
    }    
}
const countQuestions = (countQuestionsData: CountQuestionsPayload): QuestionActions  => {
    return {
        type: 'CountQuestions',
        payload: countQuestionsData
    }    
}
const noQuestionsData = (noQuestionsData: NoQuestionsDataPayload): QuestionActions => {
    return {
        type: 'NoQuestionsData',
        payload: noQuestionsData
    }
}


//CREAMOS LAS ACCIONES DEL REDUCER
//Cargar datos de la cuenta
type updateAnswerIdUserParameter = { dispatchLocalQuestions: React.Dispatch<QuestionActions>,
                                     idUser: number | null };
export const startLoadAnswers = async (loadQuestionData: updateAnswerIdUserParameter) => {

    const { dispatchLocalQuestions, idUser } = loadQuestionData;

    dispatchLocalQuestions (clearAnswer());

    const body = await fetchToken(`preguntas/answers/${idUser}`,{},'GET');
    if (body.ok) {

        //console.log(body);

        let answersUser: AnswerType[] = [];
            for (let answer of body.res){
                answersUser.push({
                idPregunta: answer._id,
                pregunta: answer.pregunta,
                respuesta: answer.respuesta ? answer.respuesta : '',
                estadoPregunta: answer.estado,
                idPublicacion: answer.publicacionId._id,
                fotoPublicacion: answer.publicacionId.fotoPublicacion,
                marca: answer.marca,
                modelo: answer.modelo,
                anio: answer.anio,
                nombreUsuarioPublic: answer.userId.nombre,
                idUsuarioPublic: answer.userId._id
            });
        }
        dispatchLocalQuestions(loadAnswer({ respuestas: answersUser }));
        dispatchLocalQuestions(countQuestions({ count: body.count }));
    } else {
        dispatchLocalQuestions(noAnswerData({ messageAnswer: body.msg }))
    }
}

const clearAnswer = (): QuestionActions  => {
    return {
        type: 'ClearAnswers'
    }    
}
const loadAnswer = (answerData: LoadAnswerPayload): QuestionActions  => {
    return {
        type: 'LoadAnswer',
        payload: answerData
    }    
}
const noAnswerData = (noAnswerData: NoAnswerDataPayload): QuestionActions => {
    return {
        type: 'NoAnswerData',
        payload: noAnswerData
    }
}


type startUpdateAnswerQuestionIdParameter = { dispatchLocalQuestions: React.Dispatch<QuestionActions>,
                                              idQuestion: string | null, answer: string | null };
export const startAnswerQuestion = async (startAnswerQuestionIdData: startUpdateAnswerQuestionIdParameter) => {

    const { dispatchLocalQuestions, idQuestion, answer } = startAnswerQuestionIdData;
    
    //dispatchLocalQuestions (clearQuestions());

    const body = await fetchToken(`preguntas`, { 
        questionId: idQuestion,
        answer: answer,
    }, 'PUT');

    if (body.ok){
        //console.log(body);
    } else {
        dispatchLocalQuestions(noAnswer({ messageAnswer: body.msg }))
    }
}

export const onChange = (onChangeData: OnChangePayload): QuestionActions => {
    return {
        type: 'OnChange',
        payload: onChangeData
    }
}
export const setError = (onErrorData: OnErrorPayload): QuestionActions => {
    return {
        type: 'OnError',
        payload: onErrorData
    }
}
//METODO CLEAN ERROR GENERAL
export const cleanError = (onCleanErrorData: OnCleanErrorPayload): QuestionActions => {
    return {
        type: 'OnCleanError',
        payload: onCleanErrorData
    }
}
export const clearAnswerError = (): QuestionActions  => {
    return {
        type: 'ClearAnswerError'
    }    
}
const noAnswer = (noAnswerData: NoAnswerPayload): QuestionActions => {
    return {
        type: 'NoAnswer',
        payload: noAnswerData
    }
}

// CARGAR LA INFORMACION DE LA PUBLICACION
type startUpdatePublicationInfoParameter = { /*stateLocalPublication: PublicationDataReducerType,*/ dispatchLocalQuestions: React.Dispatch<QuestionActions>
                                             publicacionInfoId: string };
export const startUpdatePublicationQuestionInfo = async (startUpdatePublicationInfo: startUpdatePublicationInfoParameter) => {

        const { dispatchLocalQuestions, publicacionInfoId } = startUpdatePublicationInfo;

        //dispatchLocalPublication(preparePublicationData());

        const body = await fetchSinToken(`publicaciones/${ publicacionInfoId }`, {}, 'GET');

        //console.log(body);

        if (body.ok){
            dispatchLocalQuestions( loadPublicationInfo({
                infoPublicacion: {
                    idPublicacion: body.res._id,
                    icon: body.res.marcaId.icono,
                    marca: body.res.marcaId.marca,
                    modelo: body.res.modeloId.modelo,
                    anio: body.res.anio,
                    anioInicial: body.res.anioInicial,
                    anioFinal: body.res.anioFinal,
                    version: body.res.version,
                    motor: body.res.motor,
                    transmision: body.res.transmision,
                    descripcion: body.res.descripcion,
                    estado: body.res.estado,
                    fotoPublicacion: body.res.fotoPublicacion,
                    fotoIzquierda: body.res.fotoIzquierda,
                    fotoDerecha: body.res.fotoDerecha,
                    fotoFrontal: body.res.fotoFrontal,
                    fotoPosterior: body.res.fotoPosterior,
                    fotoMotor: body.res.fotoMotorSuperior,
                    fotoInterior: body.res.fotoInteriorInferior,
                }
            }));
        } else {
            //console.log('Respuesta falsa');
        }
}
const loadPublicationInfo = ( publicationInfoData: PublicationInfoPayload ) : QuestionActions => {
    return {
        type: 'LoadPublicationInfo',
        payload: publicationInfoData
    }
}

type startReportQuestionParameter = { /*stateLocalPublication: PublicationDataReducerType,*/ dispatchLocalQuestions: React.Dispatch<QuestionActions>
                                      idQuestion: string | null };
export const startReportQuestion = async (startUpdateReportQuestion: startReportQuestionParameter) => {

    const { dispatchLocalQuestions, idQuestion } = startUpdateReportQuestion;

    const body = await fetchToken(`preguntas/reportar`, { 
        questionId: idQuestion,
    }, 'PUT');

    //console.log(body);

    if (body.ok) {
        dispatchLocalQuestions( closeModalReportarPregunta());
        dispatchLocalQuestions( actualizarPreguntas());
    } else {
        dispatchLocalQuestions( messageReportQuestionModal({
            messageReportQuestion: body.msg
        }));
    }
}
const messageReportQuestionModal = ( messageReportQuestionData: ReportQuestionPayload ) : QuestionActions => {
    return {
        type: 'MessageReportQuestionModal',
        payload: messageReportQuestionData
    }
}

type startDeleteQuestionParameter = { /*stateLocalPublication: PublicationDataReducerType,*/ dispatchLocalQuestions: React.Dispatch<QuestionActions>
                                      idQuestion: string | null };
export const startDeleteQuestion = async (startUpdateDeleteQuestion: startDeleteQuestionParameter) => {

    const { dispatchLocalQuestions, idQuestion } = startUpdateDeleteQuestion;

    const body = await fetchToken(`preguntas`, { 
        questionId: idQuestion,
    }, 'DELETE');

    if (body.ok) {
        dispatchLocalQuestions( closeModalEliminarPregunta());
        dispatchLocalQuestions( actualizarPreguntas());
    } else {
        dispatchLocalQuestions( messageDeleteQuestionModal({
            messageDeleteQuestion: body.msg
        }));
    }
}
const messageDeleteQuestionModal = ( messageDeleteQuestionData: DeleteQuestionPayload ) : QuestionActions => {
    return {
        type: 'MessageDeleteQuestionModal',
        payload: messageDeleteQuestionData
    }
}

//MOSTAR Y OCULTAR MODAL
export const showModalQuestionPublic = () : QuestionActions => {
    return {
        type: 'ShowModalQuestionPublic',
    }
}
export const closeModalQuestionPublic = () : QuestionActions => {
    return {
        type: 'CloseModalQuestionPublic'
    }
}
export const openModalReportarPregunta = ( idPreguntaData: IdQuestionPayload ) : QuestionActions => {
    return {
        type: 'ShowModalReportarPregunta',
        payload: idPreguntaData
    }
}
export const closeModalReportarPregunta = () : QuestionActions => {
    return {
        type: 'CloseModalReportarPregunta'
    }
}
export const openModalEliminarPregunta = ( idPreguntaData: IdQuestionPayload ) : QuestionActions => {
    return {
        type: 'ShowModalEliminarPregunta',
        payload: idPreguntaData
    }
}
export const closeModalEliminarPregunta = () : QuestionActions => {
    return {
        type: 'CloseModalEliminarPregunta'
    }
}

const actualizarPreguntas = (): QuestionActions  => {
    return {
        type: 'ActualizarPreguntas'
    }    
}

/*export const showModalAnswer = (showModalData: QuestionsAnswer): QuestionActions => {
    return {
        type: 'ShowModalR',
        payload: showModalData
    }
}
export const closeModalAnswer = (): QuestionActions => {
    return {
        type: 'CloseModalR'
    }
}*/