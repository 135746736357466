
import { Box, CardMedia, Typography, useMediaQuery } from "@mui/material";
import "./styles/nosotros-styles.scss"

export const NosotrosScreen = () => {

    const responsive = useMediaQuery('(max-width:600px)');
    const responsive2 = useMediaQuery('(max-width:350px)');

  return (
    <div>
      <p className="nosotros_title_container">Sobre Nosotros</p>

      <Box className="nosotros_container_blanco">
        <div className="nosotros_container_format">
          <Box sx={{ textAlign: ( responsive ? "start" : "end" ) }} className="nosotros_container_text">
            <div>
              <Typography variant={(responsive2 ? "h5" : "h4")} marginBottom={2}>
                Nuestra Historia
              </Typography>
              <Typography sx={{ fontSize: (responsive2 ? "14px" : "16px") }}>
                AUTOSPORPARTESPUNTOCOM S.A. DE C.V. es una empresa 100% mexicana con marca 
                registrada dedicada al ramo automotriz, nuestro objetivo es facilitar la comercialización de 
                autopartes nuevas y usadas, así como fomentar él rehusó de autopartes traduciéndose 
                en beneficios económicos, accesibles y ecológicos para todas las personas que necesitan 
                comprar una refacción, estamos enfocados en la especialización del ramo automotriz por 
                lo que conjuntamente con el gran capital humano generaremos soluciones constantes de dicho mercado.
              </Typography>
            </div>
          </Box>
          <CardMedia
            component="img"
            image="./assets/img-1.png"
            alt="nosotros-1"
            height={(!responsive ? "420px" : responsive2 ? "180px" : "250px" )}
          />
        </div>
      </Box>
      
      <Box className="nosotros_container_azul">
        <div className="nosotros_container_format">
          <CardMedia
            component="img"
            image="./assets/img-2.png"
            alt="nosotros-1"
            height={(!responsive ? "420px" : responsive2 ? "180px" : "250px" )}
          />
          <Box className="nosotros_container_text">
            <div>
              <Typography variant={(responsive2 ? "h5" : "h4")} marginBottom={2}>
                Misión
              </Typography>
              <Typography sx={{ fontSize: (responsive2 ? "14px" : "16px") }}>
                Nuestra misión es facilitar la comercialización y ofrecer soluciones tecnológicas en el ramo 
                de las autopartes, es nuestro propósito hacer del comercio electrónico, la herramienta que 
                ayude a generar más ventas ofreciendo soluciones a todos aquellos vendedores y 
                compradores dentro del territorio mexicano, siempre fomentando el cuidado del medio ambiente.
              </Typography>
            </div>
          </Box>
        </div>
      </Box>
      
      <Box className="nosotros_container_blanco">
        <div className="nosotros_container_format">
          <Box sx={{ textAlign: ( responsive ? "start" : "end" ) }} className="nosotros_container_text">
            <div>
              <Typography variant={(responsive2 ? "h5" : "h4")} marginBottom={2}>
                Visión
              </Typography>
              <Typography sx={{ fontSize: (responsive2 ? "14px" : "16px") }}>
                Queremos ser la plataforma número uno especializada en el comercio de auto partes, 
                estamos convencidos que lograremos mejoras en la forma de vender y comprar 
                fomentando la reutilización de partes usadas para lograr darle un respiro a nuestro entorno ambiental.
              </Typography>
            </div>
          </Box>
          <CardMedia
            component="img"
            image="./assets/slide-user-3.png"
            alt="nosotros-1"
            height={(!responsive ? "420px" : responsive2 ? "180px" : "250px" )}
          />
        </div>
      </Box>
      
      <Box className="nosotros_container_azul">
        <div className="nosotros_container_format">
          <CardMedia
            component="img"
            image="./assets/img-3.png"
            alt="nosotros-1"
            height={(!responsive ? "420px" : responsive2 ? "180px" : "250px" )}
          />
          <Box className="nosotros_container_text">
            <div>
              <Typography variant={(responsive2 ? "h5" : "h4")} marginBottom={2}>
                Valores
              </Typography>
              <Typography sx={{ fontSize: (responsive2 ? "14px" : "16px") }}>
                Nuestros valores son éticos, creemos en el mercado automotriz y en los vendedores que 
                día a día realizan el esfuerzo por crecer, somos una empresa que está comprometida con 
                el país que nos ha visto nacer por lo que nuestro principal valor es el respeto por nuestras 
                instituciones, leyes y sobre todo por México.
              </Typography>
            </div>
          </Box>
        </div>
      </Box>
    </div>
  )
}