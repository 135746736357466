import { Typography } from "@mui/material"
import { useContext, useState, useEffect } from 'react';
import { CatalogoContext } from './context/CatalogoContext';

type Props = { 
    countList: number
}

export const Summary: React.FC<Props> = ({ countList }) => {

    //OBTENEMOS EL VALOR DEL QUERY DEL REDUCER
    // const { query, dispatch } = useContext(CatalogoContext);  
    const { query } = useContext(CatalogoContext);  
    const [rowsSelected, setRowsSelected] = useState<number>(0);

    useEffect(() => {
        //obtenemos solo la lista de registros visibles
        const rowsList = query.rowsList.filter((row,index) => (index < query.rowsVisibles) && row);
        //contando los elementos seleccionados
        setRowsSelected(rowsList.reduce((counter, row) => (row.check) ? counter + 1 : counter, 0));
    },[query.rowsList, query.rowsVisibles])

    return (
        <div className="catalogo_controllers">
            { <Typography style={ { fontSize: '13px' } }>{ `${rowsSelected} Elementos seleccionados`}</Typography> }
            { <Typography style={ { fontSize: '13px' } }>{ `1-${countList} de ${query.totalRows} totales`}</Typography> }
        </div>
    )
}
