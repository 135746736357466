
import { Link } from "@mui/material"

export const AppFooter = () => {

  /*const fecha = new Date();
  const fechaFormateada = fecha.toLocaleString("es-Es", 
        { day: "2-digit", month: "long", year: "numeric" });
  console.log(fechaFormateada);*/

  return (
    <div className="app_footer_container">
      <div className="app_footer_links">
        <div className="logo-footer">
          <Link href="/users">
            <svg className="app_footer_logo_blanco" viewBox="0 0 1400 330">
              <path className="app_header_navbar_logo_letra_x" d="M787.5 68 613.4 241.4a71.024 71.024 0 0 1-50 20.6h-41.9L694.8 88.8C708.1 75.5 726.1 68 744.9 68h42.6z"/>
              <path d="m640.2 114.7-26-26C601 75.5 582.9 68 564.1 68h-42.6l82.9 82.5 35.8-35.8zM668.8 214.7l26.8 26.7c13.3 13.2 31.3 20.6 50 20.6h41.9l-82.9-82.9-35.8 35.6z"/>
              <g>
                <path d="M101.3 130.8H121c.4.4 4.5 7.8 12.2 22.4 15.6 29.3 23.8 44.6 24.4 45.9v.1h-22.8c-.5-.6-7.6-13.6-21.1-39.1-1.7-3.1-2.5-4.6-2.6-4.6-15.2 28.5-23 43.1-23.5 43.7H64.8v-.3c23.4-43.9 35.6-66.6 36.5-68.1zM163.7 130.8h19.2c.2 0 .3.1.3.3v39.8c0 5.7 2.3 9.6 6.9 11.5.9.4 2.3.6 4 .6H218c5 0 8.4-2.6 10.2-7.8.3-1.3.4-2.7.4-4.4v-39.7c0-.2.1-.3.3-.3h19.3c.2 0 .3.1.3.3v49.6c0 6.6-3.3 11.9-10 16.1-3.1 1.6-6.2 2.4-9.3 2.4h-47c-5.6 0-10.7-2.6-15.1-7.8-2.5-3.4-3.8-6.9-3.8-10.7v-49.6c.1-.2.2-.3.4-.3zM254.7 130.8h84.6c.2 0 .3.1.3.3v15.6c0 .2-.1.3-.3.3H307v51.8c0 .2-.1.3-.3.3h-19.3c-.2 0-.3-.1-.3-.3V147h-32.4c-.2 0-.3-.1-.3-.3v-15.6c0-.2.1-.3.3-.3zM364.5 130.8h46.1c6.9 0 12.6 3 16.9 8.9 2 3.1 3.1 6.5 3.1 9.9v30.9c0 6.9-3.5 12.4-10.6 16.4-3 1.5-6.1 2.3-9.4 2.3h-45.5c-6.7 0-12.4-3-16.8-9.1-1.9-3-2.9-6.2-2.9-9.4v-31.2c0-7.3 3.9-12.9 11.7-17 3-1.1 5.5-1.7 7.4-1.7zm.7 27.4v13.5c0 5.5 2.6 9.2 7.8 11 .7.2 1.5.3 2.3.3h25.4c4.5 0 7.7-2.7 9.5-8 .3-.8.4-1.8.4-3.1v-13c0-5.8-2.4-9.7-7.3-11.7-1.4-.2-2.7-.3-3.8-.3h-24.3c-4.7 0-7.9 2.6-9.7 7.9-.2 1.2-.3 2.3-.3 3.4zM455.6 130.8h65.6c.2 0 .3.1.3.3v15.6c0 .2-.1.3-.3.3h-60.1c-3.4.5-5 2.3-5 5.4 0 2.1 1 3.7 3.1 4.7.6.3 1.3.4 2.2.4h40.8c5.7 0 10.8 2.5 15.3 7.5 2.7 3.4 4 7.1 4 11v4.7c0 6.7-3.5 12.1-10.6 16.2-2.9 1.4-5.9 2.2-9 2.2h-65.3c-.2 0-.3-.1-.3-.3v-15.6c0-.2.1-.3.3-.3h59.8c2.4 0 4.1-1.2 5-3.6.2-1 .2-1.6.2-1.8 0-2.1-1-3.7-3-4.8-.6-.3-1.4-.4-2.3-.4h-40.2c-6.6 0-12.2-2.8-16.6-8.5-2.1-3.1-3.2-6.4-3.2-9.8v-4.7c0-6.2 3.1-11.4 9.4-15.5 3.6-1.8 6.9-2.8 9.9-3z"/>
              </g>
              <g>
                <path d="M787.8 130.8H853c6.8 0 12.4 3.1 16.9 9.2 1.8 3.2 2.8 6.3 2.8 9.2v12.7c0 6.2-3 11.4-9 15.6-3.4 2-6.8 2.9-10.2 2.9h-46.2v18.5c0 .2-.1.3-.3.3h-19.2c-.2 0-.3-.1-.3-.3v-67.8c0-.2.1-.3.3-.3zm19.5 16.3v17.1h37.6c4 0 6.6-1.9 7.6-5.8.2-.9.2-1.8.2-2.6 0-4.1-1.6-6.9-4.7-8.2-1-.3-1.9-.4-2.9-.4h-37.8zM914.9 130.8h19.7c.4.4 4.5 7.8 12.2 22.4 15.6 29.3 23.8 44.6 24.4 45.9v.1h-22.8c-.5-.6-7.6-13.6-21.1-39.1-1.7-3.1-2.5-4.6-2.6-4.6-15.2 28.5-23 43.1-23.5 43.7h-22.9v-.3c23.6-43.9 35.8-66.6 36.6-68.1zM977.4 130.8h65.3c5.9 0 11.1 2.5 15.5 7.5 2.7 3.4 4 7.2 4 11.2v12.4c0 6.4-3.2 11.7-9.6 15.9l-2.5 1.2 12.1 20.1-.3.1h-23.5c-.6-.7-4.4-7-11.5-18.8h-30.1v18.5c0 .2-.1.3-.3.3h-19.2c-.2 0-.3-.1-.3-.3v-67.8c.1-.2.2-.3.4-.3zm19.5 16.3v17.1h37.6c4 0 6.6-1.9 7.6-5.8.2-.9.2-1.8.2-2.6 0-4.1-1.6-6.9-4.7-8.2-1-.3-1.9-.4-2.9-.4h-37.8zM1068.3 130.8h84.6c.2 0 .3.1.3.3v15.6c0 .2-.1.3-.3.3h-32.3v51.8c0 .2-.1.3-.3.3H1101c-.2 0-.3-.1-.3-.3V147h-32.4c-.2 0-.3-.1-.3-.3v-15.6c.1-.2.2-.3.3-.3zM1159.3 130.8h84.6c.2 0 .3.1.3.3v15.6c0 .2-.1.3-.3.3h-84.6c-.2 0-.3-.1-.3-.3v-15.6c.1-.2.2-.3.3-.3zm0 26.8h84.6c.2 0 .3.1.3.3v14.2c0 .2-.1.3-.3.3h-84.6c-.2 0-.3-.1-.3-.3v-14.2c.1-.2.2-.3.3-.3zm0 25.4h84.6c.2 0 .3.1.3.3v15.6c0 .2-.1.3-.3.3h-84.6c-.2 0-.3-.1-.3-.3v-15.6c.1-.2.2-.3.3-.3zM1269.3 130.8h65.6c.2 0 .3.1.3.3v15.6c0 .2-.1.3-.3.3h-60.1c-3.4.5-5 2.3-5 5.4 0 2.1 1 3.7 3.1 4.7.6.3 1.3.4 2.2.4h40.8c5.7 0 10.8 2.5 15.3 7.5 2.7 3.4 4 7.1 4 11v4.7c0 6.7-3.5 12.1-10.6 16.2-2.9 1.4-5.9 2.2-9 2.2h-65.3c-.2 0-.3-.1-.3-.3v-15.6c0-.2.1-.3.3-.3h59.8c2.4 0 4.1-1.2 5-3.6.2-1 .2-1.6.2-1.8 0-2.1-1-3.7-3-4.8-.6-.3-1.4-.4-2.3-.4h-40.2c-6.6 0-12.2-2.8-16.6-8.5-2.1-3.1-3.2-6.4-3.2-9.8v-4.7c0-6.2 3.1-11.4 9.4-15.5 3.6-1.8 6.9-2.8 9.9-3z"/>
              </g>
            </svg>
          </Link>
        </div>
      </div>

      <div className="app_footer_redes">
        <div>
          <Link href="https://www.facebook.com/autosXpartes?mibextid=LQQJ4d" target="_blank">
            <svg viewBox="0 0 250 250" className="app_footer_redes_iconos">
              <path d="M223.8 0H26.7C12.2 0 .5 11.7.5 26.2v197.1c0 14.5 11.7 26.2 26.2 26.2h77v-84.9H68.9v-40.5h34.8V94c1.3-50.8 41.2-53.8 52-53.6 10.8.2 30.9 3 30.9 3v34l-17.2.1c-16.2-1.1-22.7 9.6-22.6 21.6v25.1h38.4l-6.5 40.5h-31.9c0 32.1-.1 67.3-.1 84.9h77.1c14.5 0 26.2-11.7 26.2-26.2V26.2C250 11.7 238.3 0 223.8 0z"/>
            </svg>
          </Link>
        </div>

        <div>
          <Link href="https://www.youtube.com/@autosxpartes" target="_blank">
            <svg viewBox="0 0 250 250" className="app_footer_redes_iconos">
              <path d="M222.5 0h-195C12.3 0 0 12.3 0 27.5v195C0 237.7 12.3 250 27.5 250h195c15.2 0 27.5-12.3 27.5-27.5v-195C250 12.3 237.7 0 222.5 0zm2.1 162.9c-.4 3.4-1 6.7-1.8 10-2.6 10.9-11 18.8-22.4 20-11.9 1.3-23.9 2.2-35.8 2.6-13.2.4-73.6.2-90.6-.4-8-.3-16-1.1-23.9-2.1-12.8-1.6-21.1-9.8-23.5-22.5-2.1-11-2.7-22.2-3.2-33.4-.7-16.7 0-33.4 2-49.9.4-3.4 1-6.9 1.8-10.2 2.6-11 11.1-18.7 22.5-20 10-1.1 20.1-2.1 30.2-2.3 23.7-.5 47.4-.8 71.1-.6 14.4.1 28.7 1.2 43 2.1 4.2.3 8.5 1 12.5 2.4 10.7 3.9 16 12.3 17.2 23.2 1.2 10.5 2.2 21 2.8 31.5 1 16.5 0 33.1-1.9 49.6z"/>
              <path d="M104.7 155.5c17.8-10.2 35.2-20.2 53.1-30.5-17.9-10.3-35.4-20.3-53.1-30.5v61z"/>
            </svg>
          </Link>
        </div>

        <div>
          <Link href="https://twitter.com/autosxpartes?s=11&t=Z42JBjF0Wv71f9l96NppRA" target="_blank">
            <svg viewBox="0 0 250 250" className="app_footer_redes_iconos">
              <path d="M222.9 0H27.1C12.1 0 0 12.1 0 27.1v195.7c0 15 12.1 27.1 27.1 27.1h195.7c15 0 27.1-12.1 27.1-27.1V27.1c.1-15-12-27.1-27-27.1zm-22.4 84.7c-2.9 2.2-3.9 4.5-4 8.3-1.5 38.7-17.1 70.2-50.7 90.3-36.5 21.8-73.8 19.7-110.5-1.1 19.4 1 36.9-3.7 53.1-15.7-16.9-1.7-28-10.2-34.3-25.9 5.4.4 10.3 1.1 15.2-.7-19.4-8.5-28.6-20.5-27.8-36.7 4.9 2.1 9.7 4.1 15.9 4.3-7.9-6.2-13-13.5-14.9-22.8-1.9-9.3-.7-18.1 4.2-26.7 6.7 7.9 13.9 14.7 22.2 20.4 15 10.3 31.6 16.1 49.6 18.1 2.9.3 4.3.4 3.9-3.6-2.2-22.6 13-40.4 35.6-41.6 9.9-.5 18.7 2.8 26.1 9.6 1.9 1.8 3.5 1.9 5.8 1.3 6.8-1.8 13.2-4.4 19.9-8.2-2.7 8.6-7.7 14.7-15.1 20.2 7.2-.7 13.1-2.8 19.9-5.3-4.2 6.6-8.7 11.7-14.1 15.8z"/>
            </svg>
          </Link>
        </div>

        <div>
          <Link href="https://instagram.com/autosxpartes?igshid=YmMyMTA2M2Y=" target="_blank">
            <svg viewBox="0 0 250 250" className="app_footer_redes_iconos">
              <path d="M125.4 94.1c-16.6-.1-30.9 13.6-31.1 29.7-.1 17.3 13.1 31.6 29.5 31.8 17.7.1 31.9-13 31.9-29.5 0-17.8-13.3-31.8-30.3-32z"/>
              <path d="M159.4 51.5H91c-22.4 0-39.5 17.1-39.5 39.5 0 22.3 0 44.6.1 67 0 2.4.2 4.7.5 7.1 2.3 18.5 18.9 33.2 37.5 33.2 23.6.1 47.1.1 70.7 0 2.6 0 5.3-.3 7.9-.9 18.1-4.2 30.2-19.6 30.2-38.1V90.6c0-21.9-17.2-39.1-39-39.1zm-34.8 116.2c-23.1 0-42.5-19.9-42.4-43.3.1-22.5 20.4-42.4 43.1-42.3 22.7.1 42.7 20.1 42.6 42.7-.1 23.9-19.4 43-43.3 42.9zm45.8-79.1c-5.2-.1-9.1-4.3-9.1-9.6.1-5.3 4.1-9 9.6-8.9 5.2.1 9 4.1 8.9 9.4 0 5.1-4.1 9.1-9.4 9.1z"/>
              <path d="M222.9-.1H27.1C12.1-.1 0 12.1 0 27.1v195.7c0 15 12.1 27.1 27.1 27.1h195.7c15 0 27.1-12.1 27.1-27.1V27.1c.1-15-12-27.2-27-27.2zm-7.2 165.5c-.5 23.5-20.6 45.9-43.9 49.4-5.3.8-10.7.8-16 .9-10.4.1-20.8 0-31.3 0v-.1c-13.4 0-26.7.6-40-.1-20.2-1-34.6-11.7-44.4-28.9-4.4-7.8-6.1-16.4-6-25.4.1-24.8 0-49.6.3-74.4C34.7 60.4 55 37.7 81 34.4c3.2-.4 6.5-.4 9.7-.4 24.7 0 49.4-.2 74.1.2 23 .4 44.4 18 49.7 40.6 1 4.1 1.5 8.4 1.5 12.6 0 26 .2 52-.3 78z"/>
            </svg>
          </Link>
        </div>
      </div>

      <div className="app_footer_marcas">
        *Las marcas y logotipos mostrados en este sitio, son marcas registradas, por lo que solo son referencias gráficas de los vehículos, esta plataforma no representa a ninguna de estas marcas ni se ostenta como concesionaria y/o vendedor oficial autorizado, el comprador es responsable de los productos que adquiere mediante la plataforma y ha aceptado las condiciones de navegación así como los términos y condiciones.
      </div>

      <div className="app_footer_derechos_autor">
        Copyright: &copy; {new Date().getFullYear()} Autos X Partes | Todos los derechos reservados | <Link href="/terminos-y-condiciones" sx={{ color: "white" }}>Términos y Condiciones</Link> | <Link href="/aviso-de-privacidad" sx={{ color: "white", }}>Aviso de Privacidad</Link>
      </div>
    </div>
  )
}