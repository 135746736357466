// COMPONENTE PRINCIPAL DEL "HOME"
import Carousel from 'react-material-ui-carousel';
import { Avatar, Box, Button, CardMedia, CircularProgress, Typography, useMediaQuery } from '@mui/material';
import { HomeSliderCardsPublicaciones } from './HomeSliderCardsPublicaciones';
import Slider from 'react-slick';

import "./styles/home.scss"
import { HomeContext } from './context/ContextHome';
import { useContext, useEffect } from 'react';
import { BuscarModal } from './BuscarModal';
import SVG from 'react-inlinesvg';
import { startLoadAssociatesHome } from './reducer/HomeReducer';
import ReactPlayer from 'react-player';
import InstallPWA from '../../helpers/installPwa';

export const Home = () => {

  //LEER EL REDUCER LOCAL
  const { /*setFindModal, */homeReducerState, dispatchHome } = useContext(HomeContext); 
  const { carouselAssociates } = homeReducerState;

  //console.log(carouselAssociates);

  const responsive = useMediaQuery('(max-width:900px)');
  const responsive2 = useMediaQuery('(max-width:500px)');

  /////////////////////     ARREGLOS DE OBJETOS PARA LOS CARRUSELES DE LA PAGINA PRINCIPAL     /////////////////////
  const items = [  // CARRUSEL PARA EL SLIDER PRINCIPAL DEL "HOME"
    {
      key: 'c1', Title: "¡Bienvenido!", Image: "./assets/slide-1.png",
      Subtitle: "AUTOSXPARTESPUNTOCOM S.A. DE C.V., una plataforma para ayudarte a encontrar las refacciones que tú necesitas."
    },
    {
      key: 'c2', Title: "Vendedores certificados", Image: "./assets/slide-2.png",
      Subtitle: "Vendedores certificados y aprobados por nuestro equipo legal, solo los mejores y más confiables vendedores de autopartes."
    },
    {
      key: 'c3', Title: "Conoce nuestro blog", Image: "./assets/slide-3.png",
      Subtitle: "Si quieres conocernos más, visita nuestro blog y nuestras redes sociales de AutosXPartes.com."
    }
  ];

  const videos = [
    { key: 'v1', urlVideo: "https://youtu.be/x5ika6WnDio" },
    { key: 'v2', urlVideo: "https://youtu.be/3cEUA4Ugigg" },
    { key: 'v3', urlVideo: "https://youtu.be/YTyHxrt7dHE" },
    { key: 'v4', urlVideo: "https://youtu.be/Ulzju1uq0qE" },
    { key: 'v5', urlVideo: "https://youtu.be/_dacRf4cRyg" },
    { key: 'v6', urlVideo: "https://youtu.be/AeAVYduPtD0" },
    { key: 'v7', urlVideo: "https://youtu.be/D3j4Z1-fh5Y" }
  ]
  ////////////////////     ARREGLOS DE OBJETOS PARA LOS CARRUSELES DE LA PAGINA PRINCIPAL     ////////////////////

  // PARAMETROS PRINCIPALES ( RESPONSIVE ) PARA LOS CARRUSELES DE ASOCIADOS Y VIDEOS
  const settings = { 
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          arrows: false,
        }
      }
    ],
  };

  const settings2 = { 
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
        }
      }
    ],
  };

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }:any) => (
    <SVG
      src={process.env.REACT_APP_ICONSERVER+'dz-angle-left'}
      className={ "slick-prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")}
      aria-disabled={currentSlide === 0 ? true : false}
      {...props}
    />
  );

  const SlickArrowRight = ({ currentSlide, slideCount, ...props }: any) => (
    <SVG
      src={process.env.REACT_APP_ICONSERVER+'dz-angle-right'}
      className={ "slick-next slick-arrow" + (currentSlide === slideCount - 1 ? " slick-disabled" : "")} 
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      {...props}
    />
  );

  useEffect(() => {
    startLoadAssociatesHome({dispatchHome: dispatchHome })
  }, [dispatchHome])

  const moverScroll = () => {
    const element = document.getElementById('section-1');
    if (element) {
      element.scrollIntoView();
    }
  }
  
  return (
    <div className="home_principal">
      <div className="home_slider">  {/* SLIDER PRINCIPAL DE LA PAGINA "HOME" */}
        <Box sx={{ maxWidth: "1366px", margin: "auto" }}>
          <Carousel navButtonsAlwaysVisible={true} interval={10000}>
            {
              items.map( (item) =>
                <div className="home_slider_container" key={item.key}>
                  <div className="home_slider_container_text">
                    <div className="home_slide_text">
                      <div>
                        <div>{item.Title}</div>
                        <p>
                          {item.Subtitle}
                        </p>
                        { ( function() {
                          switch ( item.key ) {
                            case 'c1':
                              return  <Button
                                        variant="contained"
                                        color="warning"
                                        size="medium"
                                        href="/us"
                                      >
                                        Ver más
                                      </Button>
                            case 'c2':
                              return  <Button
                                        variant="contained"
                                        color="warning"
                                        size="medium"
                                        onClick={ moverScroll }
                                      >
                                        Ver más
                                      </Button>
                            case 'c3':
                              return  <Button
                                        variant="contained"
                                        color="warning"
                                        size="medium"
                                        href="https://www.facebook.com/autosXpartes?mibextid=LQQJ4d"
                                        target="_blank"
                                      >
                                        Conócenos
                                      </Button>
                            }
                          })()
                        }
                      </div>
                    </div>
                  </div>
                  <CardMedia className="home_slider_image" image={item.Image} title={item.Title}/>
                </div>
              )
            }
          </Carousel>
        </Box>
      </div>

      <div className="home_instalar_app">
        <InstallPWA />
      </div>

      <div className="home_carousel_publicaciones">
        <BuscarModal/>
        {/* SLIDER DE LAS PUBLICACIONES DE AUTOS */}
        {/*<div className="home_slide_buttons_orange">
          <Button 
            variant="contained" 
            color="warning"
            //onClick={() => (setFindModal(true))}
            href="/find"
            size="large"
            sx={{ fontSize: "21px" }}
          >
            Busca tu autoparte
          </Button>
        </div>*/}
        <p className="home_subtitulos">Autos, Motos, Vehiculos Comerciales y más</p>
        <HomeSliderCardsPublicaciones/>
      </div>
      <div className="home_asociados" id="section-1">
        <div className="home_asociados_container">
          <p className="home_subtitulos">Nuestros asociados certificados</p>  {/* SLIDER DE LOS ASOCIADOS */}

          {
            !carouselAssociates ?
            <Box sx={{ height: "500px", display: "flex", alignItems: "center", justifyContent: "center" }}>
              <CircularProgress size={30} sx={{ mr: 1 }} /><Typography variant="h5">Cargando...</Typography>
            </Box>
            :
            <Slider
              {...settings}
              prevArrow={ <SlickArrowLeft /> }
              nextArrow={ <SlickArrowRight /> }
            >
              {
                carouselAssociates.map( (asociados) => 
                <Box key={asociados.idAsociado}>
                  <div className="home_asociados_fotos_container">
                    <CardMedia
                      component="img"
                      image={asociados.portada ? process.env.REACT_APP_USERSERVER+asociados.portada : './assets/sin-foto.png'}
                      alt="imagenes"
                      height={(responsive ? "250px" : "300px" )}
                    />
                    <div className="home_asociados_fotos_logo">
                      <Avatar
                        alt="imagen"
                        src={asociados.logo ? process.env.REACT_APP_USERSERVER+asociados.logo : process.env.REACT_APP_ICONSERVER+'dz-user-circle'}
                        sx={{ width: (!responsive ? 120 : responsive2 ? 60 : 80 ), height: (!responsive ? 120 : responsive2 ? 60 : 80 ), borderColor: "black", borderStyle: "solid", borderWidth: "3px" }}
                      />
                    </div>
                    <Box className="home_asociados_fotos_texto">
                      <Typography sx={{ p: 1, color: "white", fontSize: (!responsive ? "20px" : responsive2 ? "14px" : "16px" ), fontWeight: 500 }} >
                        {asociados.establecimiento}
                      </Typography>
                    </Box>
                  </div>
                </Box>
                )
              }
            </Slider>
          }
          <div className="home_slide_buttons_orange">
            <Button variant="contained" color="warning" size="large" href="/register" sx={{ fontSize: (responsive2 ? "18px" : "21px"), mt: "20px" }}>
              Registrate Aquí
            </Button>
          </div>
        </div>
      </div>

      <div className="home_videos">
        <p className="home_subtitulos">Visita nuestros canales de información</p>  {/* SLIDER DE LOS VIDEOS */}
        <div>
          {/*<Carousel navButtonsAlwaysVisible={true} autoPlay={false}>
            {
              videos.map( (videos) =>
              <div key={videos.key}>
                <CardMedia>
                  <ReactPlayer width="100%" height={(responsive ? "300px" : "600px" )} url={videos.urlVideo}/>
                </CardMedia>
              </div>
              )
            }
          </Carousel>*/}
          <Slider
            {...settings2}
            prevArrow={ <SlickArrowLeft /> }
            nextArrow={ <SlickArrowRight /> }
          >
            {
              videos.map( (videos) =>
              <div key={videos.key}>
                <CardMedia sx={{ paddingLeft: "8px", paddingRight: "8px" }}>
                  <ReactPlayer width="100%" height={(responsive ? "250px" : "300px" )} url={videos.urlVideo}/>
                </CardMedia>
              </div>
              )
            }
          </Slider>
        </div>
      </div>
    </div>
  )
}