import { useEffect, createContext, useReducer, useState } from 'react';
import { SalesReducerType, initialSalesState, salesReducer } from '../reducers/SalesReducer';
import { PublicationsReducerType, initialPublicationsState, publicationsReducer } from '../reducers/PublicationsReducer';
import { MySales } from '../MySales';
import { NotFound } from '../../../../notfound/NotFound';
import { Publicaciones } from '../components/misPublicaciones/Publicaciones';
import { Ventas } from '../components/misVentas/Ventas';
import { PlanVentas } from '../components/miPlan/Plan';
import { RootState, AppDispatch } from '../../../../../store/store';
import { startLoadFilters
  } from '../../../../../reducers/filterReducer';
  import { useSelector, useDispatch } from 'react-redux';

//DEFINIMOS UN CONTEXT PARA CARGAR EL REDUCER DE VENTAS
type salesContextType = {
    salesReducerState: SalesReducerType,
    dispatchSales: React.Dispatch<any>
}
const salesContextDefaultValues: salesContextType = {
    salesReducerState: initialSalesState,
    dispatchSales: () => null
};
export const SalesContext = createContext<salesContextType>(salesContextDefaultValues);

//DEFINIMOS UN CONTEXT PARA CARGAR EL REDUCER DE PUBLICACIONES
type publicationsContextType = {
    publicationsReducerState: PublicationsReducerType,
    dispatchPublications: React.Dispatch<any>,
}
const publicationsContextDefaultValues: publicationsContextType = {
    publicationsReducerState: initialPublicationsState,
    dispatchPublications: () => null,
};
export const PublicationsContext = createContext<publicationsContextType>(publicationsContextDefaultValues);

//DEFINIMOS UN CONTEXT PARA NAVEGAR ENTRE COMPONENTES
type menuContextType = {
    componentJSX: JSX.Element,
    setIndexComponent: any,
    indexComponent: any
} 
const menuContextDefaultValues: menuContextType = {
    componentJSX: <NotFound/>,
    setIndexComponent: undefined,
    indexComponent: undefined
};
export const MenuContext = createContext<menuContextType>(menuContextDefaultValues);

//creamos el Functional Component
export const ContextSales = () => {

    //REDUCER DE VENTAS
    const [salesReducerState, dispatchSales] = useReducer(salesReducer, initialSalesState)

    //REDUCER DE PUBLICACIONES
    const [publicationsReducerState, dispatchPublications] = useReducer(publicationsReducer, initialPublicationsState);

    const dispatchRedux = useDispatch<AppDispatch>();

    //NAVEGACION DE COMPONENTES
    const [componentJSX, setComponentJSX] = useState<JSX.Element>(<NotFound/>);
    const [indexComponent, setIndexComponent] = useState(0);
    useEffect(() => {
        switch (indexComponent) {
            case 0: setComponentJSX(<PlanVentas />); break;       
            case 1: setComponentJSX(<Publicaciones />); break;       
            case 2: setComponentJSX(<Ventas />); break;       
            default: setComponentJSX(<NotFound />); break;
        }
        if (indexComponent ===1){
            
            dispatchRedux<any>(startLoadFilters());
        }
    }, [indexComponent])    

    return (

            <SalesContext.Provider value = {{ salesReducerState, dispatchSales }}>
                <PublicationsContext.Provider value = {{ publicationsReducerState, dispatchPublications }}>
                    <MenuContext.Provider value = {{ componentJSX, indexComponent, setIndexComponent }}>    
                        <MySales />
                    </MenuContext.Provider>
                </PublicationsContext.Provider>
            </SalesContext.Provider>
    )
} 
