import { Route, RouteProps, Redirect } from 'react-router';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { CircularProgress } from '@mui/material';

import "./style/private-route.scss";

export type PublicRouteProps = {
  isAuthenticated: boolean,
  redirectPath: string,  
} & RouteProps;

export default function PublicRoute({isAuthenticated, redirectPath, ...routeProps}: PublicRouteProps) {

  //LEER VARIABLES DE REDUX
  const storeAuth = (state: RootState ) => state.auth;
  const { checking } = useSelector(storeAuth);
   
  if (checking) {
    return (<div className='public_route_container_cargando'><div className='public_route_cargando'><CircularProgress color="warning"/> Cargando...</div></div>)
  } else {
    if(isAuthenticated) {
        return <Redirect to={{ pathname: redirectPath }} />;
        } else {
        return <Route {...routeProps} />;
    }
  }


  // if (checking) {
  //   return (<div className='public_route_container_cargando'><div className='public_route_cargando'><CircularProgress color="warning"/> Cargando...</div></div>)
  // } else {
  //     return <Route {...routeProps} />;
  // }

};