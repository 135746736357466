
import { Navbar } from "./components/navbar/Navbar"
import { ViewModal } from './Modal';
import { ShowMessage } from "./ShowMessage";
import { SubModal } from "./Submodal";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import { startLoadUser } from '../../reducers/authReducer';
import { startLoadMenu } from '../../reducers/sidebarReducer';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import relativeTime from 'dayjs/plugin/relativeTime';
import { dayjsLocaleObject } from '../../helpers/dayjsTime';

//LISTA DE COMPONENTES DISPONIBLES
import { Catalogo } from './components/catalogo/Catalogo';
import { Default } from './components/default/Default';
import { Sidebar } from './components/sidebar/Sidebar';

import "./styles/principal.scss"

//PERSONALIZACION DE FECHAS
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.locale(dayjsLocaleObject);

type componentsType = {
    id: string,
    component: JSX.Element
}
export const components: componentsType[] = [
    {id: 'Home',    component: <Catalogo />},
] 

export const Principal = () => {

    // console.log('Me renderize Principal');

    //LEER VARIABLES DE REDUX
    const storeSidebar = (state: RootState ) => state.sidebar;
    const { activeComponent } = useSelector(storeSidebar);
    const storeDarkMode = (state: RootState ) => state.darkmode;
    const { darkMode } = useSelector(storeDarkMode);

    //ESCRIBIR EN EL STORE DE REDUX
    const dispatchRedux = useDispatch<AppDispatch>();  

    //CARGAR PERSONALIZACION DEL USUARIO
    useEffect(() => {
        dispatchRedux<any>(startLoadMenu())
        dispatchRedux<any>(startLoadUser());
    }, [dispatchRedux])
    
    //EVALUAMOS EL COMPONENTE A MOSTRAR
    const [componentJSX, setComponentJSX] = useState<JSX.Element>(<Default />)
    useEffect(() => {
        setComponentJSX(components.find(component => component.id === activeComponent)?.component || <Default />);
    },[activeComponent])
    
    return (
        <div>
            <Navbar />
            <div className={(darkMode) ? 'principal_main principal_dark' : 'principal_main principal_light' }>
                <aside>
                    <Sidebar />
                </aside>
                <main>
                    { componentJSX }  
                </main>
            </div>
            <ViewModal />
            <SubModal />
            <ShowMessage /> 
        </div>
    )
}
