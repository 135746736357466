import { useDispatch, useSelector } from "react-redux";
import SVG from 'react-inlinesvg';
import { RootState, AppDispatch } from '../../../../store/store';
import { activationAlert } from '../../../../reducers/navbarReducer';
import { useContext } from 'react';
import { SocketContext } from '../../../../context/SocketContext';
import { socketTypes } from '../../../../hooks/useSocket';
import dayjs from "dayjs";


export const Alerts = () => { 

    // console.log('Se renderizo Alerts');
    
    //LEER EL CONTEXT DE SOCKETS PARA NOTIFICARLOS
    const { enviarSocket } = useContext( SocketContext );

    //LEER VARIABLES DE REDUX
    const storeDarkMode = (state: RootState ) => state.darkmode;
    const { darkMode } = useSelector(storeDarkMode);
    const storeNavbar = (state: RootState) => state.navbar;
    const { alerts } = useSelector(storeNavbar);

    //ESCRIBIR EN EL STORE DE REDUX
    const dispatchRedux = useDispatch<AppDispatch>(); 

    //ONCLICK DE ITEM
    const handleItemClick = async (id: string) => {
        await dispatchRedux<any>(activationAlert({id: id}));
        //notificar a los clientes el cambio de estado
        const type: socketTypes = 'Alert';
        enviarSocket(type);
    } 
    
    return (
        <>
        {
            (alerts[0].key === 0) 
            ? (
                <div className="navbar_modal"></div>
            )
            : ( 
                alerts.map((alert) => (
                    <div 
                        key={ alert.key }
                        id={ alert.id }
                        className={(darkMode) 
                            ? 'navbar_modal_grid navbar_modal_grid_dark' 
                            : 'navbar_modal_grid navbar_modal_grid_light'
                        }
                        onClick = { (e) => { handleItemClick(e.currentTarget.id) } }
                    >
                        <div className={ (alert.active) ? 'navbar_modal_icon navbar_modal_icon_red' : 'navbar_modal_icon' }>
                            {/* <i className="fas fa-exclamation"></i> */}
                            <SVG src={process.env.REACT_APP_ICONSERVER+'dz-alert-circle'} height={25} fill="currentColor"/>
                        </div>
                        <div className="navbar_modal_text">{ alert.text }</div>
                        <div className={ (alert.active) ? 'navbar_modal_active navbar_modal_active_red' : 'navbar_modal_active' }/>
                        <div
                            className={(darkMode) 
                                ? 'navbar_modal_date navbar_modal_date_dark' 
                                : 'navbar_modal_date navbar_modal_date_light'
                            } 
                        >
                            { dayjs(alert.date).fromNow() }
                        </div>
                    </div>                                      
                ))
            )
        }
        </>
    )
}
