
import "../styles/image-carousel-zoom.scss"
import React, { useContext, useEffect, useRef, useState } from "react";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Box, Card, CardMedia } from "@mui/material";

import SVG from 'react-inlinesvg';
import { PublicationDataContext } from "../context/ContextPublicationData";

import {  setImagesZoomCarousel,
  
} from './../reducers/PublicationDataReducer';
export type ImageType = { id: number; url: string };



const ImageCarousel: React.FC<{ images?: ImageType[] }> = ({ images }) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [selectedImage, setSelectedImage] = useState<ImageType>();
  const carouselItemsRef = useRef<HTMLDivElement[] | null[]>([]);
  const { publicationDataReducerState, dispatchPublicationData } = useContext(PublicationDataContext);
  //const { datosPublicacion, modalImagesZoomCarousel  } = publicationDataReducerState;

  useEffect(() => {
    if (images && images[0]) {
      carouselItemsRef.current = carouselItemsRef.current.slice(
        0,
        images.length
      );
      setSelectedImageIndex(publicationDataReducerState.indexCarousel as number);
      setSelectedImage(images[publicationDataReducerState.indexCarousel as number]);
    }
  }, [images]);

  const handleSelectedImageChange = (newIdx: number) => {
    if (images && images.length > 0) {

      setSelectedImage(images[newIdx]);
      setSelectedImageIndex(newIdx);
      dispatchPublicationData(setImagesZoomCarousel({clicID : newIdx}));

      if (carouselItemsRef?.current[newIdx]) {
        carouselItemsRef?.current[newIdx]?.scrollIntoView({
          inline: "center",
          behavior: "smooth"
        });
      }
    }
  };

  const handleRightClick = () => {
    if (images && images.length > 0) {
      let newIdx = selectedImageIndex + 1;
      if (newIdx >= images.length) {
        newIdx = 0;
      }
      handleSelectedImageChange(newIdx);
    }
  };

  const handleLeftClick = () => {
    if (images && images.length > 0) {
      let newIdx = selectedImageIndex - 1;
      if (newIdx < 0) {
        newIdx = images.length - 1;
      }
      handleSelectedImageChange(newIdx);
    }
  };

  /*function ImageMagnifier({
    width,
    height,
    magnifierHeight = 150,
    magnifieWidth = 150,
    zoomLevel = 1.75
  }: {
    width?: string;
    height?: string;
    magnifierHeight?: number;
    magnifieWidth?: number;
    zoomLevel?: number;
  }) {
    const [[x, y], setXY] = useState([0, 0]);
    const [[imgWidth, imgHeight], setSize] = useState([0, 0]);
    const [showMagnifier, setShowMagnifier] = useState(false);
    return (
      <div
        style={{
          position: "relative",
          height: height,
          width: width
        }}
      >
        <img
        src={selectedImage?.url}
        className="selected-image"
        style={{  height: height, width: width }}
      
          onMouseEnter={(e) => {
            // update image size and turn-on magnifier
            const elem = e.currentTarget;
            const { width, height } = elem.getBoundingClientRect();
            setSize([width, height]);
            setShowMagnifier(true);
          }}
          onMouseMove={(e) => {
            // update cursor position
            const elem = e.currentTarget;
            const { top, left } = elem.getBoundingClientRect();
  
            // calculate cursor position on the image
            const x = e.pageX - left - window.pageXOffset;
            const y = e.pageY - top - window.pageYOffset;
            setXY([x, y]);
          }}
          onMouseLeave={() => {
            // close magnifier
            setShowMagnifier(false);
          }}
        />
  
        <div
          style={{
            display: showMagnifier ? "" : "none",
            position: "absolute",
  
            // prevent maginier blocks the mousemove event of img
            pointerEvents: "none",
            // set size of magnifier
            height: `${magnifierHeight}px`,
            width: `${magnifieWidth}px`,
            // move element center to cursor pos
            top: `${y - magnifierHeight / 2}px`,
            left: `${x - magnifieWidth / 2}px`,
            opacity: "1", // reduce opacity so you can verify position
            border: "1px solid lightgray",
            backgroundColor: "white",
            backgroundImage: `url('${selectedImage?.url}')`,
            backgroundRepeat: "no-repeat",
  
            //calculate zoomed image size
            backgroundSize: `${imgWidth * zoomLevel}px ${
              imgHeight * zoomLevel
            }px`,
  
            //calculete position of zoomed image.
            backgroundPositionX: `${-x * zoomLevel + magnifieWidth / 2}px`,
            backgroundPositionY: `${-y * zoomLevel + magnifierHeight / 2}px`
          }}
        ></div>
      </div>
    );
  }*/

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }:any) => (
    <SVG
      src={process.env.REACT_APP_ICONSERVER+'dz-angle-left'}
      className={ "slick-prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")}
      aria-disabled={currentSlide === 0 ? true : false}
      {...props}
    />
  );

  const SlickArrowRight = ({ currentSlide, slideCount, ...props }: any) => (
    <SVG
      src={process.env.REACT_APP_ICONSERVER+'dz-angle-right'}
      className={ "slick-next slick-arrow" + (currentSlide === slideCount - 1 ? "slick-disabled" : "" )} 
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      {...props}
    />
  );

  return (
    <div className="carousel-container">
        {/*<button
          className="carousel__button carousel__button-left"
          onClick={handleLeftClick}
        >
          Prev
        </button>*/}

        <SlickArrowLeft onClick={handleLeftClick}/>
        
        {/*<ImageMagnifier />*/}
        <div>
          <TransformWrapper>
            <TransformComponent>
              <Card variant="outlined" sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Box>
                  <CardMedia
                    component="img"
                    image={selectedImage?.url}
                    alt=""
                    className="carousel_image_style"
                  />
                </Box>
              </Card>
            </TransformComponent>
          </TransformWrapper>
        </div>

        {/*<button 
          className="carousel__button carousel__button-right"
          onClick={handleRightClick}
        >
          Next
        </button>*/}

        <SlickArrowRight onClick={handleRightClick}/>
      {/*<div className="carousel">
        <div className="carousel__images">
          {images &&
            images.map((image, idx) => (
              <div
                onClick={() => handleSelectedImageChange(idx)}
                style={{ backgroundImage: `url(${image.url})` }}
                key={image.id}
                className={`carousel__image ${
                  selectedImageIndex === idx && "carousel__image-selected"
                }`}
                ref={(el) => (carouselItemsRef.current[idx] = el)}
              />
            ))
          }
        </div>
      </div>*/}
    </div>
  );
};

export default ImageCarousel;