
import { Alert, AlertTitle, Button, CircularProgress, InputAdornment, styled, TextField, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { cleanError, onChange, setError, startUpdateReceiveEmail } from "./reducers/ContactReducer";
import validator from 'validator';
import "./styles/contacto-styles.scss"
import { useEffect } from "react";
import { useMediaQuery } from "@material-ui/core";

const PhoneInput = styled( TextField ) `input[type='number']::-webkit-inner-spin-button, input[type='number']::-webkit-outer-spin-button { display: none; }`;

export const ContactoScreen = () => {

    useEffect(() => {
      window.scrollTo(0, 0)
    }, []);
  
  const storeRegister = (state: RootState ) => state.contact;
  const {
      nombreApellido,
      nombreApellidoError,
      email,
      emailError,
      telefono,
      telefonoError,
      mensaje,
      mensajeError,
      receiveMessageCorrect,
      receiveMessageSending,
      receiveMessageError,
      envioFinalizado
  } = useSelector(storeRegister);
  const dispatchRedux = useDispatch<AppDispatch>();

  const handleSendMesagge = () => {
    let error = false;
      if (!nombreApellido) {
          dispatchRedux(setError({ errorField: 'nombreApellidoError', label: 'Ingrese su nombre y apellido' }));
          error=true;
      } else {
        if (!validator.isLength(nombreApellido,{min: 4})) {
            dispatchRedux(setError({ errorField: 'nombreApellidoError', label: 'Agregre más valores por favor' }));
            error=true;
        } else {
            dispatchRedux(cleanError({ errorField: 'nombreApellidoError' }));
        }
      }

      if (!email) {
          dispatchRedux(setError({ errorField: 'emailError', label: 'Ingrese un correo electrónico' }));
          error=true;
      } else {
        if (!validator.isEmail(email)) {
            dispatchRedux(setError({ errorField: 'emailError', label: 'Correo electrónico incorrecto' }));
            error=true;
        } else {
            dispatchRedux(cleanError({ errorField: 'emailError' }));
        }
      }

      if (telefono) 
      {
        if (!validator.isLength(telefono,{min: 10, max:10})) {
            dispatchRedux(setError({ errorField: 'telefonoError', label: 'Número telefónico incorrecto' }));
            error=true;
        } else {
            dispatchRedux(cleanError({ errorField: 'telefonoError' }));
        }
      }

      if (!mensaje) {
        dispatchRedux(setError({ errorField: 'mensajeError', label: 'Escriba su mensaje' }));
        error=true;
      } else {
        if (!validator.isLength(mensaje,{min: 4})) {
            dispatchRedux(setError({ errorField: 'mensajeError', label: 'Agrege más valores' }));
            error=true;
        } else {
            dispatchRedux(cleanError({ errorField: 'mensajeError' }));
        }
      }

      if (!error) {
          //si no hay error creamos una publicacion nueva
          dispatchRedux<any>(startUpdateReceiveEmail());
      }
  }

  const responsive = useMediaQuery('(max-width:900px)');
  const responsive2 = useMediaQuery('(max-width:300px)');

  return (
    <div className="contacto_container_format">
      <p className="contacto_title_container">Contacto</p>
      <div className="contact_container_info">
        <Typography fontSize={(!responsive ? "22px" : responsive2 ? "12p4" : "18px")}>
        ¿Tienes alguna duda, pregunta, queja o quieres información?. Ponte en contacto con nuestro equipo de AutosXPartes.com. Te ayudaremos a resolverlo, escribe tu nombre y tu correo electrónico y envíanos tu mensaje
        </Typography>
      </div>
      <div className="contacto_container_form">
        <TextField
          id="nombre-apellido" variant="filled" fullWidth
          value={ nombreApellido } error={ nombreApellidoError ? true : false }
          label={nombreApellidoError ? nombreApellidoError : 'Nombre y primer apellido'} sx={{ mb: 1 }}
          onChange={ ({target}) => dispatchRedux( onChange({ field: 'nombreApellido', value: target.value })) }
        />
        <TextField
          id="correo" variant="filled" fullWidth
          value={ email } error={ emailError ? true : false }
          label={emailError ? emailError : 'Correo electrónico'} sx={{ mb: 1, mt: "25px" }}
          onChange={ ({target}) => dispatchRedux( onChange({ field: 'email', value: target.value })) }
        />
        <PhoneInput
          id="telefono" variant="filled" fullWidth type="number"
          value={ telefono } error={ telefonoError ? true : false }
          label={ telefonoError ? telefonoError : 'Teléfono (Opcional)' } sx={{ mb: 1, mt: "25px" }}
          onChange={ ({target}) => dispatchRedux( onChange({ field: 'telefono', value: target.value })) }
          InputProps={{
            startAdornment: <InputAdornment position="start">MX (+52)</InputAdornment>
          }}
        />
        <TextField
          id="mensaje" variant="filled" multiline rows={5}
          value={ mensaje || "" } error={ mensajeError ? true : false }
          label={ mensajeError ? mensajeError : 'Mensaje' } sx={{ mb: 1, mt: "25px", width: "100%" }}
          onChange={ ({target}) => dispatchRedux( onChange({ field: 'mensaje', value: target.value })) }
        />
        
        { receiveMessageCorrect && <Alert severity="success" sx={{ marginTop: 2 }}><AlertTitle>{ receiveMessageCorrect }</AlertTitle></Alert> }
        { receiveMessageError && <Alert severity="error" sx={{ marginTop: 2 }}><AlertTitle>{ receiveMessageError }</AlertTitle></Alert> }

        {
          envioFinalizado
          ? <Button disabled fullWidth size="large" variant="contained" sx={{ marginTop: "25px" }}>Enviado</Button>
          : ( receiveMessageSending
              ? <Button disabled fullWidth size="large" variant="contained" endIcon={<CircularProgress color="inherit" size="1.5rem" />} sx={{ marginTop: "25px" }}>Enviando...</Button>
              : <Button fullWidth size="large" variant="contained" onClick={handleSendMesagge} sx={{ marginTop: "25px" }}>Enviar</Button>
            )
        }
      </div>
    </div>
  )
}