//definimos los ids de cada modal
type modalIdsType = {
    Alert: string,
    Message: string,
    Notification: string
}
export const modalIds: modalIdsType  = {
    Alert: 'Alertas',
    Message: 'Mensajes',
    Notification: 'Notificaciones'
}

type submodalIdsType = {
    Message: string,
}
export const submodalIds: submodalIdsType  = {
    Message: 'Mensaje'
}

type messagesIdsType = {
    Message: string,
    Alert: string,
    Information: string,
    Question: string
}
export const messagesIds: messagesIdsType  = {
    Message: 'Message',
    Alert: 'Alert',
    Information: 'Information',
    Question: 'Question'
}

//definimos la estructura del estilo del modal
export type modalStyles = {
    overlay: {
        top: number,
        left: number,
        right: number,
        bottom: number,
        backgroundColor: string
    },
    content : {
      top : string,
      left : string,
      right : string,
      bottom : string,
      padding: string,
      marginRight: string,
      transform: string
    }
};

//definimos la estructura del reducer
interface ModalState {
    showModal: boolean,
    showSubmodal: boolean,
    showMessage: boolean,
    modal: string,
    submodal: string,
    modaltype: string,
    title: string,
    message: string,
    style: modalStyles,
    styleSubmodal: modalStyles
};
//definimos el valor inicial
//http://reactcommunity.org/react-modal/styles/
const initialState: ModalState = {
    showModal: false,
    showSubmodal: false,
    showMessage: false,
    modal: '',
    submodal: '',
    modaltype: '',
    title: '',
    message: '',
    style: { 
        overlay: {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.75)'
        },
        content: {
            top: '40px',
            left: '40px',
            right: '40px',
            bottom: '40px',
            padding: '20px',
            marginRight: '',
            transform: ''
        }
    },
    styleSubmodal: { 
        overlay: {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.75)'
        },
        content: {
            top: '40px',
            left: '40px',
            right: '40px',
            bottom: '40px',
            padding: '20px',
            marginRight: '',
            transform: ''
        }
    }
}
//definimos los types del reducer
type ModalPayload = { modal: string, style: modalStyles }
type MessagePayload = { modaltype: string, title: string, message: string }
type ModalAction =  { type: 'ShowModal' , payload: ModalPayload } | 
                    { type: 'CloseModal' } | 
                    { type: 'ShowSubmodal' , payload: ModalPayload } | 
                    { type: 'CloseSubmodal' } |
                    { type: 'ShowMessage' , payload: MessagePayload } | 
                    { type: 'CloseMessage' };

//definimos el reducer
export const modalReducer = (state: ModalState = initialState, action: ModalAction): ModalState => {
    switch (action.type) {
        case 'ShowModal': return { ...state, showModal: true, modal: action.payload.modal, style: action.payload.style }
        case 'CloseModal': return { ...state, showModal: false, modal: ''}
        case 'ShowSubmodal': return { ...state, showSubmodal: true, submodal: action.payload.modal, styleSubmodal: action.payload.style }
        case 'CloseSubmodal': return { ...state, showSubmodal: false, submodal: ''}
        case 'ShowMessage': return { ...state, showMessage: true, modaltype: action.payload.modaltype, title: action.payload.title, message: action.payload.message }
        case 'CloseMessage': return { ...state, showMessage: false, message: ''}
        default: return state;
    }
}

//definimos los actions sincronos del reducer
export const showModal = (modalData: ModalPayload): ModalAction => {
    return{
        type: 'ShowModal',
        payload: modalData
    }
}
export const closeModal = (): ModalAction => {
    return{
        type: 'CloseModal'
    }
}
export const showSubmodal = (modalData: ModalPayload): ModalAction => {
    return{
        type: 'ShowSubmodal',
        payload: modalData
    }
}
export const closeSubmodal = (): ModalAction => {
    return{
        type: 'CloseSubmodal'
    }
}
export const showMessage = (modalData: MessagePayload): ModalAction => {
    return{
        type: 'ShowMessage',
        payload: modalData
    }
}
export const closeMessage = (): ModalAction => {
    return{
        type: 'CloseMessage'
    }
}