
import { Alert, Box, Button, Dialog, IconButton, Typography } from "@mui/material"
import { useContext } from "react";
import { QuestionsContext } from "../../../context/QuestionsContext";
import { closeModalReportarPregunta, startReportQuestion } from "../../../reducers/QuestionsReducer";

import SVG from 'react-inlinesvg';

export const ReportarPreguntaModal = () => {

  //LEER EL REDUCER LOCAL
  const { questionsReducerState, dispatchQuestion } = useContext(QuestionsContext); 
  const { modalReportarPregunta, mensajesModal, idPregunta } = questionsReducerState;

  return (
    <Dialog
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={modalReportarPregunta}
      onClose={()=>{dispatchQuestion(closeModalReportarPregunta())}}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            maxWidth: "1200px",
          },
        },
      }}
    >
      <Box sx={{ backgroundColor: "black", color: "white", p: 3, display: "flex" }}>
        <Typography variant='h5'>Reportar esta pregunta</Typography>
        <IconButton
          aria-label="close"
          onClick={()=>{dispatchQuestion(closeModalReportarPregunta())}}
          sx={{
            position: 'absolute',
            right: 18
          }}
        >
          <SVG src={process.env.REACT_APP_ICONSERVER+'dz-error'} width={20} fill="white" />
        </IconButton>
      </Box>
      <Box sx={{ p: "25px 20px 10px 20px" }}>
        <Typography sx={{ fontSize: "18px" }}>¿Estas seguro que quieres reportar esta pregunta?</Typography>
      </Box>
      {
        mensajesModal && <Alert severity="error"> {mensajesModal} </Alert>
      }
      <Box sx={{ display: "flex", justifyContent: "center", p: 2 }} >
        <Box>
          <Button
            color="warning" sx={{ marginRight: 1 }} size="large"
            onClick={()=>{dispatchQuestion(closeModalReportarPregunta())}}
          >
            Cancelar
          </Button>
          <Button
            variant="contained" size="large" sx={{ marginLeft: 1 }}
            onClick={()=>{dispatchQuestion(startReportQuestion({dispatchLocalQuestions: dispatchQuestion, idQuestion: idPregunta}))}}
          >
            Aceptar
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}