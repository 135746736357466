//DEFINICION DE TIPOS DEL REDUCER
export type Header = {
    id:     string,
    order:  number,
    label:  string,
    field:  keyof Item,
    aligment: 'center' | 'left' | 'right' | 'justify',
    type: 'id' | 'string' | 'boolean' | 'integer' | 'decimal' | 'money' | 'date' | 'time' | 'datetime' | 'icon' | 'picture',
    edit: boolean, 
    requiered: boolean,
    unique: boolean,
    width:  number
};
//DEFINICION DE LA ESTRUCTURA DEL REDUCER
export interface QueryState {
    urlFetch: string,
    title: string,
    icon: string,
    initialHeaders: Header[],
    headerList: Header[],
    initialRows: Item[],
    rowsList: Item[],
    rowsVisibles: number,
    totalRows: number
}
//DEFINIMONS EL VALOR INICIAL
export interface Item{
    [key: string]: any;
    _id:    string,  
    check:  boolean,
};
//EDITAR ESTO ------------------------------------------------------------------------
const initialHeaders: Header[] =
    [
        { id: 'a', order: 1, label: 'Id',        field: '_id',       type: 'id',       edit: false, requiered: true, unique: true,  width: 50,  aligment: 'center'}, //<-No quitar no editar
        { id: 'b', order: 0, label: 'Check',     field: 'check',     type: 'boolean',  edit: false, requiered: true, unique: false, width: 50,  aligment: 'center'}, //<-No quitar no editar
        { id: 'c', order: 0, label: 'Texto',     field: 'texto',     type: 'string',   edit: true,  requiered: true, unique: false, width: 150, aligment: 'left'},
        { id: 'd', order: 0, label: 'Booleano',  field: 'booleano',  type: 'boolean',  edit: true,  requiered: true, unique: false, width: 100, aligment: 'center'},
        { id: 'e', order: 0, label: 'Entero',    field: 'entero',    type: 'integer',  edit: true,  requiered: true, unique: false, width: 100, aligment: 'center'},
        { id: 'f', order: 0, label: 'Decimal',   field: 'decimal',   type: 'decimal',  edit: true,  requiered: true, unique: false, width: 100, aligment: 'right'},
        { id: 'g', order: 0, label: 'Dinero',    field: 'dinero',    type: 'money',    edit: true,  requiered: true, unique: false, width: 100, aligment: 'right'},
        { id: 'h', order: 0, label: 'Fecha',     field: 'fecha',     type: 'date',     edit: true,  requiered: true, unique: false, width: 150, aligment: 'center'},
        { id: 'i', order: 0, label: 'Hora',      field: 'hora',      type: 'time',     edit: true,  requiered: true, unique: false, width: 100, aligment: 'center'},
        { id: 'j', order: 0, label: 'FechaHora', field: 'fechahora', type: 'datetime', edit: true,  requiered: true, unique: false, width: 200, aligment: 'center'},
        { id: 'k', order: 0, label: 'Icono',     field: 'icono',     type: 'icon',     edit: true,  requiered: true, unique: false, width: 100, aligment: 'center'},
        { id: 'l', order: 0, label: 'Imagen',    field: 'imagen',    type: 'picture',  edit: true,  requiered: true, unique: false, width: 100, aligment: 'center'}
    ];
export const initialRows: Item[] = [{_id: '', check: false }];
export const initialStateReducer: QueryState = {
    urlFetch: 'catalogos',
    title: 'catalogos',
    icon: 'dz-clipboard-list',
    initialHeaders: initialHeaders,
    headerList: initialHeaders,
    initialRows: initialRows,
    rowsList: initialRows,
    rowsVisibles: 0,
    totalRows: 0,
}
//EDITAR ESTO ------------------------------------------------------------------------
//DEFINICION DEL REDUCER
type RowsInitialPayload = { rowsList: Item[], totalRows: number }
type RowsPayload = { rowsList: Item[] }
type HeaderPayload = { headerList: Header[] }
type VisiblesPayload = { rowsVisibles: number }
type CheckPayload = { check: boolean }
type CheckRowPayload = { rowid: String } 
type SelectItemPayload = { field: String, rowid: String }
type QueryActions = { type: 'loadInitialRows', payload: RowsInitialPayload} |
                    { type: 'setRows', payload: RowsPayload } |
                    { type: 'setHeader', payload: HeaderPayload } |
                    { type: 'setVisibles', payload: VisiblesPayload } | 
                    { type: 'checkRows', payload: CheckPayload } | 
                    { type: 'checkRow', payload: CheckRowPayload } | 
                    { type: 'selectItem', payload: SelectItemPayload };
export const queryReducer = (state: QueryState = initialStateReducer, action: QueryActions) : QueryState => {
    switch (action.type) {
        // case 'cleanChecks' : return { ...state, rowsList: state.rowsList.map ((row,index) => (index>action.payload.rowsVisible) ? {...row, check: false} : {...row}) } 
        case 'loadInitialRows' : return { ...state, initialRows: action.payload.rowsList, rowsList: action.payload.rowsList.map(row => (row)), totalRows: action.payload.totalRows }
        case 'setRows' : return { ...state, rowsList: action.payload.rowsList }
        case 'setHeader' : return { ...state, headerList: action.payload.headerList }
        case 'setVisibles' : return { ...state, rowsVisibles: action.payload.rowsVisibles } 
        case 'checkRow' : return { ...state, rowsList: state.rowsList.map(row => (row._id === action.payload.rowid) ? { ...row, check : !row.check } : { ...row }) }
        case 'checkRows' : return { ...state, rowsList: state.rowsList.map(row => ({ ...row, check:action.payload.check })) } 
        case 'selectItem' : return { ...state, rowsList: state.rowsList.map(row => (row._id === action.payload.rowid) ? selItem(row, action.payload.field) : noselItem(row)) }
        default: return state;
    }
};

//DEFINICION DE LAS ACCIONES
export const loadInitialRows = (rowsList: any[]): QueryActions => {
    let items: Item[] = [];
    let totalRows: number = 0;
    const counter: number = rowsList.length;
    if (counter > 0) {
        const results: any[] = rowsList
        for (let result of results){
            let newItem: any = {};
            for (let item in result){
                if (item === '_id')
                    newItem[item] = result[item];
                else
                    newItem[item] = { value: result[item], selected: false }
            }
            items.push({
                check: false,
                ...newItem
            });
        }
        totalRows = rowsList.length;
    } 
    else { 
        items = initialRows 
    }
    return{
        type: 'loadInitialRows',
        payload: { rowsList: items, totalRows: totalRows} 
    }
}
export const selectItem = (field: String, rowid: String): QueryActions => {
    return{
        type: 'selectItem', 
        payload: { field: field, rowid: rowid }
    }
}
const selItem = (row: Item, field: String): Item => {
    for (let item in row){
        switch (item) {
            case '_id':
                //sin cambios 
            break;
            case 'check':
                //sin cambios  
            break;   
            default: 
                if (item === field)
                    row[item] = { value: row[item].value, selected: true }
                else 
                    row[item] = { value: row[item].value, selected: false }
            break;
        }
    }    
    return row;
}
const noselItem = (row: Item): Item => {
    for (let item in row){
        switch (item) {
            case '_id':
                //sin cambios  
            break;
            case 'check':
                //sin cambios  
            break;       
            default:
                row[item] = { value: row[item].value, selected: false }
            break;
        }
    }    
    return row;
}

export const setRows = (rowsList: RowsPayload): QueryActions => {
    return{
        type: 'setRows',
        payload: rowsList
    }
}
export const setHeader = (headerList: HeaderPayload): QueryActions => {
    return{
        type: 'setHeader',
        payload: headerList
    }
}
export const setVisibles = (rowsVisibles: VisiblesPayload): QueryActions => {
    return{
        type: 'setVisibles',
        payload: rowsVisibles
    }
}
export const checkRows = (check: CheckPayload ): QueryActions => {
    return{
        type: 'checkRows',
        payload: check
    }
}
export const checkRow = (rowid: CheckRowPayload ): QueryActions => {
    return{
        type: 'checkRow',
        payload: rowid
    }
}