import { AppThunk } from "../store/store";
import { fetchToken } from '../helpers/fetch';
import { showMessage } from './modalReducer';
import dayjs from 'dayjs';


export type notificationType = {
    key: number,
    id: string,
    title: string,
    text: string,
    active: boolean,
    date: Date,
    flag: string
};

const initialModel: notificationType = {
    key: 0,
    id: '',
    title: '',
    text: '',
    active: false,
    date: dayjs().toDate(),
    flag: ''
}

//definimos la estructura del reducer
interface NavbarState {
    title: string,
    smallLogo: boolean,
    alert: number,
    alerts: notificationType[],
    message: number,
    messages: notificationType[],
    notification: number,
    notifications: notificationType[],
    activemessage: notificationType
};
//definimos el valor inicial
const initialState: NavbarState = {
    title: 'Principal',
    smallLogo: false,
    alert: 0,
    alerts: [initialModel],
    message: 0,
    messages: [initialModel],
    notification: 0,
    notifications: [initialModel],
    activemessage: initialModel
}
//definimos los types del reducer
type TitlePayload = { title: string }
type LogoPayload = { smallLogo: boolean }
type ActivePayload = { id: string }
type AlertsPayload = { alerts: notificationType[] }
type AlertPayload = { newalert: notificationType }
type MessagesPayload = { messages: notificationType[] }
type MessagePayload = { newmessage: notificationType }
type NotificationsPayload = { notifications: notificationType[] }
type NotificationPayload = { newnotification: notificationType }
type NavbarAction = { type: 'Title' , payload: TitlePayload } |
                 { type: 'Logo' , payload: LogoPayload } |
                 { type: 'ClearTitle', } |
                 { type: 'Alerts' , payload: AlertsPayload } |
                 { type: 'AddAlert', payload: AlertPayload } |
                 { type: 'ActiveAlert', payload: ActivePayload } |
                 { type: 'ClearAlerts' } |
                 { type: 'CountAlerts' } |
                 { type: 'Messages' , payload: MessagesPayload } |
                 { type: 'AddMessage', payload: MessagePayload } |
                 { type: 'DesactiveMessage' } |
                 { type: 'ActiveMessage' } |
                 { type: 'ClearMessages' } |
                 { type: 'CountMessages' } |
                 { type: 'Notifications' , payload: NotificationsPayload } |
                 { type: 'AddNotification', payload: NotificationPayload } |
                 { type: 'DesactiveNotifications' } |
                 { type: 'ClearNotifications' } |
                 { type: 'CountNotifications' } |
                 { type: 'EnableMessage', payload: ActivePayload };
//definimos el reducer
export const navbarReducer = (state: NavbarState = initialState, action: NavbarAction): NavbarState => {
    switch (action.type) {
        case 'Logo': return { ...state, smallLogo: action.payload.smallLogo }
        case 'Title': return { ...state, title: action.payload.title }
        case 'ClearTitle': return { ...state, title: 'Principal'}
        
        case 'Alerts': return { ...state, alerts: action.payload.alerts }
        case 'AddAlert': return { ...state, alert: state.alert + 1, alerts: [action.payload.newalert, ...state.alerts] }
        case 'ActiveAlert': return { ...state, alerts: state.alerts.map(alert => ((alert.id === action.payload.id) ? { ...alert, active: !alert.active } : { ...alert })) }
        case 'CountAlerts': return { ...state, alert: state.alerts.reduce((counter, alert) => (alert.active) ? counter + 1 : counter, 0) }
        case 'ClearAlerts': return { ...state, alert: 0, alerts: [initialModel] }
        
        case 'Messages': return { ...state, messages: action.payload.messages }
        case 'AddMessage': return { ...state, message: state.message + 1, messages: [action.payload.newmessage, ...state.messages] }
        case 'DesactiveMessage': return { ...state, messages: state.messages.map(message => ((message.id === state.activemessage.id) ? { ...message, active: false } : { ...message })) }
        case 'ActiveMessage' : return { ...state, messages: state.messages.map(message => ((message.id === state.activemessage.id) ? { ...message, active: true } : { ...message })) }
        case 'CountMessages': return { ...state, message: state.messages.reduce((counter, message) => (message.active) ? counter + 1 : counter, 0) }
        case 'ClearMessages': return { ...state, message: 0, messages: [initialModel], activemessage: initialModel }
        case 'EnableMessage' : return { ...state, activemessage: state.messages.filter(message => (message.id === action.payload.id))[0] }

        case 'Notifications': return { ...state, notifications: action.payload.notifications }
        case 'AddNotification': return { ...state, notification: state.notification + 1, notifications: [action.payload.newnotification, ...state.notifications] }
        case 'CountNotifications': return { ...state, notification: state.notifications.reduce((counter, notification) => (notification.active) ? counter + 1 : counter, 0) }
        case 'DesactiveNotifications': return { ...state, notifications: state.notifications.map(notification => ({...notification, active: false}) )}
        
        case 'ClearNotifications': return { ...state, notification: 0, notifications: [initialModel] }

        default: return state;
    }
}

//definimos los actions sincronos del reducer
export const setLogo = (logoData: LogoPayload): NavbarAction => {
    return{
        type: 'Logo',
        payload: logoData
    }
}
export const setTitle = (sidebarItemData: TitlePayload): NavbarAction => {
    return{
        type: 'Title',
        payload: sidebarItemData
    }
}

const countAlerts = (): NavbarAction => {
    return{
        type: 'CountAlerts'
    }
}
const setAlerts = (alertsData: AlertsPayload): NavbarAction => {
    return{
        type: 'Alerts',
        payload: alertsData
    }
}
export const loadAlerts = (alertsData: AlertsPayload): AppThunk => { 
    return (dispatch) => {
        dispatch(setAlerts(alertsData));
        dispatch(countAlerts());
    }
}
export const addAlert = (alertData: AlertPayload): NavbarAction => {
    return{
        type: 'AddAlert',
        payload: alertData
    }
}
const activeAlert = (alertData: ActivePayload): NavbarAction => {
    return{
        type: 'ActiveAlert',
        payload: alertData
    }    
}
export const activationAlert = (alertData: ActivePayload): AppThunk => {
    return async (dispatch, getState) => {

        //cambiando estado de la alerta
        dispatch(activeAlert(alertData));
        dispatch(countAlerts());

        //revisando estado actual de la alerta
        let status: boolean = false;
        for (let alert of getState().navbar.alerts){ 
            if (alert.id === alertData.id) {
                status= alert.active;
            }
        }

        const route = (status) ? `alerts/enable/${ alertData.id }` : `alerts/disable/${ alertData.id }`;
        const body = await fetchToken(route , {}, 'PUT'); 

        if (!body.ok){
            dispatch(activeAlert(alertData));
            dispatch(countAlerts());
            dispatch(showMessage({ modaltype: 'Alert', title: 'Alertas', message: body.msg }));
            return;
        } 
    }
}
export const clearAlerts = (): NavbarAction => {
    return{
        type: 'ClearAlerts'
    }
}

const countMessages = (): NavbarAction => {
    return{
        type: 'CountMessages'
    }
}
const setMessages = (messagesData: MessagesPayload): NavbarAction => {
    return{
        type: 'Messages',
        payload: messagesData
    }
}
export const loadMessages = (messagesData: MessagesPayload): AppThunk => {
    return (dispatch) => {
        dispatch(setMessages(messagesData));
        dispatch(countMessages());
    }
}
export const addMessage = (messageData: MessagePayload): NavbarAction => {
    return{
        type: 'AddMessage',
        payload: messageData
    }
}
const activeMessage = (): NavbarAction => {
    return{
        type: 'ActiveMessage',
    }    
}
const desactiveMessage = (): NavbarAction => {
    return{
        type: 'DesactiveMessage'
    }    
}
export const startDesactivationMessage = (): AppThunk => {
    return async (dispatch, getState) => {
        //validar si el mensaje esta sin leer
        const id = getState().navbar.activemessage.id;
        const active: boolean = getState().navbar.messages.find(message => message.id === id)?.active || false;

        if (active) {
            dispatch(desactiveMessage());
            dispatch(countMessages());

            const route = `messages/disable/${ getState().navbar.activemessage.id }`;
            const body = await fetchToken(route , {}, 'PUT');

            if (body.ok) {

            } else {
                dispatch(activeMessage());
                dispatch(countMessages());

                dispatch(showMessage({ modaltype: 'Alert', title: 'Mensajes', message: body.msg }));
            }

            return true;
        }
        else {
            return false;
        }
    }
}
export const enableMessage = (enableData: ActivePayload): NavbarAction => {
    return{
        type: 'EnableMessage',
        payload: enableData
    }    
}
export const clearMessages = (): NavbarAction => {
    return{
        type: 'ClearMessages'
    }
}

const countNotifications = (): NavbarAction => {
    return{
        type: 'CountNotifications'
    }
}
const setNotifications = (notificationData: NotificationsPayload): NavbarAction => {
    return{
        type: 'Notifications',
        payload: notificationData
    }
}
export const loadNotifications = (notificationData: NotificationsPayload): AppThunk => {
    return (dispatch) => {
        dispatch(setNotifications(notificationData));
        dispatch(countNotifications());
    }
}
export const addNotification = (notificationData: NotificationPayload): NavbarAction => {
    return{
        type: 'AddNotification',
        payload: notificationData
    }
}
export const desactiveNotifications = (): NavbarAction => {
    return{
        type: 'DesactiveNotifications'
    }
}
export const clearNotifications = (): NavbarAction => {
    return{
        type: 'ClearNotifications'
    }
}
export const startDesactiveNotifications = (): AppThunk => {
    return async (dispatch, getState) => {

        const route = `notifications/disable/${ getState().auth.id }`;
        const body = await fetchToken(route , {}, 'PUT');

        if (body.ok){
            dispatch(desactiveNotifications());
            dispatch(countNotifications());
        } else {
            dispatch(showMessage({ modaltype: 'Alert', title: 'Notificaciones', message: body.msg }));
        }
    }
}


//definimos los actions asincronos del reducer
export const startLogo = (logoData: LogoPayload): AppThunk => {
    return (dispatch) => {
        if (logoData.smallLogo) {
            setTimeout( () => { 
                dispatch(setLogo({smallLogo: logoData.smallLogo}));
            },250)
        }
        else {
            dispatch(setLogo({smallLogo: logoData.smallLogo}));
        }
    }
}

const clearTitle = (): NavbarAction => {
    return{
        type: 'ClearTitle'
    }
}

export const clearNavbar = (): AppThunk => {
    return (dispatch) => {
        //quitando notificaciones
        dispatch(clearTitle());
        dispatch(clearAlerts());
        dispatch(clearMessages());
        dispatch(clearNotifications());
        //regresando tamaño de logo
        dispatch(startLogo({smallLogo: false}))
    }
}

export const startLoadAlerts = (): AppThunk  => {
    return async (dispatch, getState) => {
        //cargando alertas
        const body = await fetchToken(`alerts/${ getState().auth.id }`,{},'GET');
        if (body.ok){
            let alerts: notificationType[] = [];
            const counter: number = body.count || 0;
            if (counter > 0) {
                try {
                    for (let alert of body.res){
                        alerts.push({
                            key: alert._id,
                            id: alert._id.toString(),
                            title: '',
                            text: alert.texto,
                            active: alert.activo,
                            date: dayjs(alert.fecha).toDate(),
                            flag: alert.flag             
                        });
                    }
                } catch (error) {
                    dispatch(showMessage({ modaltype: 'Alert', title: 'Alertas', message: 'No fue posible cargar las alertas del usuario' }));
                    return;
                }
            } else {
                alerts.push(initialModel);
            }
            dispatch(loadAlerts({alerts: alerts}));
        } else {
            dispatch(showMessage({ modaltype: 'Alert', title: 'Alertas', message: body.msg }));
            return;
        }
    }
}

export const startLoadNotifications = (): AppThunk  => {
    return async (dispatch, getState) => {
        //cargando alertas
        // console.log('Cargando Notificaciones');
        const body = await fetchToken(`notifications/${ getState().auth.id }`,{},'GET');
        if (body.ok){
            let notifications: notificationType[] = [];
            const counter: number = body.count || 0;
            if (counter > 0) {
                try {
                    for (let notification of body.res){
                        notifications.push({
                            key: notification._id,
                            id: notification._id.toString(),
                            title: '',
                            text: notification.texto,
                            active: notification.activo,
                            date: dayjs(notification.fecha).toDate(),
                            flag: notification.flag               
                        });
                    }
                } catch (error) {
                    dispatch(showMessage({ modaltype: 'Alert', title: 'Notificaciones', message: 'No fue posible cargar las notificaciones del usuario' }));
                    return;
                }
            } else {
                notifications.push(initialModel);
            }
            dispatch(loadNotifications({notifications: notifications}));
        } else {
            dispatch(showMessage({ modaltype: 'Alert', title: 'Notificaciones', message: body.msg }));
            return;
        }
    }
}

export const startLoadMessages = (): AppThunk  => {
    return async (dispatch, getState) => {
        //cargando alertas
        const body = await fetchToken(`messages/${ getState().auth.id }`,{},'GET');
        if (body.ok){
            let messages: notificationType[] = [];
            const counter: number = body.count || 0;
            if (counter > 0) {
                try {
                    for (let message of body.res){
                        messages.push({
                            key: message._id,
                            id: message._id.toString(),
                            title: 'De: ' + message.remitente,
                            text: message.texto,
                            active: message.activo,
                            date: dayjs(message.fecha).toDate(),
                            flag: message.flag               
                        });
                    }
                } catch (error) {
                    dispatch(showMessage({ modaltype: 'Alert', title: 'Mensajes', message: 'No fue posible cargar los mensajes del usuario' }));
                    return;
                }
            } else {
                messages.push(initialModel);
            }
            dispatch(loadMessages({messages: messages}));
        } else {
            dispatch(showMessage({ modaltype: 'Alert', title: 'Mensajes', message: body.msg }));
            return;
        }
    }
}

export const startLoadNavbar = (): AppThunk => {
    return async (dispatch) => {
        dispatch(startLoadAlerts());
        dispatch(startLoadNotifications());
        dispatch(startLoadMessages());
    }
}