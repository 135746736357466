//import { CardMedia, Button } from '@mui/material';
//import SVG from 'react-inlinesvg';
//import { showModalSales } from '../../reducers/SalesReducer';
//import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import './styles/ventas.scss';

export const Ventas = () => {

    //LEER EL REDUCER LOCAL
    // const { salesReducerState } = useContext(SalesContext);
    // const { sales } = salesReducerState;

    return (
      <div className="ventas_publications_container">
        {/*<div>
        <div className="sales_titulo_principal">Mis ventas</div>
        <div className="sales_container_cards">
          {
            sales ? (
              sales.map((sale) => (
                <div className="sales_format_cards" key={sale.id}>
                  <div className="sales_format_cards_image">
                    <CardMedia
                      component="img"
                      image="./assets/carro-5.png"
                      alt="car"
                    />
                  </div>
                  <div className="sales_format_cards_datos">
                    <div className="sales_datos_nombre_producto">
                      Chevroleth Aveo 2015 (partes)
                    </div>
                    <div className="sales_datos_precio">
                      $ { sale.total }
                    </div>
                    <div className="sales_datos_estado">
                      <div>
                        {
                          ( function() {
                              switch ( sale.estado ) {
                                case 'ACTIVA':
                                return <SVG src={process.env.REACT_APP_ICONSERVER+'dz-play'} width={35} fill="green"/>
                                case 'INACTIVA':
                                return <SVG src={process.env.REACT_APP_ICONSERVER+'dz-play-disabled'} width={35} fill="red"/>
                                case 'PAUSADA':
                                return <SVG src={process.env.REACT_APP_ICONSERVER+'dz-pause'} width={35} fill="rgb(255, 145, 0)"/>
                              }
                            }
                          )()
                        }
                      </div>
                      <div>
                        { sale.estado }
                      </div>
                    </div>
                    <div className="sales_button">
                      <Button color="warning" onClick={()=>{dispatchSales(showModalSales(sale))}} sx={{ textTransform: 'none' }}>Ver Publicación</Button>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="sales_format">
                <p>Aún no has generado ninguna venta.</p>
                <Button variant="contained" color="warning">Buscar Refaccion</Button>
              </div>
            )
          }
        </div>
      </div>*/}



        <div className="ventas_proximo_container">
          <svg viewBox="0 0 50 40" className="ventas_icon_soon">
            <g>
              <path d="M44.6,35.3c0-2.1-1.6-3.7-3.7-3.8c-2.1,0-3.7,1.6-3.8,3.7c0,2.1,1.6,3.7,3.7,3.8C42.8,39,44.5,37.4,44.6,35.3z M38.7,35.3
                c0-1.2,1-2.1,2.1-2.1s2.1,1,2.1,2.1s-1,2.1-2.1,2.1S38.7,36.5,38.7,35.3z"/>
              <polygon points="18.3,4.8 3.8,12.3 4.8,14.1 18.5,6.9 31.2,11 43.6,4.7 44.3,6.1 45.8,3.9 47.2,1.6 44.6,1.5 41.9,1.3 42.7,2.9 
                31,8.8 	"/>
              <path d="M13.5,35.3c0-2.1-1.6-3.7-3.7-3.8c-2.1,0-3.7,1.6-3.8,3.7C6,37.3,7.7,39,9.8,39C11.8,39,13.5,37.4,13.5,35.3z M7.7,35.3
                c0-1.2,1-2.1,2.1-2.1s2.1,1,2.1,2.1c0,1.2-1,2.1-2.1,2.1S7.7,36.5,7.7,35.3z"/>
              <path d="M47.1,28.6c-0.2-0.6-0.5-1.3-0.6-1.7c-3.3-1.1-6.6-2.1-9.9-3.2c-1.1-0.3-2.2-0.9-3.3-1c-5.7-0.5-11.3-0.7-16.3,2.7
                c-1.4,0.9-2.9,1.3-4.6,1.6c-2.3,0.3-4.5,0.8-6.7,1.4c-2.2,0.6-4.4,3.6-4.5,5.9c0,0.9,0.3,1.5,1.1,1.7c1,0.2,2,0.3,3,0.5
                c0.4-3.7,1.8-5.6,4.3-5.5c1,0,2.2,0.4,3.1,1c1.7,1.1,2,3,1.6,4.7c7.5,0,14.8,0,22.2,0c-0.2-3.2,1.4-5.5,4-5.7
                c2.9-0.3,4.7,1.6,5.1,5.4c0.6-0.2,1.2-0.4,1.7-0.5c1.3-0.3,1.9-1.1,1.6-2.4C48.3,31.7,47.6,30.2,47.1,28.6z M3,30.9
                c1-1.4,2.3-1.5,3.8-1.5C5.7,31.2,5.1,31.4,3,30.9z M26.5,26.8c-3.1,0-6.1,0-9.4,0c3-2,5-3.4,9.6-3.4C26.7,24.6,26.6,25.7,26.5,26.8
                z M34.3,25.6c0,0.4,0,0.7-0.1,1.1c-2,0-4,0-6.1,0c-0.1-1.1-0.1-2-0.2-3.3c1.7-0.1,3.3,0,4.8,0.2C34.3,23.8,34.3,24,34.3,25.6z
                M38.4,26.7c-1,0-2.1,0-3.2,0c0-0.6,0-1.2,0-2c1.2,0.7,2.3,1.2,3.3,1.8C38.5,26.5,38.5,26.6,38.4,26.7z"/>
              <path d="M3.1,28.3c0.7-0.6,1.4-1.1,2.2-1.3c1.3-0.3,2.6-0.6,3.9-0.9V15.3H3.1V28.3z"/>
              <path d="M40.3,23.4c2,0.6,4,1.2,6.1,1.8V7.5h-6.1V23.4z"/>
              <path d="M15.4,24.6c0.4-0.2,0.8-0.4,1.3-0.7c1.5-1,3.2-1.7,4.8-2.1V9.9h-6.1V24.6z"/>
              <path d="M28.1,21.1c1.7,0,3.4,0.1,5.2,0.3c0.3,0,0.6,0.1,0.9,0.2v-9h-6.1V21.1z"/>
            </g>
          </svg>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <div> Mis Ventas <br/> <p>Proximamente</p> </div>
          </Box>
        </div>
      </div>             
    )
}
