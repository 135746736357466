import { createContext, useReducer, useState } from "react"
import { Home } from "../Home";
import { homeReducer, HomeReducerType, initialHomeState } from "../reducer/HomeReducer";

//DEFINIMOS UN CONTEXT PARA MANEJAR VALORES DEL HOME
type homeContextType = {
    findModal: boolean,
    setFindModal: React.Dispatch<any>,
    homeReducerState: HomeReducerType,
    dispatchHome: React.Dispatch<any>
}
const homeContextDefaultValues: homeContextType = {
    findModal: false,
    setFindModal: () => null,
    homeReducerState: initialHomeState,
    dispatchHome: () => null
};

export const HomeContext = createContext<homeContextType>(homeContextDefaultValues);

//creamos el Functional Component
export const ContextHome = () => {

    //REDUCER DEL COMPONENTE
    const [homeReducerState, dispatchHome] = useReducer(homeReducer, initialHomeState)

    //Creamos el state para enviar al contexto
    const [findModal, setFindModal] = useState(false);

    return (
        <HomeContext.Provider value={{ findModal, setFindModal, homeReducerState, dispatchHome }}>
            <Home />
        </HomeContext.Provider>
    )
    
} 