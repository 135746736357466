
import { fetchSinToken } from "../../../helpers/fetch";
import { Brand, Model } from "../../../reducers/filterReducer";

//DEFINIMOS LA ESTRUCTURA DE LOS RESULTADOS
type ResultType = {
    id: string,
    marcaId: string,
    marca: string,
    modeloId: string,
    modelo: string,
    anio: number,
    anioInicial: number,
    anioFinal: number,
    icono: string,
    fotoPublicacion: string,
    descripcion: string,
    vesion: string | null,
    motor: string | null,
    transmision: string | null,
    tipo: string | null,
    precio: string | number | undefined | null
}

//DEFINIMOS LA ESTRUCTURA DEL REDUCER
export interface FindsReducerType {
    totalPublicaciones: number | undefined,
    exactos: number | null,
    compatibles: number | null,
    parciales: number | null,
    porMarca: number | null,
    resultados: ResultType[] | null,
    resultadosMensaje: string | null

    brandSearch: Brand | null,
    modelSearch: Model | null,
    anioSearch: number | null
}
//DEFINIMOS EL VALOR INICIAL DEL REDUCER
export const initialFindsState: FindsReducerType = {
    totalPublicaciones: undefined,
    exactos: null,
    compatibles: null,
    parciales: null,
    porMarca: null,
    resultados: null,
    resultadosMensaje: null,

    brandSearch: null,
    modelSearch: null,
    anioSearch: null
}

//DEFINIMOS LOS PAYLOAD DE LOS ACTIONS
type FindResultsPayload = { resultados: ResultType[] }

type NoResultsPayload = { message: string }

type CountsResultsPayload = { totalPublicaciones: number | undefined, exactos: number, compatibles: number, parciales: number, porMarca: number,
                              marca: Brand | null, modelo: Model| null, anio: number }

//DEFINIMOS LOS ACTIONS DEL REDUCER
type FindActions = { type: 'PrepareFind' } |
                   { type: 'FindResults', payload: FindResultsPayload } |
                   { type: 'CountsResults', payload: CountsResultsPayload } |
                   { type: 'NoResults', payload: NoResultsPayload };

//DEFINIMOS LAS ACCIONES DEL REDUCER
export const findsReducer = (state: FindsReducerType = initialFindsState, action: FindActions) : FindsReducerType => {
    switch (action.type) {
        case 'PrepareFind' : return {
            ...state,
            resultados: null,
            resultadosMensaje: null
        }
        case 'FindResults' : return {
            ...state,
            resultados: action.payload.resultados
        }
        case 'CountsResults' : return {
            ...state,
            totalPublicaciones: action.payload.totalPublicaciones,
            exactos: action.payload.exactos,
            compatibles: action.payload.compatibles,
            parciales: action.payload.parciales,
            porMarca: action.payload.porMarca,
            brandSearch: action.payload.marca,
            modelSearch: action.payload.modelo,
            anioSearch: action.payload.anio
        }
        case 'NoResults' : return {
            ...state,
            resultadosMensaje: action.payload.message
        }
        default: return state;
    }
}

//CREAMOS LAS ACCIONES DEL REDUCER
type startUpdateSearchParameter = { stateLocal: FindsReducerType, dispatchLocal: React.Dispatch<FindActions> 
                                    marca: Brand | null, modelo: Model| null, anio: number };
export const startUpdateSearch = async (startUpdateSearchData: startUpdateSearchParameter) => {
    const { dispatchLocal, marca, modelo, anio } = startUpdateSearchData;

        dispatchLocal(prepareFind());

        const body = await fetchSinToken(`publicaciones/busqueda`, {
            marcaId: marca ? marca.id : '',
            modeloId: modelo ? modelo.id : '',
            anio: anio
        }, 'POST');

        if (body.ok){
            const resultados: number = body.count ? body.count : 0;
            //let resultadosFormato: ResultType[] = [];
            if (resultados > 0) {
                let resultadosFormato: ResultType[] = [];
                resultadosFormato.push(...formatoResultados(body.res.resultadosExactos, 'Exactos'));
                resultadosFormato.push(...formatoResultados(body.res.resultadosCompatibles, 'Compatibles')); 
                resultadosFormato.push(...formatoResultados(body.res.resultadosParciales, 'Parciales'));
                resultadosFormato.push(...formatoResultados(body.res.resultadosMarca, 'Marcas'));
                //findResults({resultados: resultadosFormato});
                dispatchLocal(findResults({ resultados: resultadosFormato }));

            } else {
                dispatchLocal(noResults({message: 'No se encontraron resultados para su busqueda'}));
            }
            dispatchLocal(countsResults({
                            totalPublicaciones: body.count,
                            exactos: body.res.contadorExactos,
                            compatibles: body.res.contadorCompatibles,
                            parciales: body.res.contadorParciales,
                            porMarca: body.res.contadorMarca,
                            marca: marca,
                            modelo: modelo,
                            anio: anio
                        }));
        } else {
            //(body.msg);
        }
}

const formatoResultados = (arregloSinFormato : any[], tipo: string): ResultType[]  => {

    let resultadosFormateados: ResultType[] = [];

    for (let resultado of arregloSinFormato){
        resultadosFormateados.push({
            id: resultado._id,
            marcaId: resultado.marcaId._id,
            marca: resultado.marcaId.marca,
            modeloId: resultado.modeloId._id,
            modelo: resultado.modeloId.modelo,
            anio: resultado.anio,
            anioInicial: resultado.anioInicial ? resultado.anioInicial : 0,
            anioFinal: resultado.anioFinal ? resultado.anioFinal : 0,
            icono: resultado.marcaId.icono,
            fotoPublicacion: resultado.fotoPublicacion ? resultado.fotoPublicacion : '',
            descripcion: resultado.descripcion ? resultado.descripcion : '',
            vesion: resultado.version ? resultado.version : '',
            motor: resultado.motor ? resultado.motor : '',
            transmision: resultado.transmision ? resultado.transmision : '',
            tipo: resultado.tipo,
            precio: resultado.precio
        });
    }

    return resultadosFormateados;
} 

const prepareFind = (): FindActions => {
    return {
        type: 'PrepareFind',
    }
}

const findResults = (findResultsData: FindResultsPayload): FindActions => {
    return {
        type: 'FindResults',
        payload: findResultsData
    }
}

const countsResults = (CountsResultsData: CountsResultsPayload): FindActions => {
    return {
        type: 'CountsResults',
        payload: CountsResultsData
    }
}

const noResults = (noResultsData: NoResultsPayload): FindActions => {
    return {
        type: 'NoResults',
        payload: noResultsData
    }
}