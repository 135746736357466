import "./styles/cuenta.scss"
import { useContext, useEffect } from 'react';
import { MenuContext } from '../../context/AccountContext';

import SVG from 'react-inlinesvg';
import { Box } from "@mui/material";

export const Cuenta = () => {

    //LEER EL CONTEXT PARA CAMBIAR EL COMPONENTE DEL MENU
    const { setIndexComponent } = useContext( MenuContext );
    const handleClickPerfil = (index: number) => {
        setIndexComponent(index+1);
    }

    useEffect(() => {
      window.scrollTo(0, 0)
    }, []);
    
    return (
      <div className="cuenta_main">
        <div className="cuenta_title">
          Mi Cuenta
        </div>
        <div className="cuenta_section">
          <div className="cuenta_section_format" onClick={() => { handleClickPerfil(0) }}>
            <div className="cuenta_section_icono">
              <Box sx={{ width: 70, height: 55 }}>
                <SVG src={process.env.REACT_APP_ICONSERVER+'dz-user-2'} width={70} fill="rgb(0, 93, 199)"/>
              </Box>
            </div>
            <div className="cuenta_section_datos">
              <b>Mis datos</b>
              <br/>
              Información de la cuenta, nombre, correo electrónico, número de teléfono.
            </div>
            <div className="cuenta_section_opcion">
              <SVG src={process.env.REACT_APP_ICONSERVER+'dz-angle-right'} width={18}/>
            </div>
          </div>
        </div>
        <div className="cuenta_section">
          <div className="cuenta_section_format" onClick={() => { handleClickPerfil(1) }}>
            <div className="cuenta_section_icono">
              <Box sx={{ width: 70, height: 55 }}>
                <SVG src={process.env.REACT_APP_ICONSERVER+'dz-box'} width={70} fill="rgb(0, 93, 199)"/>
              </Box>
            </div>
            <div className="cuenta_section_datos">
              <b>Plan</b>
              <br/>
              Tu plan de ventas en AutosXPartes.
            </div>
            <div className="cuenta_section_opcion">
              <SVG src={process.env.REACT_APP_ICONSERVER+'dz-angle-right'} width={18}/>
            </div>
          </div>
        </div>
        <div className="cuenta_section">
          <div className="cuenta_section_format" onClick={() => { handleClickPerfil(2) }}>
            <div className="cuenta_section_icono">
              <Box sx={{ width: 70, height: 55 }}>
                <SVG src={process.env.REACT_APP_ICONSERVER+'dz-pointer'} width={70} fill="rgb(0, 93, 199)"/>
              </Box>
            </div>
            <div className="cuenta_section_datos">
              <b>Direcciones</b>
              <br/>
              Lista de domicilios a donde enviamos tus artículos.
            </div>
            <div className="cuenta_section_opcion">
              <SVG src={process.env.REACT_APP_ICONSERVER+'dz-angle-right'} width={18}/>
            </div>
          </div>
        </div>
        <div className="cuenta_section">
          <div className="cuenta_section_format" onClick={() => { handleClickPerfil(3) }}>
            <div className="cuenta_section_icono">
              <Box sx={{ width: 70, height: 55 }}>
                <SVG src={process.env.REACT_APP_ICONSERVER+'dz-credit-card'} width={70} fill="rgb(0, 93, 199)"/>
              </Box>
            </div>
            <div className="cuenta_section_datos">
              <b>Mis tarjetas</b>
              <br/>
              Los datos de tus tarjetas y formas de pago.
            </div>
            <div className="cuenta_section_opcion">
              <SVG src={process.env.REACT_APP_ICONSERVER+'dz-angle-right'} width={18}/>
            </div>
          </div>
        </div>
      </div>
    )
}