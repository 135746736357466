import Modal from 'react-modal';
import { useContext } from 'react';
import { RootState, AppDispatch } from '../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, modalIds } from '../../reducers/modalReducer';
import { Alerts } from './components/navbar/Alerts';
import { Messages } from './components/navbar/Messages';
import { Notifications } from './components/navbar/Notifications';
import { startDesactiveNotifications } from '../../reducers/navbarReducer';
//import { clearAlerts, clearNotifications } from '../../reducers/navbarReducer';
import { SocketContext } from '../../context/SocketContext';
import { socketTypes } from '../../hooks/useSocket';

//enviamos el id del documento principal html 
Modal.setAppElement('#root');

export const ViewModal = () => {

    // console.log('Se renderizo Modal');
    //LEER EL CONTEXT DE SOCKETS PARA NOTIFICARLOS
    const { enviarSocket } = useContext( SocketContext );
   
    //LEER VARIABLES DE REDUX
    const storeDarkMode = (state: RootState ) => state.darkmode;
    const { darkMode } = useSelector(storeDarkMode);
    const storeModal = (state: RootState) => state.modal;
    const { showModal } = useSelector(storeModal);
    const { modal } = useSelector(storeModal);
    const { style } = useSelector(storeModal);

    //ESCRIBIR EN EL STORE DE REDUX
    const dispatchRedux = useDispatch<AppDispatch>();  

    //EVALUAMOS EL TIPO DE MODAL A MOSTRAR
    let componentJSX: JSX.Element = <></>;
    switch (modal) {
        case modalIds.Alert:
            componentJSX = <Alerts />
        break;
        case modalIds.Message:
            componentJSX = <Messages />
        break;
        case modalIds.Notification:
            componentJSX = <Notifications />
        break;
        default:
            componentJSX = 
                <h1 className="modal_main">
                    Sin notificaciones
                </h1>        
    }

    //ONCLOSE DEL MODAL
    const handleClose = async () => {
        //EJECUTAMOS FUNCIONES ANTES DE CERRAR
        switch (modal) {
            case modalIds.Alert:
                //dispatchRedux(clearAlerts());
            break;
            case modalIds.Message:
            break;
            case modalIds.Notification:
                await dispatchRedux<any>(startDesactiveNotifications());
                //notificar el cambio de estado del mensaje
                const type: socketTypes = 'Notification';
                enviarSocket(type);
            break;      
        }
        //cerramos el modal
        dispatchRedux(closeModal());
    }
    
    return (
        <>
            < Modal
                //closeTimeoutMS={200}
                isOpen={ showModal }          
                onRequestClose={ handleClose }
                style={ style }
            > 
                <div className={ 
                (darkMode) 
                    ? 'modal_main modal_dark_theme' 
                    : 'modal_main modal_light_theme'
                }>
                    <div className="modal_title ">
                        <div>
                                <p>{ modal }</p>
                        </div>
                        <div>
                            <button 
                                type="button" 
                                className={ 
                                    (darkMode) 
                                    ? 'btn-close btn-close-white' 
                                    : 'btn-close'
                                }
                                aria-label="Close"
                                onClick={ handleClose }
                            />    
                        </div>
                    </div>
                    { componentJSX }  
                </div>    
            </Modal>
        </>
    )
}