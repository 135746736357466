import { Box } from "@mui/material"
import "./styles/direcciones.scss"
//import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
//import { useContext, useState } from 'react';
//import { AccountContext } from '../../context/AccountContext';

//import SVG from 'react-inlinesvg';
//import { EditarDireccion } from "./modal/EditarDireccion";
//import { AccountAddress, openEditAddress } from "../../reducers/AccountReducer";
//import { useForm } from "../../../../../../hooks/useForm";

export const Direcciones = () => {

    //LEER EL REDUCER LOCAL
    //const { accountReducerState, dispatch } = useContext(AccountContext); 
    //const { addresses } = accountReducerState;

    /*const editDireccion = (direccionEdicion: AccountAddress) => {
      dispatch (openEditAddress(direccionEdicion));
      console.log('modal editar direccion ABIERTO')
    }*/

    //const [eliminarModal, setEliminarModal] = useState(false);
    //const handleClickOpenEliminarModal = () => { setEliminarModal(true); };
    //const handleClickCloseEliminarModal = () => { setEliminarModal(false); };

    return (
      <>
      {/*<EditarDireccion />

      <Dialog open={eliminarModal} onClose={handleClickCloseEliminarModal}>
        <div className="dir_modal_title">
          <h2>¿Estas seguro que queieres eliminar esta dirección?</h2>
        </div>
        <DialogContent>
          <DialogContentText>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickCloseEliminarModal} color="warning" variant="contained">Eliminar</Button>
          <Button onClick={handleClickCloseEliminarModal} >Cancelar</Button>
        </DialogActions>
      </Dialog>

      <div className="dir_main">
        <div className="dir_titulo">
          Tus direcciones
        </div>
        <div className="dir_section">
          {
            addresses ? (
              addresses.map((direction)=> (
                <div className="dir_format" key={direction.id}>
                  <div className="dir_icono">
                    <SVG src={process.env.REACT_APP_ICONSERVER+'dz-pointer'} width={80} fill="rgb(0, 89, 255)"/>
                  </div>
                  <div className="dir_datos">
                    <p>{ direction.calle } { direction.numero }</p>
                    { direction.colonia }
                    <br />
                    Código Postal: { direction.cp }
                    <br />
                    { direction.ciudad } - { direction.estado }
                    <br/>
                    Teléfono: { direction.telefono }
                  </div>
                  <div className="dir_opcion">
                    <div>
                      <Button variant="contained" onClick={() => editDireccion( direction )} sx={{ textTransform: 'none' }}>
                        Editar
                      </Button>
                      <Button color="warning" sx={{ marginTop: 2, textTransform: 'none' }} onClick={handleClickOpenEliminarModal}>
                        Eliminar
                      </Button>
                    </div>
                  </div>
                </div>
            ))) : 
            (
              <div className="dir_format">
                <p>Aún no has guardado tu dirección. Deseas agregar una?</p>
                <Button variant="contained" color="warning">Agregar</Button>
              </div>
            )
          }
        </div>
        <div className="dir_titulo">
          <Button variant="contained" color="warning">Agregar nueva dirección</Button>
        </div>
      </div>*/}


        <div className="ventas_publications_container">
          <div className="ventas_proximo_container">
            <svg x="0px" y="0px" viewBox="0 0 500 400" className="ventas_icon_soon">
              <g>
                <path d="M401.3,307.2c-2.6,3.5-5.1,7-7.7,10.4c37.6,4.8,63.3,14.6,63.3,25.9c0,16-52,29.1-116.1,29.1s-116.1-13-116.1-29.1
                  c0-11.1,25.1-20.8,61.9-25.7c-2.6-3.4-5.2-6.8-7.8-10.4c-57.1,5.9-97.2,19.9-97.2,36.1c0,21.6,71.3,39.2,159.2,39.2
                  c87.9,0,159.2-17.5,159.2-39.2C500,327.1,459.2,313,401.3,307.2z"/>
                <path d="M0,240.2c0,14.4,47.6,26.1,106.2,26.1c58.7,0,106.2-11.7,106.2-26.1c0-10.9-27.2-20.3-65.9-24.2c-1.7,2.4-3.4,4.7-5.1,6.9
                  c25.1,3.2,42.3,9.7,42.3,17.3c0,10.7-34.7,19.4-77.4,19.4s-77.4-8.7-77.4-19.4c0-7.4,16.7-13.9,41.3-17.1c-1.7-2.3-3.5-4.6-5.2-6.9
                  C26.7,220.1,0,229.4,0,240.2z"/>
                <path d="M182.3,99.4c0-57.9-50.2-76.2-76.2-76.7C79.7,22.1,30,44.2,30,99.4S93.5,228,106.2,241.2
                  C106.2,241.2,182.3,156.8,182.3,99.4z M78.6,99.4c0-15.5,12.1-27.6,27.6-27.6s27.6,12.1,27.6,27.6S121.1,127,106.2,127
                  S78.6,114.8,78.6,99.4z"/>
                <path d="M455,132.4c0-86.8-75.3-114.1-114.1-115c-39.7-0.8-114.1,32.3-114.1,115s95.1,192.7,114.1,212.5
                  C340.9,344.9,455,218.4,455,132.4z M299.5,132.4c0-23.2,18.2-41.4,41.4-41.4c23.2,0,41.4,18.2,41.4,41.4c0,23.2-19,41.4-41.4,41.4
                  C318.5,173.7,299.5,155.5,299.5,132.4z"/>
              </g>
            </svg>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <div> Mis Direcciones <br/> <p>Proximamente</p> </div>
            </Box>
          </div>
        </div>


      </>
    )
}