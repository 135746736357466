import { useRef } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";

export const Default = () => {

    // console.log('Me renderize Formulario');
    
    //LEER EL MODO OSCURO DE REDUX
    const storeSidebar = (state: RootState ) => state.sidebar;
    //destructurando el state de Redux obtenemos el valor del reducer que buscamos
    const { sidebarWidth, sidebarInitialWidth } = useSelector(storeSidebar);   
    const modoAmplio = useRef<boolean>(false);
    if(sidebarWidth === sidebarInitialWidth){
        modoAmplio.current = false;
    } else {
        modoAmplio.current = true;
    }

    const sidebarStyles = {
        "--sidebarwidth": `${sidebarWidth + 20}px`
    } as React.CSSProperties;

    return (
        <div className={(modoAmplio) ? 'form_principal form_amplio' : 'form_principal form_angosto'}
            style={ sidebarStyles }
        >
            Componente no encontrado
        </div>
    )
}
