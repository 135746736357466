import SVG from 'react-inlinesvg';

import { useForm } from '../../hooks/useForm';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import { startLogin } from '../../reducers/authReducer';
import { Box, Button, IconButton, InputAdornment, Link, TextField } from '@mui/material';

import "./styles/login.scss"
import { useState } from 'react';
import validator from 'validator';
import { Visibility, VisibilityOff } from '@mui/icons-material';

export const Login = () => {

    //HOOK PARA CONTROLAR INPUTS
    const { usuario, password, onChange } = useForm({
        usuario: '',
        password: ''
    }); 

    const [correoTextError, setCorreoTextError] = useState(false);
    const [correoTextLabel, setCorreoTextLabel] = useState('');
    const [contraseñaTextError, setContraseñaTextError] = useState(false);
    const [contraseñaTextLabel, setContraseñaTextLabel] = useState('');

    //LEER VARIABLES DE REDUX
    const storeAuth = (state: RootState ) => state.auth;
    const { login, msg } = useSelector(storeAuth);
    
    //ESCRIBIR EN EL STORE DE REDUX
    const dispatchRedux = useDispatch<AppDispatch>();
    const handleLogin = (e: React.FormEvent) => {
        e.preventDefault();
        let error = false;

        if (validator.isEmpty(usuario)){
            //hooks de control de error de input
            setCorreoTextError(true);
            setCorreoTextLabel('Ingrese su correo');
            error = true;
        } else { setCorreoTextError(false); }
        if (validator.isEmpty(password)){
            //hooks de control de error de input
            setContraseñaTextError(true);
            setContraseñaTextLabel('Ingrese su contraseña');
            error = true;
        } else { setContraseñaTextError(false); }
        if (!error){
            //EJECUCION DE FETCH
            dispatchRedux<any>(startLogin({usuario, password}));
        }
    }

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => { setShowPassword(!showPassword); };

    //REGRESANDO EL COMPONENTE
    return (
      <div className="login_principal">
        <form onSubmit={ (e) => (handleLogin(e)) }>            
          <div className="login_grid">
            <div className="login_header">
              <Box sx={{ width: "50px", height: "40px" }}>
                <SVG src={process.env.REACT_APP_ICONSERVER+'dz-user-circle'} height={40} fill="currentColor"/> 
              </Box>
              <h2>Iniciar sesión o Registrate</h2>                      
            </div>
            <div className="login_format">
              <div className="login_user">
                <Box sx={{ width: "40px", height: "40px", display: "flex", alignItems: "center" }}>
                  <SVG src={process.env.REACT_APP_ICONSERVER+'dz-user-2'} width={30} fill="currentColor"/>
                </Box>
                { correoTextError 
                  ? <TextField error fullWidth id="outlined-basic" value={ usuario } onChange={ ({target}) => onChange( target.value, 'usuario') } label={ correoTextLabel } variant="outlined" size="small" /*autoComplete='off'*/ />                                                         
                  : <TextField fullWidth id="outlined-basic" value={ usuario } onChange={ ({target}) => onChange( target.value, 'usuario') } label="Correo electrónico" variant="outlined" size="small" /*autoComplete='off'*/ />
                }
              </div>
              <div className="login_password">
                <Box sx={{ width: "40px", height: "40px", display: "flex", alignItems: "center" }}>
                  <SVG src={process.env.REACT_APP_ICONSERVER+'dz-key'} width={30} fill="currentColor"/>
                </Box>
                { contraseñaTextError 
                  ? <TextField
                    error fullWidth id="textfieldshow" variant="outlined" size="small" type={showPassword ? 'text' : 'password'}
                    value={ password } onChange={ ({target}) => onChange( target.value, 'password') } label={ contraseñaTextLabel } autoComplete='off'
                    InputProps={{
                      endAdornment:
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          { showPassword ? <VisibilityOff /> : <Visibility /> }
                        </IconButton>
                      </InputAdornment>
                    }}
                  />                                                         
                  : <TextField
                    fullWidth id="textfieldnoshow" variant="outlined" size="small" type={showPassword ? 'text' : 'password'}
                    value={ password } onChange={ ({target}) => onChange( target.value, 'password') } label="Contraseña" autoComplete='off'
                    InputProps={{
                      endAdornment:
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          { showPassword ? <VisibilityOff /> : <Visibility /> }
                        </IconButton>
                      </InputAdornment>
                    }}
                  />
                }               
              </div>
              <div className="login_button">
                <Button 
                  variant="contained"
                  onClick={ (e) => (handleLogin(e)) }
                  color="warning"
                  fullWidth
                >
                  Ingresar
                </Button>
              </div>
              <div className="login_info">
                <p className={ (login) ? 'login_text' : 'login_textalert' }>
                  { (msg) }
                </p>
              </div>
              <div className="login_register">
                <Button variant="text" href="/passwordRecovery" sx={{ textTransform: "none", mb: 1 }}>¿Olvidaste tu contraseña?</Button>
                <p style={{ fontSize: "20px" }}>¿Aún no tienes una cuenta?</p>
                <Link sx={{ fontSize: "20px" }} href="/register" underline="hover">
                  Registrate aqui
                </Link>
              </div>
            </div>
          </div>
        </form>
      </div>
    )
}