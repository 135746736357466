
import "./styles/modals.scss"
import Box from '@mui/material/Box';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { CircularProgress, Autocomplete, TextField, MenuItem, Button, Dialog, Toolbar, Stepper, Step, StepLabel, StepContent, CardMedia, InputAdornment, Menu, useMediaQuery } from '@mui/material';

import { PublicationsContext, SalesContext } from './context/SalesContext';
import { closeModalCar, closeModalNewPub } from './reducers/SalesReducer';
import { RootState, AppDispatch } from '../../../../store/store';
import { useSelector, useDispatch } from 'react-redux';
import { startLoadFilters, 
         Brand, startSelectBrand, setClearBrand, 
         Model, startSelectModel, setClearModel, 
         startSelectAnio, setClearAnio,
         setErrorBrand, setClearErrorBrand,
         setErrorModel, setClearErrorModel,
         setErrorAnio, setClearErrorAnio,
         startSelectModelEdit
       } from '../../../../reducers/filterReducer';

import SVG from 'react-inlinesvg';
import IconButton from '@mui/material/IconButton';
import { PhotoCamera } from "@mui/icons-material";
import Typography from '@mui/material/Typography';
import { AppBar } from "@material-ui/core";
import { onChange, 
         cleanAnios, cleanError, clearEndError,
         clearFrontError, clearOptionalsError, clearPublicationError,
         publicationTypes, setError, startClearPublication,
         startCreatePublication, startDeletePublication, startUpdateEnd,
         startUpdateFront, startUpdateOptionals, startUploadPhoto, updateOptionalSet, startCancelDataEditPublication, startEditDataSavePublicationCarro, startUploadPhoto2
       } from "./reducers/PublicationsReducer";
import { startShowOkDialog, startShowYesNoDialog } from "../../../../reducers/dialogsReducer";

const steps = [
  { label: 'Marca, modelo y año.', description: 'Para crear una publicación ingresé los datos del vehículo' },
  { label: 'Versión y compatibilidad', description: 'Si lo desea, indique más información para mejorar la búsqueda' },
  { label: 'Imagen de Publicación', description: 'Una imagen dice más que mil palabras, pero agregue una descripción detallada del artículo' },
  { label: 'Fotos y Publicar', description:'Le recomendamos cargar fotos del artículo, ayuda a identificar y mejor la publicación' }
];

export const CarModal = () => {

    //FUNCION PARA ESCRIBIR EN REDUX
    const dispatchRedux = useDispatch<AppDispatch>();
    //Hook de control del stepper
    const [activeStep, setActiveStep] = useState(0);

    const responsive = useMediaQuery('(max-width:300px)');

    //LEER EL REDUCER LOCAL DE PUBLICACIONES
    //Cargar Informacion del modal 
    const { publicationsReducerState, dispatchPublications } = useContext(PublicationsContext); 

    // ---------------------------------------------------------------------- FUNCIONES DEL MODAL

    //LEER EL REDUCER LOCAL DEL MODAL
    //Cargar Informacion del modal 
    const { salesReducerState, dispatchSales } = useContext(SalesContext); 
    const { carmodal, editarPublicacion, publicationInfo } = salesReducerState; 

    //AL ABRIR EL MODAL
    useEffect(() => {
      if (carmodal){
          //console.log('Limpiando modal');
          setActiveStep(0);
          //limpiando filtros de busqueda
          dispatchRedux<any>(setClearBrand());  
          //limpiando informacion de publicacion
          dispatchPublications(startClearPublication());  

          //console.log('Entre a car modal');
      }
    }, [carmodal, dispatchRedux, dispatchPublications])

    //------------------------------------------------------------------FILTROS DE MARCA Y MODELO 
    //LEER INFORMACION DE REDUX
    //Cargar datos de filtros de autos
    const storeFilters = (state: RootState ) => state.filters;
    const { loading, load, brands, availableModels, availableAnios, brand, model, anio, 
            brandTextError, brandTextLabel, modelTextError, modelTextLabel, anioTextError, anioTextLabel
            } = useSelector(storeFilters);
    // Cargar los filtros si no han sido cargados
    useEffect(() => {
        if (carmodal && !load) {
            // console.log('Cargando filtros');
            dispatchRedux<any>(startLoadFilters());
        }
    }, [load, carmodal, dispatchRedux])
    //LISTAS
    //Marcas
    const [selectedBrand, setSelectedBrand] = useState<Brand | null>(null);
    const [iconBrand, setIconBrand] = useState<string>('');
    useEffect(() => {
      //console.log("entra 2")
      if (carmodal) {
        if (selectedBrand) {
            // console.log('Marca seleccionada:');
            clearBrand(); 
            setIconBrand(selectedBrand.icono);
            dispatchRedux<any>(startSelectBrand({selectedBrand}));    
            
            if (editarPublicacion && selectedModel===null)  {
              
              dispatchRedux<any>(startSelectModelEdit({ selectedModel :{
                id: publicationInfo?.modeloId ?? "",
                marca: publicationInfo?.marca ?? "",
                modelo: publicationInfo?.modelo ?? "",
                anioInicial: publicationInfo?.anioInicial ?? null ?? undefined ?? 0,
                anioFinal: publicationInfo?.anioFinal ?? null ?? undefined ?? 0
              }

              })); 
               
              if (availableModels!==null){
              let ModelFound: Model[]= availableModels.filter( m => (m.id === publicationInfo?.modeloId ) );
        
            }
              setSelectedModel({
                id: publicationInfo?.modeloId ?? "",
                marca: publicationInfo?.marca ?? "",
                modelo: publicationInfo?.modelo ?? "",
                anioInicial: publicationInfo?.anioInicial ?? null ?? undefined ?? 0,
                anioFinal: publicationInfo?.anioFinal ?? null ?? undefined ?? 0
              })
            }


        } else {
            if (carmodal) { //solo si se esta mostrando el modal
                // console.log('Limpiando la marca del reducer');
                clearBrand();              
                dispatchRedux<any>(setClearBrand()); 
            }
        }
      }
    }, [editarPublicacion, selectedBrand, carmodal, dispatchRedux,
      publicationInfo?.modeloId, publicationInfo?.marca,
      publicationInfo?.modelo, publicationInfo?.anioInicial, publicationInfo?.anioFinal
    ])
    //Modelos
    const [selectedModel, setSelectedModel] = useState<Model | null>(null);
    useEffect(() => {
      if (carmodal) {
        if (selectedModel) {
            // console.log('Modelo seleccionado');
            clearModel();  
            dispatchRedux<any>(startSelectModel({selectedModel}));

            if(editarPublicacion && selectedAnio === null){
              setSelectedAnio( publicationInfo?.anio ?? null ?? undefined ?? 0 );
              const editarAnio = { selectedAnio:  publicationInfo?.anio ?? null ?? undefined ?? 0 }
              dispatchRedux<any>(startSelectAnio(editarAnio));
            }

        } else {
            if (carmodal) { //solo si se esta mostrando el modal
                // console.log('Limpiando el modelo del reducer');
                clearModel();  
                dispatchRedux<any>(setClearModel()); 
            }
        }
      }
    }, [selectedModel, carmodal, dispatchRedux, editarPublicacion, publicationInfo?.anio])
    //Anios
    const [selectedAnio, setSelectedAnio] = useState<number | null>(null);
    useEffect(() => {
        if (carmodal) {
            if (selectedAnio) {
                // console.log('Anio seleccionado');
                dispatchRedux<any>(startSelectAnio({selectedAnio}));
            } else {
                /*if (carmodal) {*/ //solo si se esta mostrando el modal
                    // console.log('Limpiando el anio del reducer');  
                dispatchRedux<any>(setClearAnio());
            }
        }
    }, [selectedAnio, carmodal, dispatchRedux]);

    //METODOS DE LIMPIEZA DE LISTAS
    const clearBrand = () => {
        setIconBrand('');
        setSelectedModel(null);
        setSelectedAnio(null);
    }
    const clearModel = () => {
        setSelectedAnio(null);
    }

    useEffect(() => {
      if (editarPublicacion) {
        setSelectedBrand({
          id: publicationInfo?.marcaId ?? "",
          marca: publicationInfo?.marca ?? "",
          icono: publicationInfo?.icon ?? ""
        })
      }
    }, [editarPublicacion, publicationInfo?.idPub,
        publicationInfo?.marca, publicationInfo?.icon, publicationInfo?.marcaId
    ])
 
    //------------------------------------------------------------------FILTROS DE MARCA Y MODELO

    //---------------------------------------------------------------------------INPUTS DEL MODAL
    const { publicationCreated, publicationSending, publicationError, publicationLabel, 
        publicationOptionaled, publicationOptionalSending, publicationOptionalError, publicationOptionalLabel,
        publicationFronted, publicationFrontSending, publicationFrontError, publicationFrontLabel,
        publicationEnded, publicationEndSending, publicationEndError, publicationEndLabel,
        version, versionError, 
        transmision, transmisionError, 
        motor, motorError,
        anioInicial, anioInicialError, 
        anioFinal, anioFinalError,
        fpublicacion, fpublicacionError,
        descripcion, descripcionError,
        fizquierda, fizquierdaError,
        fderecha, fderechaError,
        finferior, finferiorError,
        fsuperior, fsuperiorError,
        ftrasera, ftraseraError,
        ffrontal, ffrontalError
    } = publicationsReducerState;

    //HOOK PARA CONTROLAR INPUTS
    const fotoFrontalRef = useRef<HTMLInputElement>(null);
    const fotoPublicacionRef = useRef<HTMLInputElement>(null);
    const fotoIzquierdaRef = useRef<HTMLInputElement>(null);
    const fotoDerechaRef = useRef<HTMLInputElement>(null);
    const fotoSuperiorRef = useRef<HTMLInputElement>(null);
    const fotoInferiorRef = useRef<HTMLInputElement>(null);
    const fotoTraseraRef = useRef<HTMLInputElement>(null);
    const transmisionList = [{value: 'Automatica', label: 'Automática'}, {value: 'Estandar', label: 'Estandar'}];
        
    //Limpiando listas de compatibilidad si se cambia el modelo en los filtros
    const [availableAniosComp, setAvailableAniosComp] = useState<number[] | null>(null); 
    useEffect(() => {
      setAvailableAniosComp(availableAnios); 
      dispatchPublications(cleanAnios());
        if (editarPublicacion) {
          let idPublic = publicationInfo?.idPub ?? null ?? undefined ?? 0
          dispatchPublications(updateOptionalSet({
              publicationId: idPublic as number,
              version :  publicationInfo?.version ?? "",
              motor:  publicationInfo?.motor ?? "",
              transmision:  publicationInfo?.transmision ?? "",
              anioInicial: publicationInfo?.anioInicial ?? null ?? undefined ?? 0,
              anioFinal: publicationInfo?.anioFinal ?? null ?? undefined ?? 0,
              descripcion: publicationInfo?.descripcion ?? "",

              fpublicacion: publicationInfo?.fotoPublicacion ?? "",
              fizquierda: publicationInfo?.fotoIzquierda ?? "",
              fderecha: publicationInfo?.fotoDerecha ?? "",
              finferior: publicationInfo?.fotoInterior ?? "",
              fsuperior:publicationInfo?.fotoMotor ?? "",
              ftrasera: publicationInfo?.fotoPosterior ?? "",
              ffrontal: publicationInfo?.fotoFrontal ?? "",

              precio: publicationInfo?.precio ?? "" ?? 0 ?? undefined ?? null ?? 0,

              publicated: true
            }
          ));
        }
    }, [availableAnios, dispatchPublications, editarPublicacion,
        publicationInfo?.idPub, publicationInfo?.version,
        publicationInfo?.motor, publicationInfo?.transmision,
        publicationInfo?.anioInicial, publicationInfo?.anioFinal,
        publicationInfo?.descripcion, publicationInfo?.fotoPublicacion,
        publicationInfo?.fotoIzquierda, publicationInfo?.fotoDerecha,
        publicationInfo?.fotoInterior, publicationInfo?.fotoMotor,
        publicationInfo?.fotoPosterior, publicationInfo?.fotoFrontal
    ]);
    //---------------------------------------------------------------------------INPUTS DEL MODAL
      //----------------------------------------------------------------------CONTROLES DEL STEPPER
    //VALIDACIONES BOTON SIGUIENTE
    const handleNext = async (index: number) => {
      let error = false;
      switch (index) {
        case 0:            
            //hacer validaciones
            if (!brand) {
                dispatchRedux<any>(setErrorBrand({label: 'Debe indicar la marca del automóvil'})); 
                error=true;
            } else { dispatchRedux<any>(setClearErrorBrand()) }
            if (!model) {
                dispatchRedux<any>(setErrorModel({label: 'Debe indicar el modelo del automóvil'})); 
                error=true;
            } else { dispatchRedux<any>(setClearErrorModel()) }
            if (!anio) {
                dispatchRedux<any>(setErrorAnio({label: 'Debe indicar el año del automóvil'}));
                error=true;
              } else { dispatchRedux<any>(setClearErrorAnio()) }
            if (!error) {
                //si no hay error creamos una publicacion nueva
                dispatchPublications(startCreatePublication({ publicationType: publicationTypes.Auto, 
                                                              state: publicationsReducerState, dispatch: dispatchPublications,  
                                                              marcaId: brand?.id, modeloId: model?.id, anio: anio
                                                            }));
                moveNext(index);
            } else {
                //si hay error limpiamos posibles errores de fetch para que solo aparezcan los errores de validacion
                dispatchPublications(clearPublicationError());
            }
        break;            
        case 1:              
            //hacer validaciones
            if (anioInicial && !anioFinal){
                dispatchPublications(setError({ errorField: 'anioFinalError', label: 'Indique año final' }));
                error=true;
            } else { dispatchPublications(cleanError({ errorField: 'anioFinalError' }))  }
            if (!anioInicial && anioFinal){
                dispatchPublications(setError({ errorField: 'anioInicialError', label: 'Indique año inicial' }));
                error=true;
            } else { dispatchPublications(cleanError({ errorField: 'anioInicialError' }))  }
            if (anioInicial && anioFinal){
                if (anioFinal < anioInicial){
                    dispatchPublications(setError({ errorField: 'anioFinalError', label: 'Año final menor' }));
                    error=true;
                } else {
                    dispatchPublications(cleanError({ errorField: 'anioFinalError' }))  
                }
            }
            if (!error) {           
                //si no hay error creamos una publicacion nueva
                dispatchPublications(startUpdateOptionals({ state: publicationsReducerState, dispatch: dispatchPublications }));
                moveNext(index);
            } else {
                //si hay error limpiamos posibles errores de fetch para que solo aparezcan los errores de validacion
                dispatchPublications(clearOptionalsError());
            }
        break; 
        case 2:              
            //hacer validaciones
            if (!fpublicacion){
                dispatchPublications(setError({ errorField: 'fpublicacionError', label:'Debe seleccionar la foto de portada' }));
                error=true;
            }
            if (!descripcion){
                dispatchPublications(setError({ errorField: 'descripcionError', label:'Debe indicar una descripción' }));
                error=true;
            } else { dispatchPublications(cleanError({ errorField: 'descripcionError' }))  }              
            // if (!precio){
            //     dispatchPublications(setError({ errorField: 'precioError', label:'Debe indicar el precio' }));
            //     error=true;
            // } else { dispatchPublications(cleanError({ errorField: 'precioError' }))  }              
            if (!error) {           
                //si no hay error creamos una publicacion nueva
                dispatchPublications(startUpdateFront({ state: publicationsReducerState, dispatch: dispatchPublications }));
                moveNext(index);
            } else {
                //si hay error limpiamos posibles errores de fetch para que solo aparezcan los errores de validacion
                dispatchPublications(clearFrontError());
            }
        break;                         
        case 3:
            //hacer validaciones
            //si no hay error continuas
            if (!error) {
                if (editarPublicacion) {
                  //dispatchPublications(startEditDataPublication({ dispatch: dispatchPublications, idPublicacion: publicationInfo?.idPub }))
                  dispatchPublications(startEditDataSavePublicationCarro({ state: publicationsReducerState, dispatch: dispatchPublications, idMarca: brand?.id, idModelo: model?.id, idAnio: anio }))
                } else {  
                  //si no hay error creamos una publicacion nueva
                  dispatchPublications(startUpdateEnd({ state: publicationsReducerState, dispatch: dispatchPublications }));
                }
            } else {
                //si hay error limpiamos posibles errores de fetch para que solo aparezcan los errores de validacion
                dispatchPublications(clearEndError());
            }
        break;
      }
    };

    //VALIDANDO RESPUESTAS DEL REDUCER PARA AVANZAR PESTAÑA DEL STEPPER
    //CREACION DE LA PUBLICACION
    useEffect(() => {
      // console.log('Validando si se creo la publicacion');
      if (publicationCreated) {
          // console.log('Avanzando si la publicacion se creo correctamente');
          setActiveStep((prevActiveStep) => 1);
      } 
    }, [publicationCreated])
    
    //ACTUALIZACION DE OPCIONALES DE LA PUBLICACION
    useEffect(() => {
      // console.log('Validando si se creo la publicacion');
      if (publicationOptionaled) {
          // console.log('Avanzando si la publicacion se creo correctamente');
          setActiveStep((prevActiveStep) => 2);
      } 
    }, [publicationOptionaled])

    //ACTUALIZACION DE PORTADA DE LA PUBLICACION
    useEffect(() => {
      // console.log('Validando si se creo la publicacion');
      if (publicationFronted) {
          // console.log('Avanzando si la publicacion se creo correctamente');
          setActiveStep((prevActiveStep) => 3);
      } 
    }, [publicationFronted])


    const moveNext = (ind :number) => {
      if(ind ===0 &&  publicationCreated){
        setActiveStep((prevActiveStep) => ind+ 1);
      }
      if(ind ===1 &&  publicationOptionaled){
        setActiveStep((prevActiveStep) => ind+ 1);
      }
      if(ind ===2 &&  publicationFronted){
        setActiveStep((prevActiveStep) => ind + 1);
      }
    };

  
    //AL CERRAR EL MODAL   
    const handleClose = useCallback( async () => {
      clearBrand();  //limpiar los inputs del formulario
      setSelectedBrand(null); // limpiar las selecciones de los autocomplete
      //cerrando modals
      dispatchSales(closeModalCar());
      dispatchSales(closeModalNewPub());  // cerrar el modal de hacer una nueva publicacion
    },[dispatchSales]);

    //VERIFICAR SI SE CREO UNA PUBLICACION ANTES DE CERRAR EL MODAL
    const storeDialogs = (state: RootState ) => state.dialogs;
    const { yesnoSelection } = useSelector(storeDialogs);
    //verificando si se habia creado una publicacion antes del cierre
    const handleBeforeClose = async () => {
      if (publicationCreated && !editarPublicacion){
          await dispatchRedux<any>(startShowYesNoDialog({title: '¡Publicación Incompleta!', content: 'Su publicación aún no está terminada, ¿Desea eliminarla?'}));
      } else {
        if (editarPublicacion) {
          await dispatchRedux<any>(startShowYesNoDialog({title: '¡Estás editando esta publicación!', content: '¿Deseas continuar editando la publicación?'}));
        } else {
          handleClose();
        }
      }
    }

    //realizar acciones despues de obtener la respuesta del modal
    const handleAfterResponse = useCallback( async () => {
      if ( editarPublicacion ) {
        if ( !yesnoSelection ) {
          dispatchPublications(startCancelDataEditPublication({ dispatch: dispatchPublications, idPublicacion: publicationInfo?.idPub }))
          dispatchSales(closeModalCar());
          dispatchRedux<any>(setClearBrand());
        } else { }
      } else {
        if ( yesnoSelection ) {
          //console.log('Por alguna puta razon');
          dispatchPublications(startDeletePublication({ state: publicationsReducerState }));
          handleClose(); 
        } else { }
      }
    },[ yesnoSelection,dispatchPublications,publicationsReducerState,handleClose ]);
    //verificando si se mostro el modal de respuesta
    useEffect(() => {
        if (carmodal && yesnoSelection !== null) {
          handleAfterResponse();
        } else {}
    }, [yesnoSelection,carmodal,handleAfterResponse])

    //ACTUALIZACION FINALIZACION DE LA PUBLICACION
    useEffect(() => {
      if (publicationEnded) {
          // console.log('Avanzando si la publicacion se creo correctamente');
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          dispatchRedux<any>(startShowOkDialog({title: `Publicación ${editarPublicacion ? 'editada' : 'creada'} correctamente`,
                                                content: 'Su publicación pasará a revisión, para corroborar que la información esté correcta y así poder activar su publicación'}));        
          handleClose();
          window.scrollTo(0, 0);
      }
    }, [publicationEnded,dispatchRedux,handleClose])

    useEffect(() => {
      if (publicationEnded){
        //console.log('publicacion creada o ediatada completamente')
      }
    }, [publicationEnded])
    
    useEffect(() => {
      if (editarPublicacion){
        //console.log('se esta editando');
      }
    }, [editarPublicacion])
    
    

    //VALIDACIONES BOTON ATRAS
    const handleBack = (index: number) => {
      //console.log('Back'+index);
      switch (index) {
          case 1:
              //haciendo limpieza si es necesario
          break;
          case 2:
              //haciendo limpieza si es necesario
          break;
          case 3:
              //haciendo limpieza si es necesario
          break;
          case 4:
              //haciendo limpieza si es necesario
          break;
      }
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    //----------------------------------------------------------------------CONTROLES DEL STEPPER


    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);  //MODAL DE AYUDA PARA EL EJEMPLO DE DESCRIPCION
    const open2 = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose2 = () => {
        setAnchorEl(null);
    };

    //---------------------------------------------------------------------------INPUTS DEL MODAL
    return (
      <Dialog
        open={carmodal}
        //onClose={async (_,reason) => { handleBeforeClose() }}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              maxWidth: "1000px",
            },
          },
        }}
      >
        <AppBar position="relative" style={{ background: '#ff6700' }}>
          <Toolbar>
            <div className="new_publication_spare_title">
              Publicación de Auto X Partes
            </div>
            <IconButton
              aria-label="close"
              onClick={async () => { handleBeforeClose() }}
            >
              <SVG src={process.env.REACT_APP_ICONSERVER+'dz-error'} width={20} fill="white" />
            </IconButton>
          </Toolbar>
        </AppBar>
          {
            loading ? ( 
              <div className="sales_wait"> 
                <CircularProgress/>Cargando...
              </div>
            ) : (
              <div className="new_car_modal_content">
                <Box sx={{ width: 1000}}>
                  <Stepper nonLinear activeStep={activeStep} orientation='vertical'>
                    {steps.map((step, index) => (
                      <Step key={step.label}>
                        {/* SUBTITULOS */}
                        <StepLabel>
                          <Typography fontSize={(responsive ? "17px" : "20px")}>
                            <b>{step.label}</b>
                          </Typography>
                        </StepLabel>
                        <StepContent>                                    
                          <Typography fontSize={(responsive ? "15px" : "16px")}>{step.description}</Typography>
                          <Box sx={{ mt: 2, width: '100%' }}> 
                            {/* COMPONENTES */} {                                            
                              function() {                                     
                                switch (index) {
                                  case 0 : return <>
                                    {/* FILTRO DE MARCAS */}
                                    <Autocomplete
                                      id="selectedBrand" value={selectedBrand} disabled={brands ? false : true} sx={{ mb: 2 }}
                                      onChange={(_event: any, newValue: Brand | null) => {
                                        setSelectedBrand(newValue);
                                      }}
                                      options={brands ? brands : [{id:'', marca:'', icono:''}]}
                                      autoHighlight getOptionLabel={(option) => option.marca}
                                      renderOption={(props, option) => (
                                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                          <SVG src={process.env.REACT_APP_ICONSERVER+option.icono} width={30}/>
                                          <Typography sx={{ marginLeft: 2 }}>{option.marca}</Typography>
                                        </Box>
                                      )}
                                      isOptionEqualToValue={(option, value) => option.id === value.id || option.icono === value.icono || option.marca === value.marca || option.icono === '' || option.id === '' || option.marca === ''}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params} error={brandTextError? true : false}
                                          label={brandTextError ? brandTextLabel : 'Marca'} placeholder="Ver lista de marcas"
                                          InputProps={{
                                            ...params.InputProps, // disable autocomplete and autofill                                            
                                            startAdornment: (
                                              <InputAdornment position="start">                                                                                      
                                                {brand && <SVG src={process.env.REACT_APP_ICONSERVER+brand.icono} width={(responsive ? 25 : 30)}/>}
                                              </InputAdornment>
                                            )                                                                                                                                                                                    
                                          }}
                                        />
                                      )}
                                    />
                                    {/* FILTRO DE MODELOS */}
                                    <Autocomplete
                                      id="selectedModel" value={selectedModel} disabled={availableModels ? false : true} sx={{ mb: 2 }}
                                      onChange={(_event: any, newValue: Model | null) => {
                                        setSelectedModel(newValue);
                                      }}
                                      options={availableModels ? availableModels : [{id:'',marca:'',modelo:'',anioInicial:0,anioFinal:0}]}
                                      autoHighlight getOptionLabel={(option) => option.modelo}
                                      renderOption={(props, option) => (
                                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                          <SVG src={process.env.REACT_APP_ICONSERVER+iconBrand} width={30} fill="currentColor"/>
                                          <Typography sx={{ marginLeft: 2 }}>{option.modelo}</Typography>
                                        </Box>
                                      )}
                                      isOptionEqualToValue={(option, newValue) => option.id === newValue.id}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          error={modelTextError ? true : false}
                                          label={modelTextError ? modelTextLabel : 'Modelo'} placeholder="Ver lista de modelos"
                                          InputProps={{
                                            ...params.InputProps, // disable autocomplete and autofill                                            
                                            startAdornment: (
                                              <InputAdornment position="start">                                                                                      
                                                {brand && <SVG src={process.env.REACT_APP_ICONSERVER+brand.icono} width={(responsive ? 25 : 30)}/>}
                                              </InputAdornment>
                                            )                                                                                                                                                                                    
                                          }}
                                        />
                                      )}                                  
                                    />      
                                    {/* FILTRO DE ANIOS */}
                                    <Autocomplete
                                      id="selectedAnio" value={selectedAnio} disabled={availableAnios ? false : true} sx={{ mb: 2 }}
                                      onChange={(_event: any, newValue: number | null) => {
                                        setSelectedAnio(newValue);
                                      }}
                                      options={availableAnios ? availableAnios : []}
                                      autoHighlight getOptionLabel={(option) => option.toString()}
                                      isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                                      renderOption={(props, option) => (
                                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                          {option}
                                        </Box>
                                      )}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          error={anioTextError ? true : false}
                                          label={anioTextError ? anioTextLabel : 'Año'} placeholder="Ver lista de años"
                                          inputProps={{
                                            ...params.inputProps, autoComplete: 'new-password', // disable autocomplete and autofill
                                          }}
                                        />
                                      )}                                  
                                    />  
                                    {publicationError ? <Typography variant="caption" color="error">{ publicationLabel }</Typography> : null}                                                 
                                  </>
                                  case 1 : return <>
                                    <div className="new_publication_spare_format">
                                      <div>
                                        {/* INPUT DE VERSION */}
                                        <TextField 
                                          id="version" value = { version } error={versionError ? true : false}
                                          label={versionError? versionError : 'Versión (Opcional)'} fullWidth sx={{ mb: 1 }}
                                          onChange={ ({target}) => dispatchPublications(onChange({ field: 'version', value: target.value })) } 
                                          variant="outlined" margin="dense" 
                                        />   
                                        {/* INPUT DE MOTOR */}     
                                        <TextField
                                          id="motor" value = { motor } error={motorError ? true : false}
                                          label={motorError ? motorError : 'Motor (Opcional)'} fullWidth sx={{ mb: 2 }}
                                          onChange={ ({target}) => dispatchPublications(onChange({ field: 'motor', value: target.value })) } 
                                          variant="outlined" margin="dense" 
                                        /> 
                                        {/* LISTA DE TRANSMISION */} 
                                        <TextField  
                                          id="transmision" value = { transmision } error={transmisionError ? true : false}
                                          label={transmisionError ? transmisionError : 'Transmisión (Opcional)'} fullWidth select
                                          onChange={ ({target}) => dispatchPublications(onChange({ field: 'transmision', value: target.value })) } 
                                        >
                                          {transmisionList.map((option) => ( <MenuItem key={option.value} value={option.value}> {option.label} </MenuItem> ))}
                                        </TextField>
                                      </div>
                                      <div className="new_publication_spare_compa">
                                        <h4>{`Compatibilidad (Opcional)`}</h4>
                                        <Box>
                                          {/* LIST DE ANIO INICIAL */}  
                                          <Autocomplete
                                            id="anioInicial" value={anioInicial} disabled={availableAniosComp ? false : true} sx={{ mb: 2, mt: 2 }}
                                            onChange={(_event: any, newValue: number | null) => {
                                              dispatchPublications(onChange({ field: 'anioInicial', value: newValue}))                                        
                                            }}
                                            options={availableAniosComp ? availableAniosComp : []}
                                            autoHighlight getOptionLabel={(option) => option.toString()}
                                            renderOption={(props, option) => (
                                              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                {option}
                                              </Box>
                                            )}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                error={anioInicialError ? true : false} label={anioInicialError ? anioInicialError : 'Desde'}
                                                inputProps={{
                                                  ...params.inputProps, autoComplete: 'new-password', // disable autocomplete and autofill
                                                }}
                                              />
                                            )}  
                                          />  
                                          {/* LIST DE ANIO FINAL */}  
                                          <Autocomplete
                                            id="anioFinal" value={anioFinal} disabled={availableAniosComp ? false : true} sx={{ mb: 2 }}
                                            onChange={(_event: any, newValue: number | null) => {
                                              dispatchPublications(onChange({ field: 'anioFinal', value: newValue}))  
                                            }}
                                            options={availableAniosComp? availableAniosComp : []}
                                            autoHighlight getOptionLabel={(option) => option.toString()}
                                            renderOption={(props, option) => (
                                              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                {option}
                                              </Box>
                                            )}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params} error={anioFinalError ? true : false} label={anioFinalError? anioFinalError : 'Hasta'}
                                                inputProps={{
                                                  ...params.inputProps, autoComplete: 'new-password', // disable autocomplete and autofill
                                                }}
                                              />
                                            )}                                  
                                          /> 
                                        </Box> 
                                      </div>
                                      { publicationOptionalError ? <Typography variant="caption" color="error">{ publicationOptionalLabel }</Typography> : null } 
                                    </div>                                                                                                                                    
                                  </>                                           
                                  case 2 : return <>
                                    <div className="new_publication_spare_format">
                                      {/* INPUT DE IMAGEN DE PORTADA */}
                                      <div className="new_publication_spare_format_datos">
                                        <Typography variant="h6">{model?.marca} {model?.modelo} {anio}</Typography>
                                      </div>
                                      <div className="new_publication_spare_format_imagen">
                                        Foto de Publicacion
                                        <IconButton color="primary" aria-label="upload picture" component="label">
                                          <input hidden accept="image/*" type="file"
                                            ref={ fotoPublicacionRef }
                                            onChange={ ({target}) => { if (target.files !== null){ if(target.files.length > 0) { 
                                            dispatchPublications(startUploadPhoto({ field: 'fpublicacion', errorField: 'fpublicacionError', file: target.files[0],
                                              state: publicationsReducerState, dispatch: dispatchPublications })) } } }
                                            }/>
                                          {/* onChange={ ({target}) => { setLoadPortada(target.value ? true : false); onChange( target.value, 'fportada'); }}  /> */}
                                          <PhotoCamera />
                                        </IconButton>
                                        {
                                          fpublicacionError 
                                          ? <Box sx={{ height: 200, border: 1, borderRadius: 1, borderColor: "error.main", color: "error.main", display: "flex", alignItems: "center", justifyContent: "center", cursor:"pointer"}}
                                            onClick={()=>{ fotoPublicacionRef.current?.click() }}
                                          >
                                            { fpublicacionError }
                                          </Box>
                                          : fpublicacion ?
                                            <div className="new_publication_spare_image">
                                              <Box sx={{ cursor:"pointer" }}>
                                                <CardMedia component="img" height="200px"
                                                  src={process.env.REACT_APP_PHOTOSERVER+fpublicacion}
                                                  // src={fpublicacion}
                                                  onClick={()=>{ fotoPublicacionRef.current?.click() }}
                                                />
                                              </Box>
                                            </div>
                                            : 
                                            <div className="new_publication_spare_image">
                                              <Box sx={{ cursor:"pointer"  }}>
                                                <CardMedia component="img" height="200px"
                                                  src='../assets/f-publicacion.png'
                                                  onClick={()=>{ fotoPublicacionRef.current?.click() }}
                                                />
                                              </Box>
                                            </div>
                                        }
                                      </div>
                                      <div className="new_publication_spare_format_descrip">
                                        {/* MULTILINEA DE DESCRIPCION */}

                                        <TextField
                                          id="descripcion" value = {descripcion} error={descripcionError ? true : false}
                                          fullWidth label={descripcionError? descripcionError : 'Descripción'}
                                          variant="outlined" margin="dense" multiline rows={6}
                                          onChange={ ({target}) => dispatchPublications(onChange({ field: 'descripcion', value: target.value })) }
                                        />

                                          <Box sx={{ textAlign: "right" }}>
                                            <Button sx={{ textTransform: "none" }} onClick={handleClick}>Ejemplo</Button>
                                          </Box>

                                        <Menu
                                          id="basic-menu"
                                          open={open2}
                                          anchorEl={anchorEl}
                                          onClose={handleClose2}
                                          MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                          }}
                                          PaperProps={{  
                                            style: {
                                              width: 300,
                                              padding: "10px"
                                            },
                                         }}
                                        >
                                          <Typography color="inherit" sx={{ mb: 1 }}><b>Ejemplo de Descripción</b></Typography>
                                          Chevrolet Aveo 2012, En excelentes condiciones para su venta en autopartes, tiene una sola puerta, faltan las cuatro llantas, el cofre está en buen estado, etc. Pregunte por los precios.
                                        </Menu>

                                      </div>
                                      { publicationFrontError ? <Typography variant="caption" color="error">{ publicationFrontLabel }</Typography> : null}
                                      </div>
                                    </>
                                  case 3 : return <>
                                    <div className="new_publication_spare_images_container">
                                      <div>
                                        Foto Frontal
                                        <IconButton color="primary" aria-label="upload picture" component="label">
                                          <input hidden multiple accept="image/*" type="file" ref={ fotoFrontalRef }
                                            onChange={ ({target}) => { if (target.files!==null){ if(target.files.length>0) { dispatchPublications(startUploadPhoto2({ field: 'ffrontal', errorField: 'ffrontalError', files: target.files,
                                                                                                                                                                      state: publicationsReducerState, dispatch: dispatchPublications })) } } }
                                          }/>
                                          {/* onChange={ ({target}) => { setLoadPortada(target.value ? true : false); onChange( target.value, 'fportada'); }}  /> */}
                                          <PhotoCamera />
                                        </IconButton>
                                        {
                                          ffrontalError
                                          ? <Box sx={{ height: 150, border: 1, borderRadius: 1, borderColor: "error.main", color: "error.main", display: "flex", alignItems: "center", justifyContent: "center", cursor:"pointer"}}
                                            onClick={()=>{ fotoFrontalRef.current?.click() }}
                                          >
                                            { ffrontalError }
                                          </Box>
                                          : ffrontal ?
                                            <div className="new_publication_spare_image">
                                              <Box sx={{ cursor:"pointer" }}>
                                                <CardMedia component="img" height="150px"
                                                  src={process.env.REACT_APP_PHOTOSERVER+ffrontal}   
                                                  // src={ffrontal}   
                                                  onClick={()=>{ fotoFrontalRef.current?.click() }}                                                                          
                                                />
                                              </Box>
                                            </div>
                                            : 
                                            <div className="new_publication_spare_image">
                                              <Box sx={{ cursor:"pointer"  }}>
                                                <CardMedia component="img" height="150px"
                                                  src='../assets/f-frontal.png'
                                                  onClick={()=>{ fotoFrontalRef.current?.click() }}
                                                />
                                              </Box>
                                            </div>
                                        }
                                      </div>
                                      <div>
                                        Foto Lateral Izquierdo
                                        <IconButton color="primary" aria-label="upload picture" component="label">
                                          <input hidden multiple accept="image/*" type="file" ref={ fotoIzquierdaRef }
                                            onChange={ ({target}) => { if (target.files!==null){ if(target.files.length>0) { dispatchPublications(startUploadPhoto2({ field: 'fizquierda', errorField: 'fizquierdaError', files: target.files,
                                                                                                                                                                      state: publicationsReducerState, dispatch: dispatchPublications })) } } }
                                          }/>
                                          {/* onChange={ ({target}) => { setLoadPortada(target.value ? true : false); onChange( target.value, 'fportada'); }}  /> */}
                                          <PhotoCamera />
                                        </IconButton>
                                        {                                         
                                          fizquierdaError 
                                          ? <Box sx={{ height: 150, border: 1, borderRadius: 1, borderColor: "error.main", color: "error.main", display: "flex", alignItems: "center", justifyContent: "center", cursor:"pointer"}}
                                            onClick={()=>{ fotoIzquierdaRef.current?.click() }}
                                          > 
                                            { fizquierdaError }
                                          </Box>
                                          : fizquierda ? 
                                            <div className="new_publication_spare_image">
                                              <Box sx={{ cursor:"pointer" }}>
                                                <CardMedia component="img" height="150px"
                                                  src={process.env.REACT_APP_PHOTOSERVER+fizquierda}   
                                                  // src={fizquierda}   
                                                  onClick={()=>{ fotoIzquierdaRef.current?.click() }}                                                                          
                                                />
                                              </Box>
                                            </div>
                                            : 
                                            <div className="new_publication_spare_image">
                                              <Box sx={{ cursor:"pointer"  }}>
                                                <CardMedia component="img" height="150px"
                                                  src='../assets/f-late-izquierdo.png'
                                                  onClick={()=>{ fotoIzquierdaRef.current?.click() }}
                                                />
                                              </Box>
                                            </div>
                                        }
                                      </div>
                                      <div>
                                        Foto Lateral Derecho
                                        <IconButton color="primary" aria-label="upload picture" component="label">
                                          <input hidden multiple accept="image/*" type="file" ref={ fotoDerechaRef }
                                            onChange={ ({target}) => { if (target.files!==null){ if(target.files.length>0) { dispatchPublications(startUploadPhoto2({ field: 'fderecha', errorField: 'fderechaError', files: target.files,
                                                                                                                                                                      state: publicationsReducerState, dispatch: dispatchPublications })) } } }
                                          }/>
                                          {/* onChange={ ({target}) => { setLoadPortada(target.value ? true : false); onChange( target.value, 'fportada'); }}  /> */}
                                          <PhotoCamera />
                                        </IconButton>
                                        {
                                          fderechaError
                                          ? <Box sx={{ height: 150, border: 1, borderRadius: 1, borderColor: "error.main", color: "error.main", display: "flex", alignItems: "center", justifyContent: "center", cursor:"pointer"}}
                                            onClick={()=>{ fotoDerechaRef.current?.click() }}
                                          >
                                            { fderechaError }
                                          </Box>
                                          : fderecha ?
                                            <div className="new_publication_spare_image">
                                              <Box sx={{ cursor:"pointer" }}>
                                                <CardMedia component="img" height="150px"
                                                  src={process.env.REACT_APP_PHOTOSERVER+fderecha}
                                                  // src={fderecha}
                                                  onClick={()=>{ fotoDerechaRef.current?.click() }}
                                                />
                                              </Box>
                                            </div>
                                            : 
                                            <div className="new_publication_spare_image">
                                              <Box sx={{ cursor:"pointer"  }}>
                                                <CardMedia component="img" height="150px"
                                                  src='../assets/f-late-derecho.png'
                                                  onClick={()=>{ fotoDerechaRef.current?.click() }}
                                                />
                                              </Box>
                                            </div>
                                        }
                                      </div>
                                      <div>
                                        Foto Posterior
                                        <IconButton color="primary" aria-label="upload picture" component="label">
                                          <input hidden multiple accept="image/*" type="file" ref={ fotoTraseraRef }
                                            onChange={ ({target}) => { if (target.files!==null){ if(target.files.length>0) { dispatchPublications(startUploadPhoto2({ field: 'ftrasera', errorField: 'ftraseraError', files: target.files,
                                                                                                                                                                      state: publicationsReducerState, dispatch: dispatchPublications })) } } }
                                          }/>
                                          {/* onChange={ ({target}) => { setLoadPortada(target.value ? true : false); onChange( target.value, 'fportada'); }}  /> */}
                                          <PhotoCamera />
                                        </IconButton>
                                        {                                         
                                          ftraseraError 
                                          ? <Box sx={{ height: 150, border: 1, borderRadius: 1, borderColor: "error.main", color: "error.main", display: "flex", alignItems: "center", justifyContent: "center", cursor:"pointer"}}
                                            onClick={()=>{ fotoTraseraRef.current?.click() }}
                                          > 
                                            { ftraseraError }
                                          </Box>
                                          : ftrasera ?
                                            <div className="new_publication_spare_image">
                                              <Box sx={{ cursor:"pointer" }}>
                                                <CardMedia component="img" height="150px"
                                                  src={process.env.REACT_APP_PHOTOSERVER+ftrasera}   
                                                  // src={ftrasera}   
                                                  onClick={()=>{ fotoTraseraRef.current?.click() }}                                                                          
                                                />
                                              </Box>
                                            </div>
                                            :
                                            <div className="new_publication_spare_image">
                                              <Box sx={{ cursor:"pointer"  }}>
                                                <CardMedia component="img" height="150px"
                                                  src='../assets/f-posterior.png'
                                                  onClick={()=>{ fotoTraseraRef.current?.click() }}
                                                />
                                              </Box>
                                            </div>
                                        }
                                      </div>                                  
                                      <div>
                                        Foto de Interiores
                                        <IconButton color="primary" aria-label="upload picture" component="label">
                                          <input hidden multiple accept="image/*" type="file" ref={ fotoInferiorRef }
                                            onChange={ ({target}) => { if (target.files!==null){ if(target.files.length>0) { dispatchPublications(startUploadPhoto2({ field: 'finferior', errorField: 'finferiorError', files: target.files,
                                                                                                                                                                      state: publicationsReducerState, dispatch: dispatchPublications })) } } }
                                          }/>
                                          {/* onChange={ ({target}) => { setLoadPortada(target.value ? true : false); onChange( target.value, 'fportada'); }}  /> */}
                                          <PhotoCamera />
                                        </IconButton>
                                        {
                                          finferiorError 
                                          ? <Box sx={{ height: 150, border: 1, borderRadius: 1, borderColor: "error.main", color: "error.main", display: "flex", alignItems: "center", justifyContent: "center", cursor:"pointer"}}
                                            onClick={()=>{ fotoInferiorRef.current?.click() }}
                                          > 
                                            { finferiorError }
                                          </Box>
                                          : finferior ?
                                            <div className="new_publication_spare_image">
                                              <Box sx={{ cursor:"pointer" }}>
                                                <CardMedia component="img" height="150px"
                                                  src={process.env.REACT_APP_PHOTOSERVER+finferior}   
                                                  // src={finferior}   
                                                  onClick={()=>{ fotoInferiorRef.current?.click() }}                                                                          
                                                />
                                              </Box>
                                            </div>                               
                                            :
                                            <div className="new_publication_spare_image">
                                              <Box sx={{ cursor:"pointer"  }}>
                                                <CardMedia component="img" height="150px"
                                                  src='../assets/f-interiores.png'
                                                  onClick={()=>{ fotoInferiorRef.current?.click() }}
                                                />
                                              </Box>
                                            </div>
                                        }
                                      </div>                                  
                                      <div>
                                        Foto de Motor
                                        <IconButton color="primary" aria-label="upload picture" component="label">
                                          <input hidden multiple accept="image/*" type="file" ref={ fotoSuperiorRef }
                                            onChange={ ({target}) => { if (target.files!==null){ if(target.files.length>0) { dispatchPublications(startUploadPhoto2({ field: 'fsuperior', errorField: 'fsuperiorError', files: target.files,
                                                                                                                                                                      state: publicationsReducerState, dispatch: dispatchPublications })) } } }
                                          }/>
                                          {/* onChange={ ({target}) => { setLoadPortada(target.value ? true : false); onChange( target.value, 'fportada'); }}  /> */}
                                          <PhotoCamera />
                                        </IconButton>
                                        {
                                          fsuperiorError
                                          ? <Box sx={{ height: 150, border: 1, borderRadius: 1, borderColor: "error.main", color: "error.main", display: "flex", alignItems: "center", justifyContent: "center", cursor:"pointer"}}
                                            onClick={()=>{ fotoSuperiorRef.current?.click() }}
                                          >
                                            { fsuperiorError }
                                          </Box>
                                          : fsuperior ?
                                            <div className="new_publication_spare_image">
                                              <Box sx={{ cursor:"pointer" }}>
                                                <CardMedia component="img" height="150px"
                                                  src={process.env.REACT_APP_PHOTOSERVER+fsuperior}
                                                  // src={fsuperior}
                                                  onClick={()=>{ fotoSuperiorRef.current?.click() }}
                                                />
                                              </Box>
                                            </div>
                                            :
                                            <div className="new_publication_spare_image">
                                              <Box sx={{ cursor:"pointer"  }}>
                                                <CardMedia component="img" height="150px"
                                                  src='../assets/f-motor.png'
                                                  onClick={()=>{ fotoSuperiorRef.current?.click() }}
                                                />
                                              </Box>
                                            </div>
                                        }
                                      </div>

                                      { publicationEndError ? <Typography variant="caption" color="error">{ publicationEndLabel }</Typography> : null }
                                    </div>
                                  </>                                                                                                                                                                                                  
                                }
                              }()                                                
                            }                                                                       
                            {/* CONTROLES */}
                            <div>
                              { function() {
                                switch (index) {
                                  case 0: return <>
                                  { publicationSending
                                      ? <Button disabled variant="contained" onClick={ () => { handleNext(index) }} sx={{ mt: 1, mr: 1 }}>Creando...</Button>
                                      : <Button color="warning" variant="contained" onClick={ () => { handleNext(index) }} sx={{ mt: 1, mr: 1 }}>Siguiente</Button>
                                  }
                                  </>      
                                  case 1: return <>
                                  { publicationOptionalSending                                            
                                      ? <Button disabled variant="contained" onClick={ () => { handleNext(index) }} sx={{ mt: 1, mr: 1 }}>Registrando...</Button>
                                      : <Button color="warning" variant="contained" onClick={ () => { handleNext(index) }} sx={{ mt: 1, mr: 1 }}>Siguiente</Button>
                                  }
                                  </>       
                                  case 2: return <>
                                  { publicationFrontSending                                            
                                      ? <Button disabled variant="contained" onClick={ () => { handleNext(index) }} sx={{ mt: 1, mr: 1 }}>Registrando...</Button>
                                      : <Button color="warning" variant="contained" onClick={ () => { handleNext(index) }} sx={{ mt: 2, mr: 1 }}>Siguiente</Button>
                                  }
                                  </>                                                                                                                                                                                                                                                      
                                  case 3: return <>
                                  { publicationEndSending                                            
                                      ? <Button disabled variant="contained" onClick={ () => { handleNext(index) }} sx={{ mt: 1, mr: 1 }}>Publicando...</Button>
                                      : <Button color="warning" variant="contained" onClick={ () => { handleNext(index) }} sx={{ mt: 3, mr: 1 }}>Publicar</Button>
                                  }
                                  </>                                                         
                                  }
                                }()                                                                                        
                              }
                              { function() { 
                                switch (index) {
                                  case 0: return null;
                                  case 1: return <Button onClick={ () => { handleBack(index) }} sx={{ mt: 1, mr: 1 }}>Atrás</Button>
                                  case 2: return <Button onClick={ () => { handleBack(index) }} sx={{ mt: 2, mr: 1 }}>Atrás</Button>
                                  case 3: return <Button onClick={ () => { handleBack(index) }} sx={{ mt: 3, mr: 1 }}>Atrás</Button>
                                  }
                                }()
                              }
                            </div>
                          </Box>
                        </StepContent>
                      </Step>
                    ))}
                  </Stepper>
                </Box>
              </div>
            )
          }
      </Dialog>
    );
}