import { AppThunk } from '../store/store';
import { fetchToken } from '../helpers/fetch';

//DEFINIMOS LOS TIPOS PARA DEL REDUCER
type UsersPackage = {
    id: string,
    icono: string,
    paquete: string, 
    precio: number,
    descripcion: string  
}
//definimos la estructura del reducer
interface BusinessState {
    plan: string | null,
    registroVendedor: boolean,
    publicaciones: number,
    publicacionesActivas: number,
    publicacionesLimite: number,
    autos: number,
    autosActivos: number,
    autosLimite: number,
    packageId: string,
    showmodal: boolean,
    userPackages: UsersPackage[] | null,
    loading: boolean,
    reload: boolean
};
//definimos el valor inicial
const initialState: BusinessState = {
    plan: null,
    registroVendedor: false,
    publicaciones: 0,
    publicacionesActivas: 0,
    publicacionesLimite: 0,
    autos: 0,
    autosActivos: 0,
    autosLimite: 0,
    packageId: '',
    showmodal: false,
    userPackages: null,
    loading: false,
    reload: false
}

//definimos los types del reducer
type LoadBuisinessPayload = {   plan: string,
                                registroVendedor: boolean,
                                publicaciones: number,
                                publicacionesActivas: number,
                                publicacionesLimite: number, 
                                autos: number,
                                autosActivos: number,
                                autosLimite: number, 
                            };
type LoadPackagesPayload =  { 
                                packageId: string, 
                                userPackages: UsersPackage[] | null
                            };                                

type BusinessAction = { type: 'LoadBusiness' , payload: LoadBuisinessPayload } |
                    { type: 'ClearPackages' } |
                    { type: 'LoadPackages', payload: LoadPackagesPayload } |
                    { type: 'ShowModalPackage' } | 
                    { type: 'CloseModalPackage' } | 
                    { type: 'Reload' }  
                    ;
;
//definimos el reducer
export const businessReducer = (state: BusinessState = initialState, action: BusinessAction): BusinessState => {
    switch (action.type) {
        case 'LoadBusiness': return { 
            ...state, 
            ...action.payload 
        }
        case 'ClearPackages' : return {
            ...state,
            packageId: '',
            userPackages: null,
            showmodal: false,
            loading: false,
            reload: false   
        }
        case 'LoadPackages' : return { 
            ...state, 
            ...action.payload
        }
        case 'ShowModalPackage' : return {
            ...state,
            showmodal: true,
            loading: true
        }
        case 'CloseModalPackage' : return {
            ...state,
            showmodal: false
        }
        case 'Reload' : return {
            ...state,
            reload: true 
        }        
        default: return state;
    }
}

//definimos los actions sincronos del reducer
export const startLoadBusiness = (): AppThunk => {
    return async (dispatch) => {
        const body = await fetchToken('paquetes/loadBussines', {} , 'GET');
        if (body.ok){
            dispatch(loadBusiness({
                plan: body.res.paquete,
                registroVendedor: body.res.registroVendedor,
                publicaciones: body.res.publicaciones,
                publicacionesActivas: body.res.publicacionesActivas,
                publicacionesLimite: body.res.publicacionesLimite,
                autos: body.res.autos,
                autosActivos: body.res.autosActivos,
                autosLimite: body.res.autosLimite
            }));
        } 
    }
}
const loadBusiness = (loadData: LoadBuisinessPayload): BusinessAction  => {
    return {
        type: 'LoadBusiness',
        payload: loadData
    }    
}

//CARGAR PAQUETES DE USUARIOS
type LoadPackagesParameter = { packageId: string };
export const startLoadPackages = (loadPackagesData: LoadPackagesParameter): AppThunk => {
    return async (dispatch) => {

        const { packageId } = loadPackagesData;
        dispatch (clearPackages());

        const body = await fetchToken(`paquetes`,{},'GET');
        if (body.ok){
            //Cambiando formato a respuesta del servidor
            let packages: UsersPackage[] = [];
            for (let paquete of body.res){
                packages.push({
                    id: paquete._id,
                    icono: paquete.icono,
                    paquete: paquete.paquete,
                    precio: paquete.precio,
                    descripcion: paquete.descripcion
                });
            }
            dispatch (loadPackages({
                packageId,
                userPackages: packages
            }));
        } 
    }
}
const clearPackages = (): BusinessAction  => {
    return {
        type: 'ClearPackages'
    }    
}
const loadPackages = (packagesData: LoadPackagesPayload): BusinessAction  => {
    return {
        type: 'LoadPackages',
        payload: packagesData
    }    
}

//MOSTAR Y OCULTAR MODAL
export const showModalPackage = (): BusinessAction => {
    return {
        type: 'ShowModalPackage'
    }
}
export const closeModalPackage = (): BusinessAction => {
    return {
        type: 'CloseModalPackage' 
    }
} 

//CAMBIAR PAQUETE DE USUARIO
type ChangePackagesParameter = { packageId: string };
export const startChangePackage = (changePackageData: ChangePackagesParameter): AppThunk  => {
    return async (dispatch) => {
        const { packageId } = changePackageData;
        const body = await fetchToken(`paquetes`, { id: packageId }, 'PUT');
        if (body.ok){
            dispatch (reloadSite());
        } 
    }
}
const reloadSite = (): BusinessAction  => {
    return {
        type: 'Reload'
    }    
} 