
import "./styles/publicaciones.scss"

import { useContext } from 'react';
import { MenuContext, SalesContext } from '../../context/SalesContext';
import { CardMedia, Button, Box, Pagination, Typography } from '@mui/material';
import SVG from 'react-inlinesvg';
import { showModalNewPub, showModalSales, startUpdatePublicationInfo } from "../../reducers/SalesReducer";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../store/store";
import { useHistory, useParams } from "react-router-dom";
import { isNumeric } from "../../../../../../helpers/checkValues";
import { paginate } from "../../../../../../helpers/arrays";

//TOTAL DE RESULTADOS POR PAGINA
const resuladosPorPagina = 12;

export const Publicaciones = () => {

    //LEER EL REDUCER LOCAL
    const { salesReducerState, dispatchSales } = useContext(SalesContext);
    const { publications, countPublications } = salesReducerState;

    //LEER EL REDUCER LOCAL
    //const { salesReducerState, dispatchSales } = useContext(SalesContext);
    //const { sales } = salesReducerState;

    const storeBusiness = (state: RootState ) => state.business;
    const { plan } = useSelector(storeBusiness);

    const storeAuth = (state: RootState ) => state.auth;
    const { rol } = useSelector(storeAuth);

    // PARAMETRO PARA NAVEGAR HACIA OTRO COMPONENTE (HREF O LINK)
    const history = useHistory();

    //GENERAR NUEVA VENTA
    const handleNuevaClick = () => {
        dispatchSales(showModalNewPub());
    }

    const { setIndexComponent } = useContext( MenuContext );
    const handleClickVerPlanes = () => {
      setIndexComponent(0);
    }

    const handleClickInfoPublication = (idPublicacion: any) => {
        dispatchSales(startUpdatePublicationInfo({ dispatchLocalSales: dispatchSales, publicacionInfoId: idPublicacion }))
        dispatchSales(showModalSales());
    }

    const publicacionesAscendente = publications?.slice().reverse();

    // SACAMOS LOS PARAMETROS DE LA DIRECCION URL 
    const { salesPage } = useParams<{ salesPage: string }>();

    const handlePaginationChange = (event: React.ChangeEvent<unknown>, value: number) => {
      history.push(`/sales/${value}`);
      window.scrollTo(0, 0);
    };

    console.log(publications);

    return (
      <div className="sales_publications_container">
        <div className="sales_titulo_principal">Mis publicaciones</div>
        { rol === "COMPRADOR" ?
          <div className="plan_format">
            <div className="plan_icono">
              <SVG src={process.env.REACT_APP_ICONSERVER+'dz-plan'} height="100%" />
            </div>
            <div className="plan_titulo">
              Plan de ventas
            </div>
            <div className="plan_dato1">
              <p>Actualmente tienes un plan: <b className="plan_dato1_color">{ plan }</b></p>
            </div>
            <div></div>
            <div className="plan_dato2">
              <p>
                ¿Quieres publicar algo en AutosXPartes.com?
              </p>
            </div>
            <div className="plan_opcion">
              <Box sx={{ textAlign: "center" }}>
                <Button
                  onClick={handleClickVerPlanes}
                  variant="contained"
                  sx={{ textTransform: 'none' }}
                >
                  Cambia tu plan aqui
                </Button>
              </Box>
            </div>
          </div>
          :
          <>
            <div className="sales_section_publicar">
              <p>¿Quieres publicar algo nuevo?</p>
              <div>
                <Button variant="contained" color="warning" onClick={ () => {handleNuevaClick()} }>Nueva publicacion</Button>
              </div>
            </div>
            <div className="sales_container_cards">
              {
                publicacionesAscendente && (
                  publicacionesAscendente?.length ? (
                    paginate(publicacionesAscendente , resuladosPorPagina, isNumeric(salesPage) ? Number(salesPage) : 1 ).map( (publication) => 
                    
                      <div className="sales_format_cards" key={publication.id} onClick={() => handleClickInfoPublication(publication.id)}>

                        <Box sx={{ backgroundColor: (publication.tipo === "Auto" ? "#ff6700" : "rgb(0, 93, 199)" ), color: "white", height: "45px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                          <Typography variant="h6">{publication.tipo}</Typography>
                        </Box>

                        <div className="sales_cards_imagen">
                          <Box>
                            <CardMedia component="img"
                              image={publication.fotoPublicacion ? process.env.REACT_APP_PHOTOSERVER+publication.fotoPublicacion : './assets/sin-foto.png'}
                              // image={publication.fotoPublicacion ? publication.fotoPublicacion : './assets/sin-foto.png'}
                              className="sales_cards_style_image"
                            />
                          </Box>
                        </div>

                        <div className="sales_format_cards_datos">
                          <Box style={{ display: "flex", alignItems: "center" }}>
                            <Box sx={{ display: "flex", alignItems: "center" }}><SVG src={publication.icono ? process.env.REACT_APP_ICONSERVER+publication.icono : '' } fill="currentColor" height={25}/></Box>
                            <Box sx={{ ml: 1 }} className="sales_datos_titulo">{`${publication.marca} ${publication.modelo} ${publication.anio}`}</Box>
                          </Box>

                          <div className="sales_format_card_subdatos">
                            <div><b>Transmisión:</b></div>
                            <div>{publication.transmision ? publication.transmision : 'Sin dato'}</div>
                            <div><b>Motor:</b></div>
                            <div>{publication.motor ? publication.motor : 'Sin dato'}</div>
                            <div><b>Version:</b></div>
                            <div>{publication.version ? publication.version : 'Sin dato'}</div>
                          </div>

                          <Box style={{ display: "flex", alignItems: "center", height: "100%" }}>
                            <div>
                              {
                                plan === 'BASICO' 
                                ? <div style={{ width: "25px", height: "25px", borderRadius: "50%", background: "black" }}/>
                                : ( function() {
                                  switch ( publication?.estado ) {
                                    case 'Activa':
                                      return <div style={{ width: "25px", height: "25px", borderRadius: "50%", background: "#00c853" }}/>
                                    case 'Pausada':
                                      return <div style={{ width: "25px", height: "25px", borderRadius: "50%", border: "3px solid black" }}/>
                                    case 'Pendiente':
                                      return <div style={{ width: "25px", height: "25px", borderRadius: "50%", background: "#fca311" }}/>
                                    case 'Rechazada':
                                      return <div style={{ width: "25px", height: "25px", borderRadius: "50%", background: "red" }}/>
                                    case 'Incompleta':
                                      return <div style={{ width: "25px", height: "25px", borderRadius: "50%", border: "3px solid red" }}/>
                                  }
                                })()
                              }
                            </div>
                            <Box sx={{ ml: 1 }}><b>
                              {
                                publication.estado === 'Pendiente' ?
                                'En Revisión'
                                :
                                `Publicación ${ publication.estado }`
                              }</b>
                            </Box>
                          </Box>

                        </div>
                      </div>
                  )) : (
                    <div className="sales_format">
                      <SVG src={process.env.REACT_APP_ICONSERVER+'dz-car'} fill="#D3D2D2"/>
                      <p>Aún no has generado ninguna publicación.</p>
                    </div>
                  )
                )
              }
            </div>
            <Box sx={{ display: "flex", justifyContent: "center", marginTop: "40px" }}>  {/* COMPONENTE DE LA PAGINACION DE LAS PUBLICACIONES */}
              <Pagination
                count={countPublications ? Math.ceil(countPublications/resuladosPorPagina) : 1} 
                page={ isNumeric(salesPage) ? Number(salesPage) : 1 }
                defaultPage={1} 
                color="primary" 
                size="large"
                onChange={ handlePaginationChange }
              />
            </Box>
          </>
        }
      </div>
    )
}