import { AppThunk } from '../store/store';
import { fetchToken, fetchSinToken } from '../helpers/fetch';

//DEFINIMOS LOS TIPOS PARA DEL REDUCER
export type Brand = {
    id: string,
    marca: string, 
    icono: string
}
export type Model = {
    id: string,
    marca: string,
    modelo: string, 
    anioInicial: number,
    anioFinal: number 
}

//definimos la estructura del reducer
interface FilterState {

    brands: Brand[] | null,
    models: Model[] | null,

    brand: Brand | null,
    selectedBrand: boolean,
    brandTextError: boolean,
    brandTextLabel: string,

    availableModels: Model[] | null,
    model: Model | null,
    selectedModel: boolean, 
    modelTextError: boolean,
    modelTextLabel: string,

    availableAnioInicial: number | null,
    availableAnioFinal: number | null,
    availableAnios: number[] | null,
    anio: number | null,
    anioTextError: boolean,
    anioTextLabel: string,

    load: boolean,
    loading: boolean,
    reload: boolean,

    buscarRefaccion: '',
    buscarRefaccionError: null,

    sendRequestRefaccion: boolean,
    sendRequestRefaccionSending: boolean,
    sendRequestRefaccionError: string | null,
    sendRequestRefaccionCorrect: string | null,

    envioFinalizado: boolean

    asistencia: boolean
};
//definimos el valor inicial
const initialState: FilterState = {
    brands: null,
    models: null,

    brand: null,
    selectedBrand: false,
    brandTextError: false,
    brandTextLabel: '',

    availableModels: null,
    model: null,
    selectedModel: false, 
    modelTextError: false,
    modelTextLabel: '',

    availableAnioInicial: null,
    availableAnioFinal: null,
    availableAnios: null,
    anio: null,
    anioTextError: false,
    anioTextLabel: '',  

    load: false,
    loading: false,
    reload: false,

    buscarRefaccion: '',
    buscarRefaccionError: null,

    sendRequestRefaccion: false,
    sendRequestRefaccionSending: false,
    sendRequestRefaccionError: null,
    sendRequestRefaccionCorrect: null,

    envioFinalizado: false,

    asistencia: false
}

//definimos los types del reducer
type LoadFilterPayload = {  
                            brands: Brand[],
                            models: Model[] 
};
type SelectBrandPayload = {
                            brand: Brand,
                            availableModels: Model[] | null
};
type SelectModelPayload = {
                            model: Model,
                            availableAnioInicial: number | null,
                            availableAnioFinal: number | null,
                            availableAnios: number[] | null
};
type SelectAnioPayload = {
                            selectedAnio: number
};
type SetErrorPayload = {
                            label: string
}

type OnChangePayload = {
    field: keyof FilterState,
    value: any
}
type OnErrorPayload = {
    errorField: keyof FilterState,
    label: string
}
type OnCleanErrorPayload = {
    errorField: keyof FilterState
}

type UpdateCorrectPayload = {
    label: string
};
type UpdateFailedPayload = {
    label: string
};

type FilterActions = { type: 'PreLoadFilters' } |
                     { type: 'LoadFilters' , payload: LoadFilterPayload } |

                     { type: 'ClearBrand' } |
                     { type: 'SelectBrand', payload: SelectBrandPayload } |
                     { type: 'ErrorBrand' , payload: SetErrorPayload } |
                     { type: 'ClearErrorBrand' } |

                     { type: 'ClearModel' } |
                     { type: 'SelectModel', payload: SelectModelPayload } |
                     { type: 'ErrorModel' , payload: SetErrorPayload } |
                     { type: 'ClearErrorModel' } |

                     { type: 'SelectAnio', payload: SelectAnioPayload } | 
                     { type: 'ClearAnio' } |
                     { type: 'ErrorAnio' , payload: SetErrorPayload } |
                     { type: 'ClearErrorAnio' } |

                     { type: 'OnChange', payload: OnChangePayload } |
                     { type: 'OnError', payload: OnErrorPayload } |
                     { type: 'OnCleanError', payload: OnCleanErrorPayload } |

                     { type: 'PrepareFetchSendRequestRefaccion' } |
                     { type: 'UpdateSendRequestRefaccion' } |
                     { type: 'FetchSendRequestRefaccionFailed', payload: UpdateFailedPayload } |
                     { type: 'FetchSendRequestRefaccionCorrect', payload: UpdateCorrectPayload } |
                     { type: 'CleanSendRequestRefaccion' } |
                     { type: 'CleanAsistenciaError' };
;
//definimos el reducer
export const filterReducer = (state: FilterState = initialState, action: FilterActions): FilterState => {
    switch (action.type) {
        case 'PreLoadFilters': return {
            ...state,
            ...initialState,
            loading: true
        }
        case 'LoadFilters': return {
            ...state,
            ...action.payload,
            loading: false,
            load: true
        }
        case 'ClearBrand': return {
            ...state,
            brand: null,
            selectedBrand: false,
            availableModels: null,

            brandTextError: false,
            brandTextLabel: '',

            model: null,
            selectedModel: false, 
            availableAnioInicial: null,
            availableAnioFinal: null,

            availableAnios: null,
            anio: null,
        }
        case 'SelectBrand': return {
            ...state,
            brand: action.payload.brand,
            availableModels: action.payload.availableModels,
            selectedBrand: true
        }
        case 'ErrorBrand': return {
            ...state,
            brandTextError: true,
            brandTextLabel: action.payload.label
        }
        case 'ClearErrorBrand': return {
            ...state,
            brandTextError: false,
            brandTextLabel: ''
        }
        case 'ClearModel': return { 
            ...state, 
            model: null,
            selectedModel: false, 

            modelTextError: false,
            modelTextLabel: '',

            availableAnioInicial: null,
            availableAnioFinal: null,
            availableAnios: null,
            anio: null
        }        
        case 'SelectModel': return {
            ...state,
            model: action.payload.model,
            availableAnioFinal: action.payload.availableAnioFinal,
            availableAnioInicial: action.payload.availableAnioInicial,
            availableAnios: action.payload.availableAnios,
            selectedModel: true
        }
        case 'ErrorModel': return {
            ...state,
            modelTextError: true,
            modelTextLabel: action.payload.label
        }
        case 'ClearErrorModel': return {
            ...state,
            modelTextError: false,
            modelTextLabel: ''
        }        
        case 'SelectAnio': return {
            ...state,
            anio: action.payload.selectedAnio
        }
        case 'ClearAnio' : return {
            ...state,
            anio: null,

            anioTextError: false,
            anioTextLabel: ''
        }
        case 'ErrorAnio': return {
            ...state,
            anioTextError: true,
            anioTextLabel: action.payload.label
        }
        case 'ClearErrorAnio': return {
            ...state,
            anioTextError: false,
            anioTextLabel: ''
        }

        case 'OnChange' : return {
            ...state,
            [action.payload.field]: action.payload.value
        }
        case 'OnError' : return {
            ...state,
            [action.payload.errorField]: action.payload.label
        }
        case 'OnCleanError' : return {
            ...state,
            [action.payload.errorField]: null
        }

        case 'PrepareFetchSendRequestRefaccion' : return {
            ...state,
            sendRequestRefaccion: false,
            sendRequestRefaccionSending: true,
            sendRequestRefaccionError: null,
        }
        case 'UpdateSendRequestRefaccion' : return {
            ...state,
            sendRequestRefaccion: true,
            sendRequestRefaccionSending: false
        }
        case 'FetchSendRequestRefaccionFailed' : return {
            ...state,
            sendRequestRefaccionError: action.payload.label,
            sendRequestRefaccionSending: false,
            asistencia: true
        }
        case 'FetchSendRequestRefaccionCorrect' : return {
            ...state,
            sendRequestRefaccionCorrect: action.payload.label,
            envioFinalizado: true,
            asistencia: false
        }
        case 'CleanSendRequestRefaccion' : return {
            ...state,
            buscarRefaccion: '',
            buscarRefaccionError: null,
        }
        case 'CleanAsistenciaError' : return {
            ...state,
            sendRequestRefaccionCorrect: null,
            envioFinalizado: false,
        }

        default: return state;
    }
}

//CARGANDO DATOS
export const startLoadFilters = (): AppThunk => {
    return async (dispatch) => {
        dispatch (preLoadFilters());
        const body = await fetchToken('filtros/loadFilters', {} , 'GET');
        if (body.ok){
            //Cambiando formato a respuesta del servidor
            let brands: Brand[] = [];
            let models: Model[] = [];
            //console.log('hizo fetch OK');

            for (let brand of body.res.marcas){
                brands.push({
                    id: brand._id,
                    marca: brand.marca,
                    icono: brand.icono
                });
            }
            for (let model of body.res.modelos){
                models.push({
                    id: model._id,
                    marca: model.marca,
                    modelo: model.modelo,
                    anioInicial: model.anioInicial,
                    anioFinal: model.anioFinal
                });
            }

            dispatch(loadFilters({
                brands,
                models
            }));
        }
    }
}
export const startLoadFiltersSinToken = (): AppThunk => {
    return async (dispatch) => {
        dispatch (preLoadFilters());
        const body = await fetchSinToken('filtros/loadFiltersNoToken', {} , 'GET');
        if (body.ok){
            //Cambiando formato a respuesta del servidor
            let brands: Brand[] = [];
            let models: Model[] = [];

            for (let brand of body.res.marcas){
                brands.push({
                    id: brand._id,
                    marca: brand.marca,
                    icono: brand.icono
                });
            }
            for (let model of body.res.modelos){
                models.push({
                    id: model._id,
                    marca: model.marca,
                    modelo: model.modelo,
                    anioInicial: model.anioInicial,
                    anioFinal: model.anioFinal
                });
            }

            dispatch(loadFilters({
                brands,
                models
            }));
        }
    }
}
const preLoadFilters = (): FilterActions  => {
    return {
        type: 'PreLoadFilters'
    }
}
const loadFilters = (filtersData: LoadFilterPayload): FilterActions  => {
    //console.log(filtersData);
    return {
        type: 'LoadFilters',
        payload: filtersData
    }    
}

//SELECCIONANDO MARCA
type selectBrandParameter = { selectedBrand: Brand };
export const startSelectBrand = (selectBrandData: selectBrandParameter): AppThunk => {
    return async (dispatch, getState) => {

        const { selectedBrand } = selectBrandData;
        dispatch (clearBrand());

        //buscando modelos compatibles
        const models = getState().filters.models;
        let compatibleModels: Model[];
        models ? compatibleModels = models.filter(model => model.marca === selectedBrand.marca) : compatibleModels = [];

        dispatch (selectBrand({
            brand: selectedBrand,
            availableModels: compatibleModels              
        })) 
    }
}
const selectBrand = (selectBrandData: SelectBrandPayload): FilterActions  => {
    return {
        type: 'SelectBrand',
        payload: selectBrandData
    }    
}
const clearBrand = (): FilterActions => {
    return {
        type: 'ClearBrand'
    }
}
export const setClearBrand = (): FilterActions => {
    return {
        type: 'ClearBrand'
    }
}
export const setErrorBrand = (errorBrandData: SetErrorPayload): FilterActions => {
    return {
        type: 'ErrorBrand',
        payload: errorBrandData
    }
}
export const setClearErrorBrand = (): FilterActions => {
    return {
        type: 'ClearErrorBrand'
    }
}


//SELECCIONANDO MODELO
type selectModelParameter = { selectedModel: Model };
export const startSelectModel = (selectModelData: selectModelParameter): AppThunk => {
    return async (dispatch, getState) => {

        const { selectedModel } = selectModelData;
        dispatch (clearModel());

        //generando lista de años disponibles
        //const availableAnios = Array.from({length: selectedModel.anioFinal-selectedModel.anioInicial+1},(v,k)=>k+selectedModel.anioInicial)

        let availableAnios : number[] | null = Array.from({length: selectedModel.anioFinal-selectedModel.anioInicial+1},(v,k)=>k+selectedModel.anioInicial)
       
        //generando lista de años disponibles
        if (selectedModel.anioFinal=== 0|| selectedModel.anioInicial=== 0){
                if (getState().filters.availableAnios !== null){
                     availableAnios =getState().filters.availableAnios;
                }
        }

        dispatch (selectModel({
            model: selectedModel,
            availableAnioInicial: selectedModel.anioInicial,
            availableAnioFinal: selectedModel.anioFinal,
            availableAnios: availableAnios
        })) 
    }
}
const selectModel = (selectModelData: SelectModelPayload): FilterActions  => {
    return {
        type: 'SelectModel',
        payload: selectModelData
    }
}
const clearModel = (): FilterActions => {
    return {
        type: 'ClearModel'
    }
}
export const setClearModel = (): FilterActions => {
    return {
        type: 'ClearModel'
    }
}
export const setErrorModel = (errorModelData: SetErrorPayload): FilterActions => {
    return {
        type: 'ErrorModel',
        payload: errorModelData
    }
}
export const setClearErrorModel = (): FilterActions => {
    return {
        type: 'ClearErrorModel'
    }
}

//SELECCIONANDO ANIOS
export const startSelectAnio = (selectedAnio: SelectAnioPayload): AppThunk => {
    return async (dispatch) => {
        //dispatch (setClearAnio());
        dispatch (selectAnio(selectedAnio));
        //console.log(selectedAnio);
    }
}
const selectAnio = (selectedAnio: SelectAnioPayload): FilterActions  => {
    return {
        type: 'SelectAnio',
        payload: selectedAnio

    }  
}
export const setClearAnio = (): FilterActions => {
    return {
        type: 'ClearAnio'
    }
}
export const setErrorAnio = (errorAnioData: SetErrorPayload): FilterActions => {
    return {
        type: 'ErrorAnio',
        payload: errorAnioData
    }
}
export const setClearErrorAnio = (): FilterActions => {
    return {
        type: 'ClearErrorAnio'
    }
}

export const startSelectModelEdit = (selectModelData: selectModelParameter ,): AppThunk => {
    return async (dispatch) => {

        const { selectedModel } = selectModelData;
     
        const body = await fetchSinToken('filtros/loadFiltersNoToken', {} , 'GET');
        let brands: Brand[] = [];
        let models: Model[] = [];
        if (body.ok){
            //Cambiando formato a respuesta del servidor

            for (let brand of body.res.marcas){
                brands.push({
                    id: brand._id,
                    marca: brand.marca,
                    icono: brand.icono
                });
            }
            for (let model of body.res.modelos){
                models.push({
                    id: model._id,
                    marca: model.marca,
                    modelo: model.modelo,
                    anioInicial: model.anioInicial,
                    anioFinal: model.anioFinal
                });
            }


        }

         let ModelFound: Model[]= models.filter( m => (m.id === selectedModel.id) );
        //generando lista de años disponibles
        const availableAnios = Array.from({length: ModelFound[0].anioFinal-ModelFound[0].anioInicial+1},(v,k)=>k+ModelFound[0].anioInicial)
        
        dispatch (selectModel({
            model: ModelFound[0],
            availableAnioInicial: ModelFound[0].anioInicial,
            availableAnioFinal: ModelFound[0].anioFinal,
            availableAnios: availableAnios
        })) 
    }
}

//METODO ON CHANGE GENERAL
export const onChange = (onChangeData: OnChangePayload): FilterActions => {
    return {
        type: 'OnChange',
        payload: onChangeData
    }
}
//METODO SET ERROR GENERAL
export const setError = (onErrorData: OnErrorPayload): FilterActions => {
    return {
        type: 'OnError',
        payload: onErrorData
    }
}
//METODO CLEAN ERROR GENERAL
export const cleanError = (onCleanErrorData: OnCleanErrorPayload): FilterActions => {
    return {
        type: 'OnCleanError',
        payload: onCleanErrorData
    }
}

type startSendRequestRefaccionParameter = { marcaId: string | undefined, modeloId: string | undefined, anio: number | string | null , refaccion: string | undefined };
export const startSendRequestRefaccion = (startSendRequestRefaccionInfo: startSendRequestRefaccionParameter): AppThunk => {
    return async (dispatch) => {

        dispatch(prepareFetchSendRequestRefaccion());

        const { marcaId, modeloId, anio, refaccion } = startSendRequestRefaccionInfo;

        localStorage.setItem('marca', (marcaId ? marcaId : ''));
        localStorage.setItem('modelo', (modeloId ? modeloId : ''));
        localStorage.setItem('anio', (anio ? anio.toString() : ''));
        localStorage.setItem('refaccion', (refaccion ? refaccion : ''));

        console.log(marcaId)
        console.log(modeloId)
        console.log(anio)
        console.log(refaccion)

        /*console.log(getState().filters.brand?.id)
        console.log(getState().filters.model?.id)
        console.log(getState().filters.anio)
        console.log(getState().filters.buscarRefaccion)*/

        /*const body = await fetchToken('publicaciones/asistencia', {
            marcaId: getState().filters.brand?.id,
            modeloId: getState().filters.model?.id,
            anio: getState().filters.anio,
            refaccion: getState().filters.buscarRefaccion
        }, 'POST');*/

        const body = await fetchToken(`publicaciones/asistencia`, {
            marcaId: marcaId,
            modeloId: modeloId,
            anio: anio,
            refaccion: refaccion
        }, 'POST');

        console.log(body);

        if (body.ok) {
            dispatch(updateSendRequestRefaccion());
            dispatch(cleanSendRequestRefaccion());
            dispatch(fetchSendRequestRefaccionCorrect({ label: body.msg }));
            localStorage.removeItem('marca');
            localStorage.removeItem('modelo');
            localStorage.removeItem('anio');
            localStorage.removeItem('refaccion');
        } else {
            dispatch(fetchSendRequestRefaccionFailed({ label: body.msg }));
            console.log('operacion respondio false')
        }
    }
}

const prepareFetchSendRequestRefaccion = (): FilterActions => {
    return {
        type: 'PrepareFetchSendRequestRefaccion'
    }
}
const updateSendRequestRefaccion = (): FilterActions  => {
    return {
        type: 'UpdateSendRequestRefaccion'
    }    
}
const fetchSendRequestRefaccionFailed = (updateSendRequestRefaccionFailedData: UpdateFailedPayload): FilterActions => {
    return {
        type: 'FetchSendRequestRefaccionFailed',
        payload: updateSendRequestRefaccionFailedData
    }
}
const fetchSendRequestRefaccionCorrect = (updateSendRequestRefaccionCorrectData: UpdateCorrectPayload): FilterActions => {
    return {
        type: 'FetchSendRequestRefaccionCorrect',
        payload: updateSendRequestRefaccionCorrectData
    }
}
const cleanSendRequestRefaccion = (): FilterActions  => {
    return {
        type: 'CleanSendRequestRefaccion'
    }    
}

export const cleanAsistenciaError = (): FilterActions => {
    return {
        type: 'CleanAsistenciaError',
    }
}

/*export const startUpdateReceiveEmail = (): AppThunk => {
    return async (dispatch, getState) => {

        dispatch(prepareFetchReceiveMessage());

        const body = await fetchToken(`auth/receiveEmail`, {
            email: getState().contact.email,
            nombre: getState().contact.nombreApellido,
            telefono: getState().contact.telefono,
            mensaje: getState().contact.mensaje

        }, 'POST');

        //console.log(body);

        if (body.ok){
            dispatch(updateReceiveMessage());
            dispatch(cleanReceiveMessage());
            dispatch(fetchReceiveMessageCorrect({ label: body.msg }));
        } else {
            dispatch(fetchReceiveMessageFailed({ label: body.msg }));
        }
    }
}*/