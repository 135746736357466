import { useState, useEffect, useReducer } from 'react';
import { Box, Step, StepContent, Stepper, Typography, Button, StepLabel, TextField, InputAdornment, IconButton } from '@mui/material';
import SVG from 'react-inlinesvg';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store/store';
import { startLogin } from '../../reducers/authReducer';
import { useForm } from '../../hooks/useForm';
import validator from 'validator';
import { registerReducer, initialRegisterState, changePasswordUser , startPasswordChange ,startVerifyCodeCHangePassword } from './reducers/RegisterReducer';
//import {  useParams } from "react-router-dom";
import "./styles/register.scss"
import { Visibility, VisibilityOff } from '@mui/icons-material';
import InputMask from 'react-input-mask';


export const PasswordChange = () => {
    //const { token } = useParams<{ token: string }>();
    //console.log(token);

    const [{ emailSend, 
            emailSendMsg, 
            smsValid, 
            smsValidMsg,
            userCreate,
            userCreateMsg
        }, dispatch] = useReducer(registerReducer, initialRegisterState)

        const steps = [
            {
                label: 'Ingresa tu cuenta de correo electrónico.',
                description: `Para cambiar tu contraseña es necesario validar tu cuenta de correo electrónico.`,
              }
             ,
              {
                label: 'Confirmar cambio de contraseña',
                description:'Introduce el código enviado al teléfono. ' ,
              },
              {
                label: 'Nueva contraseña',
                description: `Ingresa tu nueva contraseña.`,
              },
        ];

    //HOOKS DE CONTROL DE STEPPER
    const [activeStep, setActiveStep] = useState(0);
    const [emailError, setEmailError] = useState(false);
    const [emailLabel, setEmailLabel] = useState('');
    const [emailSending, setEmailSending] = useState(false);
    const [emailTextError, setEmailTextError] = useState(false);
    const [emailTextLabel, setEmailTextLabel] = useState('');

    const [passwordTextError, setPasswordTextError] = useState(false);
    const [passwordTextLabel, setPasswordTextLabel] = useState('');
    const [secondPasswordTextError, setSecondPasswordTextError] = useState(false);
    const [secondPasswordTextLabel, setSecondPasswordTextLabel] = useState('');

    const [usuarioError, setUsuarioError] = useState(false);
    const [usuarioLabel, setUsuarioLabel] = useState('');    

    const [smsCodeError, setSmsCodeError] = useState(false);
    const [smsCodeLabel, setSmsCodeLabel] = useState('');
    const [smsCodeSending, setSmsCodeSending] = useState(false);
    const [smsCodeTextError, setSmsCodeTextError] = useState(false);
    const [smsCodeTextLabel, setSmsCodeTextLabel] = useState('');


    //HOOK PARA CONTROLAR INPUTS
    const {  email,password, secondPassword, telefonoCode, onChange } = useForm({
        email: '',
        password: '',
        secondPassword: '',
        telefonoCode: '',
    });

    //ESCRIBIR EN EL STORE DE REDUX
    const dispatchRedux = useDispatch<AppDispatch>();   

    useEffect(() => {
        console.log('Validando si se envio email');
        if (emailSend) {
            console.log('Avanzando pestaña por email enviado');
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } 
    }, [emailSend])

    useEffect(() => {
        console.log('Validando si el codigo del sms es correcto');
        if (smsValid) {
            console.log('Avanzando pestaña por codigo de sms correcto');
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } 
    }, [smsValid])
    //Validacion de respuesta de fetch de codigo de email
    useEffect(() => {
        if (smsValidMsg) {
            setSmsCodeSending(false);
            setSmsCodeError(true);
            setSmsCodeLabel(smsValidMsg);        
        }
    }, [smsValidMsg])

    //ENVIANDO FETCH AL SERVIDOR DEPENDIENDO EL PASO
    const handleNext = async (index: number) => {
        console.log('Next'+index);
        let error = false;
        switch (index) {
            case 0:          
                //validando email 
                if (validator.isEmail(email)){  
                    console.log('Enviando email');
                    //hooks de control de error de input
                    setEmailTextError(false);
                    //hook de control de enviando
                    setEmailSending(true);
                    //hooks de control de error de fetch
                    setEmailError(false);
                    setEmailLabel('');
                    //EJECUCION DE FETCH
                    await startPasswordChange({email: email, dispatch});             
                } else {
                    //hooks de control de error de input
                    setEmailTextError(true);
                    setEmailTextLabel('El correo no es válido');
                }
            break;  
            case 1:
                //validando codigo de email
                if (validator.isLength(telefonoCode,{min: 9, max: 9})){
                    console.log('Verificando codigo de sms');
                    //hooks de control de error de input
                    setSmsCodeTextError(false);  
                    //hook de control de enviando  
                    setSmsCodeSending(true);
                    //hooks de control de error de fetch
                    setSmsCodeError(false);
                    setSmsCodeLabel('');
                    //EJECUCION DE FETCH
                    await startVerifyCodeCHangePassword({email: email,
                                            telefono: 0,
                                          codigo: telefonoCode,
                                          dispatch
                                        });
                } else {
                    //hooks de control de error de input
                    setSmsCodeTextError(true);
                    setSmsCodeTextLabel('El código de verificación no es válido');
                }
            break;    
            case 2:          
                if (!validator.isLength(password,{min: 8, max:20})){
                    //hooks de control de error de input
                    setPasswordTextError(true);
                    setPasswordTextLabel('Ingrese una contraseña de 8 valores');
                    error = true;
                } else { setPasswordTextError(false); }
                if (!validator.equals(secondPassword, password)){
                    //hooks de control de error de input
                    setSecondPasswordTextError(true);
                    setSecondPasswordTextLabel('La contraseña no coincide');
                    error = true;
                } else { setSecondPasswordTextError(false); }
                if (!error){
                    setPasswordTextError(false);
                    setSecondPasswordTextError(false);
                  
                    //EJECUCION DE FETCH
                    await changePasswordUser({
                        token: telefonoCode,
                        password: password,
                        dispatch
                      }); 

                      
                }
            break;                         
                  
        }        
    };

    useEffect(()=> {
        if (userCreate) {
            dispatchRedux<any>(startLogin({usuario: email, password: password}));
        }
    }, [userCreate,dispatchRedux,email,password]) 

   
    //Validacion de respuesta de fetch de creando usuario
    useEffect(() => {
        
        if (userCreateMsg) {
            setUsuarioError(true);
            setUsuarioLabel(userCreateMsg);        
        }
    }, [userCreateMsg,password])

    useEffect(() => {
        if (emailSendMsg) {
            setEmailSending(false);
            setEmailError(true);
            setEmailLabel(emailSendMsg);        
        }
    }, [emailSendMsg])

    //VALIDANDO FUNCION DEL BOTON ATRAS
    

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const [showSecondPassword, setShowSecondPassword] = useState(false);
    const handleClickShowSecondPassword = () => {
        setShowSecondPassword(!showSecondPassword);
    };

    return (
    <div className="register_principal">
        <div className="register_grid">
            <div className="register_header">
                <Box sx={{ height: 40, width: 50, marginRight: 1 }}>
                    <SVG src={process.env.REACT_APP_ICONSERVER+'dz-return'} height={40} fill="currentColor"/>
                </Box> 
                <h4>Cambiar contraseña</h4>
            </div>                      
            <div className="register_stepper">
                <Box sx={{ maxWidth: 400 }}>
                    <Stepper activeStep={activeStep} orientation="vertical">
                        {steps.map((step, index) => (
                            <Step key={step.label}>
                                {/* SUBTITULOS */}
                                <StepLabel>                                   
                                    {step.label}
                                </StepLabel>
                                <StepContent>                                    
                                    <Typography>{step.description}</Typography> 
                                    <Box sx={{ mb: 2 }}> 
                                        {/* COMPONENTES */} {                                            
                                            function() {                                     
                                                switch (index) {
                                                    case 0 : return <>
                                                        { emailTextError 
                                                            ? <TextField error fullWidth id="outlined-basic" value = { email } onChange={ ({target}) => onChange( target.value, 'email') } label={ emailTextLabel } variant="outlined" margin="dense" />                                                         
                                                            : <TextField fullWidth id="outlined-basic" value = { email } onChange={ ({target}) => onChange( target.value, 'email') } label="Correo electrónico" variant="outlined" margin="dense" />
                                                        }
                                                        { emailError
                                                            ? <Typography variant="caption" color="error">{ emailLabel }</Typography>
                                                            : null
                                                        }
                                                    </>
                                                      case 1 : return <>
                                                      { smsCodeTextError 

                                                          ? <InputMask
                                                              mask="****-****"
                                                              value={ telefonoCode }
                                                              onChange={ ({target}) => onChange( target.value, 'telefonoCode') }
                                                          >
                                                              {() => <TextField
                                                                  error fullWidth id="outlined-basic" label={ smsCodeTextLabel }
                                                                  variant="outlined" margin="dense"
                                                                  inputProps={{ style: { textTransform: "uppercase" } }}
                                                              />}
                                                          </InputMask>                                                            
                                                          :  
                                                          <>
                                                          <Typography variant="caption" >{ emailSendMsg }</Typography>
                                                          <InputMask
                                                              mask="****-****"
                                                              value={ telefonoCode }
                                                              onChange={ ({target}) => onChange( target.value, 'telefonoCode') }
                                                          >
                                                              {() => <TextField
                                                                  fullWidth id="outlined-basic" label="Código de validación"
                                                                  variant="outlined" margin="dense" 
                                                                  inputProps={{ style: { textTransform: "uppercase" } }}
                                                              />}
                                                          </InputMask>
                                                          </>
                                                      }  
                                                      { smsCodeError 
                                                          ? <Typography variant="caption" color="error">{ smsCodeLabel }</Typography>
                                                          : null                                                        
                                                      }   
                                                  </>     
                                                    case 2 : return <>
                                                                                  
                                                        { passwordTextError
                                                            ? <TextField
                                                                error fullWidth size="small" id="outlined-basic"
                                                                value={ password } onChange={ ({target}) => onChange( target.value, 'password') }
                                                                label={ passwordTextLabel } variant="outlined" margin="dense"
                                                                type={showPassword ? 'text' : 'password'}
                                                                InputProps={{
                                                                    endAdornment:
                                                                    <InputAdornment position="end">
                                                                        <IconButton
                                                                            aria-label="toggle password visibility"
                                                                            onClick={handleClickShowPassword}
                                                                            edge="end"
                                                                        >
                                                                            { showPassword ? <VisibilityOff /> : <Visibility /> }
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                }}
                                                            />
                                                            : <TextField
                                                                fullWidth size="small" id="outlined-basic"
                                                                value={ password } onChange={ ({target}) => onChange( target.value, 'password') }
                                                                label="Contraseña" variant="outlined" margin="dense"
                                                                type={showPassword ? 'text' : 'password'}
                                                                InputProps={{
                                                                    endAdornment:
                                                                    <InputAdornment position="end">
                                                                        <IconButton
                                                                            aria-label="toggle password visibility"
                                                                            onClick={handleClickShowPassword}
                                                                            edge="end"
                                                                        >
                                                                            { showPassword ? <VisibilityOff /> : <Visibility /> }
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                }}
                                                            />
                                                        }
                                                        { secondPasswordTextError 
                                                            ? <TextField
                                                                error fullWidth size="small" id="outlined-basic"
                                                                value={ secondPassword } onChange={ ({target}) => onChange( target.value, 'secondPassword') }
                                                                label={ secondPasswordTextLabel } variant="outlined" margin="dense"
                                                                type={showSecondPassword ? 'text' : 'password'}
                                                                InputProps={{
                                                                    endAdornment:
                                                                    <InputAdornment position="end">
                                                                        <IconButton
                                                                            aria-label="toggle password visibility"
                                                                            onClick={handleClickShowSecondPassword}
                                                                            edge="end"
                                                                        >
                                                                            { showSecondPassword ? <VisibilityOff /> : <Visibility /> }
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                }}
                                                            />
                                                            : <TextField
                                                                fullWidth size="small" id="outlined-basic"
                                                                value={ secondPassword } onChange={ ({target}) => onChange( target.value, 'secondPassword') }
                                                                label="Corroborar contraseña" variant="outlined" margin="dense"
                                                                type={showSecondPassword ? 'text' : 'password'}
                                                                InputProps={{
                                                                    endAdornment:
                                                                    <InputAdornment position="end">
                                                                        <IconButton
                                                                            aria-label="toggle password visibility"
                                                                            onClick={handleClickShowSecondPassword}
                                                                            edge="end"
                                                                        >
                                                                            { showSecondPassword ? <VisibilityOff /> : <Visibility /> }
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                }}
                                                            />
                                                        }
                                                         { usuarioError 
                                                            ? <Typography variant="caption" color="error">{ usuarioLabel }</Typography>
                                                            : null                                                        
                                                        }  
            
                                     
                                                    </>     
                                                                                                                                      
                                                                                                                               
                                                }
                                            }()                                                
                                        }                                                                       
                                        {/* CONTROLES */}
                                        <div>
                                        {
                                            function() { 
                                                switch (index) {
                                                    case 0: return <>
                                                    { emailSending                                            
                                                        ? <Button disabled variant="contained" onClick={ () => { handleNext(index) }} sx={{ mt: 1, mr: 1 }}>Enviando...</Button>                                        
                                                        : <Button color="warning" variant="contained" onClick={ () => { handleNext(index) }} sx={{ mt: 1, mr: 1 }}>Siguiente</Button>                                        
                                                    }
                                                </>
                                                 case 1: return <>
                                                 { smsCodeSending                                            
                                                     ? <Button disabled variant="contained" onClick={ () => { handleNext(index) }} sx={{ mt: 1, mr: 1 }}>Verificando...</Button>                                        
                                                     : <Button color="warning" variant="contained" onClick={ () => { handleNext(index) }} sx={{ mt: 1, mr: 1 }}>Siguiente</Button>                                        
                                                 }                                 
                                             </>   
                                                    case 2: return <>
                                                        { emailSending                                            
                                                            ? <Button disabled variant="contained" onClick={ () => { handleNext(index) }} sx={{ mt: 1, mr: 1 }}>Enviando...</Button>                                        
                                                            : <Button color="warning" variant="contained" onClick={ () => { handleNext(index) }} sx={{ mt: 1, mr: 1 }}>Aceptar</Button>                                        
                                                        }
                                                    </>                                      
                                                }
                                            }()
                                        }
                                        {
                                            function() { 
                                                switch (index) {
                                                    case 0: return null;                         
                                                }
                                            }()                                                                                        
                                        }                                        
                                        </div>
                                    </Box>
                                </StepContent>
                            </Step>
                        ))}
                    </Stepper>                    
                </Box>
            </div>
        </div>
    </div>
    )
}
