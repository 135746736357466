import { fetchSinToken, fetchToken } from '../../../../../helpers/fetch';

//DEFINIMOS LOS TIPOS PARA DEL REDUCER
type Publications = {
    id: string,
    tipo: string | undefined,
    estado: string,
    marca: string, 
    marcaId: string,
    modelo: string,
    anio: number,
    anioInicial: number | null,
    anioFinal: number | null,
    icono: string | undefined,
    version: string | undefined,
    descripcion: string | undefined,
    motor: string | undefined,
    transmision: string | undefined,
    fotoPublicacion: string | undefined,
    precio: string | number | undefined | null
}
type PublicationInfoType = {
    idPub: string | null,
    icon: string | null,
    marca: string | null,
    marcaId: string | null
    modelo: string | null,
    modeloId: string | null,
    anio: number | null,
    anioInicial: number | null,
    anioFinal: number | null,
    version: string | null,
    motor: string | null,
    transmision: string | null,
    descripcion: string | null,
    estado: string | null,
    fotoPublicacion: string | null,
    fotoIzquierda: string | null,
    fotoDerecha: string | null,
    fotoFrontal: string | null,
    fotoPosterior: string | null,
    fotoMotor: string | null,
    fotoInterior: string | null,
    motivoRechazo: string | null,
    tipoPublicacion: string | null,
    precio: string | null | undefined
}
export type Publication = { id: string }
export type Car = { id: string }

//DEFINIMOS LA ESTRUCTURA DEL REDUCER
export interface SalesReducerType {
    //sales: SalesSale[] | undefined,
    publications: Publications[] | undefined,
    modal: boolean,
    salemodal: Publications | undefined,
    loading: boolean,
    newpubmodal: boolean,
    carmodal: boolean,
    car: Car | undefined,
    pubmodal: boolean,
    publication: Publication | undefined,
    reloadPublications: boolean,
    publicationInfo: PublicationInfoType | undefined,
    publicationInfoOrg: PublicationInfoType | undefined,
    countPublications: number | undefined

    editarPubModal: boolean,
    activarPubModal: boolean,
    pausarPubModal: boolean,
    eliminarPubModal: boolean,

    editarPublicacion: boolean,

    mensajeAccion: string | undefined
}
//DEFINIMOS EL VALOR INICIAL DEL REDUCER
export const initialSalesState: SalesReducerType = {
    //sales: undefined,
    publications: undefined,
    modal: false,
    salemodal: undefined,
    loading: false,
    newpubmodal: false,
    carmodal: false, 
    car: undefined,
    pubmodal: false,
    publication: undefined,
    reloadPublications: false,
    publicationInfo: undefined,
    publicationInfoOrg: undefined,
    countPublications: undefined,

    editarPubModal: false,
    activarPubModal: false,
    pausarPubModal: false,
    eliminarPubModal: false,

    editarPublicacion: false,

    mensajeAccion: undefined
}
//DEFINIMOS LOS PAYLOAD DE LOS ACTIONS
type LoadSalesPayload = {     
    total: number,
    inicio: number,
    fin: number,
    pagina: number,
    //sales: SalesSale[] | undefined
}
type LoadPublicationsPayload = { publicacionesVendedor: Publications[] }

type PublicationInfoPayload = { infoPublicacion: PublicationInfoType };

type CountPublicationsPayload = { count: number | undefined };

type EditPublicationPayload = { messageEditPublication: string };
type ActivatePublicationPayload = { messageActivatePublication: string };
type DeactivatePublicationPayload = { messageDeactivatePublication: string };
type PausePublicationPayload = { messagePausePublication: string };
type DeletePublicationPayload = { messageDeletePublication: string };

//type MensajeAccionPayload = { mensaje: string }

//DEFINIMOS LOS ACTIONS DEL REDUCER
type SalesActions = { type: 'ClearSales' } |
                    { type: 'LoadSales', payload: LoadSalesPayload } |
                    { type: 'LoadPublications', payload: LoadPublicationsPayload } |
                    { type: 'LoadPublicationInfo', payload: PublicationInfoPayload } |
                    { type: 'CountPublications', payload: CountPublicationsPayload } |
                    { type: 'ShowModal'/*, payload: Publications*/ } |
                    { type: 'CloseModal' } |
                    { type: 'ShowModalNewPub' } |
                    { type: 'CloseModalNewPub' } |
                    { type: 'ShowModalCar', payload: Car } |
                    { type: 'CloseModalCar' } |
                    { type: 'ShowModalPub', payload: Publication } |
                    { type: 'CloseModalPub' } |

                    { type: 'MessageEditPublicationModal', payload: EditPublicationPayload } |
                    { type: 'MessageActivatePublicationModal', payload: ActivatePublicationPayload } |
                    { type: 'MessageDeactivatePublicationModal', payload: DeactivatePublicationPayload } |
                    { type: 'MessagePausePublicationModal', payload: PausePublicationPayload } |
                    { type: 'MessageDeletePublicationModal', payload: DeletePublicationPayload } |

                    { type: 'OpenEditarPubModal'/*, payload: MensajeAccionPayload*/ } |
                    { type: 'CloseEditarPubModal' } |
                    { type: 'OpenActivarPubModal'/*, payload: MensajeAccionPayload*/ } |
                    { type: 'CloseActivarPubModal' } |
                    { type: 'OpenPausarPubModal'/*, payload: MensajeAccionPayload*/ } |
                    { type: 'ClosePausarPubModal' } |
                    { type: 'OpenEliminarPubModal'/*, payload: MensajeAccionPayload*/ } |
                    { type: 'CloseEliminarPubModal' } |
                    { type: 'ActualizarPublicaciones' } |
                    { type: 'EditarPublicacion' } ;

//DEFINIMOS LAS ACCIONES DEL REDUCER
export const salesReducer = (state: SalesReducerType = initialSalesState, action: SalesActions) : SalesReducerType => {
    switch (action.type) {
        case 'ClearSales' : return {
            ...state,
            ...initialSalesState,
            loading: true
        }
        case 'LoadSales' : return { 
            ...state, 
            ...action.payload,
        }
        case 'LoadPublications' : return { 
            ...state, 
            publications: action.payload.publicacionesVendedor,
            loading: false,
            reloadPublications: false
        }
        case 'LoadPublicationInfo' : return {
            ...state,
            publicationInfo: action.payload.infoPublicacion,
            publicationInfoOrg :action.payload.infoPublicacion 
        }
        case 'CountPublications' : return {
            ...state,
            countPublications: action.payload.count
        }
        case 'ShowModal' : return {
            ...state,
            modal: true,
            //salemodal: action.payload
        }
        case 'CloseModal' : return {
            ...state,
            modal: false,
            publicationInfo: undefined,
            publicationInfoOrg: undefined,
            reloadPublications: true
        }
        case 'ShowModalNewPub' : return {
            ...state,
            newpubmodal: true
        }
        case 'CloseModalNewPub' : return {
            ...state,
            newpubmodal: false,
            reloadPublications: true
        }
        case 'ShowModalCar' : return {
            ...state,
            carmodal: true,
            car: action.payload
        }
        case 'CloseModalCar' : return {
            ...state,
            carmodal: false,
            car: undefined,
            editarPublicacion: false
        }
        case 'ShowModalPub' : return {
            ...state,
            pubmodal: true,
            publication: action.payload
        }
        case 'CloseModalPub' : return {
            ...state,
            pubmodal: false,
            publication: undefined,
            editarPublicacion: false
        }

        case 'MessageEditPublicationModal' : return {
            ...state,
            mensajeAccion: action.payload.messageEditPublication,
        }
        case 'MessageActivatePublicationModal' : return {
            ...state,
            mensajeAccion: action.payload.messageActivatePublication,
        }
        case 'MessageDeactivatePublicationModal' : return {
            ...state,
            mensajeAccion: action.payload.messageDeactivatePublication,
        }
        case 'MessagePausePublicationModal' : return {
            ...state,
            mensajeAccion: action.payload.messagePausePublication,
        }
        case 'MessageDeletePublicationModal' : return {
            ...state,
            mensajeAccion: action.payload.messageDeletePublication,
        }

        case 'OpenEditarPubModal' : return {
            ...state,
            editarPubModal: true,
        }
        case 'CloseEditarPubModal' : return {
            ...state,
            editarPubModal: false,
            mensajeAccion: undefined
        }
        case 'OpenActivarPubModal' : return {
            ...state,
            activarPubModal: true,
        }
        case 'CloseActivarPubModal' : return {
            ...state,
            activarPubModal: false,
            mensajeAccion: undefined,
            //reloadPublications: true
        }
        case 'OpenPausarPubModal' : return {
            ...state,
            pausarPubModal: true,
        }
        case 'ClosePausarPubModal' : return {
            ...state,
            pausarPubModal: false,
            mensajeAccion: undefined,
            //reloadPublications: true
        }
        case 'OpenEliminarPubModal' : return {
            ...state,
            eliminarPubModal: true,
        }
        case 'CloseEliminarPubModal' : return {
            ...state,
            eliminarPubModal: false,
            mensajeAccion: undefined
        }
        case 'ActualizarPublicaciones' : return {
            ...state,
            reloadPublications: true
        }

        case 'EditarPublicacion' : return {
            ...state,
            editarPublicacion: true
        }
        
        default: return state;
    }
}

//CREAMOS LAS ACCIONES DEL REDUCER 

//CARGAR DATOS DE LA COMPRA
type LoadSalesParameter = { id: number | null, dispatchSales: React.Dispatch<SalesActions> };
export const startLoadSales = async (loadShoppingData: LoadSalesParameter) => {

    const {dispatchSales} = loadShoppingData;
    
    dispatchSales (clearSales());

    let body = await fetchToken(`publicaciones`,{},'GET');

    console.log(body);

    if (body.ok){
        //Cambiando formato a respuesta del servidor
        let publications: Publications[] = [];
        for (let publication of body.res.publicaciones){
            publications.push({
                id: publication._id,
                tipo: publication.tipo,
                estado: publication.estado,
                marca: publication.marcaId.marca,
                marcaId: publication.marcaId._id,
                modelo: publication.modeloId.modelo,
                anio: publication.anio,
                anioInicial: publication.anioInicial,
                anioFinal: publication.anioFinal,
                icono: publication.marcaId.icono,
                version: publication.version,
                descripcion: publication.descripcion,
                motor: publication.motor,
                transmision: publication.transmision,
                fotoPublicacion: publication.fotoPublicacion,
                precio: publication.precio
            });
        }
        dispatchSales(loadPublications({ publicacionesVendedor: publications }));
        dispatchSales(countPublications({ count: body.res.publicaciones.length }));
    }

}
const clearSales = (): SalesActions  => {
    return {
        type: 'ClearSales'
    }    
}

// const loadSales = (salesData: LoadSalesPayload): SalesActions  => {
//     return {
//         type: 'LoadSales',
//         payload: salesData
//     }
// }

const loadPublications = (publicationsData: LoadPublicationsPayload): SalesActions  => {
    return {
        type: 'LoadPublications',
        payload: publicationsData
    }    
}

const countPublications = (countPublicationsData: CountPublicationsPayload): SalesActions  => {
    return {
        type: 'CountPublications',
        payload: countPublicationsData
    }    
}

//MOSTAR Y OCULTAR MODAL
export const showModalSales = (/*showModalData: Publications*/): SalesActions => {
    return {
        type: 'ShowModal',
        //payload: showModalData
    }
}
export const closeModalSales = (): SalesActions => {
    return {
        type: 'CloseModal'
    }
} 
export const showModalNewPub = (): SalesActions => {
    return {
        type: 'ShowModalNewPub'
    }
}
export const closeModalNewPub = (): SalesActions => {
    return {
        type: 'CloseModalNewPub'
    }
} 
export const showModalCar = (showModalCarData: Car): SalesActions => {
    return {
        type: 'ShowModalCar',
        payload: showModalCarData
    }
}
export const closeModalCar = (): SalesActions => {
    return {
        type: 'CloseModalCar'
    }
} 
export const showModalPub = (showModalPubData: Publication): SalesActions => {
    return {
        type: 'ShowModalPub',
        payload: showModalPubData
    }
}
export const closeModalPub = (): SalesActions => {
    return {
        type: 'CloseModalPub'
    }
}

//CREAMOS LAS ACCIONES DEL REDUCER
type startUpdatePublicationInfoParameter = { /*stateLocalPublication: PublicationDataReducerType,*/ dispatchLocalSales: React.Dispatch<SalesActions>
                                             publicacionInfoId: string };
export const startUpdatePublicationInfo = async (startUpdatePublicationInfo: startUpdatePublicationInfoParameter) => {

    const { dispatchLocalSales, publicacionInfoId } = startUpdatePublicationInfo;

    const body = await fetchSinToken(`publicaciones/${ publicacionInfoId }`, {}, 'GET');

    console.log(body);

    if (body.ok){
        dispatchLocalSales( loadPublicationInfo({
            infoPublicacion: {
                idPub: body.res._id,
                icon: body.res.marcaId.icono,
                marca: body.res.marcaId.marca,
                marcaId: body.res.marcaId._id,
                modelo: body.res.modeloId.modelo,
                modeloId: body.res.modeloId._id,
                anio: body.res.anio,
                anioInicial: body.res.anioInicial,
                anioFinal: body.res.anioFinal,
                version: body.res.version,
                motor: body.res.motor,
                transmision: body.res.transmision,
                descripcion: body.res.descripcion,
                estado: body.res.estado,
                fotoPublicacion: body.res.fotoPublicacion,
                fotoIzquierda: body.res.fotoIzquierda,
                fotoDerecha: body.res.fotoDerecha,
                fotoFrontal: body.res.fotoFrontal,
                fotoPosterior: body.res.fotoPosterior,
                fotoMotor: body.res.fotoMotorSuperior,
                fotoInterior: body.res.fotoInteriorInferior,
                motivoRechazo: body.res.motivoRechazo,
                tipoPublicacion: body.res.tipo,
                precio: body.res.precio
            }
        }));
    } else {
        //console.log('Respuesta falsa');
    }
}
/*const preparePublicationData = (): SalesActions => {
    return {
        type: 'PreparePublicationData',
    }
}*/
const loadPublicationInfo = ( publicationInfoData: PublicationInfoPayload ): SalesActions => {
    return {
        type: 'LoadPublicationInfo',
        payload: publicationInfoData
    }
}

type startEditPublicationParameter = { /*stateLocalPublication: PublicationDataReducerType,*/ dispatchLocalSales: React.Dispatch<SalesActions>
                                       parametro: string };
export const startEditPublication = async (startEditPublication: startEditPublicationParameter) => {

    const { dispatchLocalSales, parametro } = startEditPublication;

    const body = await fetchToken('test/pruebaTokenError', {parametro}, 'POST');

    //console.log(body);

    if (body.ok) {
        dispatchLocalSales( closeEditarPubModal());
    } else {
        dispatchLocalSales( messageEditPublicationModal({
            messageEditPublication: body.msg
        }));
    }
}
const messageEditPublicationModal = ( messageEditPublicationData: EditPublicationPayload ) : SalesActions => {
    return {
        type: 'MessageEditPublicationModal',
        payload: messageEditPublicationData
    }
}

type startActivatePublicationParameter = { /*stateLocalPublication: PublicationDataReducerType,*/ dispatchLocalSales: React.Dispatch<SalesActions>
                                           parametro: string | undefined | null };
export const startActivatePublication = async (startActivatePublication: startActivatePublicationParameter) => {

    const { dispatchLocalSales, parametro } = startActivatePublication;

    const body = await fetchToken('publicaciones/activar', { 
        publicacionId: parametro,
    }, 'PUT');

    if (body.ok) {
        dispatchLocalSales( closeActivarPubModal());
        dispatchLocalSales( closeModalSales() );
        dispatchLocalSales( actualizarPublicaciones() );
    } else {
        dispatchLocalSales( messageActivatePublicationModal({
            messageActivatePublication: body.msg
        }));
    }
}
const messageActivatePublicationModal = ( messageActivatePublicationData: ActivatePublicationPayload ) : SalesActions => {
    return {
        type: 'MessageActivatePublicationModal',
        payload: messageActivatePublicationData
    }
}

type startPausePublicationParameter = { /*stateLocalPublication: PublicationDataReducerType,*/ dispatchLocalSales: React.Dispatch<SalesActions>
                                             parametro: string | undefined | null };
export const startPausePublication = async (startPausePublication: startPausePublicationParameter) => {

    const { dispatchLocalSales, parametro } = startPausePublication;

    const body = await fetchToken(`publicaciones/pausar`, { 
        publicacionId: parametro,
    }, 'PUT');

    //console.log(body);

    if (body.ok) {
        dispatchLocalSales( closePausarPubModal() );
        dispatchLocalSales( closeModalSales() );
        dispatchLocalSales( actualizarPublicaciones() );
    } else {
        dispatchLocalSales( messagePausePublicationModal({
            messagePausePublication: body.msg
        }));
    }
}
const messagePausePublicationModal = ( messagePausePublicationData: PausePublicationPayload ) : SalesActions => {
    return {
        type: 'MessagePausePublicationModal',
        payload: messagePausePublicationData
    }
}

type startDeletePublicationParameter = { /*stateLocalPublication: PublicationDataReducerType,*/ dispatchLocalSales: React.Dispatch<SalesActions>
                                         parametro: string | undefined | null };
export const startDeletePublication = async (startDeletePublication: startDeletePublicationParameter) => {

    const { dispatchLocalSales, parametro } = startDeletePublication;

    const body = await fetchToken(`publicaciones`, { 
        publicacionId: parametro,
    }, 'DELETE');

    //console.log(body);

    if (body.ok) {
        dispatchLocalSales( closeEliminarPubModal() );
        dispatchLocalSales( closeModalSales() );
        dispatchLocalSales( actualizarPublicaciones() );
    } else {
        dispatchLocalSales( messageDeletePublicationModal({
            messageDeletePublication: body.msg
        }));
    }
}
const messageDeletePublicationModal = ( messageDeletePublicationData: DeletePublicationPayload ) : SalesActions => {
    return {
        type: 'MessageDeletePublicationModal',
        payload: messageDeletePublicationData
    }
}

const actualizarPublicaciones = (): SalesActions  => {
    return {
        type: 'ActualizarPublicaciones'
    }    
}

export const editarPublicacionBandera = (): SalesActions => {
    return {
        type: 'EditarPublicacion',
    }
}

export const openEditarPubModal = (): SalesActions => {
    return {
        type: 'OpenEditarPubModal'
    }
}
export const closeEditarPubModal = (): SalesActions => {
    return {
        type: 'CloseEditarPubModal'
    }
}
export const openActivarPubModal = (): SalesActions => {
    return {
        type: 'OpenActivarPubModal'
    }
}
export const closeActivarPubModal = (): SalesActions => {
    return {
        type: 'CloseActivarPubModal'
    }
}
export const openPausarPubModal = (): SalesActions => {
    return {
        type: 'OpenPausarPubModal'
    }
}
export const closePausarPubModal = (): SalesActions => {
    return {
        type: 'ClosePausarPubModal'
    }
}
export const openEliminarPubModal = (): SalesActions => {
    return {
        type: 'OpenEliminarPubModal'
    }
}
export const closeEliminarPubModal = (): SalesActions => {
    return {
        type: 'CloseEliminarPubModal'
    }
}