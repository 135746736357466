import { createContext, useReducer } from 'react';
import { RegisterState, initialRegisterState, registerReducer } from '../reducers/RegisterReducer';
import { Register } from '../Register';

//definimos las variables globales del state
type registerContextType = {
    reducer: RegisterState,
    dispatch: React.Dispatch<any>,
}

const ContextDefaultValues: registerContextType = {
    reducer: initialRegisterState,
    dispatch: () => null
};

//creamos el Context
export const RegisterContext = createContext<registerContextType>(ContextDefaultValues);

export const ContextRegister = () => {

    //CARGAR EL REDUCER LOCAL
    const [reducer, dispatch] = useReducer(registerReducer, initialRegisterState)

    return (
        <RegisterContext.Provider value={{ reducer , dispatch }}> 
            <Register />
        </RegisterContext.Provider>
    )
}
