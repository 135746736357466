import { Box } from "@mui/material"
import "./styles/tarjetas.scss"
//import { Button, Dialog, DialogActions, DialogContent, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
//import { useContext, useState, /*useState*/ } from 'react';
//import { AccountContext } from '../../context/AccountContext';
//import { useSelector } from "react-redux";
//import { RootState } from "../../../../../../store/store";
//import { EditarTarjeta } from "./modal/EditarTarjeta";
//import { AccountCards, openEditCard } from "../../reducers/AccountReducer";

//import SVG from 'react-inlinesvg';

export const Tarjetas = () => {

    //LEER EL REDUCER LOCAL
    //const { accountReducerState, dispatch } = useContext(AccountContext); 
    //const { cards } = accountReducerState; 
    
    //const storeAuth = (state: RootState ) => state.auth;
    //const { name, lastName } = useSelector(storeAuth);

    /*const editTarjeta = (tarjetaEdicion: AccountCards) => {
      dispatch (openEditCard(tarjetaEdicion));
      console.log('modal editar tarjeta ABIERTO')
    }*/

    //const [eliminarModal, setEliminarModal] = useState(false);
    //const handleClickOpenEliminarModal = () => { setEliminarModal(true); };
    //const handleClickCloseEliminarModal = () => { setEliminarModal(false); };

    return (
      <>
        {/*<EditarTarjeta/>
        <Dialog open={eliminarModal} onClose={handleClickCloseEliminarModal}>
          <div className="dir_modal_title">
            <h2>¿Estas seguro que queieres eliminar esta tarjeta de pago?</h2>
          </div>
          <DialogContent>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClickCloseEliminarModal} variant="contained" color="warning">Eliminar</Button>
            <Button onClick={handleClickCloseEliminarModal}>Cancelar</Button>
          </DialogActions>
        </Dialog>

        <div className="tarjetas_main">
          <div className="tarjetas_titulo">
            Tus tarjetas
          </div>
          <div className="tarjetas_section">
            { 
              cards ? (
                cards.map((card)=> (
                  <div className="tarjetas_format" key={card.id}>
                    <div className="tarjetas_tarjeta">
                      <div className="tarjetas_tarjeta_container">
                        <div className="tarjetas_tarjeta_imagen">
                          { card.tipo === 'MASTERCARD'
                            ? (
                              <img
                                src="./assets/mastercard.png"
                                alt="mastercard"
                              />
                            ) : (
                              <img
                                src="./assets/visa.png"
                                alt="visa"
                              />
                          )}
                        </div>
                        <div className="tarjetas_tarjeta_datos">
                          <p className="tarjetas_tarjeta_datos_numero">
                            <b>{ card.numero }</b>
                          </p>
                          <p className="tarjetas_tarjeta_datos_nombre">
                            { name } { lastName }
                          </p>
                          <div className="tarjetas_tarjeta_datos_fechaCVV">
                            <div className="tarjetas_tarjeta_datos_fecha">
                              <p>
                                Vencimiento <b>{ card.fecha }</b>
                              </p>
                            </div>
                            <div className="tarjetas_tarjeta_datos_CVV">
                              <p>
                                CVV <b>  * * *</b>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tarjetas_opcion1">
                      <Button variant="contained" onClick={() => editTarjeta( card )} sx={{ textTransform: 'none' }}>
                        Editar
                      </Button>
                    </div>
                    <div className="tarjetas_opcion2">
                      <Button color="warning" onClick={handleClickOpenEliminarModal} sx={{ textTransform: 'none' }}>
                        Eliminar
                      </Button>
                    </div>
                  </div>
                ))
              ) : (
                <div className="dir_format">
                  <p>Aún no has registrado una tarjeta. Deseas agregar una?</p>
                  <Button variant="contained" color="warning">Agregar</Button>                
                </div>  
              )
            }
          </div>
          <div className="tarjetas_titulo">
            <Button variant="contained" color="warning" sx={{ mt: 2 }}>Agregar nueva tarjeta</Button> 
          </div>        
        </div>*/}

        <div className="ventas_publications_container">
          <div className="ventas_proximo_container">
            <svg x="0px" y="0px" viewBox="0 0 500 400" className="ventas_icon_soon">
              <g>
                <path d="M182.3,324.7c0.8,4.6,5.2,7.7,9.8,6.9l67.3-11.9c4.6-0.8,7.7-5.2,6.9-9.8l-3-16.8c-0.8-4.6-5.2-7.7-9.8-6.9l-67.3,11.9
                  c-4.6,0.8-7.7,5.2-6.9,9.8L182.3,324.7z"/>
                <path d="M115.4,337c0.8,4.3,4.9,7.2,9.2,6.5l34.9-6.1c4.3-0.8,7.2-4.9,6.5-9.2l-3.1-17.8c-0.8-4.3-4.9-7.2-9.2-6.5l-34.9,6.1
                  c-4.3,0.8-7.2,4.9-6.5,9.2L115.4,337z"/>
                <path d="M443.4,66.9l-247-66.2c-13.3-3.6-27,4.3-30.6,17.7l-31.3,117l28.2-5l4-14.8l31.7,8.5l113.3-20c21-3.7,40.9,10.3,44.6,31.3
                  l5.7,32.6l50.6,13.6l-23.2,86.7c-0.5,1.9-2.5,3-4.4,2.5l-5.2-1.4l4.9,27.8c12.4,1.8,24.4-5.9,27.7-18.3l48.6-181.4
                  C464.6,84.1,456.7,70.4,443.4,66.9z M435.5,96l-5.3,19.9L184,50l5.3-19.9c0.7-2.6,3.4-4.1,6-3.4l236.8,63.5
                  C434.7,90.8,436.2,93.4,435.5,96z"/>
                <path d="M100,399.8l251.9-44.4c13.6-2.4,22.7-15.4,20.3-28.9l-32.6-184.9c-2.4-13.6-15.4-22.7-28.9-20.3L58.7,165.7
                  c-13.6,2.4-22.7,15.4-20.3,28.9l32.6,184.9C73.5,393.1,86.4,402.2,100,399.8z M346.7,326.5c0.3,1.9-1,3.8-2.9,4.1l-243.8,43
                  c-1.9,0.3-3.8-1-4.1-2.9l-15.6-88.4l250.8-44.2L346.7,326.5z M64.8,195.3c-0.5-2.6,1.3-5.2,3.9-5.6l241.5-42.6
                  c2.6-0.5,5.2,1.3,5.6,3.9l3.6,20.3l-251,44.3L64.8,195.3z"/>
              </g>
            </svg>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <div> Mis Tarjetas <br/> <p>Proximamente</p> </div>
            </Box>
          </div>
        </div>

      </>
    )
}