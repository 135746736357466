
import { fetchToken } from "../../../helpers/fetch"
import { AppThunk } from "../../../store/store"

//DEFINIMOS LA ESTRUCTURA DEL REDUCER
export interface ContactReducerType {
    nombreApellido: string,
    nombreApellidoError: string | null,

    email: string,
    emailError: string | null,

    telefono: string,
    telefonoError: string | null,
    
    mensaje: string | null,
    mensajeError: string | null,

    receiveMessage: boolean, //indica si ya se actualizaron los datos del mensaje
    receiveMessageSending: boolean, //indica si se esta haciendo fetch para la actualizacion de los datos del mensaje
    receiveMessageError: string | null, //indica si el fetch respondio con error
    receiveMessageCorrect: string | null, //indica si el fetch respondio con error

    envioFinalizado: boolean
};
//DEFINIMOS EL VALOR INICIAL DEL REDUCER
export const initialContactState: ContactReducerType = {

    nombreApellido: '',
    nombreApellidoError: null,

    email: '',
    emailError: null,

    telefono: '',
    telefonoError: null,

    mensaje: '',
    mensajeError: null,

    receiveMessage: false,
    receiveMessageSending: false,
    receiveMessageError: null,
    receiveMessageCorrect: null,

    envioFinalizado: false
}

//DEFINIMOS LOS PAYLOAD DE LOS ACTIONS
type OnChangePayload = {
    field: keyof ContactReducerType,
    value: any
}
type OnErrorPayload = {
    errorField: keyof ContactReducerType,
    label: string
}
type OnCleanErrorPayload = {
    errorField: keyof ContactReducerType
}

type UpdateFailedPayload = {
    label: string
};

type UpdateCorrectPayload = {
    label: string
};

//DEFINIMOS LOS ACTIONS DEL REDUCER
type ContactActions = 
                        { type: 'OnChange', payload: OnChangePayload } |
                        { type: 'OnError', payload: OnErrorPayload } |
                        { type: 'OnCleanError', payload: OnCleanErrorPayload } |

                        { type: 'PrepareFetchReceiveMessage' } |
                        { type: 'UpdateReceiveMessage' } |
                        { type: 'FetchReceiveMessageFailed', payload: UpdateFailedPayload } |
                        { type: 'FetchReceiveMessageCorrect', payload: UpdateCorrectPayload } |

                        { type: 'CleanReceiveMessage' };

//DEFINIMOS LAS ACCIONES DEL REDUCER
export const contactReducer = (state: ContactReducerType = initialContactState, action: ContactActions): ContactReducerType => {
    switch (action.type) {
        case 'OnChange' : return {
            ...state,
            [action.payload.field]: action.payload.value
        }
        case 'OnError' : return {
            ...state,
            [action.payload.errorField]: action.payload.label
        }
        case 'OnCleanError' : return {
            ...state,
            [action.payload.errorField]: null
        }

        case 'PrepareFetchReceiveMessage' : return {
            ...state,
            receiveMessage: false,
            receiveMessageError: null,
            receiveMessageSending: true
        }
        case 'UpdateReceiveMessage' : return {
            ...state,
            receiveMessage: true,
            receiveMessageSending: false
        }
        case 'FetchReceiveMessageFailed' : return {
            ...state,
            receiveMessageError: action.payload.label,
            receiveMessageSending: false
        }
        case 'FetchReceiveMessageCorrect' : return {
            ...state,
            receiveMessageCorrect: action.payload.label,
            envioFinalizado: true
        }

        case 'CleanReceiveMessage' : return {
            ...state,
            nombreApellido: '',
            nombreApellidoError: null,

            email: '',
            emailError: null,

            telefono: '',
            telefonoError: null,

            mensaje: '',
            mensajeError: null,
        }

        default: return state;
    }
}

//METODO ON CHANGE GENERAL
export const onChange = (onChangeData: OnChangePayload): ContactActions => {
    return {
        type: 'OnChange',
        payload: onChangeData
    }
}
//METODO SET ERROR GENERAL
export const setError = (onErrorData: OnErrorPayload): ContactActions => {
    return {
        type: 'OnError',
        payload: onErrorData
    }
}
//METODO CLEAN ERROR GENERAL
export const cleanError = (onCleanErrorData: OnCleanErrorPayload): ContactActions => {
    return {
        type: 'OnCleanError',
        payload: onCleanErrorData
    }
}

//ACTUALIZANDO EL ESTADO DE VENDEDOR
export const startUpdateReceiveEmail = (): AppThunk => {
    return async (dispatch, getState) => {

        dispatch(prepareFetchReceiveMessage());

        const body = await fetchToken(`auth/receiveEmail`, {
            email: getState().contact.email,
            nombre: getState().contact.nombreApellido,
            telefono: getState().contact.telefono,
            mensaje: getState().contact.mensaje

        }, 'POST');

        //console.log(body);

        if (body.ok){
            dispatch(updateReceiveMessage());
            dispatch(cleanReceiveMessage());
            dispatch(fetchReceiveMessageCorrect({ label: body.msg }));
        } else {
            dispatch(fetchReceiveMessageFailed({ label: body.msg }));
        }
    }
}
const prepareFetchReceiveMessage = (): ContactActions => {
    return {
        type: 'PrepareFetchReceiveMessage'
    }
}
const updateReceiveMessage = (): ContactActions  => {
    return {
        type: 'UpdateReceiveMessage'
    }    
}
const fetchReceiveMessageFailed = (updateReceiveMessageFailedData: UpdateFailedPayload): ContactActions => {
    return {
        type: 'FetchReceiveMessageFailed',
        payload: updateReceiveMessageFailedData
    }
}
const fetchReceiveMessageCorrect = (updateReceiveMessageCorrectData: UpdateCorrectPayload): ContactActions => {
    return {
        type: 'FetchReceiveMessageCorrect',
        payload: updateReceiveMessageCorrectData
    }
}
const cleanReceiveMessage = (): ContactActions  => {
    return {
        type: 'CleanReceiveMessage'
    }    
}