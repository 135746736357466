
import { Box, CircularProgress, Dialog, IconButton, Typography } from "@mui/material"
import { useContext, useEffect, useState } from "react";
//import Slider from "react-slick";
import { PublicationDataContext } from "../context/ContextPublicationData";
import { closeImagesZoomCarousel } from "../reducers/PublicationDataReducer";

import CloseIcon from '@mui/icons-material/Close';


import ImageCarousel, { ImageType } from "./ImageCarousel";

export const ImageZoomCarouselModal = () => {

  /*const settings = {
    dots: false,
    dotsClass: "slick-dots custom-indicator",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true
  };*/

  const { publicationDataReducerState, dispatchPublicationData } = useContext(PublicationDataContext);
  const { datosPublicacion, modalImagesZoomCarousel } = publicationDataReducerState;

  //EVALUANDO LA CANTIDAD DE FOTOS DE LA PUBLICACION
  /*const [imagenes, setImagenes] = useState<string[]>([]);
  useEffect(() => {
    let fotos: string[] = [];
    if (datosPublicacion) {
      datosPublicacion.fotoPublicacion && fotos.push(datosPublicacion.fotoPublicacion);
      datosPublicacion.fotoIzquierda && fotos.push(datosPublicacion.fotoIzquierda);
      datosPublicacion.fotoDerecha && fotos.push(datosPublicacion.fotoDerecha);
      datosPublicacion.fotoFrontal && fotos.push(datosPublicacion.fotoFrontal);
      datosPublicacion.fotoPosterior && fotos.push(datosPublicacion.fotoPosterior);
      datosPublicacion.fotoMotor && fotos.push(datosPublicacion.fotoMotor);
      datosPublicacion.fotoInterior && fotos.push(datosPublicacion.fotoInterior);
      setImagenes(fotos);
    }
  }, [datosPublicacion]);
  */

  //console.log(indexCarousel);

  const [images, setImages] = useState<ImageType[]>();
    useEffect(() => {
      //let fotos: ImageType[] = [];
      if (datosPublicacion) {
        let fotoss: string[] = [];
        datosPublicacion.fotoPublicacion && fotoss.push(datosPublicacion.fotoPublicacion);
        datosPublicacion.fotoIzquierda && fotoss.push(datosPublicacion.fotoIzquierda);
        datosPublicacion.fotoDerecha && fotoss.push(datosPublicacion.fotoDerecha);
        datosPublicacion.fotoFrontal && fotoss.push(datosPublicacion.fotoFrontal);
        datosPublicacion.fotoPosterior && fotoss.push(datosPublicacion.fotoPosterior);
        datosPublicacion.fotoMotor && fotoss.push(datosPublicacion.fotoMotor);
        datosPublicacion.fotoInterior && fotoss.push(datosPublicacion.fotoInterior);
        setImages(
          Array.from(fotoss).map((currElement, index) => ({
            id:index ,
            url: `${process.env.REACT_APP_PHOTOSERVER}${currElement}`
          }))
        );
      }
  }, [datosPublicacion]);


  /*const SlickArrowLeft = ({ currentSlide, slideCount, ...props }:any) => (
    <SVG
      src={process.env.REACT_APP_ICONSERVER+'dz-angle-left'}
      className={ "slick-prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")}
      aria-disabled={currentSlide === 0 ? true : false}
      {...props}
    />
  );

  const SlickArrowRight = ({ currentSlide, slideCount, ...props }: any) => (
    <SVG
      src={process.env.REACT_APP_ICONSERVER+'dz-angle-right'}
      className={ "slick-next slick-arrow" + (currentSlide === slideCount - 1 ? "slick-disabled" : "" )} 
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      {...props}
    />
  );*/

  return (
    <Dialog
      open={modalImagesZoomCarousel}
      //onClose={()=>{dispatchPublicationData(closeImagesZoomCarousel())}}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            minHeight: '95vh',
            maxHeight: '95vh',
            maxWidth: '90vw',
            minWidth: '90vw',
            background: 'transparent',
            overflowY: 'unset'
          },
        },
      }}
    >
      <div>
        {
          images?.length === 0 ?
          <Box sx={{ height: "500px", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <CircularProgress size={30} sx={{ mr: 1 }} /><Typography variant="h5">Cargando</Typography>
          </Box>
          :
          /*<Slider
            {...settings}
            prevArrow={ <SlickArrowLeft /> }
            nextArrow={ <SlickArrowRight /> }
          >
            {
              imagenes.map((imagen, i) => (
                <div key={i}>
                  <Card variant="outlined" sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Box>
                      <QuickPinchZoom onUpdate={onUpdate}>
                        <CardMedia
                          component="img"
                          image={process.env.REACT_APP_PHOTOSERVER+imagen}
                          alt=""
                          sx={{ maxHeight: '95vh' }}
                        />
                      </QuickPinchZoom>
                    </Box>
                  </Card>
                </div>
              ))
            }
          </Slider>*/ 
          <ImageCarousel images={images} />
        }
      </div>
      <IconButton
        aria-label="close"
        onClick={()=>{dispatchPublicationData(closeImagesZoomCarousel())}}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          background: "rgba(255, 255, 255, 1)",
          "&:hover": { background: "rgba(255, 255, 255, 1)" }
        }}
      >
        <CloseIcon sx={{ height: "35px", width: "35px", fill: "black" }} />
      </IconButton>

    </Dialog>
  )
}