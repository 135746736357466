import "./styles/questions.scss"
import { useState, useEffect, useContext } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiContainer from '@mui/material/Container';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useMediaQuery } from '@mui/material';

import SVG from 'react-inlinesvg';

import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';
import { QuestionsContext, MenuContext } from './context/QuestionsContext';
//import { startLoadQuestions } from './reducers/QuestionsReducer';
import { startLoadAnswers, startLoadQuestions } from "./reducers/QuestionsReducer";
import { QuestionPublicationModal } from "./QuestionPublicationModal";
import { withStyles } from "@material-ui/core";


const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0.5, 1.5),
}));

const Container = styled(MuiContainer)(
    ({ theme }) => ({
        width: 200,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        overflowX: 'hidden',
    }),
); 

const StyledListItem = withStyles({
  root: {
    "&$selected": {
      backgroundColor: "#ff6700",
      color: "white",
      "& .MuiListItemIcon-root": {
        color: "white",
        fill: "white"
      }
    },
    "&$selected:hover": {
      backgroundColor: "#ff6700",
      color: "white",
      "& .MuiListItemIcon-root": {
        color: "white"
      }
    },
    "&:hover": {
      backgroundColor: "#ff66003f",
      "& .MuiListItemIcon-root": {
        color: "white"
      }
    }
  },
  selected: {}
})(ListItemButton);

const menu = ['Mis preguntas', 'Mis respuestas']; 

export const MyQuestions = () => {

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [])
    
    //HOOKS DE CONTROL DE MENU
    const [open, setOpen] = useState(true);
    const handleDrawerClose = () => {
      open ? setOpen(false) : setOpen(true);
    };

    //MENU RESPONSIVE
    const responsive = useMediaQuery('(max-width:900px)');
    const responsive2 = useMediaQuery('(max-width:300px)');
    useEffect(() => {
        responsive ? setOpen(false) : setOpen(true);
    }, [responsive])

    //CARGAR LOS DATOS DEL USUARIO
    //LEER INFORMACION DE REDUX
    const storeAuth = (state: RootState ) => state.auth;
    const { id } = useSelector(storeAuth);
    //LEER EL REDUCER LOCAL
    const { dispatchQuestion, questionsReducerState } = useContext(QuestionsContext); 
    const { reloadQuestions, reloadAnswers } = questionsReducerState;
    

    //cargar los datos del usuario a travez del reducer
    /*useEffect(() => {
        startLoadQuestions({id, dispatchQuestion});
    }, [id, dispatchQuestion])*/

    useEffect(() => {
      if (reloadQuestions){
        dispatchQuestion(startLoadQuestions({ dispatchLocalQuestions: dispatchQuestion, idUser: id }));
      }
    }, [id, reloadQuestions, dispatchQuestion]);

    useEffect(() => {
      if (reloadAnswers){
        dispatchQuestion(startLoadAnswers({ dispatchLocalQuestions: dispatchQuestion, idUser: id }));
      }
    }, [id, reloadAnswers, dispatchQuestion]);



    //LEER EL CONTEXT PARA CAMBIAR EL COMPONENTE DEL MENU
    const { componentJSX, setIndexComponent, indexComponent } = useContext( MenuContext );
    const handleClick = (index: number) => {
        setIndexComponent(index);
    }

    //console.log(indexComponent);

    return (
      <>
        <QuestionPublicationModal />
          <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Container disableGutters maxWidth={false}
            sx={{ width: open ? 250 : responsive2 ? 50 : 60 }}
            className='myaccount_menu_border'
          >
            <DrawerHeader>
              <IconButton onClick={handleDrawerClose}>
                {open ? <ChevronLeftIcon/> : <ChevronRightIcon />}
              </IconButton>
            </DrawerHeader>
            <Divider />
            <List>
              {menu.map((text, index) => (
                <ListItem key={text} disablePadding sx={{ display: 'block' }}>
                  <StyledListItem
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                    onClick = {()=> { handleClick(index) } }
                    selected={indexComponent === index}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      {
                        function() {
                          switch (index) {
                            case 0 : return <Box sx={{ width: "26px", height: "26px", display: "flex", alignItems: "center" }}>
                                              <SVG src={process.env.REACT_APP_ICONSERVER+'dz-question'} width={26}/>
                                            </Box>
                            case 1 : return <Box sx={{ width: "26px", height: "26px", display: "flex", alignItems: "center" }}>
                                              <SVG src={process.env.REACT_APP_ICONSERVER+'dz-edit'} width={25}/>
                                            </Box>
                          }
                        } ()
                      }
                    </ListItemIcon>
                    <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                  </StyledListItem>
                </ListItem>
              ))}
            </List>
            <Divider />
            </Container>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }} className="max_height_sidebar_menu">
              <DrawerHeader />
              { componentJSX } 
            </Box>
          </Box>
      </>        
    );
}