
import { Alert, Box, Button, Dialog, IconButton, Typography } from "@mui/material"
import { useContext } from "react";
import { SalesContext } from "../context/SalesContext";
import { closePausarPubModal, startPausePublication } from "../reducers/SalesReducer";

import SVG from 'react-inlinesvg';

export const PausarPublicaModal = () => {

  //LEER EL REDUCER LOCAL
  const { salesReducerState, dispatchSales } = useContext(SalesContext); 
  const { pausarPubModal, mensajeAccion, publicationInfo } = salesReducerState;

  return (
    <Dialog
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={pausarPubModal}
      onClose={()=>{dispatchSales(closePausarPubModal())}}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            maxWidth: "1200px",
          },
        },
      }}
    >
      <Box sx={{ backgroundColor: "black", color: "white", p: 3, display: "flex" }}>
        <Typography variant='h5'>Pausar Publicacion</Typography>
        <IconButton
          aria-label="close"
          onClick={()=>{dispatchSales(closePausarPubModal())}}
          sx={{
            position: 'absolute',
            right: 18
          }}
        >
          <SVG src={process.env.REACT_APP_ICONSERVER+'dz-error'} width={20} fill="white" />
        </IconButton>
      </Box>
      <Box sx={{ p: "25px 20px 10px 20px" }}>
        <Typography sx={{ fontSize: "18px" }}>¿Quieres pausar esta publicación?</Typography>
      </Box>
      {
        mensajeAccion && <Alert severity="error"> {mensajeAccion} </Alert>
      }
      <Box sx={{ display: "flex", justifyContent: "center", p: 2 }} >
        <Box>
          <Button
            color="warning" sx={{ marginRight: 1 }} size="large"
            onClick={()=>{dispatchSales(closePausarPubModal())}}
          >
            Cancelar
          </Button>
          <Button
            variant="contained" size="large" sx={{ marginLeft: 1 }}
            onClick={()=>{dispatchSales(startPausePublication({dispatchLocalSales: dispatchSales, parametro: publicationInfo?.idPub}))}}
          >
            Si
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}