import { AppThunk } from '../store/store';
import { fetchToken } from '../helpers/fetch';
import { showMessage } from './modalReducer';

export interface SubItemType {
    key: number,
    id: string, 
    title: string 
}
export interface ItemType {
    key: number;
    id: string;
    icon: string;
    title: string;
    arrow: boolean;
    subitem: SubItemType[]
}

//definimos la estructura del reducer
interface SidebarState {
    sidebarWidth: number,
    sidebarInitialWidth: number,
    activeComponent: string,
    sidebarItems: ItemType[],
    itemSelected: string
};
//definimos el valor inicial
const initialState: SidebarState = {
    sidebarInitialWidth: 200,
    sidebarWidth: 200,
    activeComponent: 'Home',
    sidebarItems: [{ key: 1, id: 'Home', icon: 'dz-home',title: 'Inicio', arrow: false, subitem: [] }],
    itemSelected: ''
}
//definimos los types del reducer
type SidebarPayload = { width: number  };
type ComponentPayload = { component: string }
type ItemsPayload = { items: ItemType[] }
type ItemPayload = { id: string };
type ArrowPayload = { id: string };
type SetArrowPayload = { id: string, state: boolean };
type SidebarAction = { type: 'SidebarWidth' , payload: SidebarPayload } |
                     { type: 'ClearSidebar' } | 
                     { type: 'SetComponent' , payload: ComponentPayload} | 
                     { type: 'SetItems', payload: ItemsPayload } | 
                     { type: 'ItemSelected' , payload: ItemPayload  } |
                     { type: 'ChangeArrow' , payload: ArrowPayload } |
                     { type: 'SetArrow' , payload: SetArrowPayload };

//definimos el reducer
export const sidebarReducer = (state: SidebarState = initialState, action: SidebarAction): SidebarState => {
    switch (action.type) {
        case 'SidebarWidth': return { ...state, sidebarWidth: action.payload.width }
        case 'ClearSidebar': return { ...initialState }
        case 'SetComponent': return { ...state, activeComponent: action.payload.component }
        case 'SetItems': return { ...state, sidebarItems: action.payload.items }

        case 'ItemSelected': return { ...state, itemSelected: action.payload.id }
        case 'ChangeArrow': return { ...state, sidebarItems:  state.sidebarItems.map(item => (item.id === action.payload.id) ? {...item, arrow: !item.arrow} : {...item}) }  
        case 'SetArrow': return { ...state, sidebarItems:  state.sidebarItems.map(item => (item.id === action.payload.id) ? {...item, arrow: action.payload.state} : {...item}) } 

        default: return state;
    }
}

//definimos los actions sincronos del reducer
export const setSidebarWidth = (sidebarData: SidebarPayload): SidebarAction => {
    return{
        type: 'SidebarWidth',
        payload: sidebarData
    }
}

export const clearSidebar = () : SidebarAction => {
    return{
        type: 'ClearSidebar'
    }
}

export const setComponent = (sidebarData: ComponentPayload): SidebarAction => {
    return{
        type: 'SetComponent',
        payload: sidebarData
    }
}

const setItems = (sidebarData: ItemsPayload): SidebarAction => {
    return{
        type: 'SetItems',
        payload: sidebarData
    }
} 

export const setItemSelected = (itemPayload: ItemPayload): SidebarAction => {
    return {
        type: 'ItemSelected',
        payload: itemPayload
    }
}
export const changeArrow = (arrowPayload: ArrowPayload): SidebarAction => {
    return {
        type: 'ChangeArrow',
        payload: arrowPayload
    }
}
export const setArrow = (arrowPayload: SetArrowPayload): SidebarAction => {
    return {
        type: 'SetArrow',
        payload: arrowPayload
    }
}

export const startLoadMenu = (): AppThunk  => {
    return async (dispatch, getState) => {
        const items: ItemType[] = []
        const body = await fetchToken(`menu/${ getState().auth.idrol }`,{},'GET');
        if (body.ok){
            const counter: number = body.count || 0;
            if (counter > 0) {
                const results: any[] = body.res;
                for (let result of results){
                    items.push({
                        key: result.key._id,
                        id: result.key.componente,
                        icon: result.key.icono,
                        title: result.key.titulo,
                        arrow: false,
                        subitem: []
                    });
                    let index = items.length - 1;
                    for (let subitem of result.subitem){
                        items[index].subitem.push({
                            key: subitem.key._id,
                            id: subitem.key.componente,
                            title: subitem.key.titulo
                        })
                    }
                }
                
            } else {
                items.concat(initialState.sidebarItems);
            }
        } else {
            dispatch(showMessage({ modaltype: 'Alert', title: 'Menu', message: body.msg }));
            return;
        }
        dispatch(setItems({ items }));
        dispatch(setComponent({component: items[0].id}));
        
    }
}


