//importamos redux para colocarlo como componente de orden superios
import { useSelector, useDispatch } from 'react-redux';
//importamos el componente principal
import 'animate.css'
import { RootState, AppDispatch } from './store/store';
import ProtectedRoute, { ProtectedRouteProps } from './router/PrivateRoute';
import PublicRoute, { PublicRouteProps } from './router/PublicRoute';
import { createTheme, ThemeProvider } from '@mui/material';
import { startCheking } from './reducers/authReducer';
import { Route } from 'react-router';
import { BrowserRouter, Switch } from 'react-router-dom';
import { useEffect } from 'react';
import { YesNoDialogModal } from './modals/YesNoDialogModal';
import { OkDialogModal } from './modals/OkDialogModal';
import { AppNavbar } from './AppNavbar';
import { AppFooter } from './AppFooter';
import { Login } from './components/login/Login';
import { PasswordChange } from './components/register/PasswordChange';

import { NosotrosScreen } from './components/screens/NosotrosScreen';
import { ContactoScreen } from './components/screens/ContactoScreen';
import { NotFound } from './components/notfound/NotFound';

import { ContextAdmin } from './components/principalAdmin/context/ContextAdmin';
import { ContextUsers } from './components/principalUsers/context/ContextUsers';
import { ContextAccount } from './components/principalUsers/components/miCuenta/context/AccountContext';
import { ContextShopping } from './components/principalUsers/components/misCompras/context/ShoppingContext';
import { ContextSales } from './components/principalUsers/components/misVentas/context/SalesContext';
import { ContextQuestions } from './components/principalUsers/components/misPreguntas/context/QuestionsContext';
import { ContextHome } from './components/home/context/ContextHome';
import { ContextFind } from './components/screens/context/ContextFind';
import { ContextPublicationData } from './components/screens/context/ContextPublicationData';
import CookieConsent from 'react-cookie-consent';
import { ContextRegister } from './components/register/context/RegisterContext';
import { TerminosCondicionesScreen } from './components/screens/TerminosCondicionesScreen';
import { AvisoPrivacidadScreen } from './components/screens/AvisoPrivacidadScreen';


// import { useTheme } from '@mui/material/styles';

//creamos el componente principal
const App = () => {

  //LEER VARIABLES DE REDUX
  const storeAuth = (state: RootState ) => state.auth;
  const { login, rol } = useSelector(storeAuth);

  //ESCRIBIR EN EL STORE DE REDUX
  const dispatchRedux = useDispatch<AppDispatch>();

  //VALIDAR SI HAY UNA SESION ACTUAL
  useEffect(() => {
    const token = localStorage.getItem('token') || '';
    dispatchRedux<any>(startCheking(token));
  }, [dispatchRedux])

  let protectedPath : string = '/';
  switch (rol) {
    case 'COMPRADOR' :
      protectedPath = '/users' 
    break;
    case 'VENDEDOR' : 
      protectedPath = '/users'
    break;
    case 'SUPERADMIN' :
      protectedPath = '/main'
    break; 
    case 'ADMIN' :
      protectedPath = '/main'
    break; 
    case 'AUDITOR' :
      protectedPath = '/main'
    break; 
    case 'SISTEMAS' :
      protectedPath = '/main'
    break; 
  }

  const defaultProtectedRouteProps: ProtectedRouteProps = {
    isAuthenticated: login,
    authenticationPath: '/',
    redirectPath: protectedPath
  };

  const defaultPublicRouteProps: PublicRouteProps = {
    isAuthenticated: login,
    redirectPath: protectedPath
  };

  //CONFIRGURANDO PARA QUE SIDEBAR APAREZCA DEBAJO DEL APPBAR
  // const currentTheme = useTheme();
  const customTheme = createTheme({
      // zIndex: {
      //     appBar : currentTheme.zIndex.appBar,
      //     drawer : currentTheme.zIndex.appBar-1
      // }
  });
  
  return (
    <ThemeProvider theme={ customTheme } >
        <YesNoDialogModal/>
        <OkDialogModal/>        
          <BrowserRouter>
            <div className="app_header">  {/* NAVBAR PRINCIPAL DE LA APLICACION */}
              <AppNavbar /> 
            </div>          
            <Switch>
              <ProtectedRoute {...defaultProtectedRouteProps} exact path='/main' component={ ContextAdmin } />
              <ProtectedRoute {...defaultProtectedRouteProps} exact path='/users' component={ ContextUsers }  />
              <ProtectedRoute {...defaultProtectedRouteProps} exact path='/account' component={ ContextAccount }  />
              <ProtectedRoute {...defaultProtectedRouteProps} exact path='/shopping' component={ ContextShopping }  />
              <ProtectedRoute {...defaultProtectedRouteProps} exact path='/sales/:salesPage' component={ ContextSales }  />
              <ProtectedRoute {...defaultProtectedRouteProps} exact path='/questions/:questionPage' component={ ContextQuestions }  />
              <ProtectedRoute {...defaultProtectedRouteProps} exact path='/response' component={ ContextQuestions }  />
              <ProtectedRoute {...defaultProtectedRouteProps} exact path='/contactUser' component={ ContactoScreen } />
              <ProtectedRoute {...defaultProtectedRouteProps} exact path='/findUser' component={ ContextFind } />
              <ProtectedRoute {...defaultProtectedRouteProps} exact path='/findUser/:marcaParam/:modeloParam/:anioParam/:paginaParam' component={ ContextFind } />
              <ProtectedRoute {...defaultProtectedRouteProps} exact path='/findUser/publication/:idPublicacion' component={ ContextPublicationData } />

              <PublicRoute {...defaultPublicRouteProps} exact path='/' component={ ContextHome } />
              <PublicRoute {...defaultPublicRouteProps} exact path='/find' component={ ContextFind } />
              <PublicRoute {...defaultPublicRouteProps} exact path='/find/:marcaParam/:modeloParam/:anioParam/:paginaParam' component={ ContextFind } />
              <PublicRoute {...defaultPublicRouteProps} exact path='/find/publication/:idPublicacion' component={ ContextPublicationData } />
              <PublicRoute {...defaultPublicRouteProps} exact path='/us' component={ NosotrosScreen } />
              <PublicRoute {...defaultPublicRouteProps} exact path='/contact' component={ ContactoScreen } />
              <PublicRoute {...defaultPublicRouteProps} exact path='/login' component={ Login } />
              <PublicRoute {...defaultPublicRouteProps} exact path='/register' component={ ContextRegister } />
              <PublicRoute {...defaultPublicRouteProps} exact path='/passwordRecovery' component={ PasswordChange } />

              <Route path='/terminos-y-condiciones' component={ TerminosCondicionesScreen } />
              <Route path='/aviso-de-privacidad' component={ AvisoPrivacidadScreen } />

              <Route path='*' component={ NotFound } />

            </Switch>
          </BrowserRouter>
        
        <div className="app_footer">  {/* FOOTER PRINCIAPL DE LA APLICACION */}
            <AppFooter />
        </div>
        <CookieConsent location="bottom" cookieName="myAwesomeCookieName3" expires={999}  
        buttonStyle={{
          "background": "#004e98",
          "color": "white",
          "padding": "20px 40px 20px 40px",
          "fontSize": "18px"
         }}
         style={{
          "background": "#ff6700",
           "height": "150px",
           "fontSize": "15px"
         }}
        buttonText="Aceptar" overlay>
          Éste sitio web usa cookies, si permanece aquì acepta su uso. Puede leer más sobre el uso de cookies
           en nuestra <a href='aviso-de-privacidad'> política de privacidad </a>
        </CookieConsent>
    </ThemeProvider>
  );
};

export default App;