import { Box, Button, ClickAwayListener, FormControlLabel, List, ListItem, ListItemButton, ListItemText, Portal, Stack, Switch, Typography, ListItemIcon, ListSubheader, Theme, Tooltip } from '@mui/material';
import { useState, useEffect, useContext } from 'react';
import { SxProps } from '@mui/material';
import SVG from 'react-inlinesvg';
import { CatalogoContext } from './context/CatalogoContext';

type Props = { 
    theme: Theme,
    sidebarWidth: number,   
    setAjusteAutomatico: React.Dispatch<React.SetStateAction<boolean>>
    setRowHeight: React.Dispatch<React.SetStateAction<number>>
}
export const Controller: React.FC<Props> = ({ theme, sidebarWidth, setAjusteAutomatico}) => {

    //OBTENEMOS EL REDUCER
    // const { query, dispatch } = useContext(CatalogoContext);
    const { query } = useContext(CatalogoContext);

    //LISTA DE ORDENAR
    const [openOrdenar, setOpenOrdenar] = useState<boolean>(false);
    const [ordenarPor, setOrdenarPor] = useState<string>('');
    const handleClickOrdenar = () => { setOpenOrdenar((prev) => !prev); }
    const handleClickOrdenarAway = () => { 
        if (openOrdenar) { setOpenOrdenar(false); setOrdenarPor('');}
    }
    const handleClickOrdenarOption = (e: React.MouseEvent<HTMLLIElement, MouseEvent>, field: any) => {
        setOrdenarPor(field);
        setOpenOrdenar(false); 
    }
    useEffect(() => {
        //console.log('ordenar por:'+ordenarPor);
    }, [ordenarPor])

    const materialStyles: SxProps = { position: 'fixed', top: 150, bgcolor: 'background.paper', color: 'text.primary', boxShadow: 3, borderRadius: 1 };

    //AJUSTAR LISTA
    const handleChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAjusteAutomatico(e.target.checked);
    }

    return (
        <div className="catalogo_search">
            <Stack className="catalogo_left" direction="row" spacing={2}>
                <ClickAwayListener onClickAway={ handleClickOrdenarAway }>
                    <Box sx={{ position: 'relative' }}>
                        <Tooltip title="Ordenar Lista" placement="top" arrow>
                            <Button size="small" onClick={ handleClickOrdenar } variant="text" startIcon={<SVG src={process.env.REACT_APP_ICONSERVER+'dz-file-download'} height={18} fill="currentColor"/>}>
                                Ordenar
                            </Button>
                        </Tooltip>
                        { (openOrdenar) && 
                            <Portal>
                                <Box sx={{ ...materialStyles, left : sidebarWidth + 30, width: 150 }}>
                                    <List
                                        subheader= {
                                            <ListSubheader component="div" id="nested-list-subheader">
                                                Ordenar por :
                                            </ListSubheader>
                                        }
                                    >                                                
                                    {
                                        query.headerList.map((header) => (
                                            <ListItem disablePadding
                                                key={ header.id + header.field }
                                                onClick={ (e) => ( handleClickOrdenarOption (e, header.field)) }
                                            >
                                            <ListItemButton>
                                                <ListItemIcon style={{ minWidth: '30px', color: theme.palette.primary.main }}>
                                                    <SVG src={process.env.REACT_APP_ICONSERVER+'dz-angle-right'} height={14} fill="currentColor"/>
                                                </ListItemIcon>
                                                <ListItemText 
                                                    primary={ header.field }
                                                    primaryTypographyProps={{
                                                        fontSize: 13,
                                                        lineHeight: '0px',
                                                    }}
                                                />
                                            </ListItemButton>
                                            </ListItem>
                                        ))
                                    }                                                
                                    </List>                                            
                                </Box>
                            </Portal>
                        }
                        <Tooltip title="Filtrar resultados" placement="top" arrow>
                            <Button size="small" variant="text" startIcon={<SVG src={process.env.REACT_APP_ICONSERVER+'dz-file-search'} height={18} fill="currentColor"/>}>
                                Filtrar
                            </Button>
                        </Tooltip>
                        <Tooltip title="Editar campos" placement="top" arrow>
                            <Button size="small" variant="text" startIcon={<SVG src={process.env.REACT_APP_ICONSERVER+'dz-file-check'} height={18} fill="currentColor"/>}>
                                Editar
                            </Button>
                        </Tooltip>
                        <Tooltip title="Cambiar tamaño de elementos" placement="top" arrow>
                            <Button size="small" variant="text" startIcon={<SVG src={process.env.REACT_APP_ICONSERVER+'dz-file-text'} height={18} fill="currentColor"/>}>
                                Densidad
                            </Button>
                        </Tooltip>
                        <Tooltip title="Exportar datos" placement="top" arrow>
                            <Button size="small" variant="text" startIcon={<SVG src={process.env.REACT_APP_ICONSERVER+'dz-file-pdf'} height={18} fill="currentColor"/>}>
                                Exportar
                            </Button>
                        </Tooltip>
                    </Box>
                </ClickAwayListener>
            

            </Stack>
            <Tooltip title="Ajustar tamaño de lista" placement="top" arrow>
                <FormControlLabel 
                    labelPlacement="start"
                    className="catalogo_right"
                    control={
                    <Switch 
                        onChange = { (e) => { handleChecked(e) }}
                        size="small" 
                    />
                    } 
                    label={ <Typography style={ { fontSize: '12px', color: 'primary', fontStyle: 'bold'} }>{'AJUSTAR LISTA'}</Typography> }
                />
            </Tooltip>
        </div>            
    )
}
