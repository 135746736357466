
import { fetchFormDataToken, fetchToken } from '../../../../../helpers/fetch';
//DEFINIMOS LOS TIPOS PARA DEL REDUCER
export type AccountAddress = {
    id: string,
    calle: string,
    numero: string,
    colonia: string,
    cp: number,
    ciudad: string,
    estado: string,
    entre1: string,
    entre2: string,
    telefono: number,
}
export type AccountCards = {
    id: string,
    tipo: string,
    numero: string,
    fecha: string,
}
//DEFINIMOS LA ESTRUCTURA DEL REDUCER
export interface AccountReducerType {
    name: string,
    lastName: string,
    email: string,
    phone: string,
    registroVendedor: boolean,
    numContacto: number | string | null,
    establecimiento: string,
    paquete: string,
    rfc: string,
    rol: string,
    flogo: string,
    flogoError: string | null,
    fportada: string,
    fportadaError: string | null,
    fine: string,
    addresses: AccountAddress[] | undefined,
    cards: AccountCards[] | undefined,
    selectedCard:  AccountCards | undefined,
    selectedAddress: AccountAddress | undefined,
    editarTarjetaModal: boolean,
    editarDireccionModal: boolean,
    loading: boolean,

    editarNombresModal: boolean,
    editarTelefonoModal: boolean,
    editarEstablecimiento: boolean,
    editarTelefonoLocal: boolean,

    reloadAccount: boolean,
    codigo: string,
    sendCodeNewTelephone: boolean,

    mensajeEditar: string | undefined
}
//DEFINIMOS EL VALOR INICIAL DEL REDUCER
export const initialAccountState: AccountReducerType = {
    name: '',
    lastName: '',
    email: '',
    phone: '',
    registroVendedor: false,
    numContacto: null,
    establecimiento: '',
    paquete: '',
    rfc: '',
    rol: '',
    flogo: '',
    flogoError: null,
    fportada: '',
    fportadaError: null,
    fine: '',
    addresses: undefined,
    cards: undefined,
    selectedCard: undefined,
    selectedAddress: undefined,
    editarTarjetaModal: false,
    editarDireccionModal: false,
    loading: false,

    editarNombresModal: false,
    editarTelefonoModal: false,
    editarEstablecimiento: false,
    editarTelefonoLocal: false,

    reloadAccount: false,
    codigo: '',
    sendCodeNewTelephone: false,

    mensajeEditar: undefined
}
//DEFINIMOS LOS PAYLOAD DE LOS ACTIONS
type LoadAccountPayload = {     
    name: string,
    lastName: string,
    email: string,
    phone: string,
    registroVendedor: boolean,
    numContacto: number,
    establecimiento: string
    paquete: string,
    rfc: string,
    rol: string,
    flogo: string,
    fportada: string,
    fine: string,
    //addresses: AccountAddress[] | undefined,
    //cards: AccountCards[] | undefined
};

type EditNamePayload = { messageEditName: string };
type EditTelephonePayload = { messageEditTelephone: string };

type OnChangePayload = {
    field: keyof AccountReducerType,
    value: any
}

type UploadFilePayload = {
    field: keyof AccountReducerType,
    errorField : keyof AccountReducerType,
    file: string
}
type prepareUploadPayload = {
    field: keyof AccountReducerType,
    errorField : keyof AccountReducerType,
}
type OnErrorPayload = {
    errorField: keyof AccountReducerType,
    label: string
}

//DEFINIMOS LOS ACTIONS DEL REDUCER
type AccountActions =   { type: 'ClearAccount' } |
                        { type: 'LoadAccount', payload: LoadAccountPayload } |

                        { type: 'MessageEditNameModal', payload: EditNamePayload } |
                        { type: 'MessageEditTelephoneModal', payload: EditTelephonePayload } |

                        { type: 'OpenEditarNombre' } |
                        { type: 'CloseEditarNombre' } |

                        { type: 'OpenEditarTelefono' } |
                        { type: 'CloseEditarTelefono' } |

                        { type: 'OpenEditarEstablecimiento' } |
                        { type: 'CloseEditarEstablecimiento' } |

                        { type: 'OpenEditarTelefonoLocal' } |
                        { type: 'CloseEditarTelefonoLocal' } |

                        { type: 'OpenEditarTarjeta', payload:  AccountCards } |
                        { type: 'CloseEditarTarjeta' } |

                        { type: 'OpenEditarDireccion', payload: AccountAddress } |
                        { type: 'CloseEditarDireccion' } |
                        { type: 'ActualizarDatosCuenta' } |

                        { type: 'SendingCodeTelephone' } |

                        { type: 'OnChange', payload: OnChangePayload } |

                        { type: 'OnError', payload: OnErrorPayload } |
                        { type: 'PrepareUpload', payload: prepareUploadPayload } |
                        { type: 'UploadFile', payload: UploadFilePayload };

//DEFINIMOS LAS ACCIONES DEL REDUCER
export const accountReducer = (state: AccountReducerType = initialAccountState, action: AccountActions) : AccountReducerType => {
    switch (action.type) {
        case 'ClearAccount' : return {
            ...state,
            ...initialAccountState,
            loading: true
        }
        case 'LoadAccount' : return { 
            ...state, 
            ...action.payload,
            loading: false,
            reloadAccount: false
        }

        case 'MessageEditNameModal' : return {
            ...state,
            mensajeEditar: action.payload.messageEditName,
        }
        case 'MessageEditTelephoneModal' : return {
            ...state,
            mensajeEditar: action.payload.messageEditTelephone,
        }

        case 'OpenEditarNombre' : return {
            ...state,
            editarNombresModal: true
        }
        case 'CloseEditarNombre' : return {
            ...state,
            editarNombresModal: false,
            mensajeEditar: undefined
        }
        case 'OpenEditarTelefono' : return {
            ...state,
            editarTelefonoModal: true
        }
        case 'CloseEditarTelefono' : return {
            ...state,
            editarTelefonoModal: false,
            mensajeEditar: undefined
        }
        case 'OpenEditarEstablecimiento' : return {
            ...state,
            editarEstablecimiento: true
        }
        case 'CloseEditarEstablecimiento' : return {
            ...state,
            editarEstablecimiento: false,
            mensajeEditar: undefined
        }
        case 'OpenEditarTelefonoLocal' : return {
            ...state,
            editarTelefonoLocal: true
        }
        case 'CloseEditarTelefonoLocal' : return {
            ...state,
            editarTelefonoLocal: false,
            mensajeEditar: undefined
        }

        case 'OpenEditarTarjeta' : return {
            ...state,
            selectedCard: action.payload,
            editarTarjetaModal: true
        }
        case 'CloseEditarTarjeta' : return {
            ...state,
            selectedCard: undefined,
            editarTarjetaModal: false
        }
        case 'OpenEditarDireccion' : return {
            ...state,
            selectedAddress: action.payload,
            editarDireccionModal: true
        }
        case 'CloseEditarDireccion' : return {
            ...state,
            selectedAddress: undefined,
            editarDireccionModal: false
        }
        case 'ActualizarDatosCuenta' : return {
            ...state,
            reloadAccount: true
        }

        case 'SendingCodeTelephone' : return {
            ...state,
            sendCodeNewTelephone: true,
            mensajeEditar: undefined
        }

        case 'OnChange' : return {
            ...state,
            [action.payload.field]: action.payload.value
        }

        case 'OnError' : return {
            ...state,
            [action.payload.errorField]: action.payload.label
        }
        case 'PrepareUpload' : return {
            ...state,
            [action.payload.field]: null,
            [action.payload.errorField]: null         
        }
        case 'UploadFile' : return {
            ...state,
            [action.payload.field]: action.payload.file
        }

        default: return state;
    }
}

/*const directions: AccountAddress[] = [{
    id: 'da',
    calle: 'MARMOL',
    numero: '51',
    colonia: 'LOMAS DEL PEDREGAL',
    cp: 76804,
    ciudad: 'San Juan del Río',
    estado: 'Querétaro',
    entre1: 'Turqueza',
    entre2: 'Rubi',
    telefono: 4275670980
  },{
    id: 'db',
    calle: 'JACARANDA',
    numero: '186',
    colonia: 'PRADERAS DEL SOL',
    cp: 76806,
    ciudad: 'San Juan del Río',
    estado: 'Querétaro',
    entre1: 'Tepozan',
    entre2: 'Naranjos',
    telefono: 4275670980
  }];
  const cards: AccountCards[] = [{
    id: 'ca',
    tipo: 'MASTERCARD',
    numero: '5234 5678 1234 5678',
    fecha: '12/24'
  },{
    id: 'bb',
    tipo: 'VISA',
    numero: '4321 8765 4321 8765',
    fecha: '06/23'        
  },{
    id: 'cb',
    tipo: 'VISA',
    numero: '2357 2468 1357 2468',
    fecha: '10/22'        
  }];*/

//CREAMOS LAS ACCIONES DEL REDUCER

//Cargar datos de la cuenta
type LoadAccountParameter = { id: number | null, dispatch: React.Dispatch<AccountActions> };

export const startLoadAccount = async (loadAccountData: LoadAccountParameter) => {

    const {id, dispatch} = loadAccountData;
    
    dispatch (clearAccount());

    const body = await fetchToken(`usuarios/cuenta/${id}`,{},'GET');

    //console.log(body);

    if (body.ok){
        dispatch ( loadAccount({
            name: body.res.nombre,
            lastName: body.res.apellidos,
            email: body.res.email,
            phone: body.res.telefono,
            registroVendedor: body.res.registroVendedor,
            numContacto: body.res.contacto,
            establecimiento: body.res.establecimiento,
            paquete: body.res.paquete,
            rfc: body.res.rfc,
            rol: body.res.rol,
            flogo: body.res.fotoLogo,
            fportada: body.res.fotoPortada,
            fine: body.res.fotoIne,
            //addresses: directions,
            //cards: cards,
        }));
    } 
    //console.log(body);
}
const clearAccount = (): AccountActions  => {
    return {
        type: 'ClearAccount'
    }    
}
const loadAccount = (accountData: LoadAccountPayload): AccountActions  => {
    return {
        type: 'LoadAccount',
        payload: accountData
    }    
}

type startEditNameParameter = { /*stateLocalPublication: PublicationDataReducerType,*/ dispatch: React.Dispatch<AccountActions>
                                nombre: string, apellido: string };
export const startEditName = async (startEditName: startEditNameParameter) => {

    const { dispatch, nombre, apellido } = startEditName;

    const body = await fetchToken(`auth/editUserName`, { 
        nombre: nombre,
        apellidos: apellido
    }, 'PUT');

    //console.log(body);

    if (body.ok) {
        dispatch( closeEditNames());
        dispatch( actualizarDatosCuenta());
    } else {
        dispatch( messageEditNameModal({
            messageEditName: body.msg
        }));
    }
}
const messageEditNameModal = ( messageEditNameData: EditNamePayload ) : AccountActions => {
    return {
        type: 'MessageEditNameModal',
        payload: messageEditNameData
    }
}

type startEditTelephoneParameter = { /*stateLocalPublication: PublicationDataReducerType,*/ dispatch: React.Dispatch<AccountActions>
                                     telefono: string };
export const startEditTelephone = async (startEditTelephone: startEditTelephoneParameter) => {

    const { dispatch, telefono } = startEditTelephone;

    const body = await fetchToken(`auth/editUserPhone`, { 
        telefono: telefono
    }, 'PUT');

    if (body.ok) {
        dispatch( sendingCodeTelephone());
    } else {
        dispatch( messageEditTelephoneModal({
            messageEditTelephone: body.msg
        }));
    }
}
const messageEditTelephoneModal = ( messageEditTelephoneData: EditTelephonePayload ) : AccountActions => {
    return {
        type: 'MessageEditTelephoneModal',
        payload: messageEditTelephoneData
    }
}
export const sendingCodeTelephone = () : AccountActions  => {
    return {
        type: 'SendingCodeTelephone'
    }    
}


type startCodeNewTelephoneParameter = { /*stateLocalPublication: PublicationDataReducerType,*/ dispatch: React.Dispatch<AccountActions>
                                     telefono: number, codigo: string };
export const startCodeNewTelephone = async (startCodeNewTelephoneData: startCodeNewTelephoneParameter) => {

    const { dispatch, telefono, codigo } = startCodeNewTelephoneData;

    const body = await fetchToken(`auth/validateNewPhone`, { 
        telefono: telefono,
        codigo: codigo
    }, 'POST');

    if (body.ok) {
        dispatch( closeEditPhone());
        dispatch( actualizarDatosCuenta());
    } else {
        dispatch( messageEditTelephoneModal({
            messageEditTelephone: body.msg
        }));
    }
}


const actualizarDatosCuenta = (): AccountActions  => {
    return {
        type: 'ActualizarDatosCuenta'
    }    
}

export const openEditNames = () : AccountActions  => {
    return {
        type: 'OpenEditarNombre'
    }    
}
export const closeEditNames = () : AccountActions  => {
    return {
        type: 'CloseEditarNombre'
    }    
}
export const openEditPhone = () : AccountActions  => {
    return {
        type: 'OpenEditarTelefono'
    }    
}
export const closeEditPhone = () : AccountActions  => {
    return {
        type: 'CloseEditarTelefono'
    }    
}
export const openEditEstablecimiento = () : AccountActions  => {
    return {
        type: 'OpenEditarEstablecimiento'
    }    
}
export const closeEditEstablecimiento = () : AccountActions  => {
    return {
        type: 'CloseEditarEstablecimiento'
    }    
}
export const openEditTelefonoLocal = () : AccountActions  => {
    return {
        type: 'OpenEditarTelefonoLocal'
    }    
}
export const closeEditTelefonoLocal = () : AccountActions  => {
    return {
        type: 'CloseEditarTelefonoLocal'
    }    
}

export const openEditCard = (cardData: AccountCards) : AccountActions  => {
    return {
        type: 'OpenEditarTarjeta',
        payload: cardData
    }    
}
export const closeEditCard = (): AccountActions  => {
    return {
        type: 'CloseEditarTarjeta'
    }    
}

export const openEditAddress = (addressData: AccountAddress): AccountActions  => {
    return {
        type: 'OpenEditarDireccion',
        payload: addressData
    }    
}
export const closeEditaddress = (): AccountActions  => {
    return {
        type: 'CloseEditarDireccion'
    }    
}

//METODO ON CHANGE GENERAL
export const onChange = (onChangeData: OnChangePayload): AccountActions => {
    return {
        type: 'OnChange',
        payload: onChangeData
    }
}

//METODO SET ERROR GENERAL
export const setError = (onErrorData: OnErrorPayload): AccountActions => {
    return {
        type: 'OnError',
        payload: onErrorData
    }
}

//METODO UPLOAD PHOTO GENERAL
//enum para asociar la variable del reducer con su nombre en mongo
export enum fileTypes  { 
    flogo = "fotoLogo", 
    fportada = "fotoPortada",
}
type UploadFileParameter = { field: keyof AccountReducerType, errorField : keyof AccountReducerType, file: File, dispatch: React.Dispatch<AccountActions>, userId: string | undefined }
export const startUploadPhoto = async (uploadFileData: UploadFileParameter) => {
   
        const { dispatch, field, errorField, file, userId } = uploadFileData;

        dispatch(prepareUpload({field, errorField}));

        //obteniendo el nombre del campo mongo a partir de su valor en el reducer
        const fieldString = field.toString();
        const index = Object.keys(fileTypes).indexOf(fieldString);
        const tipo = Object.values(fileTypes)[index];

        const body = await fetchFormDataToken(`usuarios/foto`, { 
            userId: userId,
            tipo: tipo
        }, file );

        //console.log(body);

        if (body.ok){
            dispatch(uploadFile({ field, errorField, file: body.res.fotoUpload }));
            // dispatch(uploadFile({ field, errorField, file: body.res.url }));
        } else {
            dispatch(setError({ errorField, label: body.msg}));
        }
        dispatch( actualizarDatosCuenta());
}

const prepareUpload = (prepareUploadData: prepareUploadPayload) : AccountActions  => {
    return {
        type: 'PrepareUpload',
        payload: prepareUploadData
    }    
}
const uploadFile = (uploadFileData: UploadFilePayload): AccountActions  => {
    return {
        type: 'UploadFile',
        payload: uploadFileData
    }    
}