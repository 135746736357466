
import { fetchFormDataToken, fetchToken } from '../../../../../helpers/fetch';
import { resizeFile } from '../../../../../helpers/imageResizer';

//DEFINIMOS LOS TIPOS PARA DEL REDUCER

//DEFINIMOS LA ESTRUCTURA DEL REDUCER
export interface PublicationsReducerType {

    publicationCreated: boolean, //indica si ya se creo la publicacion
    publicationId: number | null, //indica el Id de la publicacion creada
    publicationSending: boolean, //indica si se esta haciendo fetch para la creacion de la publicacion
    publicationError: boolean, //indica si el fetch respondio con error
    publicationLabel: string, //indica el error del fetch
    publicationType: string,

    publicationOptionaled: boolean, //indica si ya se actualizaron los opcionales
    publicationOptionalSending: boolean, //indica si se esta haciendo fetch para la actualizacion de la publicacion
    publicationOptionalError: boolean, //indica si el fetch respondio con error
    publicationOptionalLabel: string, //indica el error del fetch

    publicationFronted: boolean, //indica si ya se actualizaron los opcionales
    publicationFrontSending: boolean, //indica si se esta haciendo fetch para la actualizacion de la publicacion
    publicationFrontError: boolean, //indica si el fetch respondio con error
    publicationFrontLabel: string, //indica el error del fetch

    publicationEnded: boolean, //indica si ya se actualizaron los opcionales
    publicationEndSending: boolean, //indica si se esta haciendo fetch para la actualizacion de la publicacion
    publicationEndError: boolean, //indica si el fetch respondio con error
    publicationEndLabel: string, //indica el error del fetch

    puplicationEdit: boolean,

    version: string,
    versionError: string | null,

    motor: string,
    motorError: string | null,

    transmision: string,
    transmisionError: string | null,

    anioInicial: number | null,
    anioInicialError: string | null,
    
    anioFinal: number | null,
    anioFinalError: string | null,
    
    fpublicacion: string,
    fpublicacionError: string | null,
    
    descripcion: string,
    descripcionError: string | null,

    precio: string,
    precioError: string | null,

    fizquierda: string,
    fizquierdaError: string | null,

    fderecha: string,
    fderechaError: string | null,

    finferior: string,
    finferiorError: string | null,
    
    fsuperior: string,
    fsuperiorError: string | null,

    ftrasera: string,
    ftraseraError: string | null,

    ffrontal: string,
    ffrontalError: string | null,    
};
//DEFINIMOS EL VALOR INICIAL DEL REDUCER
export const initialPublicationsState: PublicationsReducerType = {

    publicationCreated: false,
    publicationId: null,
    publicationSending: false,
    publicationError: false,
    publicationLabel: '',
    publicationType: '',

    publicationOptionaled: false,
    publicationOptionalSending: false,
    publicationOptionalError: false,
    publicationOptionalLabel: '',

    publicationFronted: false,
    publicationFrontSending: false,
    publicationFrontError: false,
    publicationFrontLabel: '',

    publicationEnded: false,
    publicationEndSending: false,
    publicationEndError: false,
    publicationEndLabel: '',

    puplicationEdit: false,

    version: '',
    versionError: null,

    motor: '',
    motorError: null,

    transmision: '',
    transmisionError: null,

    anioInicial: null,
    anioInicialError: null,

    anioFinal: null,
    anioFinalError: null,

    fpublicacion: '',
    fpublicacionError: null,

    descripcion: '',
    descripcionError: null,

    precio: '',
    precioError: null,

    fizquierda: '',
    fizquierdaError: null,

    fderecha: '',
    fderechaError: null,

    finferior: '',
    finferiorError: null,

    fsuperior: '',
    fsuperiorError: null,

    ftrasera: '',
    ftraseraError: null,

    ffrontal: '',
    ffrontalError: null,    
}

//DEFINIMOS LOS PAYLOAD DE LOS ACTIONS
type OnChangePayload = {
    field: keyof PublicationsReducerType,
    value: any
}
type OnErrorPayload = {
    errorField: keyof PublicationsReducerType,
    label: string
}
type OnCleanErrorPayload = {
    errorField: keyof PublicationsReducerType
}
type UploadFilePayload = {
    field: keyof PublicationsReducerType,
    errorField : keyof PublicationsReducerType,
    file: string
}
type prepareUploadPayload = {
    field: keyof PublicationsReducerType,
    errorField : keyof PublicationsReducerType,
}

type CreatePublicationPayload = {
                            publicationID: number,
                            publicationType: string
};                        
type CreatePublicationFailedPayload = {
                            publicationLabel: string
};    

type UpdateOptionalsFailedPayload = {
    publicationLabel: string
};

type UpdateFrontFailedPayload = {
    publicationLabel: string
};

type UpdateEndFailedPayload = {
    publicationLabel: string
};

type UpdateOptionalSetPayload = {
    publicationId: number | null,
    version: string,
    motor: string,
    transmision: string,
    anioInicial: number,
    anioFinal: number,
    descripcion: string,

    fpublicacion: string,
    fizquierda: string,
    fderecha: string,
    finferior: string,
    fsuperior: string,
    ftrasera: string,
    ffrontal: string,

    precio: string,

    publicated: boolean
  /*  versionError: string | null,

    
    motorError: string | null,

    
    transmisionError: string | null,
    
    anioInicial: number | null,
    anioInicialError: string | null,
    
    anioFinal: number | null,
    anioFinalError: string | null,
    
    fpublicacion: string,
    fpublicacionError: string | null,
    
    descripcion: string,
    descripcionError: string | null,

    precio: string,
    precioError: string | null,

    fizquierda: string,
    fizquierdaError: string | null,

    fderecha: string,
    fderechaError: string | null,

    finferior: string,
    finferiorError: string | null,
    
    fsuperior: string,
    fsuperiorError: string | null,

    ftrasera: string,
    ftraseraError: string | null,

    ffrontal: string,
    ffrontalError: string | null*/
};    

//DEFINIMOS LOS ACTIONS DEL REDUCER
type PublicationActions = 
                        { type: 'OnChange', payload: OnChangePayload } |
                        { type: 'OnError', payload: OnErrorPayload } |
                        { type: 'OnCleanError', payload: OnCleanErrorPayload } |
                        { type: 'PrepareUpload', payload: prepareUploadPayload } |
                        { type: 'UploadFile', payload: UploadFilePayload } |

                        { type: 'PrepareFetchPublication' } |
                        { type: 'CreatePublication', payload: CreatePublicationPayload } |
                        { type: 'UpdatePublication' } | 
                        { type: 'FetchPublicationFailed', payload: CreatePublicationFailedPayload } |
                        { type: 'ClearPublicationError' } |
                        { type: 'ClearPublication' } |

                        { type: 'PrepareFetchOptionals' } |
                        { type: 'UpdateOptionals' } |
                        { type: 'FetchOptionalsFailed', payload: UpdateOptionalsFailedPayload } |
                        { type: 'ClearOptionalsError' } |

                        { type: 'PrepareFetchFront' } |
                        { type: 'UpdateFront' } |
                        { type: 'FetchFrontFailed', payload: UpdateFrontFailedPayload } |
                        { type: 'ClearFrontError' } |

                        { type: 'PrepareFetchEnd' } |
                        { type: 'UpdateEnd' } |
                        { type: 'FetchEndFailed', payload: UpdateEndFailedPayload } |
                        { type: 'ClearEndError' } |

                        { type: 'CleanAnios' } |

                        { type: 'UpdateOptionalSet', payload: UpdateOptionalSetPayload } ;
;
//DEFINIMOS LAS ACCIONES DEL REDUCER
export const publicationsReducer = (state: PublicationsReducerType = initialPublicationsState, action: PublicationActions): PublicationsReducerType => {
    switch (action.type) {
        case 'OnChange' : return {
            ...state,
            [action.payload.field]: action.payload.value
        }
        case 'OnError' : return {
            ...state,
            [action.payload.errorField]: action.payload.label
        }
        case 'OnCleanError' : return {
            ...state,
            [action.payload.errorField]: null
        }
        case 'PrepareUpload' : return {
            ...state,
            [action.payload.field]: null,
            [action.payload.errorField]: null         
        }
        case 'UploadFile' : return {
            ...state,
            [action.payload.field]: action.payload.file
        }

        case 'PrepareFetchPublication' : return {
            ...state,
            publicationCreated: false,
            publicationError: false,
            publicationLabel: '',
            publicationSending: true,
        }
        case 'CreatePublication' : return {
            ...state,
            publicationCreated: true,
            publicationId: action.payload.publicationID,
            publicationSending: false,
            publicationType: action.payload.publicationType
        }
        case 'UpdatePublication' : return {
            ...state,
            publicationCreated: true,
            publicationSending: false
        }
        case 'FetchPublicationFailed' : return {
            ...state,
            publicationError: true,
            publicationLabel: action.payload.publicationLabel,
            publicationSending: false
        }
        case 'ClearPublicationError' : return {
            ...state,
            publicationError: false,
            publicationLabel: '',
        }
        case 'ClearPublication' : return {
            ...initialPublicationsState
        }
        case 'CleanAnios' : return {
            ...state,
            anioInicial: null,
            anioFinal: null,
            anioInicialError: null,
            anioFinalError: null
        }

        case 'PrepareFetchOptionals' : return {
            ...state,
            publicationOptionaled: false,
            publicationOptionalError: false,
            publicationOptionalLabel: '',
            publicationOptionalSending: true,
        }
        case 'UpdateOptionals' : return {
            ...state,
            publicationOptionaled: true,
            publicationOptionalSending: false
        }
        case 'FetchOptionalsFailed' : return {
            ...state,
            publicationOptionalError: true,
            publicationOptionalLabel: action.payload.publicationLabel,
            publicationOptionalSending: false
        }
        case 'ClearOptionalsError' : return {
            ...state,
            publicationOptionaled: false,
            publicationOptionalError: false,
            publicationOptionalLabel: '',
        }

        case 'PrepareFetchFront' : return {
            ...state,
            publicationFronted: false,
            publicationFrontError: false,
            publicationFrontLabel: '',
            publicationFrontSending: true,
        }
        case 'UpdateFront' : return {
            ...state,
            publicationFronted: true,
            publicationFrontSending: false
        }
        case 'FetchFrontFailed' : return {
            ...state,
            publicationFrontError: true,
            publicationFrontLabel: action.payload.publicationLabel,
            publicationFrontSending: false
        }
        case 'ClearFrontError' : return {
            ...state,
            publicationFronted: false,
            publicationFrontError: false,
            publicationFrontLabel: '',
        }

        case 'PrepareFetchEnd' : return {
            ...state,
            publicationEnded: false,
            publicationEndError: false,
            publicationEndLabel: '',
            publicationEndSending: true,
        }
        case 'UpdateEnd' : return {
            ...state,
            publicationEnded: true,
            publicationEndSending: false,
        }
        case 'FetchEndFailed' : return {
            ...state,
            publicationEndError: true,
            publicationEndLabel: action.payload.publicationLabel,
            publicationEndSending: false
        }
        case 'ClearEndError' : return {
            ...state,
            publicationEnded: false,
            publicationEndError: false,
            publicationEndLabel: '',
        }

        case 'UpdateOptionalSet' : return {
            ...state,
            publicationId: action.payload.publicationId,
            version: action.payload.version,
            motor: action.payload.motor,
            transmision: action.payload.transmision,
            anioInicial: action.payload.anioInicial,
            anioFinal: action.payload.anioFinal,
            descripcion: action.payload.descripcion,

            fpublicacion: action.payload.fpublicacion,
            fizquierda: action.payload.fizquierda,
            fderecha: action.payload.fderecha,
            finferior: action.payload.finferior,
            fsuperior: action.payload.fsuperior,
            ftrasera: action.payload.ftrasera,
            ffrontal: action.payload.ffrontal,

            precio: action.payload.precio,

            puplicationEdit: action.payload.publicated
        }

        default: return state;
    }
}

//LIMPIANDO VALORES DE LA PUBLICACION
export const startClearPublication = (): PublicationActions  => {
    return {
        type: 'ClearPublication'
    }    
}

//METODO ON CHANGE GENERAL
export const onChange = (onChangeData: OnChangePayload): PublicationActions => {
    return {
        type: 'OnChange',
        payload: onChangeData
    }
}
//METODO SET ERROR GENERAL
export const setError = (onErrorData: OnErrorPayload): PublicationActions => {
    return {
        type: 'OnError',
        payload: onErrorData
    }
}
//METODO CLEAN ERROR GENERAL
export const cleanError = (onCleanErrorData: OnCleanErrorPayload): PublicationActions => {
    return {
        type: 'OnCleanError',
        payload: onCleanErrorData
    }
}

//METODO UPLOAD PHOTO GENERAL
//enum para asociar la variable del reducer con su nombre en mongo
export enum fileTypes  { 
    fpublicacion = "fotoPublicacion", 
    ffrontal = "fotoFrontal",
    fizquierda = "fotoIzquierda",
    fderecha = "fotoDerecha",
    ftrasera = "fotoPosterior",
    finferior = "fotoInteriorInferior",
    fsuperior = "fotoMotorSuperior",    
}

export enum fileTypes2  { 
    ffrontal = "fotoFrontal",
    fizquierda = "fotoIzquierda",
    fderecha = "fotoDerecha",
    ftrasera = "fotoPosterior",
    finferior = "fotoInteriorInferior",
    fsuperior = "fotoMotorSuperior",    
}

type UploadFileParameter = { field: keyof PublicationsReducerType, errorField : keyof PublicationsReducerType, file: File, 
                             state: PublicationsReducerType, dispatch: React.Dispatch<PublicationActions> }
export const startUploadPhoto = async (uploadFileData: UploadFileParameter) => {

    const fechaInicio : Date = new Date(Date.now());
    //redimensionar imagen

    const image : File =  await resizeFile(uploadFileData.file) as File;
    uploadFileData.file = image;
    const fechaFin : Date = new Date(Date.now());
    const diferencia = fechaFin.getTime() -fechaInicio.getTime();
    //console.log('Tiempo de conversion =' + diferencia);

    const {field, errorField, file, state, dispatch} = uploadFileData;

    dispatch(prepareUpload({ field, errorField }));

    const publicationId = state.publicationId;

    //obteniendo el nombre del campo mongo a partir de su valor en el reducer
    const fieldString = field.toString();
    const index = Object.keys(fileTypes).indexOf(fieldString);
    const tipo = Object.values(fileTypes)[index];

    const body = await fetchFormDataToken(`${state.puplicationEdit ? 'publicaciones/editFoto' : 'publicaciones/foto'}`, { 
        publicacionId: publicationId,
        tipo: tipo
    }, file );

    console.log(body);

    if (body.ok){
        dispatch(uploadFile({ field, errorField, file: body.res.fotoUpload }));
        // dispatch(uploadFile({ field, errorField, file: body.res.url}));
    } else {
        dispatch(setError({ errorField, label: body.msg}))
    }
    
}

type UploadFileParameter2 = { field: keyof PublicationsReducerType, errorField: keyof PublicationsReducerType, files: FileList, 
                              state: PublicationsReducerType, dispatch: React.Dispatch<PublicationActions> }
export const startUploadPhoto2 = async (uploadFileData: UploadFileParameter2) => {

    //const fechaInicio : Date = new Date(Date.now());
    //redimensionar imagen
    const {field, errorField, state, dispatch} = uploadFileData;
    
    //redimensionar imagen
    const fieldString = field.toString();
    const indexOrg = Object.keys(fileTypes2).indexOf(fieldString);
    const tipoOrg = Object.values(fileTypes2)[indexOrg];

    //Cargando la primer imagen en el cuadro seleccionado
    const image : File =  await resizeFile(uploadFileData.files[0]) as File;

    dispatch(prepareUpload({ field , errorField }));

    const publicationId = state.publicationId;

    //obteniendo el nombre del campo mongo a partir de su valor en el reducer
    const body = await fetchFormDataToken(`${state.puplicationEdit ? 'publicaciones/editFoto' : 'publicaciones/foto'}`, { 
        publicacionId: publicationId,
        tipo: tipoOrg
    }, image );

    if (body.ok){
        dispatch(uploadFile({ field, errorField, file: body.res.fotoUpload }));
        // dispatch(uploadFile({ field, errorField, file: body.res.url}));
    } else {
        dispatch(setError({ errorField, label: body.msg}))
    }

    //Cargando el resto de imagenes si se selecciona mas de 1
    const fileCounter = uploadFileData.files.length;
    let forLimit = 0;
    if ( indexOrg+fileCounter > 6 ) {
        forLimit = 6 - indexOrg ;
    } else {
        forLimit = fileCounter;
    }
    
    for (let step = 1; step < forLimit; step++) {
        const image : File = await resizeFile(uploadFileData.files[step]) as File;

        const tipo = Object.values(fileTypes2)[indexOrg+step];
        const key = Object.keys(fileTypes2)[indexOrg+step]  as  keyof PublicationsReducerType

        dispatch(prepareUpload({ field: key, errorField }));

        //obteniendo el nombre del campo mongo a partir de su valor en el reducer
        const body = await fetchFormDataToken(`${state.puplicationEdit ? 'publicaciones/editFoto' : 'publicaciones/foto'}`, { 
            publicacionId: publicationId,
            tipo: tipo
        }, image );

        //console.log(tipo);
        //console.log(key);

        if (body.ok){
            dispatch(uploadFile({ field: key, errorField, file: body.res.fotoUpload }));
        // dispatch(uploadFile({ field, errorField, file: body.res.url}));
        } else {
            dispatch(setError({ errorField, label: body.msg}))
        }
    }
}

const prepareUpload = (prepareUploadData: prepareUploadPayload): PublicationActions  => {
    return {
        type: 'PrepareUpload',
        payload: prepareUploadData
    }    
}
const uploadFile = (uploadFileData: UploadFilePayload): PublicationActions  => {
    return {
        type: 'UploadFile',
        payload: uploadFileData
    }    
}


//CREANDO UNA PUBLICACION
export enum publicationTypes { Auto = "Auto", Refaccion = "Refaccion" }
type createPublicationParameter = { publicationType:  publicationTypes, state: PublicationsReducerType, dispatch: React.Dispatch<PublicationActions> 
                                    marcaId: string | undefined, modeloId: string | undefined, anio: number | null };
export const startCreatePublication = async (createPublicationData: createPublicationParameter) => {

    const { publicationType, marcaId, modeloId, anio, state, dispatch } = createPublicationData;

    //revisando si la publicacion se habia creado anteriormente
    if (!state.publicationCreated && !state.puplicationEdit) {

        dispatch(prepareFetchPublication());

        const body = await fetchToken(`publicaciones`, { 
            marcaId: marcaId,
            modeloId: modeloId,
            anio: anio,
            tipo: publicationType
        }, 'POST');

        if (body.ok){
            dispatch(createPublication({ publicationID: body.res._id, publicationType: publicationType }));
        } else {
            dispatch(fetchPublicationFailed({ publicationLabel: body.msg}));
            //console.log(body.msg);
        }
    } else {
        dispatch(updatePublication());
        //actualizando los datos de la publicacion original

        /*dispatch(prepareFetchPublication());

        const body = await fetchToken(`publicaciones`, { 
            publicacionId: state.publicationId,
            marcaId: marcaId,
            modeloId: modeloId,
            anio: anio,
            tipo: publicationType
        }, 'PUT');

        if (body.ok){
            dispatch(updatePublication());
        } else {
            dispatch(fetchPublicationFailed({ publicationLabel: body.msg}))
        }*/
    }
    
}
const prepareFetchPublication = (): PublicationActions => {
    return {
        type: 'PrepareFetchPublication'
    }
}
const createPublication = (createPublicationData: CreatePublicationPayload): PublicationActions  => {
    return {
        type: 'CreatePublication',
        payload: createPublicationData
    }    
}
const updatePublication = (): PublicationActions => {
    return {
        type: 'UpdatePublication'
    }
}
const fetchPublicationFailed = (createPublicationFailedData: CreatePublicationFailedPayload): PublicationActions => {
    return {
        type: 'FetchPublicationFailed',
        payload: createPublicationFailedData
    }
}
export const clearPublicationError = (): PublicationActions  => {
    return {
        type: 'ClearPublicationError'
    }    
}

//METODO UNICO PARA LIMPIAR ANIOS AL MODIFICAR LOS FILTROS
export const cleanAnios = (): PublicationActions => {
    return {
        type: 'CleanAnios'
    }
}

//ACTUALIZANDO OPCIONALES DE LA PUBLICACION
type startUpdateParameter = { state: PublicationsReducerType, dispatch: React.Dispatch<PublicationActions> };
export const startUpdateOptionals = async (startUpdateData: startUpdateParameter) => {
 
    const { state, dispatch } = startUpdateData;

    if ( !state.puplicationEdit ) {

    dispatch(prepareFetchOptionals());

    const body = await fetchToken(`publicaciones/opcionales`, { 
        publicacionId: state.publicationId,
        version: state.version,
        motor: state.motor,
        transmision: state.transmision,
        anioInicial: state.anioInicial,
        anioFinal: state.anioFinal,
    }, 'PUT');

    if (body.ok){
        dispatch(updateOptionals());
    } else {
        dispatch(fetchOptionalsFailed({ publicationLabel: body.msg}))
    }
} else {
    dispatch(updateOptionals());
}
    
}
const prepareFetchOptionals = (): PublicationActions => {
    return {
        type: 'PrepareFetchOptionals'
    }
}
const updateOptionals = (): PublicationActions  => {
    return {
        type: 'UpdateOptionals'
    }    
}
const fetchOptionalsFailed = (updateOptionalsFailedData: UpdateOptionalsFailedPayload): PublicationActions => {
    return {
        type: 'FetchOptionalsFailed',
        payload: updateOptionalsFailedData
    }
}
export const clearOptionalsError = (): PublicationActions  => {
    return {
        type: 'ClearOptionalsError'
    }    
}

//ACTUALIZANDO PORTADA DE LA PUBLICACION
type startUpdateFrontParameter = { state: PublicationsReducerType, dispatch: React.Dispatch<PublicationActions> };
export const startUpdateFront = async (startUpdateData: startUpdateFrontParameter) => { 
   
    const { state, dispatch } = startUpdateData;

    if (!state.puplicationEdit) {
        dispatch(prepareFetchFront());

        let parameters = {};
        if (state.publicationType === publicationTypes.Refaccion ) {
            parameters = { 
                publicacionId: state.publicationId,
                descripcion: state.descripcion,
                precio: state.precio,
            };
        }
        if (state.publicationType === publicationTypes.Auto ) {
            parameters = { 
                publicacionId: state.publicationId,
                descripcion: state.descripcion
            };
        }

        console.log(parameters);
        const body = await fetchToken(`publicaciones/portada`, parameters , 'PUT');
            if (body.ok) {
                console.log(body)
                dispatch(updateFront());
            } else {
                dispatch(fetchFrontFailed({ publicationLabel: body.msg}))
            }
        } else {
            dispatch(updateFront());
    }
    
}
const prepareFetchFront = (): PublicationActions => {
    return {
        type: 'PrepareFetchFront'
    }
}
const updateFront = (): PublicationActions  => {
    return {
        type: 'UpdateFront'
    }    
}
const fetchFrontFailed = (updateFrontFailedData: UpdateFrontFailedPayload): PublicationActions => {
    return {
        type: 'FetchFrontFailed',
        payload: updateFrontFailedData
    }
}
export const clearFrontError = (): PublicationActions  => {
    return {
        type: 'ClearFrontError'
    }    
}

//ACTUALIZANDO PUBLICACION DE LA PUBLICACION
type startUpdateEndParameter = { state: PublicationsReducerType, dispatch: React.Dispatch<PublicationActions> };
export const startUpdateEnd = async (startUpdateData: startUpdateEndParameter) => {

    const { state, dispatch } = startUpdateData;

    dispatch(prepareFetchEnd());

    const body = await fetchToken(`publicaciones/publicar`, { 
        publicacionId: state.publicationId
    }, 'PUT');

    console.log(body);

    if (body.ok){
        dispatch(updateEnd());
    } else {
        dispatch(fetchEndFailed({ publicationLabel: body.msg}))
    }
    
}
const prepareFetchEnd = (): PublicationActions => {
    return {
        type: 'PrepareFetchEnd'
    }
}
const updateEnd = (): PublicationActions  => {
    return {
        type: 'UpdateEnd'
    }    
}
const fetchEndFailed = (updateEndFailedData: UpdateEndFailedPayload): PublicationActions => {
    return {
        type: 'FetchEndFailed',
        payload: updateEndFailedData
    }
}
export const clearEndError = (): PublicationActions  => {
    return {
        type: 'ClearEndError'
    }    
}


export const updateOptionalSet = (updateOptionalPayload: UpdateOptionalSetPayload): PublicationActions => {
    return {
        type: 'UpdateOptionalSet',
        payload: updateOptionalPayload
    }
}

type startEditPublicationParameter = { state: PublicationsReducerType, dispatch: React.Dispatch<PublicationActions>,
                                       idMarca: undefined | string, idModelo: undefined | string, idAnio: number | null  };
export const startEditDataSavePublicationCarro = async (editPublicationData: startEditPublicationParameter) => {

    const { state, dispatch, idMarca, idModelo, idAnio } = editPublicationData;

    dispatch(prepareFetchEnd());

    const body = await fetchToken(`publicaciones/editPublication`, { 
        publicacionId: state.publicationId,
        marcaId: idMarca,
        modeloId: idModelo,
        anio: idAnio,
        version: state.version,
        motor: state.motor,
        transmision: state.transmision,
        anioInicial: state.anioInicial,
        anioFinal: state.anioFinal,
        descripcion: state.descripcion,
    }, 'PUT');

    if (body.ok){
        dispatch(updateEnd());
    } else {
        dispatch(fetchEndFailed({ publicationLabel: body.msg}))
    }
}
export const startEditDataSavePublicationRefaccion = async (editPublicationData: startEditPublicationParameter) => {

    const { state, dispatch, idMarca, idModelo, idAnio } = editPublicationData;

    dispatch(prepareFetchEnd());

    const body = await fetchToken(`publicaciones/editPublication`, { 
        publicacionId: state.publicationId,
        marcaId: idMarca,
        modeloId: idModelo,
        anio: idAnio,
        version: state.version,
        motor: state.motor,
        transmision: state.transmision,
        anioInicial: state.anioInicial,
        anioFinal: state.anioFinal,
        descripcion: state.descripcion,
        precio: state.precio
    }, 'PUT');

    if (body.ok){
        dispatch(updateEnd());
    } else {
        dispatch(fetchEndFailed({ publicationLabel: body.msg}))
    }
}

/*type startEditPublicationDataParameter = { /*state: PublicationsReducerType, dispatch: React.Dispatch<PublicationActions>,
                                           idPublicacion: string | null | undefined  };
export const startEditDataPublication = async (editPublicationData: startEditPublicationDataParameter) => {

    const { dispatch, idPublicacion } = editPublicationData;

    dispatch(prepareFetchEnd());

    const body = await fetchToken(`publicaciones/startEdit`, {
        publicacionId: idPublicacion
    }, 'PUT');

    if (body.ok){
        dispatch(updateEnd());
    } else {
        dispatch(fetchEndFailed({ publicationLabel: body.msg}))
    }
}*/

type startCancelEditPublicationDataParameter = { /*state: PublicationsReducerType,*/ dispatch: React.Dispatch<PublicationActions>,
                                                 idPublicacion: string | null | undefined  };
export const startCancelDataEditPublication = async (cancelEditPublicationData: startCancelEditPublicationDataParameter) => {

    const { dispatch, idPublicacion } = cancelEditPublicationData;

    dispatch(prepareFetchEnd());

    const body = await fetchToken(`publicaciones/cancelEdit`, {
        publicacionId: idPublicacion
    }, 'PUT');

    //console.log(body);

    if (body.ok){
        
    } else {
        dispatch(fetchEndFailed({ publicationLabel: body.msg}))
    }
}

//ELIMINANDO PUBLICACION 
type startDeletePublicationParameter = { state: PublicationsReducerType };
export const startDeletePublication = async (startDeletePublicationData: startDeletePublicationParameter) => {

    const { state } = startDeletePublicationData;

    const body = await fetchToken(`publicaciones`, { 
        publicacionId: state.publicationId
    }, 'DELETE');

    if (!body.ok){
        //TODO
    } 
}