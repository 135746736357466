import { Socket } from 'socket.io-client';
import { createContext } from 'react';

//definimos las variables globales del state
type socketContextType = {
    socket: Socket | undefined,
    online: boolean,
    desconectarSocket: any,
    enviarSocket: any
}

const ContextDefaultValues: socketContextType = {
    socket: undefined,
    online: false,
    desconectarSocket: undefined,
    enviarSocket: undefined
};

//creamos el Context
//use context en un higher order component es decir es un componente que contiene componentes hijos
export const SocketContext = createContext<socketContextType>(ContextDefaultValues);