import "./styles/sales.scss"
import { useContext } from 'react';
import { Typography, Dialog, Box, IconButton } from '@mui/material';

import SVG from 'react-inlinesvg';

import { SalesContext } from './context/SalesContext';
import { closeModalNewPub, showModalCar, Publication, showModalPub, Car } from './reducers/SalesReducer';

export const NewPublicationModal = () => {

    //LEER EL REDUCER LOCAL
    const { salesReducerState, dispatchSales } = useContext(SalesContext); 
    const { newpubmodal } = salesReducerState;
    
    const handleNewPub = () => {
      // dispatchSales(closeModalNewPub())
      const car : Car = { id: 'a' };
      dispatchSales(showModalPub(car));
    }
    const handleNewCar = () => {
      // dispatchSales(closeModalNewPub())
      const pub : Publication = { id: 'a' }
      dispatchSales(showModalCar(pub));
    }

    return (
      <Dialog
        open={newpubmodal}
        onClose={()=>{dispatchSales(closeModalNewPub())}}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              maxWidth: "550px",
            },
          },
        }}
      >
        <Box sx={{ backgroundColor: "black", color: "white", p: 3, display: "flex" }}>
          <Typography variant='h5'>Publicar</Typography>
          <IconButton
            aria-label="close"
            onClick={()=>{dispatchSales(closeModalNewPub())}}
            sx={{
              position: 'absolute',
              right: 18
            }}
          >
            <SVG src={process.env.REACT_APP_ICONSERVER+'dz-error'} width={20} fill="white" />
          </IconButton>
        </Box>

        <div className="new_publication_container">
          <Box sx={{ width: 1000}}>
            <Box sx={{ width: '100%' }}>
              <div className="new_publication_format">

                <div className="new_publication_carro" onClick={ handleNewCar }>
                  <p>PUBLICACIÓN DE</p>
                  <div className="new_publication_carro_icon_container">
                    <SVG src={process.env.REACT_APP_ICONSERVER+'dz-car-line'} className="new_publication_carro_icon"/>
                  </div>
                  <p>AUTO X PARTES</p>
                </div>

                <div className="new_publication_refaccion" onClick={ handleNewPub }>
                  <p>PUBLICACIÓN DE</p>
                  <div className="new_publication_refaccion_icon">
                    <SVG src={process.env.REACT_APP_ICONSERVER+'dz-refection-line'} className="new_publicatio_icons_style"/>
                  </div>
                  <p>REFACCIÓN</p>
                </div>
              </div>
            </Box>
          </Box>
        </div>
      </Dialog>
    );
}