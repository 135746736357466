
import "./styles/registerSaleModal.scss";
import { AppBar, Box, Button, CardMedia, Checkbox, Dialog, IconButton, InputAdornment, Link, Step, StepContent, StepLabel, Stepper, styled, TextField, Toolbar, Typography } from "@mui/material";
import { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { cleanError, onChange, setError, startUploadPhoto, startUploadPhotoPdf,
         closeModalRegister,
         startUpdateEstablishment, clearEstablishmentError,
         startUpdateRfc, clearRfcError,
         startUpdateContact, clearContactError, clearActivateSellerError, startUpdateActivateSeller, closeCleanRegister, abrirTerminosCondicionesSaleModal, abrirAvisoPrivacidadSaleModal,
       } from "../reducers/registerReducer";

import { AppDispatch, RootState } from "../store/store";
import { PhotoCamera } from "@mui/icons-material";
import validator from 'validator';
import { startChangePackage } from '../reducers/businessReducer';
import { startShowYesNoDialog } from "../reducers/dialogsReducer";
import { TerminosCondicionesSale } from "./TerminosCondicionesSale";
import { AvisoPrivacidadSale } from "./AvisoPrivacidadSale";
import { startPdfLoadSuccess } from '../reducers/registerReducer';

import { Document, Page, pdfjs } from 'react-pdf';
// pdfjs.GlobalWorkerOptions.workerSrc = "pdfjs-dist/build/pdf.worker.entry";
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const steps = [
  { label: 'Crear perfil de vendedor', description: 'Para que puedas vender, necesitamos crear tu perfil de vendedor en nuestra plataforma para que puedas publicar en AutosXPartes.com'},
  { label: 'Nombre y logo de tu negocio.', description: 'Necesitamos que ingreses los siguientes datos de tu negocio.' },
  { label: 'INE y Constancia de Situación Fiscal', description: 'Para más seguridad requerimos comprobar tu información con los siguientes documentos.' },
  { label: 'Atención a compradores', description: 'Registra quien es la persona encargada del negocio y agrega dos números de contacto.' },
  { label: 'Por último', description: 'Para activar tu perfil de vendedor, debes aceptar los términos y condiciones y luego da clic en el botón.' }
];

const PhoneInput = styled( TextField ) `input[type='number']::-webkit-inner-spin-button, input[type='number']::-webkit-outer-spin-button { display: none; }`;

export const RegisterSaleModal = () => {

    const [activeStep, setActiveStep] = useState(0);

    //LEER INFORMACION DE REDUX
    const storeRegister = (state: RootState ) => state.register;
    const { showModal, packageId,
            establecimiento, establecimientoError,
            fportada, fportadaError,
            flogo, flogoError,
            fine, /*finepages,*/ finepage, fineError,
            rfc, rfcError, 
            frfc, /*frfcpages,*/ frfcpage, frfcError,
            encargado, encargadoError,
            contacto, contactoError,
            contacto2, contacto2Error,
            registerEstablishment, registerEstablishmentSending, registerEstablishmentError,
            registerRfc, registerRfcSending, registerRfcError,
            registerContact, registerContactSending, registerContactError,
            registerActivateSeller, registerActivateSellerError
          } = useSelector(storeRegister);
    const dispatchRedux = useDispatch<AppDispatch>();

    const fotoPortadaRef = useRef<HTMLInputElement>(null);
    const fotoLogoRef = useRef<HTMLInputElement>(null);
    const fotoIneRef = useRef<HTMLInputElement>(null);
    const fotoRfcRef = useRef<HTMLInputElement>(null);

    //AL ABRIR EL MODAL
    useEffect(() => {
        if (showModal) {
            //console.log('Limpiando modal2');
            setActiveStep(0);
        } 
    }, [showModal])

    //VALIDACIONES BOTON SIGUIENTE
    const handleNext = async (index: number) => {
      let error = false;
      switch (index) {
          case 0:
              setActiveStep((prevActiveStep) => prevActiveStep + 1);
          break;
          case 1:
              if (!establecimiento) {
                  dispatchRedux(setError({ errorField: 'establecimientoError', label: 'Coloque el nombre del establecimiento' }));
                  error=true;
              } else {
                  if (!validator.isLength(establecimiento,{min: 4})) {
                      dispatchRedux(setError({ errorField: 'establecimientoError', label: 'Requerimos más valores' }));
                      error=true;
                  } else {
                      dispatchRedux(cleanError({ errorField: 'establecimientoError' }))
                  }
              }
              if (!fportada){
                  dispatchRedux(setError({ errorField: 'fportadaError', label: 'Seleccione una foto del establecimiento' }));
                  error=true;
              }
              if (!flogo){
                  dispatchRedux(setError({ errorField: 'flogoError', label: 'Seleccione un logo' }));
                  error=true;
              }
              if (!error) {
                  //si no hay error creamos una publicacion nueva
                  dispatchRedux<any>(startUpdateEstablishment());
              } else {
                  //si hay error limpiamos posibles errores de fetch para que solo aparezcan los errores de validacion
                  dispatchRedux<any>(clearEstablishmentError());
              }
          break;
          case 2:
              if (!rfc) {
                  dispatchRedux(setError({ errorField: 'rfcError', label:'Debe indicar su RFC' }));
                  error=true;
              } else {
                  if (!validator.isLength(rfc,{min: 12, max: 13})) {
                      dispatchRedux(setError({ errorField: 'rfcError', label:'El RFC requiere 12 o 13 caracteres' }));
                      error=true;
                  } else {
                      dispatchRedux(cleanError({ errorField: 'rfcError' }))
                  }
              }
              if (!fine){
                  dispatchRedux(setError({ errorField: 'fineError', label: 'Seleccione su foto del INE' }));
                  error=true;
              }
              if (!frfc){
                  dispatchRedux(setError({ errorField: 'frfcError', label: 'Seleccione su archivo' }));
                  error=true;
              }
              if (!error) {
                  //si no hay error creamos una publicacion nueva
                  dispatchRedux<any>(startUpdateRfc());
              } else {
                  //si hay error limpiamos posibles errores de fetch para que solo aparezcan los errores de validacion
                  dispatchRedux<any>(clearRfcError());
              }
          break;
          case 3:
              if (!encargado) {
                  dispatchRedux(setError({ errorField: 'encargadoError', label:'Coloque el nombre del encargado' }));
                  error=true;
              } else {
                  if (!validator.isLength(encargado,{min: 4})) {
                      dispatchRedux(setError({ errorField: 'encargadoError', label:'Requerimos más valores' }));
                      error=true;
                  } else { 
                      dispatchRedux(cleanError({ errorField: 'encargadoError' }))
                  }
              }
              if (!contacto) {
                  dispatchRedux(setError({ errorField: 'contactoError', label:'Agregue un número de contacto' }));
                  error=true;
              } else {
                  if (!validator.isLength(contacto,{min: 10, max:10})) {
                    dispatchRedux(setError({ errorField: 'contactoError', label: 'El número telefónico es incorrecto' }));
                    error=true;
                  } else {
                      dispatchRedux(cleanError({ errorField: 'contactoError' }))
                  }
              }
              if (!contacto2) {
                  dispatchRedux(setError({ errorField: 'contacto2Error', label:'Agregue otro número de contacto' }));
                  error=true;
              } else {
                  if (!validator.isLength(contacto2,{min: 10, max:10})) {
                    dispatchRedux(setError({ errorField: 'contacto2Error', label: 'El número telefónico es incorrecto' }));
                    error=true;
                  } else {
                      dispatchRedux(cleanError({ errorField: 'contacto2Error' }))
                  }
              }
              if (!error) {
                  //si no hay error creamos una publicacion nueva
                  dispatchRedux<any>(startUpdateContact());
              } else {
                  //si hay error limpiamos posibles errores de fetch para que solo aparezcan los errores de validacion
                  dispatchRedux<any>(clearContactError());
              }
          break;
          case 4:
              await dispatchRedux<any>(startUpdateActivateSeller());
                    dispatchRedux<any>(clearActivateSellerError());
          break;
        }
    };

    //VALIDANDO RESPUESTAS DEL REDUCER PARA AVANZAR PESTAÑA DEL STEPPER
    //CREACION DEl ESTABLECIMIENTO
    useEffect(() => {
      // console.log('Validando si se creo la publicacion');
      if (registerEstablishment) {
          // console.log('Avanzando si la publicacion se creo correctamente');
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } 
    }, [registerEstablishment])
    //CREACION DEl RFC
    useEffect(() => {
      // console.log('Validando si se creo la publicacion');
      if (registerRfc) {
          // console.log('Avanzando si la publicacion se creo correctamente');
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } 
    }, [registerRfc])
    //CREACION DEl NUM. DE CONTACTO
    useEffect(() => {
      // console.log('Validando si se creo la publicacion');
      if (registerContact) {
          // console.log('Avanzando si la publicacion se creo correctamente');
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } 
    }, [registerContact, dispatchRedux])
    //ACTIVAR VENDEDOR
    useEffect(() => {
      // console.log('Validando si se creo la publicacion');
      if (registerActivateSeller) {
          dispatchRedux<any>(startChangePackage({packageId: packageId}));
          // console.log('Avanzando si la publicacion se creo correctamente');
          dispatchRedux(closeModalRegister())
      } 
    }, [registerActivateSeller, packageId, dispatchRedux])

    //VALIDACIONES BOTON ATRAS
    const handleBack = (index: number) => {
        //console.log('Back'+index);
        switch (index) {
            case 1:
                //haciendo limpieza si es necesario
            break;
            case 2:
                //haciendo limpieza si es necesario
            break;
            case 3:
                //haciendo limpieza si es necesario
            break;
            case 4:
                //haciendo limpieza si es necesario
            break;
        }
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    
    // HABILITAR EL BOTON DE "TERMINAR" AL ACEPTAR LOS TERMINOS Y CONDICIONES
    const [disabledButton, setDisabledButton] = useState(true);
    const [check, setCheck] = useState(false);
    const checking = () => {
        setCheck(!check);
        setDisabledButton(!disabledButton);
    }

    //VERIFICAR SI SE CREO UNA PUBLICACION ANTES DE CERRAR EL MODAL
    const storeDialogs = (state: RootState ) => state.dialogs;
    const { yesnoSelection } = useSelector(storeDialogs);

    const handleBeforeClose = () => {
        dispatchRedux<any>(startShowYesNoDialog({title: '!Registro Incompleto!', content: '¿Deseas cancelar el registro?'}));
    }

    const handleAfterResponse = useCallback( async () => {
      if (yesnoSelection) {
        //console.log('Eliminando informacion');
        //dispatchPublications(startDeletePublication({ state: publicationsReducerState }));
        dispatchRedux(closeModalRegister());
        dispatchRedux<any>(closeCleanRegister());
      } 
    },[yesnoSelection, dispatchRedux]);  

    useEffect(() => {
      if (showModal && yesnoSelection !== null) {
        handleAfterResponse();
      }
    }, [yesnoSelection,showModal,handleAfterResponse])

    return (
      <Dialog
        open={showModal}
        onClose={()=>{ handleBeforeClose() }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              maxWidth: "1000px",
            },
          },
        }}
      >
        <AppBar position="relative" style={{ background: '#000000' }}>
          <Toolbar>
            <div className="new_publication_spare_title">
              Registro de Vendedor
            </div>
            <Button autoFocus color="inherit" onClick={()=>{ handleBeforeClose() }}> Cancelar </Button>
          </Toolbar>
        </AppBar>

        <div className="new_car_modal_content">
          <Box sx={{ width: 1000}}>
            <Stepper nonLinear activeStep={activeStep} orientation='vertical'>
              {steps.map((step, index) => (
                <Step key={step.label}>
                  {/* SUBTITULOS */}
                  <StepLabel>
                    <Typography variant="h6">
                      {step.label}
                    </Typography>
                  </StepLabel>
                  <StepContent>                                    
                    <h4>{step.description}</h4> 
                    <Box sx={{ mt: 2, width: '100%' }}>
                      {/* COMPONENTES */} {                                            
                        function() {                                     
                          switch (index) {
                            case 0 : return <>
                              {/* VENTANA DE INICIO */}
                              {/* <Typography variant="h6">Para que puedas vender, necesitamos crear tu perfil de vendedor en nuestra plataforma para que puedas publicar en AutosXPartes.com</Typography> */}
                            </>
                            case 1 : return <>
                              {/* INPUT DE ESTABLECIMIENTO */}
                              <TextField
                                id="establecimiento" value={ establecimiento } error={ establecimientoError ? true : false }
                                label={establecimientoError? establecimientoError : 'Nombre del establecimiento'} sx={{ mb: 1 }} fullWidth
                                onChange={ ({target}) => dispatchRedux( onChange({ field: 'establecimiento', value: target.value })) }
                                variant="outlined" margin="dense"
                              />
                              {/* INPUT DE IMAGEN DE PORTADA */}
                              <div className="register_sale_portada">
                                <Typography sx={{ mb: 1, mt: 1 }}>Foto de Establecimiento y Logo</Typography>
                                  <input hidden accept="image/*" type="file"
                                    ref={ fotoPortadaRef }
                                    onChange={ ({target}) => { if (target.files !== null){ if(target.files.length > 0) { 
                                    dispatchRedux<any>(startUploadPhoto({ field: 'fportada', errorField: 'fportadaError', file: target.files[0] })) } } }}
                                  />
                                {
                                  fportadaError 
                                  ? <Box sx={{ height: 250, border: 1, borderRadius: 1, borderColor: "error.main", color: "error.main", display: "flex", alignItems: "center", cursor: "pointer"}}
                                    onClick={()=>{ fotoPortadaRef.current?.click() }}
                                    className="register_sale_image_box"
                                  >
                                    { fportadaError }
                                  </Box>
                                  : fportada ?
                                    <div>
                                      <Box sx={{ cursor: "pointer"}}>
                                        <CardMedia component="img" height="250px"
                                          src={process.env.REACT_APP_USERSERVER+fportada}
                                          // src={fportada}
                                          onClick={()=>{ fotoPortadaRef.current?.click() }}
                                        />
                                      </Box>
                                    </div>
                                    : <Box sx={{ height: 250, border: '2px dashed grey', borderRadius: 1, borderColor: "grey.400", color: "grey.600", display: "flex", alignItems: "center", cursor:"pointer"}}
                                      onClick={()=>{ fotoPortadaRef.current?.click() }}
                                      className="register_sale_image_box"
                                    >
                                      Imagen del Establecimiento
                                    </Box>
                                }
                                {/* INPUT DE IMAGEN DEL LOGO */}
                                <div className="register_sale_logo">
                                  <input hidden accept="image/*" type="file"
                                    ref={ fotoLogoRef }
                                    onChange={ ({target}) => { if (target.files !== null){ if(target.files.length > 0) { 
                                    dispatchRedux<any>(startUploadPhoto({ field: 'flogo', errorField: 'flogoError', file: target.files[0] })) } } }}
                                  />
                                  {
                                    flogoError 
                                    ? <Box sx={{ height: 200, border: 1, borderRadius: 1, borderColor: "error.main", color: "error.main", display: "flex", alignItems: "center", justifyContent: "center", cursor:"pointer", background: "white"}}
                                      onClick={()=>{ fotoLogoRef.current?.click() }}
                                      className="register_sale_logo_box"
                                    >
                                      { flogoError }
                                    </Box>
                                    : flogo ?
                                      <div className="new_publication_spare_image">
                                        <Box sx={{ cursor:"pointer" }}>
                                          <CardMedia component="img" height="200px" sx={{ maxWidth: "250px" }}
                                            src={process.env.REACT_APP_USERSERVER+flogo}
                                            // src={flogo}
                                            onClick={()=>{ fotoLogoRef.current?.click() }}
                                          />
                                        </Box>
                                      </div>
                                      : <Box sx={{ height: 200, /*width: 250,*/ border: '2px dashed grey', borderRadius: 1, borderColor: "grey.400", color: "grey.600", display: "flex", alignItems: "center", justifyContent: "center", cursor:"pointer", background: "white"  }}
                                        onClick={()=>{ fotoLogoRef.current?.click() }}
                                        className="register_sale_logo_box"
                                      >
                                        Logo
                                      </Box>
                                  }
                                </div>
                              </div>
                              {registerEstablishmentError && <Typography variant="caption" color="error">{ registerEstablishmentError }</Typography> }
                            </>
                            case 2 : return <>
                              <div className="register_sale_ine_rf_container">
                                <div className="register_sale_rfc">
                                  <TextField
                                    id="rfc" value={ rfc } error={ rfcError ? true : false }
                                    label={rfcError ? rfcError : 'RFC'} sx={{ mb: 1 }} fullWidth
                                    onChange={ ({target}) => dispatchRedux( onChange({ field: 'rfc', value: target.value })) }
                                    variant="outlined" margin="dense"
                                    inputProps={{ style: { textTransform: "uppercase" } }}
                                  />
                                  {registerRfcError && <Typography variant="caption" color="error">{ registerRfcError }</Typography> }
                                </div>
                                {/* INPUT DE IMAGEN DEL INE */}
                                <div className="register_sale_fotoIne">
                                  Foto del INE
                                  <IconButton color="primary" aria-label="upload picture" component="label">
                                    <input hidden accept="image/*,.pdf" type="file"
                                      ref={ fotoIneRef }
                                      onChange={ ({target}) => { if (target.files !== null){ if(target.files.length > 0) { 
                                      dispatchRedux<any>(startUploadPhotoPdf({ field: 'fine', errorField: 'fineError', file: target.files[0] })) } } }}
                                    />
                                    <PhotoCamera />
                                  </IconButton>
                                  {
                                    fineError 
                                    ? <Box sx={{ height: 250, border: 1, borderRadius: 1, borderColor: "error.main", color: "error.main", display: "flex", alignItems: "center", justifyContent: "center", cursor:"pointer"}}
                                      onClick={()=>{ fotoIneRef.current?.click() }}
                                    >
                                      { fineError }
                                    </Box>
                                    : fine ?
                                      <div className="new_publication_spare_image">
                                        <Box sx={{ cursor:"pointer" }}>
                                          {fine.split('.').pop()==='pdf' 
                                          ?  <div onClick={()=>{ fotoIneRef.current?.click() }} style={{'height': '250px', 'width': '300px', overflow: "hidden" }}>                                        
                                              <Document file={ process.env.REACT_APP_USERSERVER+fine  }
                                                onLoadSuccess={ ( { numPages } :any ) => { dispatchRedux<any>(startPdfLoadSuccess({ field: 'finepages', pages: numPages })) } }>
                                                <Page pageNumber={finepage} width={300} />                                         
                                              </Document>
                                            </div>
                                          :  <CardMedia component="img" height="250px"
                                                src={process.env.REACT_APP_USERSERVER+fine}
                                                // src={fine}
                                                onClick={()=>{ fotoIneRef.current?.click() }}
                                              />
                                        }                      
                                        </Box>
                                      </div>
                                      : <Box sx={{ height: 250, border: '2px dashed grey', borderRadius: 1, borderColor: "grey.400", color: "grey.600", display: "flex", alignItems: "center", justifyContent: "center", cursor:"pointer"  }}
                                        onClick={()=>{ fotoIneRef.current?.click() }}
                                      >
                                        Cargar Imagen
                                      </Box>
                                  }
                                </div>
                                {/* INPUT DE IMAGEN DEL RFC */}
                                <div className="register_sale_fotoRfc">
                                  Constancia de Situación Fiscal
                                  <IconButton color="primary" aria-label="upload picture" component="label">
                                    <input hidden accept="image/*,.pdf" type="file"
                                      ref={ fotoRfcRef }
                                      onChange={ ({target}) => { if (target.files !== null){ if(target.files.length > 0) { 
                                      dispatchRedux<any>(startUploadPhotoPdf({ field: 'frfc', errorField: 'frfcError', file: target.files[0] })) } } }}
                                    />
                                    <PhotoCamera />
                                  </IconButton>
                                  {
                                    frfcError 
                                    ? <Box sx={{ height: 250, border: 1, borderRadius: 1, borderColor: "error.main", color: "error.main", display: "flex", alignItems: "center", justifyContent: "center", cursor:"pointer"}}
                                      onClick={()=>{ fotoRfcRef.current?.click() }}
                                    >
                                      { frfcError }
                                    </Box>
                                    : frfc ?
                                      <div className="new_publication_spare_image">                                        
                                        <Box sx={{ cursor:"pointer" }}>
                                          {frfc.split('.').pop()==='pdf' 
                                            ?  <div onClick={()=>{ fotoRfcRef.current?.click() }} style={{'height': '250px', 'width': '300px', overflow: "hidden" }}>                                        
                                                <Document file={ process.env.REACT_APP_USERSERVER+frfc }
                                                  onLoadSuccess={ ( { numPages } :any ) => { dispatchRedux<any>(startPdfLoadSuccess({ field: 'frfcpages', pages: numPages })) } }>
                                                  <Page pageNumber={frfcpage} width={300} />                                         
                                                </Document>
                                              </div>
                                            : <CardMedia component="img" height="250px"
                                                src={process.env.REACT_APP_USERSERVER+frfc}
                                                // src={frfc}
                                                onClick={()=>{ fotoRfcRef.current?.click() }}
                                              />
                                          }                      
                                        </Box>
                                      </div>
                                      : <Box sx={{ height: 250, border: '2px dashed grey', borderRadius: 1, borderColor: "grey.400", color: "grey.600", display: "flex", alignItems: "center", justifyContent: "center", cursor:"pointer"  }}
                                        onClick={()=>{ fotoRfcRef.current?.click() }}
                                      >
                                        Cargar archivo
                                      </Box>
                                  }
                                </div>
                              </div>
                            </>
                            case 3 : return <>
                              <TextField
                                id="encargado" value={ encargado } error={ encargadoError ? true : false }
                                label={encargadoError ? encargadoError : 'Nombre del Encargado'} sx={{ mb: 1 }} fullWidth
                                onChange={ ({target}) => dispatchRedux( onChange({ field: 'encargado', value: target.value })) }
                                variant="outlined" margin="dense"
                              />
                              <PhoneInput
                                id="contacto" value={ contacto } error={ contactoError ? true : false }
                                label={contactoError ? contactoError : 'Primer número de contacto'} sx={{ mb: 1 }} fullWidth
                                onChange={ ({target}) => dispatchRedux( onChange({ field: 'contacto', value: target.value })) }
                                variant="outlined" margin="dense" type="number"
                                InputProps={{
                                  startAdornment: <InputAdornment position="start">MX (+52)</InputAdornment>
                               }}
                              />
                              <PhoneInput
                                id="contacto2" value={ contacto2 } error={ contacto2Error ? true : false }
                                label={contacto2Error ? contacto2Error : 'Segundo número de contacto'} sx={{ mb: 1 }} fullWidth
                                onChange={ ({target}) => dispatchRedux( onChange({ field: 'contacto2', value: target.value })) }
                                variant="outlined" margin="dense" type="number"
                                InputProps={{
                                  startAdornment: <InputAdornment position="start">MX (+52)</InputAdornment>
                               }}
                              />
                              {registerContactError && <Typography variant="caption" color="error">{ registerContactError }</Typography> }
                            </>
                            case 4 : return <>
                              <Box sx={{ marginTop: "15px", marginBottom: "10px", display: "flex", alignItems: "center" }}>
                                <Checkbox onClick={checking} color="warning" />
                                <p>Acepto los <Link href="#" underline="hover" onClick={()=>(dispatchRedux(abrirTerminosCondicionesSaleModal()))}>Términos y Condiciones</Link> y el <Link href="#" underline="hover" onClick={()=>(dispatchRedux(abrirAvisoPrivacidadSaleModal()))}>Aviso de Privacidad</Link> de  Autos X Partes</p>
                              </Box>
                              {registerActivateSellerError && <Typography variant="caption" color="error">{ registerActivateSellerError }</Typography> }  
                            </>
                          }
                        }()                                                
                      }
                      {/* CONTROLES */}
                      <div>
                        { function() {
                          switch (index) {
                            case 0: return <>
                                <Button color="warning" variant="contained" onClick={ () => { handleNext(index) }} sx={{ mt: 0 }}>Empezar</Button>
                            </>
                            case 1: return <>
                              { registerEstablishmentSending
                                ? <Button disabled variant="contained" sx={{ mt: 2, mr: 1 }}>Actualizando...</Button>
                                : <Button color="warning" variant="contained" onClick={ () => { handleNext(index) }} sx={{ mt: 2, mr: 1 }}>Siguiente</Button>
                              }
                            </>
                            case 2: return <>
                              { registerRfcSending
                                ? <Button disabled variant="contained" sx={{ mt: 2, mr: 1 }}>Actualizando...</Button>
                                : <Button color="warning" variant="contained" onClick={ () => { handleNext(index) }} sx={{ mt: 2, mr: 1 }}>Siguiente</Button>
                              }
                            </>
                            case 3: return <>
                              { registerContactSending
                                ? <Button disabled variant="contained" sx={{ mt: 2, mr: 1 }}>Actualizando...</Button>
                                : <Button color="warning" variant="contained" onClick={ () => { handleNext(index) }} sx={{ mt: 2, mr: 1 }}>Siguiente</Button>
                              }
                            </>                                                   
                            case 4: return <>
                                <Button disabled={ disabledButton } color="warning" variant="contained" onClick={ () => { handleNext(index) }} sx={{ mt: 2, mr: 1 }}>Registrarse</Button>  
                            </>                                                   
                            }
                          }()                                                                                        
                        }
                        { function() { 
                          switch (index) {
                            case 0: return null;
                            case 1: return null;
                            case 2: return <Button onClick={ () => { handleBack(index) }} sx={{ mt: 2, mr: 1 }}>Atrás</Button>
                            case 3: return <Button onClick={ () => { handleBack(index) }} sx={{ mt: 2, mr: 1 }}>Atrás</Button>
                            case 4: return null;
                            }
                          }()
                        }
                      </div>
                    </Box>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </Box>

          <TerminosCondicionesSale />
          <AvisoPrivacidadSale />

        </div>    
      </Dialog>
    );
}