import "./styles/respuestas.scss"
import { useContext, useEffect, useState } from 'react';
import { QuestionsContext } from '../../context/QuestionsContext';
import { Box, Button, CardMedia, CircularProgress, Dialog, DialogContent, DialogContentText, IconButton, Pagination, TextField, Typography, useMediaQuery } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import WarningIcon from '@mui/icons-material/Warning';
import SendIcon from '@mui/icons-material/Send';
import SVG from 'react-inlinesvg';
import { RootState } from "../../../../../../store/store";
import { useSelector } from "react-redux";
import { cleanError, clearAnswerError, onChange, openModalEliminarPregunta, openModalReportarPregunta, setError, startAnswerQuestion, startLoadQuestions } from "../../reducers/QuestionsReducer";
import { paginate } from "../../../../../../helpers/arrays";
import { isNumeric } from "../../../../../../helpers/checkValues";
import { useHistory, useParams } from "react-router-dom";
import { EliminarPreguntaModal } from "../misPreguntas/modals/EliminarPreguntaModal";
import validator from 'validator';
import { ReportarPreguntaModal } from "../misPreguntas/modals/ReportarPreguntaModal";

export const Respuestas = () => {

    //TOTAL DE RESULTADOS POR PAGINA
    const resuladosPorPagina = 6;

    // PARAMETRO PARA NAVEGAR HACIA OTRO COMPONENTE (HREF O LINK)
    const history = useHistory();

    const responsive = useMediaQuery('(max-width:400px)');

    //LEER EL REDUCER LOCAL
    const { questionsReducerState, dispatchQuestion } = useContext(QuestionsContext); 
    //const { countQuestions, answers } = questionsReducerState;
    const { questions, countQuestions, respuesta, respuestaError } = questionsReducerState;

    const storeAuth = (state: RootState ) => state.auth;
    const { id, rol } = useSelector(storeAuth);

    const storeBusiness = (state: RootState ) => state.business;
		const { plan } = useSelector(storeBusiness);

    /*useEffect(() => {
        dispatchQuestion(startLoadAnswers({ dispatchLocalQuestions: dispatchQuestion, idUser: id }));
    }, [id, dispatchQuestion]);*/

    useEffect(() => {
      dispatchQuestion(startLoadQuestions({ dispatchLocalQuestions: dispatchQuestion, idUser: id }));
    }, [id, dispatchQuestion]);

    //console.log(questions);

    // SACAMOS LOS PARAMETROS DE LA DIRECCION URL 
    const { questionPage } = useParams<{ questionPage: string }>();

    const respuestasAscendente = questions?.slice().reverse();

    const handlePaginationChange = (event: React.ChangeEvent<unknown>, value: number) => {
      history.push(`/questions/${value}`);
      window.scrollTo(0, 0);
    };

    const handleHrefPublicacion = (publicacion: any) => {
      history.push(`/findUser/publication/${publicacion}`);
    }

    const [modalContestar, setModalContestar] = useState(false);
    const [preguntaId, setPreguntaId] = useState('');
    const [pregunta, setPregunta] = useState('');

    const handleClickOpenModalContestar = (idPregunta: any) => {
      setModalContestar(true);
      setPreguntaId(idPregunta.idPregunta);
      setPregunta(idPregunta.pregunta)
    };
    const handleCloseModalContestar = () => {
      setModalContestar(false);
      setPreguntaId('');
      setPregunta('');
      dispatchQuestion(clearAnswerError());
      dispatchQuestion(startLoadQuestions({ dispatchLocalQuestions: dispatchQuestion, idUser: id }));
    };

    const handleAnswerQuestion = () => {
      let error = false;
      if (!respuesta) {
          dispatchQuestion(setError({ errorField: 'respuestaError', label: 'Escriba su respuesta' }));
          error=true;
      } else {
          if (!validator.isLength(respuesta,{min: 2})) {
              dispatchQuestion(setError({ errorField: 'respuestaError', label: 'Requerimos más valores' }));
              error=true;
          } else {
              dispatchQuestion(cleanError({ errorField: 'respuestaError' }))
          }
      }
      if (!error) {
        dispatchQuestion(startAnswerQuestion({ dispatchLocalQuestions: dispatchQuestion, idQuestion: preguntaId, answer: respuesta }));
        dispatchQuestion(startLoadQuestions({ dispatchLocalQuestions: dispatchQuestion, idUser: id }));
        
        window.scrollTo(0, 0);
        
        handleCloseModalContestar();
      }
    }

    return (
      <div className="respuestas_main">
            
        <div className="preguntas_main">
          <div className="preguntas_titulo">
            Mis respuestas
          </div>
          <div className="preguntas_section">
            {
              rol === "COMPRADOR" ?
              <Box sx={{ m: "50px 0px 50px 0px" }}>
                <div className="plan_format">
                  <div className="plan_icono">
                    <SVG src={process.env.REACT_APP_ICONSERVER+'dz-plan'} height="100%" />
                  </div>
                  <div className="plan_titulo">
                    Plan de ventas
                  </div>
                  <div className="plan_dato1">
                    <p>Actualmente tienes un plan: <b className="plan_dato1_color">{ plan }</b></p>
                  </div>
                  <div></div>
                  <div className="plan_dato2">
                    <p>
                      ¿Quieres publicar algo en AutosXPartes.com?
                    </p>
                  </div>
                  <div className="plan_opcion">
                    <Box sx={{ textAlign: "center" }}>
                      <Button
                        href="/sales/1"
                        variant="contained"
                        sx={{ textTransform: 'none' }}
                      >
                        Cambia tu plan aqui
                      </Button>
                    </Box>
                  </div>
                </div>
              </Box>
              :
              !respuestasAscendente ?
              <Box sx={{ width: "100%", height: "600px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <CircularProgress size={30} sx={{ mr: 1 }} /><Typography variant="h5">Cargando</Typography>
              </Box>
              :
              respuestasAscendente.length === 0 ?
              <Box sx={{ width: "100%", height: (responsive ? "400px" : "600px"), display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Box sx={{ width: 100, height: 80 }}>
                  <SVG src={ process.env.REACT_APP_ICONSERVER+'dz-edit' } width={(responsive ? 60 : 100)} fill="#ff6700"/>
                </Box>
                <Box sx={{ maxWidth: 400 }}>
                  <Typography variant={(responsive ? "h6" : "h5")} color="rgb(0, 93, 199)"><b>Aún no has realizado respuestas</b></Typography>
                </Box>
              </Box>
              :
              paginate(respuestasAscendente , resuladosPorPagina, isNumeric(questionPage) ? Number(questionPage) : 1 ).map( (answer, index) =>                             
                <div className="preguntas_format" key={answer.idPregunta}>

                  <div className="preguntas_format_imagen">
                    <CardMedia
                      component="img"
                      image={ answer.fotoPublicacion ? process.env.REACT_APP_PHOTOSERVER+answer.fotoPublicacion : './assets/sin-foto.png' }
                      alt="carro"
                      className="preguntas_imagen_height"
                    />
                  </div>
                  <div className="preguntas_format_publicacion">
                    {answer.modelo} {answer.anio}
                  </div>
                  <div className="preguntas_format_boton">
                    <Button
                      startIcon={<BorderColorIcon />}
                      color="success" variant="contained"
                      onClick={() => handleHrefPublicacion(answer.idPublicacion)}
                      sx={{ textTransform: 'none',  }}
                    >
                      Ver publicación
                    </Button>
                  </div>
                  <div className="preguntas_format_pregunta">
                  <b>Pregunta:</b> { answer.pregunta }
                  </div>
                  <div className="preguntas_format_respuesta">
                    {
                      answer.respuesta ?
                      <p><b>Respuesta:</b> { answer.respuesta }</p>
                      :
                      <Button
                        startIcon={<BorderColorIcon />} variant="contained"
                        onClick={() => handleClickOpenModalContestar(answer)}
                        sx={{ textTransform: 'none' }}
                      >

                        Responder
                      </Button>
                    }

                    <Box sx={{ ml: 1 }}>
                      { ( function() {
                        switch ( answer.estadoPregunta ) {
                          case 'Finalizada':
                            return <p className="estado_pregunta_color_verde"><b>{ `Contestada` }</b></p>
                          case 'Pendiente':
                            return <p className="estado_pregunta_color_amarillo"><b>{ `${answer.estadoPregunta} de responder` }</b></p>
                          case 'Reportada':
                            return <p className="estado_pregunta_color_rojo"><b>{ `Esta pregunta esta ${answer.estadoPregunta}` }</b></p>
                          }
                        })()
                      }
                    </Box>
                  </div>
                  <div className="preguntas_format_opciones">
                    {
                      answer.estadoPregunta === "Reportada" ?
                      null
                      :
                      <Button
                        color="warning" sx={{ marginRight: 2, textTransform: 'none' }} startIcon={<WarningIcon />}
                        onClick={()=>{dispatchQuestion(openModalReportarPregunta({ idPregunta: answer.idPregunta }))}}
                      >
                        Reportar esta pregunta
                      </Button>
                    }
                    
                    <Button
                      color="error" startIcon={<DeleteIcon />} sx={{ textTransform: 'none' }}
                      onClick={()=>{dispatchQuestion(openModalEliminarPregunta({ idPregunta: answer.idPregunta }))}}
                    >
                      Eliminar
                    </Button>
                  </div>

                </div>
              )
            }
            
            <Box sx={{ display: "flex", justifyContent: "center" }}>  {/* COMPONENTE DE LA PAGINACION DE LAS PUBLICACIONES */}
              <Pagination
                count={countQuestions ? Math.ceil(countQuestions/resuladosPorPagina) : 1} 
                page={ isNumeric(questionPage) ? Number(questionPage) : 1 } 
                defaultPage={1} 
                color="primary" 
                size="large" 
                onChange={ handlePaginationChange }
              />
            </Box>
          </div>

        </div>

        <ReportarPreguntaModal/>
        <EliminarPreguntaModal/>

        <Dialog
          open={modalContestar}
          onClose={handleCloseModalContestar}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                maxWidth: "500px",
              },
            },
          }}
        >
          <div>
            <Box sx={{ backgroundColor: "black", color: "white", p: 3, display: "flex" }}>
              <Typography variant='h5'>Responder pregunta</Typography>
              <IconButton
                aria-label="close"
                onClick={handleCloseModalContestar}
                sx={{
                  position: 'absolute',
                  right: 18
                }}
              >
                <SVG src={process.env.REACT_APP_ICONSERVER+'dz-error'} width={20} fill="white" />
              </IconButton>
            </Box>
            <DialogContent>
              <DialogContentText>
                Recuerda responder de la manera más correcta, sin ofender o escribir malas palabras.
              </DialogContentText>

              <Box sx={{ display: "flex", justifyContent: "center", mb: 1, mt: 1 }}>
                  <Typography variant="h5" color="rgb(0, 93, 199)">{pregunta}</Typography>
              </Box>

              <TextField
                id="pregunta" value={ respuesta } error={ respuestaError ? true : false }
                label={respuestaError ? respuestaError : 'Escribir respuesta'} sx={{ mb: 1 }} fullWidth
                onChange={ ({target}) => dispatchQuestion( onChange({ field: 'respuesta', value: target.value })) }
                variant="outlined" margin="dense" autoComplete='off'
              />

              <Box sx={{ display: "flex", justifyContent: "center", pt: 1 }} >
                <Box>
                  <Button
                    color="warning" sx={{ marginRight: 1 }}
                    size="large" startIcon={<DeleteIcon />}
                    onClick={handleCloseModalContestar}
                  >
                    Cancelar
                  </Button>
                  <Button
                    variant="contained" size="large"
                    sx={{ marginLeft: 1 }} endIcon={<SendIcon />}
                    onClick={() => handleAnswerQuestion()}
                  >
                    Enviar
                  </Button>
                </Box>
              </Box>

            </DialogContent>
          </div>
        </Dialog>
          
      </div>
    )
}
