import { useState, useEffect, useContext } from 'react';
import { Box, Step, StepContent, Stepper, Typography, Button, StepLabel, TextField, InputAdornment, IconButton, Checkbox, Link } from '@mui/material';
import SVG from 'react-inlinesvg';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import { startLogin } from '../../reducers/authReducer';
import { useForm } from '../../hooks/useForm';
import validator from 'validator';
import { startSendEmail, startVerifyEmail, startSendSms, startVerifySms, startCreateUser, abrirTerminosCondicionesModal, abrirAvisoPrivacidadModal, /*startSendWhatsAppCode*/ } from './reducers/RegisterReducer';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import "./styles/register.scss"
import { Visibility, VisibilityOff } from '@mui/icons-material';
import InputMask from 'react-input-mask';
import { TerminosCondiciones } from './TerminosCondiciones';
import { AvisoPrivacidad } from './AvisoPrivacidad';
import { RegisterContext } from './context/RegisterContext';
import { startSendRequestRefaccion } from '../../reducers/filterReducer';

const steps = [
    {
      label: 'Ingresa tu cuenta de correo electrónico.',
      description: 'Para crear una cuenta en AutosXPartes.com es necesario validar tu cuenta de correo electrónico.',
    },
    {
      label: 'Válida tu cuenta de correo electrónico',
      description: 'Introduce el código de verificación recibido en tu cuenta de correo.', 
    },
    {
      label: 'Introduce tus datos de usuario',
      description: 'Ingresa tus datos personales y contraseña.',
    },
    {
      label: 'Válida tú número de teléfono',
      description:'El código de verificación ha sido enviado a tu número de WhatsApp.',
    },
    {
      label: '¡Usuario listo!',
      description: 'Para iniciar sesión, debes aceptar los términos y condiciones y luego da clic en el botón.'
    }
];

export const Register = () => {

    //LEER VARIABLES DE REDUX
    //const storeAuth = (state: RootState ) => state.auth;
    //const { emailSend, emailSendMsg, emailValid, emailValidMsg, smsSend, smsSendMsg, smsValid, smsValidMsg } = useSelector(storeAuth);

    //OBTENER VALORES DEL REDUCER LOCAL
    /*const [{ emailSend, 
             emailSendMsg, 
             emailValid, 
             emailValidMsg, 
             smsSend, 
             smsSendMsg, 
             smsValid, 
             smsValidMsg,
             userCreate,
             userCreateMsg
        }, dispatch] = useReducer(registerReducer, initialRegisterState)*/

    const { reducer, dispatch } = useContext(RegisterContext); 
    const { emailSend, 
        emailSendMsg, 
        emailValid, 
        emailValidMsg, 
        smsSend, 
        smsSendMsg, 
        smsValid, 
        smsValidMsg,
        userCreate,
        userCreateMsg,
        /*whatsAppSending*/} = reducer;

    //HOOKS DE CONTROL DE STEPPER
    const [activeStep, setActiveStep] = useState(0);

    const [emailError, setEmailError] = useState(false);
    const [emailLabel, setEmailLabel] = useState('');
    const [emailSending, setEmailSending] = useState(false);
    const [emailTextError, setEmailTextError] = useState(false);
    const [emailTextLabel, setEmailTextLabel] = useState('');

    const [emailCodeError, setEmailCodeError] = useState(false);
    const [emailCodeLabel, setEmailCodeLabel] = useState('');
    const [emailCodeSending, setEmailCodeSending] = useState(false);
    const [emailCodeTextError, setEmailCodeTextError] = useState(false);
    const [emailCodeTextLabel, setEmailCodeTextLabel] = useState('');    

    const [smsError, setSmsError] = useState(false);
    const [smsLabel, setSmsLabel] = useState('');  
    const [smsSending, setSmsSending] = useState(false);    
    const [nombreTextError, setNombreTextError] = useState(false);
    const [nombreTextLabel, setNombreTextLabel] = useState('');
    const [apellidosTextError, setApellidosTextError] = useState(false);
    const [apellidosTextLabel, setApellidosTextLabel] = useState('');
    const [telefonoTextError, setTelefonoTextError] = useState(false);
    const [telefonoTextLabel, setTelefonoTextLabel] = useState('');
    const [passwordTextError, setPasswordTextError] = useState(false);
    const [passwordTextLabel, setPasswordTextLabel] = useState('');
    const [secondPasswordTextError, setSecondPasswordTextError] = useState(false);
    const [secondPasswordTextLabel, setSecondPasswordTextLabel] = useState('');

    const [smsCodeError, setSmsCodeError] = useState(false);
    const [smsCodeLabel, setSmsCodeLabel] = useState('');
    const [smsCodeSending, setSmsCodeSending] = useState(false);
    const [smsCodeTextError, setSmsCodeTextError] = useState(false);
    const [smsCodeTextLabel, setSmsCodeTextLabel] = useState('');

    const [usuarioError, setUsuarioError] = useState(false);
    const [usuarioLabel, setUsuarioLabel] = useState('');    
    const [usuarioSending, setUsuarioSending] = useState(false);

    //HOOK PARA CONTROLAR INPUTS
    const { email, emailCode, nombre, apellidos, telefono, password, secondPassword, telefonoCode, onChange } = useForm({
        email: '',
        emailCode: '',
        nombre: '',
        apellidos: '',
        telefono: '',
        password: '',
        secondPassword: '',
        telefonoCode: '',
    });

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    //ESCRIBIR EN EL STORE DE REDUX
    const dispatchRedux = useDispatch<AppDispatch>();   

    //ENVIANDO FETCH AL SERVIDOR DEPENDIENDO EL PASO
    const handleNext = async (index: number) => {
        //console.log('Next'+index);
        switch (index) {
            case 0:          
                //validando email 
                if (validator.isEmail(email)){  
                    //console.log('Enviando email');
                    //hooks de control de error de input
                    setEmailTextError(false);
                    //hook de control de enviando
                    setEmailSending(true);
                    //hooks de control de error de fetch
                    setEmailError(false);
                    setEmailLabel('');
                    //EJECUCION DE FETCH
                    await startSendEmail({email: email, dispatch});        
                } else {
                    //hooks de control de error de input
                    setEmailTextError(true);
                    setEmailTextLabel('El correo no es válido');
                }
            break;            
            case 1:
                //validando codigo de email
                if (validator.isLength(emailCode,{min: 9, max: 9})){
                    //console.log('Verificando codigo de email');
                    //hooks de control de error de input
                    setEmailCodeTextError(false);
                    //hook de control de enviando
                    setEmailCodeSending(true);
                    //hooks de control de error de fetch
                    setEmailCodeError(false);
                    setEmailCodeLabel('');
                    //EJECUCION DE FETCH
                    await startVerifyEmail({email: email, codigo: emailCode, dispatch});
                } else {
                    //hooks de control de error de input
                    setEmailCodeTextError(true);
                    setEmailCodeTextLabel('El código de verificación no es válido');
                }
            break; 
            case 2:
                let error = false;
                //validando datos de usuario
                if (!validator.isLength(nombre,{min: 2, max:40})){
                    //hooks de control de error de input
                    setNombreTextError(true);
                    setNombreTextLabel('El nombre es incorrecto');
                    error = true;
                } else { setNombreTextError(false); }
                if (!validator.isLength(apellidos,{min: 2, max:40})){
                    //hooks de control de error de input
                    setApellidosTextError(true);
                    setApellidosTextLabel('El apellido es incorrecto');
                    error = true;
                } else { setApellidosTextError(false); }
                if (!validator.isLength(telefono,{min: 10, max:10})){
                    //hooks de control de error de input
                    setTelefonoTextError(true);
                    setTelefonoTextLabel('El número telefónico es incorrecto');
                    error = true;
                } else { setTelefonoTextError(false); }
                if (!validator.isLength(password,{min: 8, max:20})){
                    //hooks de control de error de input
                    setPasswordTextError(true);
                    setPasswordTextLabel('Ingrese una contraseña de 8 valores');
                    error = true;
                } else { setPasswordTextError(false); }
                if (!validator.equals(secondPassword, password)){
                    //hooks de control de error de input
                    setSecondPasswordTextError(true);
                    setSecondPasswordTextLabel('La contraseña no coincide');
                    error = true;
                } else { setSecondPasswordTextError(false); }
                if (!error){
                    //console.log('Enviando sms');
                    //hooks de control de error de input
                    setNombreTextError(false);
                    setApellidosTextError(false);
                    setTelefonoTextError(false);
                    setPasswordTextError(false);
                    setSecondPasswordTextError(false);
                    //hook de control de enviando
                    setSmsSending(true);
                    //hooks de control de error de fetch
                    setSmsError(false);
                    setSmsLabel('');
                    //EJECUCION DE FETCH
                    await startSendSms({email: email, telefono: telefono, dispatch});
                }
            break;                         
            case 3:
                //validando codigo de email
                if (validator.isLength(telefonoCode,{min: 9, max: 9})){
                    //console.log('Verificando codigo de sms');
                    //hooks de control de error de input
                    setSmsCodeTextError(false);  
                    //hook de control de enviando  
                    setSmsCodeSending(true);
                    //hooks de control de error de fetch
                    setSmsCodeError(false);
                    setSmsCodeLabel('');
                    //EJECUCION DE FETCH
                    await startVerifySms({email: email,
                                          telefono: parseInt(telefono),
                                          codigo: telefonoCode,
                                          dispatch
                                        });
                } else {
                    //hooks de control de error de input
                    setSmsCodeTextError(true);
                    setSmsCodeTextLabel('El código de verificación no es válido');
                }
            break;       
            case 4:
                await startCreateUser({email: email,
                    nombre: nombre,
                    apellidos: apellidos,
                    telefono: parseInt(telefono),
                    password: password,
                    dispatch
                  });
                  
            break;      
        }
    };


    //VALIDANDO RESPUESTAS DEL REDUCER PARA AVANZAR PESTAÑA DEL STEPPER
    //EMAIL
    //validacion de email 
    useEffect(() => {
        //console.log('Validando si se envio email');
        if (emailSend) {
            //console.log('Avanzando pestaña por email enviado');
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } 
    }, [emailSend])
    //Validacion de respuesta de fetch de email
    useEffect(() => {
        if (emailSendMsg) {
            setEmailSending(false);
            setEmailError(true);
            setEmailLabel(emailSendMsg);        
        }
    }, [emailSendMsg])
    //CODIGO DE EMAIL
    //validacion de codigo de email
    useEffect(() => {
        //console.log('Validando si el codigo de email es correcto');
        if (emailValid) {
            //console.log('Avanzando pestaña por codigo de email correcto');
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } 
    }, [emailValid])
    //Validacion de respuesta de fetch de codigo de email
    useEffect(() => {
        if (emailValidMsg) {
            setEmailCodeSending(false);
            setEmailCodeError(true);
            setEmailCodeLabel(emailValidMsg);        
        }
    }, [emailValidMsg])
    //SMS
    //validacion de sms 
    useEffect(() => {
        //console.log('Validando si se envio sms');
        if (smsSend) {
            //console.log('Avanzando pestaña por sms enviado');
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } 
    }, [smsSend])
    //Validacion de respuesta de fetch de sms
    useEffect(() => {
        if (smsSendMsg) {
            setSmsSending(false);
            setSmsError(true);
            setSmsLabel(smsSendMsg);        
        }
    }, [smsSendMsg])   
    //CODIGO DE SMS
    //validacion de codigo de sms
    useEffect(() => {
        //console.log('Validando si el codigo del sms es correcto');
        if (smsValid) {
            //console.log('Avanzando pestaña por codigo de sms correcto');
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } 
    }, [smsValid])
    //Validacion de respuesta de fetch de codigo de email
    useEffect(() => {
        if (smsValidMsg) {
            setSmsCodeSending(false);
            setSmsCodeError(true);
            setSmsCodeLabel(smsValidMsg);        
        }
    }, [smsValidMsg])   
    //CREANDO USUARIO
    //Validacion de respuesta de fetch de creando usuario
    useEffect(() => {
        if (userCreateMsg) {
            setUsuarioSending(false);
            setUsuarioError(true);
            setUsuarioLabel(userCreateMsg);        
        }
    }, [userCreateMsg])
    useEffect(()=> {
        if (userCreate) {
            dispatchRedux<any>(startLogin({usuario: email, password: password}));
        }
    }, [userCreate,dispatchRedux,email,password])  

    //VALIDANDO FUNCION DEL BOTON ATRAS
    const handleBack = (index: number) => {
        //console.log('Back'+index);
        switch (index) {
            case 1:
                setEmailSending(false);
                setEmailError(false);
                setEmailLabel('');               
            break;
            case 2:
                setEmailCodeSending(false);
                setEmailCodeError(false);
                setEmailCodeLabel('');               
            break;
            case 3:
                setSmsSending(false);
                setSmsError(false);
                setSmsLabel('');             
            break;
            case 4:
                setSmsCodeSending(false);
                setSmsCodeError(false);
                setSmsCodeLabel('');               
            break;            
        }
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const [showSecondPassword, setShowSecondPassword] = useState(false);
    const handleClickShowSecondPassword = () => {
        setShowSecondPassword(!showSecondPassword);
    };

    const [disabledButton, setDisabledButton] = useState(true);
    const [check, setCheck] = useState(false);
    const checking = () => {
        setCheck(!check);
        setDisabledButton(!disabledButton);
    }

    return (
    <div className="register_principal">
        <div className="register_grid">
            <div className="register_header">
                <Box sx={{ height: 40, width: 50 }}>
                    <SVG src={process.env.REACT_APP_ICONSERVER+'dz-clipboard-list'} height={40} fill="currentColor"/>
                </Box> 
                <h4>Registro</h4>
            </div>

            <div className="register_stepper">
                <Box sx={{ maxWidth: 400 }}>
                    <Stepper activeStep={activeStep} orientation="vertical">
                        {steps.map((step, index) => (
                            <Step key={step.label}>
                                {/* SUBTITULOS */}
                                <StepLabel>                                   
                                    {step.label}
                                </StepLabel>
                                <StepContent>                                    
                                    <Typography>{step.description} {index === 3 && <WhatsAppIcon color="success" />}</Typography> 
                                    <Box sx={{ mb: 2 }}> 
                                        {/* COMPONENTES */} {                                            
                                            function() {                                     
                                                switch (index) {
                                                    case 0 : return <>
                                                        { emailTextError 
                                                            ? <TextField error fullWidth id="outlined-basic" value = { email } onChange={ ({target}) => onChange( target.value, 'email') } label={ emailTextLabel } variant="outlined" margin="dense" />                                                         
                                                            : <TextField fullWidth id="outlined-basic" value = { email } onChange={ ({target}) => onChange( target.value, 'email') } label="Correo electrónico" variant="outlined" margin="dense" />
                                                        }
                                                        { emailError
                                                            ? <Typography variant="caption" color="error">{ emailLabel }</Typography>
                                                            : null
                                                        }
                                                    </>
                                                    case 1 : return <>
                                                        { emailCodeTextError
                                                            ? <InputMask
                                                                mask="****-****"
                                                                value={ emailCode }
                                                                onChange={ ({target}) => onChange( target.value, 'emailCode') }
                                                            >
                                                                {() => <TextField
                                                                    error fullWidth id="outlined-basic" label={ emailCodeTextLabel } 
                                                                    variant="outlined" margin="dense"
                                                                    inputProps={{ style: { textTransform: "uppercase" } }}
                                                                />}
                                                            </InputMask>
                                                            : <InputMask
                                                                mask="****-****"
                                                                value={ emailCode }
                                                                onChange={ ({target}) => onChange( target.value, 'emailCode') }
                                                            >
                                                                {() => <TextField
                                                                    fullWidth id="outlined-basic" label="Código de validación"
                                                                    variant="outlined" margin="dense"
                                                                    inputProps={{ style: { textTransform: "uppercase" } }}
                                                                />}
                                                            </InputMask>
                                                        }   
                                                        { emailCodeError 
                                                            ? <Typography variant="caption" color="error">{ emailCodeLabel }</Typography>
                                                            : null                                                        
                                                        }  
                                                    </>                                           
                                                    case 2 : return <>
                                                        { nombreTextError 
                                                            ? <TextField error fullWidth size="small" id="outlined-basic" value = { nombre } onChange={ ({target}) => onChange( target.value, 'nombre') } label={ nombreTextLabel } variant="outlined" margin="dense" />
                                                            : <TextField fullWidth size="small" id="outlined-basic" value = { nombre } onChange={ ({target}) => onChange( target.value, 'nombre') } label="Nombre" variant="outlined" margin="dense" />
                                                        }                                                  
                                                        { apellidosTextError 
                                                            ? <TextField error fullWidth size="small" id="outlined-basic" value = { apellidos } onChange={ ({target}) => onChange( target.value, 'apellidos') } label={ apellidosTextLabel } variant="outlined" margin="dense" />
                                                            : <TextField fullWidth size="small" id="outlined-basic" value = { apellidos } onChange={ ({target}) => onChange( target.value, 'apellidos') } label="Apellidos" variant="outlined" margin="dense" />
                                                        }                                                  
                                                        { telefonoTextError 
                                                            ? <TextField
                                                                error fullWidth size="small" id="outlined-basic"
                                                                value={ telefono } onChange={ ({target}) => onChange( target.value, 'telefono') }
                                                                label={ telefonoTextLabel } variant="outlined" margin="dense" type="number"
                                                                InputProps={{
                                                                    startAdornment: <InputAdornment position="start">MX (+52)</InputAdornment>,
                                                                }}
                                                            />
                                                            : <TextField fullWidth size="small" id="outlined-basic"
                                                                value={ telefono } onChange={ ({target}) => onChange( target.value, 'telefono') }
                                                                label="Teléfono" variant="outlined" margin="dense" type="number"
                                                                InputProps={{
                                                                    startAdornment: <InputAdornment position="start">MX (+52)</InputAdornment>,
                                                                }}
                                                            />
                                                        }                                                  
                                                        { passwordTextError
                                                            ? <TextField
                                                                error fullWidth size="small" id="outlined-basic"
                                                                value={ password } onChange={ ({target}) => onChange( target.value, 'password') }
                                                                label={ passwordTextLabel } variant="outlined" margin="dense"
                                                                type={showPassword ? 'text' : 'password'}
                                                                InputProps={{
                                                                    endAdornment:
                                                                    <InputAdornment position="end">
                                                                        <IconButton
                                                                            aria-label="toggle password visibility"
                                                                            onClick={handleClickShowPassword}
                                                                            edge="end"
                                                                        >
                                                                            { showPassword ? <VisibilityOff /> : <Visibility /> }
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                }}
                                                            />
                                                            : <TextField
                                                                fullWidth size="small" id="outlined-basic"
                                                                value={ password } onChange={ ({target}) => onChange( target.value, 'password') }
                                                                label="Contraseña" variant="outlined" margin="dense"
                                                                type={showPassword ? 'text' : 'password'}
                                                                InputProps={{
                                                                    endAdornment:
                                                                    <InputAdornment position="end">
                                                                        <IconButton
                                                                            aria-label="toggle password visibility"
                                                                            onClick={handleClickShowPassword}
                                                                            edge="end"
                                                                        >
                                                                            { showPassword ? <VisibilityOff /> : <Visibility /> }
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                }}
                                                            />
                                                        }
                                                        { secondPasswordTextError 
                                                            ? <TextField
                                                                error fullWidth size="small" id="outlined-basic"
                                                                value={ secondPassword } onChange={ ({target}) => onChange( target.value, 'secondPassword') }
                                                                label={ secondPasswordTextLabel } variant="outlined" margin="dense"
                                                                type={showSecondPassword ? 'text' : 'password'}
                                                                InputProps={{
                                                                    endAdornment:
                                                                    <InputAdornment position="end">
                                                                        <IconButton
                                                                            aria-label="toggle password visibility"
                                                                            onClick={handleClickShowSecondPassword}
                                                                            edge="end"
                                                                        >
                                                                            { showSecondPassword ? <VisibilityOff /> : <Visibility /> }
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                }}
                                                            />
                                                            : <TextField
                                                                fullWidth size="small" id="outlined-basic"
                                                                value={ secondPassword } onChange={ ({target}) => onChange( target.value, 'secondPassword') }
                                                                label="Corroborar contraseña" variant="outlined" margin="dense"
                                                                type={showSecondPassword ? 'text' : 'password'}
                                                                InputProps={{
                                                                    endAdornment:
                                                                    <InputAdornment position="end">
                                                                        <IconButton
                                                                            aria-label="toggle password visibility"
                                                                            onClick={handleClickShowSecondPassword}
                                                                            edge="end"
                                                                        >
                                                                            { showSecondPassword ? <VisibilityOff /> : <Visibility /> }
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                }}
                                                            />
                                                        }
                                                        { smsError 
                                                            ? <Typography variant="caption" color="error">{ smsLabel }</Typography>
                                                            : null                                                        
                                                        } 
                                                    </>     
                                                    case 3 : return <>
                                                        { smsCodeTextError 

                                                            ? <InputMask
                                                                mask="****-****"
                                                                value={ telefonoCode }
                                                                onChange={ ({target}) => onChange( target.value, 'telefonoCode') }
                                                            >
                                                                {() => <TextField
                                                                    error fullWidth id="outlined-basic" label={ smsCodeTextLabel }
                                                                    variant="outlined" margin="dense"
                                                                    inputProps={{ style: { textTransform: "uppercase" } }}
                                                                />}
                                                            </InputMask>                                                            
                                                            : <InputMask
                                                                mask="****-****"
                                                                value={ telefonoCode }
                                                                onChange={ ({target}) => onChange( target.value, 'telefonoCode') }
                                                            >
                                                                {() => <TextField
                                                                    fullWidth id="outlined-basic" label="Código de validación"
                                                                    variant="outlined" margin="dense" 
                                                                    inputProps={{ style: { textTransform: "uppercase" } }}
                                                                />}
                                                            </InputMask>
                                                        }
                                                        {/* whatsAppSending &&
                                                            <Box sx={{ p: "20px 0px 20px 0px" }}>
                                                                <Typography variant="body2" sx={{ pb: 1 }}><b>No te llego el codigo por SMS, No te preocupes, recibe un nuevo codigo por WhatsApp.</b></Typography>
                                                                <Button color="success" variant="contained" startIcon={<WhatsAppIcon />} sx={{ textTransform: "none" }} onClick={()=>(dispatch(startSendWhatsAppCode({parametro: "Dato1", dispatch})))}>
                                                                    Nuevo Codigo
                                                                </Button>
                                                            </Box>
                                                        */}
                                                        { smsCodeError
                                                            ? <Typography variant="caption" color="error">{ smsCodeLabel }</Typography>
                                                            : null
                                                        }
                                                    </>
                                                    case 4 : return <>
                                                        <div className="register_form_checkbox">
                                                            <Checkbox onClick={checking} color="warning" />
                                                            <p>Acepto los <Link href="#" underline="hover" onClick={()=>(dispatch(abrirTerminosCondicionesModal()))}>Términos y Condiciones</Link> y el <Link href="#" underline="hover" onClick={()=>(dispatch(abrirAvisoPrivacidadModal()))}>Aviso de Privacidad</Link> de  Autos X Partes</p>
                                                        </div>    
                                                        { usuarioError 
                                                            ? <Typography variant="caption" color="error">{ usuarioLabel }</Typography>
                                                            : null                                                        
                                                        }                                                                                                        
                                                    </>                                                                                                  
                                                }
                                            }()                                                
                                        }                                                                       
                                        {/* CONTROLES */}
                                        <div>
                                        {
                                            function() { 
                                                switch (index) {
                                                    case 0: return <>
                                                        { emailSending                                            
                                                            ? <Button disabled variant="contained" onClick={ () => { handleNext(index) }} sx={{ mt: 1, mr: 1 }}>Enviando...</Button>                                        
                                                            : <Button color="warning" variant="contained" onClick={ () => { handleNext(index) }} sx={{ mt: 1, mr: 1 }}>Siguiente</Button>                                        
                                                        }
                                                    </>
                                                    case 1: return <>
                                                        { emailCodeSending                                            
                                                            ? <Button disabled variant="contained" onClick={ () => { handleNext(index) }} sx={{ mt: 1, mr: 1 }}>Verificando...</Button>                                        
                                                            : <Button color="warning" variant="contained" onClick={ () => { handleNext(index) }} sx={{ mt: 1, mr: 1 }}>Siguiente</Button>                                        
                                                        }                                 
                                                    </>                                                    
                                                    case 2: return <>
                                                        { smsSending                                            
                                                            ? <Button disabled variant="contained" onClick={ () => { handleNext(index) }} sx={{ mt: 1, mr: 1 }}>Enviando...</Button>                                        
                                                            : <Button color="warning" variant="contained" onClick={ () => { handleNext(index) }} sx={{ mt: 1, mr: 1 }}>Siguiente</Button>                                        
                                                        }                                 
                                                    </>
                                                    case 3: return <>
                                                        { smsCodeSending                                            
                                                            ? <Button disabled variant="contained" onClick={ () => { handleNext(index) }} sx={{ mt: 1, mr: 1 }}>Verificando...</Button>                                        
                                                            : <Button color="warning" variant="contained" onClick={ () => { handleNext(index) }} sx={{ mt: 1, mr: 1 }}>Siguiente</Button>                                        
                                                        }                                 
                                                    </>   
                                                    case 4: return <>
                                                        { usuarioSending                                            
                                                            ? <Button disabled={ disabledButton } variant="contained" onClick={ () => { handleNext(index) }} sx={{ mt: 1, mr: 1 }}>Creando usuario...</Button>                                        
                                                            : <Button disabled={ disabledButton } color="warning" variant="contained" onClick={ () => { handleNext(index) }} sx={{ mt: 1, mr: 1 }}>Finalizar</Button>                                        
                                                        }                                                                       
                                                    </>                                        
                                                }
                                            }()
                                        }
                                        {
                                            function() { 
                                                switch (index) {
                                                    case 0: return null;
                                                    case 1: return <Button onClick={ () => { handleBack(index) }} sx={{ mt: 1, mr: 1 }}>Atrás</Button>                                                                                                         
                                                    case 2: return <Button onClick={ () => { handleBack(index) }} sx={{ mt: 1, mr: 1 }}>Atrás</Button> 
                                                    case 3: return <Button onClick={ () => { handleBack(index) }} sx={{ mt: 1, mr: 1 }}>Atrás</Button> 
                                                    case 4: return null;                           
                                                }
                                            }()                                                                                        
                                        }                                        
                                        </div>
                                    </Box>
                                </StepContent>
                            </Step>
                        ))}
                    </Stepper>                    
                </Box>
            </div>
        </div>

        <TerminosCondiciones />
        <AvisoPrivacidad />

    </div>
    )
}
