import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

export const Alert = () => {

    const storeNavbar = (state: RootState) => state.modal; 
    const { message } = useSelector(storeNavbar);

    return (
        <div className="messagemodal_message">

            <hr />
            <div className="messagemodal_text">
                <p>{ message }</p>
            </div>
        </div>
    )
}
