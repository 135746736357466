import { AppThunk } from '../store/store';
import { fetchToken } from '../helpers/fetch';
import { showMessage } from './modalReducer';
//definimos la estructura del reducer
interface DarkModeState {
    darkMode: boolean,
};
//definimos el valor inicial
const initialState: DarkModeState = {
    darkMode: false
}
//definimos los types del reducer
type DarkModePayload = { darkmode: boolean };

type DarkModeAction = { type: 'DarkMode' , payload: DarkModePayload }
;
//definimos el reducer
export const darkModeReducer = (state: DarkModeState = initialState, action: DarkModeAction): DarkModeState => {
    switch (action.type) {
        case 'DarkMode': return { ...state, darkMode: action.payload.darkmode }
        default: return state;
    }
}

//definimos los actions sincronos del reducer
export const startModoOscuro = (modoOscuroData: DarkModePayload): AppThunk => {
    return async (dispatch, getState) => {
        const body = await fetchToken(`usuarios/darkmode/${ getState().auth.id }`,modoOscuroData,'PUT');
        if (body.ok){ 
            dispatch(ModoOscuro(modoOscuroData));
        } else {
            dispatch(showMessage({ modaltype: 'Alert', title: 'Error critico', message: body.msg }));
        }
    }
}

export const ModoOscuro = (modoOscuroData: DarkModePayload): DarkModeAction => {
    return {
        type: 'DarkMode',
        payload: modoOscuroData
    }
}
