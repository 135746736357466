import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import './styles/styles.scss';
import App from './App';
import { store } from "./store/store";

import "slick-carousel/slick/slick.css";      // HOJA DE ESTILOS PARA LOS CARRUSELES DE REACT SLICK
import "slick-carousel/slick/slick-theme.css";

import "../src/components/home/styles/home-carousel-arrows-slick.css"

ReactDOM.render(
  <React.StrictMode>
    <Provider store={ store }>
      <App />
    </Provider>  
  </React.StrictMode>,
  document.getElementById('root')
);