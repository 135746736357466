import "./styles/plan.scss"
import { Box, Button, Link } from '@mui/material';

import SVG from 'react-inlinesvg';
import { showModalPackage, startLoadBusiness, startLoadPackages } from "../../../../../../reducers/businessReducer";

import { PackagesModal } from '../../../../../../modals/PackagesModal';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from '../../../../../../store/store';
import { useContext, useEffect } from "react";
import { startLoadSales } from "../../../misVentas/reducers/SalesReducer";
import { SalesContext } from "../../../misVentas/context/SalesContext";

export const Plan = () => {

    //CARGAR DATOS DE NEGOCIO
    const dispatchRedux = useDispatch<AppDispatch>();
    useEffect(() => {
        dispatchRedux<any>(startLoadBusiness());
    }, [dispatchRedux]) 

    const storeBusiness = (state: RootState ) => state.business; 
    const { plan, reload } = useSelector(storeBusiness);
    const storeAuth = (state: RootState ) => state.auth;
    const { id, invitacionVendedor } = useSelector(storeAuth);

    const { dispatchSales } = useContext(SalesContext);

    useEffect(() => {
      if (plan!=='BASICO'){ 
        startLoadSales({id, dispatchSales});
      }
    }, [id, plan, dispatchSales])

    const handlePlanClick = async () => {
      await dispatchRedux<any>(startLoadPackages({packageId: 'a'}));
      dispatchRedux(showModalPackage());
    }

    useEffect(() => {
      if (reload) {
        dispatchRedux<any>(startLoadBusiness());
      } 
    }, [reload, dispatchRedux]);

    return (
      <>
        <PackagesModal/>
        <div className="plan_main"> 
          <div className="plan_section">
            <div className="plan_format">
              <div className="plan_icono">
                <SVG src={process.env.REACT_APP_ICONSERVER+'dz-plan'} height="100%" />
              </div>
              <div className="plan_titulo">
                Plan de ventas
              </div>
              <div className="plan_dato1">
                <p>Actualmente tienes un plan: <b className="plan_dato1_color">{ plan }</b></p>
              </div>
              <div></div>
              <div className="plan_dato2">
                { plan === 'BASICO' ? 
                  <p>
                    <b>¿Quieres publicar algo en AutosXPartes.com?</b>
                    <br />
                    Cambia tu plan para más beneficios
                  </p>
                  :
                  <p>
                    <b>¿Quieres vender en AutosXPartes.com?</b>
                    <br />
                    Vende tu auto por partes con nosotros
                  </p>
                }
              </div>
              <div className="plan_opcion">
                {
                  invitacionVendedor === false ?
                  <Box sx={{ marginTop: 1 }}>
                    <Link underline="hover" href="/contactUser" >Ponte en contacto con nosotros aquí</Link>
                  </Box>
                  :
                  <>
                    { plan === 'BASICO'
                      ?
                      <Box sx={{ textAlign: "center" }}>
                        <Button
                          onClick={ () => {handlePlanClick()} }
                          variant="contained"
                          sx={{ textTransform: 'none' }}
                        >
                          Cambiar mi plan
                        </Button>
                      </Box>
                      :
                      <Button
                        href="/sales/1"
                        variant="contained"
                        sx={{ textTransform: 'none' }}
                      >
                        Quiero vender 
                      </Button>
                    }
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      </>
    )
}