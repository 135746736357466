import { AppThunk } from '../store/store';

//DEFINIMOS LOS TIPOS PARA DEL REDUCER

//DEFINIMOS LA ESTRUCTURA DEL REDUCER
interface DialogsState {

    okShowDialog: boolean,
    okTitle: string,
    okContent: string,

    yesnoShowDialog: boolean,
    yesnoTitle: string,
    yesnoContent: string,
    yesnoSelection: boolean | null
};
//definimos el valor inicial
const initialState: DialogsState = {

    okShowDialog: false,
    okTitle: '',
    okContent: '',

    yesnoShowDialog: false,
    yesnoTitle: '',
    yesnoContent: '',
    yesnoSelection: null
}

//definimos los types del reducer
type ShowOkPayload = {  
                        title: string,
                        content: string 
};
type ShowYesNoPayload = {  
                        title: string,
                        content: string 
};
type CloseYesNoPayload = {
                        yesnoSelection: boolean
};
type DialogsActions = 
                    { type: 'ShowOk' } |
                    { type: 'ContentOk', payload: ShowOkPayload  } |
                    { type: 'CloseOk' } |
                    { type: 'ShowYesNo' } |
                    { type: 'ContentYesNo', payload: ShowYesNoPayload } |
                    { type: 'CloseYesNo', payload: CloseYesNoPayload } 
                    ;
//definimos el reducer
export const dialogsReducer = (state: DialogsState = initialState, action: DialogsActions): DialogsState => {
    switch (action.type) {
        case 'ShowOk': return { 
            ...state, 
            okShowDialog: true
        }
        case 'ContentOk': return { 
            ...state, 
            okTitle: action.payload.title,
            okContent: action.payload.content
        }
        case 'CloseOk': return {
            ...state,
            okShowDialog: false,
        }
        case 'ShowYesNo': return { 
            ...state, 
            yesnoShowDialog: true,
            yesnoSelection: null,
        }
        case 'ContentYesNo': return { 
            ...state, 
            yesnoTitle: action.payload.title,
            yesnoContent: action.payload.content,
            yesnoSelection: null
        }
        case 'CloseYesNo': return {
            ...state,
            yesnoShowDialog: false,
            yesnoSelection: action.payload.yesnoSelection
        }       
        default: return state;
    }
}

//FUNCION PARA EVITAR VACIAR EL DIALOG ANTES QUE CIERRE
function timeout(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

//MOSTRANDO OK DIALOG
export const startShowOkDialog = (showOkDialogData: ShowOkPayload): AppThunk => {
    return async (dispatch) => {
        dispatch(contentOk(showOkDialogData));
        dispatch(showOk());
        await timeout(9000);
        dispatch(closeOk());
        await timeout(150);
        dispatch(contentOk({title: '', content: ''}));
    }
}
export const startCloseOkDialog = (): AppThunk => {
    return async (dispatch) => {
        dispatch(closeOk());
        await timeout(150);
        dispatch(contentOk({title: '', content: ''}))
    }
}
const showOk = (): DialogsActions => {
    return {
        type: 'ShowOk'
    } 
}
const contentOk = (showOkDialogData: ShowOkPayload): DialogsActions => {
    return {
        type: 'ContentOk',
        payload: showOkDialogData
    } 
}
const closeOk = (): DialogsActions => {
    return {
        type: 'CloseOk'
    } 
}

//MOSTRANDO YESNO DIALOG
export const startShowYesNoDialog = (showYesNoDialogData: ShowOkPayload): AppThunk => {
    return async (dispatch) => {
        dispatch(contentYesNo(showYesNoDialogData));
        dispatch(showYesNo());
    }
}
export const startCloseYesNoDialog = (closeYesNoData: CloseYesNoPayload): AppThunk => {
    return async (dispatch) => {
        dispatch(closeYesNo(closeYesNoData));
        await timeout(150);
        dispatch(contentYesNo({title: '', content: ''}))

    }
}

const showYesNo = (): DialogsActions => {
    return {
        type: 'ShowYesNo'
    } 
}
const contentYesNo = (showYesNoDialogData: ShowOkPayload): DialogsActions => {
    return {
        type: 'ContentYesNo',
        payload: showYesNoDialogData
    } 
}
const closeYesNo = (closeYesNoData: CloseYesNoPayload): DialogsActions => {
    return {
        type: 'CloseYesNo',
        payload: closeYesNoData
    } 
}
