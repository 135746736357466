import { useSelector } from "react-redux";
import SVG from 'react-inlinesvg';
import { RootState } from "../../../../store/store";

export const Message = () => {

    const storeNavbar = (state: RootState) => state.navbar;
    const { activemessage } = useSelector(storeNavbar); 

    return (
        <div className="navbar_submodal_message">
            <div className="navbar_submodal_title">
                {/* <i className="fas fa-envelope-open fa-2x"/> */}
                <SVG src={process.env.REACT_APP_ICONSERVER+'dz-message-open'} height={35} fill="currentColor"/>
                <p>{ activemessage.title }</p>
            </div>
            <hr />
            <div className="navbar_submodal_text">
                <p>{ activemessage.text }</p>
            </div>
        </div>
    )
}
