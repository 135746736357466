
import { createContext, useReducer } from 'react'; 
import { BuscarRefaccionScreen } from '../BuscarRefaccionScreen';
import { findsReducer, FindsReducerType, initialFindsState } from '../reducers/FindReducer';

//DEFINIMOS UN CONTEXT PARA CARGAR INFORMACION DEL SERVIDOR
type findsContextType = {
    findsReducerState: FindsReducerType,
    dispatchFind: React.Dispatch<any>,
}
const findContextDefaultValues: findsContextType = {
    findsReducerState: initialFindsState,
    dispatchFind: () => null
};
export const FindsContext = createContext<findsContextType>(findContextDefaultValues);

//creamos el Functional Component
export const ContextFind = () => {

    //REDUCER DEL COMPONENTE
    const [findsReducerState, dispatchFind] = useReducer(findsReducer, initialFindsState)

    return (
        <FindsContext.Provider value = {{ findsReducerState, dispatchFind }}>
            <BuscarRefaccionScreen />
        </FindsContext.Provider>
    )
} 
