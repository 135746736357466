import "./styles/perfil.scss"
import { Avatar, Button, CardMedia, IconButton, useMediaQuery } from '@mui/material';
import { useContext, useEffect, useRef } from 'react';
import { AccountContext } from '../../context/AccountContext';

import SVG from 'react-inlinesvg';
import { EditarNombre } from "./modal/EditarNombre";
import { openEditEstablecimiento, openEditNames, openEditPhone, openEditTelefonoLocal, startUploadPhoto } from "../../reducers/AccountReducer";
import { EditarTelefono } from "./modal/EditarTelefono";
import { Box } from "@mui/system";
import { EditarEstablecimiento } from "./modal/EditarEstablecimiento";
import { EditarTelefonoLocal } from "./modal/EditarTelefonoLocal";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../store/store";

import PhotoCamera from '@mui/icons-material/PhotoCamera';

export const Perfil = () => {

    useEffect(() => {
      window.scrollTo(0, 0)
    }, []);

    const responsive = useMediaQuery('(max-width:300px)');

    //LEER EL REDUCER LOCAL
    const { accountReducerState, dispatch } = useContext(AccountContext); 
    const { name, lastName, email, phone, registroVendedor, numContacto, establecimiento, paquete, rfc, /*rol,*/ flogo, fportada, /*fine,*/ } = accountReducerState;

    const storeAuth = (state: RootState ) => state.auth;
    const { id } = useSelector(storeAuth);

    const fotoLogoRef = useRef<HTMLInputElement>(null);
    const fotoPortadaRef = useRef<HTMLInputElement>(null);

    const handleClickOpenNameModal = () => {
      dispatch (openEditNames());
      //const nombre = name+1
      //console.log(nombre);
    }
    const handleClickOpenPhoneModal = () => {
      dispatch (openEditPhone());
    }
    const handleClickOpenEditEstablecimientoModal = () => {
      dispatch (openEditEstablecimiento());
    }
    const handleClickOpenEditTelefonoLocalModal = () => {
      dispatch (openEditTelefonoLocal());
    }

    return (
    <>
      <EditarNombre />
      <EditarTelefono />
      <EditarEstablecimiento />
      <EditarTelefonoLocal />
      <div className="perfil_main">
        <div className="perfil_section">

          { registroVendedor
            ? <>
              <div className="perfil_container">
                <CardMedia
                  component="img"
                  height={(responsive ? 200 : 250)}
                  alt="portada"
                  src={fportada ? process.env.REACT_APP_USERSERVER+fportada : "/assets/sin-imagen.png"}
                />
                <Box sx={{ textAlign: "end", position: "absolute", right: 10, top: 10 }}>
                  <IconButton color="primary" aria-label="upload picture" component="label"
                    sx={{
                      "&:hover": { backgroundColor: "white" },
                      backgroundColor: "white"
                    }}
                  >
                    <input
                      ref={ fotoPortadaRef }
                      hidden accept="image/*" type="file"
                      onChange={ ({target}) => 
                        { if (target.files !== null)
                          { if(target.files.length > 0)
                            { 
                              dispatch(startUploadPhoto({ field: 'fportada', errorField: 'fportadaError', file: target.files[0], dispatch, userId: id?.toString() }));
                            } 
                          }
                        }
                      }
                    />
                    <PhotoCamera />
                  </IconButton>
                </Box>
                <div className="perfil_logo">
                  <Avatar
                    alt="imagen"
                    src={flogo ? process.env.REACT_APP_USERSERVER+flogo : process.env.REACT_APP_ICONSERVER+'dz-user-circle'}
                    sx={{ width: (responsive ? 130 : 200), height: (responsive ? 130 : 200), borderColor: "black", borderStyle: "solid", borderWidth: "3px" }}
                  />
                  <Box sx={{ position: "absolute", right: 2, bottom: -12 }}>
                    <IconButton color="primary" aria-label="upload picture" component="label" sx={{ textAlign: "end" }}>
                      <input
                        ref={ fotoLogoRef }
                        hidden accept="image/*" type="file"
                        onChange={ ({target}) => 
                          { if (target.files !== null)
                            { if(target.files.length > 0)
                              { 
                                dispatch(startUploadPhoto({ field: 'flogo', errorField: 'flogoError', file: target.files[0], dispatch, userId: id?.toString() }));
                              } 
                            }
                          }
                        }
                      />
                      <PhotoCamera />
                    </IconButton>
                  </Box>
                </div>
              </div>
            </>
            : 
            <Box sx={{ textAlign: "center" }}>
              <SVG src={process.env.REACT_APP_ICONSERVER+'dz-user-circle'} className="perfil_container_icon"/>
            </Box>
          }

          <div className="perfil_container_title">Tus datos</div>
          <div className="perfil_container_subtitle">{ name } { lastName }</div>
          <div className="perfil_subtitulos">
            Datos de cuenta
          </div>
          <div className="perfil_datos1">
            <p>
              E-mail
            </p>
            <div>
              { email }
            </div>
          </div>
          <div className="perfil_subtitulos">
            Datos personales
          </div>
          <div className="perfil_datos2">
            <div className="perfil_datos">
              <div className="perfil_datos_texto">
                Nombre(s) y Apellido
              </div>
              <div className="perfil_datos_dato">
                { name } { lastName }
              </div>
              <div className="perfil_datos_opcion">
                <Button size="small" onClick={handleClickOpenNameModal}>
                  editar
                </Button>
              </div>
            </div>
            <div className="perfil_datos">
              <div className="perfil_datos_texto">
                Telefono
              </div>
              <div className="perfil_datos_dato">
                { phone }
              </div>
              <div className="perfil_datos_opcion">
                <Button size="small" onClick={handleClickOpenPhoneModal}>
                  editar
                </Button>
              </div>
            </div>
          </div>

          { registroVendedor
            ? <>
                <div className="perfil_subtitulos">
                  Datos de Negocio
                </div>
                <div className="perfil_datos2">
                  <div className="perfil_datos">
                    <div className="perfil_datos_texto">
                      Establecimiento
                    </div>
                    <div className="perfil_datos_dato">
                      { establecimiento }
                    </div>
                    <div className="perfil_datos_opcion">
                      <Button size="small" onClick={handleClickOpenEditEstablecimientoModal}>
                        editar
                      </Button>
                    </div>
                  </div>
                  <div className="perfil_datos">
                    <div className="perfil_datos_texto">
                      Tel. Contacto
                    </div>
                    <div className="perfil_datos_dato">
                      { numContacto }
                    </div>
                    <div className="perfil_datos_opcion">
                    <Button size="small" onClick={handleClickOpenEditTelefonoLocalModal}>
                      editar
                    </Button>
                    </div>
                  </div>
                  <div className="perfil_datos">
                    <div className="perfil_datos_texto">
                      RFC
                    </div>
                    <div className="perfil_datos_dato">
                      { rfc }
                    </div>
                  </div>
                  <div className="perfil_datos">
                    <div className="perfil_datos_texto">
                      Paquete
                    </div>
                    <div className="perfil_datos_dato">
                      { paquete }
                    </div>
                    <div className="perfil_datos_opcion">
                      
                    </div>
                  </div>
                </div>
              </>
            : null
          }
        </div>
      </div>
    </>
  )
}