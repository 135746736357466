
import { fetchSinToken, fetchToken } from "../../../helpers/fetch";

//DEFINIMOS LA ESTRUCTURA DE LOS DATOS DE PUBLICACION
type PublicationDataType = {
    icon: string | null,
    marca: string | null,
    modelo: string | null,
    anio: number | null,
    anioInicial: number | null,
    anioFinal: number | null,
    version: string | null,
    motor: string | null,
    transmision: string | null,
    descripcion: string | null,
    fotoPublicacion: string | null,
    fotoIzquierda: string | null,
    fotoDerecha: string | null,
    fotoFrontal: string | null,
    fotoPosterior: string | null,
    fotoMotor: string | null,
    fotoInterior: string | null,
    estado: string | null,
    tipo: string | null,
    precio: string | number | undefined | null
}
//DEFINIMOS LA ESTRUCTURA DE LOS DATOS DEL VENDEDOR
type SellerDataType = {
    idVendedor: string | null,
    nombre: string | null,
    apellidos: string | null,
    telefono: number | null,
    establecimiento: string | null,
    fotoPortada: string | null,
    fotoLogo: string | null
}
type QuestionsDataType = {
    idPregunta: string | null,
    estadoPregunta: string | null,
    pregunta: string | null,
    respuesta: string | null,
    idPublicacion: string | null,
    //idVendedor: string | null,
    //nombreVendedor: string | null,
}

//DEFINIMOS LA ESTRUCTURA DEL REDUCER
export interface PublicationDataReducerType {
    datosPublicacion: PublicationDataType | null,
    datosVendedor: SellerDataType | null,
    publicationMessage: string | null,
    preguntasPublicacion: QuestionsDataType[] | null,
    questionMessage: string | null,

    pregunta: string,
    preguntaError: string | null,

    receiveMessage: string | null,
    receiveMessageError: string | null,

    openModalCreateQuestion: boolean,
    openModalContactSeller: boolean,
    openModalReportPublication: boolean,

    modalImagesZoomCarousel: boolean,

    mensajePublica: string | undefined,

    indexCarousel: number | string | null
}
//DEFINIMOS EL VALOR INICIAL DEL REDUCER
export const initialPublicationDataState: PublicationDataReducerType = {
    datosPublicacion: null,
    datosVendedor: null,
    publicationMessage: null,
    preguntasPublicacion: null,
    questionMessage: null,

    pregunta: '',
    preguntaError: null,

    receiveMessage: '',
    receiveMessageError: '',

    openModalCreateQuestion: false,
    openModalContactSeller: false,
    openModalReportPublication: false,

    modalImagesZoomCarousel: false,

    mensajePublica: undefined,

    indexCarousel: 0
}

//DEFINIMOS LOS PAYLOAD DE LOS ACTIONS
type PublicationDataPayload = { publicacionDatos: PublicationDataType, vendedorDatos: SellerDataType };
type QuestionsDataPayload = { preguntas: QuestionsDataType[] };

type NoPublicationDataPayload = { messagePublication: string };
type NoQuestionsDataPayload = { messageQuestions: string };

type ReceiveMessagePayload = { receiveMessageQuestion: string };
type ReceiveMessageErrorPayload = { receiveMessageErrorQuestion: string };

type OnChangePayload = {
    field: keyof PublicationDataReducerType,
    value: any
}
type OnErrorPayload = {
    errorField: keyof PublicationDataReducerType,
    label: string
}
type OnCleanErrorPayload = {
    errorField: keyof PublicationDataReducerType 
}

type ContactSellerPublicationPayload = { messageContactSellerPublication: string };
type ReportPublicationPayload = { messageReportPublication: string };

type ClicPublicationPayload = { clicID: number };

//DEFINIMOS LOS ACTIONS DEL REDUCER
type PublicationDataActions = 
                              { type: 'OnChange', payload: OnChangePayload } |
                              { type: 'OnError', payload: OnErrorPayload } |
                              { type: 'OnCleanError', payload: OnCleanErrorPayload } |

                              { type: 'PreparePublicationData' } |
                              { type: 'LoadPublicationData', payload: PublicationDataPayload } |
                              { type: 'NoPublicationData', payload: NoPublicationDataPayload } |
                              { type: 'PrepareQuestionsData' } |
                              { type: 'LoadQuestionsData', payload: QuestionsDataPayload } |
                              { type: 'NoQuestionsData', payload: NoQuestionsDataPayload } |

                              { type: 'ReceiveMessage', payload: ReceiveMessagePayload } |
                              { type: 'ReceiveErrorMessage', payload: ReceiveMessageErrorPayload } |
                              { type: 'ClearQuestionError' } |

                              { type: 'OpenModalQuestion' } |
                              { type: 'CloseModalQuestion' } |

                              { type: 'OpenCreateQuestionModal' } |
                              { type: 'CloseCreateQuestionModal' } |
                              { type: 'OpenContactSellerModal' } |
                              { type: 'CloseContactSellerModal' } |
                              { type: 'OpenReportPublicationModal' } |
                              { type: 'CloseReportPublicationModal' } |

                              { type: 'OpenImagesZoomCarousel'  } |
                              { type: 'SetImagesZoomCarousel' ,  payload: ClicPublicationPayload } |
                              
                              { type: 'CloseImagesZoomCarousel' } |

                              { type: 'MessageContactSellerPublicationModal', payload: ContactSellerPublicationPayload } |
                              { type: 'MessageReportPublicationModal', payload: ReportPublicationPayload };

//DEFINIMOS LAS ACCIONES DEL REDUCER
export const publicationDataReducer = (state: PublicationDataReducerType = initialPublicationDataState, action: PublicationDataActions) : PublicationDataReducerType => {
    switch (action.type) {
        case 'OnChange' : return {
            ...state,
            [action.payload.field]: action.payload.value
        }
        case 'OnError' : return {
            ...state,
            [action.payload.errorField]: action.payload.label
        }
        case 'OnCleanError' : return {
            ...state,
            [action.payload.errorField]: null
        }

        case 'PreparePublicationData' : return {
            ...state,
            publicationMessage: null
        }
        case 'LoadPublicationData' : return {
            ...state,
            datosPublicacion: action.payload.publicacionDatos,
            datosVendedor: action.payload.vendedorDatos
        }
        case 'NoPublicationData' : return {
            ...state,
            publicationMessage: action.payload.messagePublication
        }
        case 'PrepareQuestionsData' : return {
            ...state,
            questionMessage: null
        }
        case 'LoadQuestionsData' : return {
            ...state,
            preguntasPublicacion: action.payload.preguntas
        }
        case 'NoQuestionsData' : return {
            ...state,
            publicationMessage: action.payload.messageQuestions
        }
        case 'ReceiveMessage' : return {
            ...state,
            receiveMessage: action.payload.receiveMessageQuestion
        }
        case 'ReceiveErrorMessage' : return {
            ...state,
            receiveMessageError: action.payload.receiveMessageErrorQuestion
        }
        case 'ClearQuestionError' : return {
            ...state,
            pregunta: '',
            preguntaError: null
        }
        case 'OpenModalQuestion' : return {
            ...state,
            openModalCreateQuestion: true
        }
        case 'CloseModalQuestion' : return {
            ...state,
            openModalCreateQuestion: false
        }

        case 'OpenCreateQuestionModal' : return {
            ...state,
            openModalCreateQuestion: true,
        }
        case 'CloseCreateQuestionModal' : return {
            ...state,
            openModalCreateQuestion: false,
            mensajePublica: undefined,
            pregunta: '',
            preguntaError: null
        }
        case 'OpenContactSellerModal' : return {
            ...state,
            openModalContactSeller: true,
        }
        case 'CloseContactSellerModal' : return {
            ...state,
            openModalContactSeller: false,
            mensajePublica: undefined
        }
        case 'OpenReportPublicationModal' : return {
            ...state,
            openModalReportPublication: true,
        }
        case 'CloseReportPublicationModal' : return {
            ...state,
            openModalReportPublication: false,
            mensajePublica: undefined
        }

        case 'OpenImagesZoomCarousel' : return {
            ...state,
            modalImagesZoomCarousel: true,
        }
        case 'CloseImagesZoomCarousel' : return {
            ...state,
            modalImagesZoomCarousel: false,
            mensajePublica: undefined
        }

        case 'MessageContactSellerPublicationModal' : return {
            ...state,
            mensajePublica: action.payload.messageContactSellerPublication,
        }
        case 'MessageReportPublicationModal' : return {
            ...state,
            mensajePublica: action.payload.messageReportPublication,
        }
        case 'SetImagesZoomCarousel' : return {
            ...state,
            indexCarousel: action.payload.clicID,
        }

        default: return state;
    }
}

//METODO ON CHANGE GENERAL
export const onChange = (onChangeData: OnChangePayload): PublicationDataActions => {
    return {
        type: 'OnChange',
        payload: onChangeData
    }
}
//METODO SET ERROR GENERAL
export const setError = (onErrorData: OnErrorPayload): PublicationDataActions => {
    return {
        type: 'OnError',
        payload: onErrorData
    }
}
//METODO CLEAN ERROR GENERAL
export const cleanError = (onCleanErrorData: OnCleanErrorPayload): PublicationDataActions => {
    return {
        type: 'OnCleanError',
        payload: onCleanErrorData
    }
}

//CREAMOS LAS ACCIONES DEL REDUCER
type startUpdatePublicationDataParameter = { stateLocalPublication: PublicationDataReducerType, dispatchLocalPublication: React.Dispatch<PublicationDataActions>
                                             publicacionDataId: string };
export const startUpdatePublicationData = async (startUpdatePublicationData: startUpdatePublicationDataParameter) => {

        const { dispatchLocalPublication, publicacionDataId } = startUpdatePublicationData;

        dispatchLocalPublication(preparePublicationData());

        const body = await fetchSinToken(`publicaciones/${ publicacionDataId }`, {}, 'GET');

        console.log(body);

        if (body.ok){
            dispatchLocalPublication( loadPublicationData({
                publicacionDatos: {
                    icon: body.res.marcaId.icono,
                    marca: body.res.marcaId.marca,
                    modelo: body.res.modeloId.modelo,
                    anio: body.res.anio,
                    anioInicial: body.res.anioInicial,
                    anioFinal: body.res.anioFinal,
                    version: body.res.version,
                    motor: body.res.motor,
                    transmision: body.res.transmision,
                    descripcion: body.res.descripcion,
                    fotoPublicacion: body.res.fotoPublicacion,
                    fotoIzquierda: body.res.fotoIzquierda,
                    fotoDerecha: body.res.fotoDerecha,
                    fotoFrontal: body.res.fotoFrontal,
                    fotoPosterior: body.res.fotoPosterior,
                    fotoMotor: body.res.fotoMotorSuperior,
                    fotoInterior: body.res.fotoInteriorInferior,
                    estado: body.res.estado,
                    tipo: body.res.tipo,
                    precio: body.res.precio
                }, 
                vendedorDatos: {
                    idVendedor: body.res.userId._id,
                    nombre: body.res.userId.nombre,
                    apellidos: body.res.userId.apellidos,
                    telefono: body.res.userId.telefono,
                    establecimiento: body.res.userId.establecimiento,
                    fotoPortada: body.res.userId.fotoPortada,
                    fotoLogo: body.res.userId.fotoLogo
                }
            }));
        } else {
            //console.log('Respuesta falsa');
            dispatchLocalPublication( noPublicationData({ messagePublication: body.msg }))
        }
}
const preparePublicationData = (): PublicationDataActions => {
    return {
        type: 'PreparePublicationData',
    }
}
const loadPublicationData = (publicationInfoData: PublicationDataPayload): PublicationDataActions => {
    return {
        type: 'LoadPublicationData',
        payload: publicationInfoData
    }
}
const noPublicationData = (noPublicationData: NoPublicationDataPayload): PublicationDataActions => {
    return {
        type: 'NoPublicationData',
        payload: noPublicationData
    }
}

export const startUpdateQuestionsData = async ( startUpdatePublicationData: startUpdatePublicationDataParameter ) => {
        const { dispatchLocalPublication, publicacionDataId } = startUpdatePublicationData;

        dispatchLocalPublication(prepareQuestionsData());

        const body = await fetchSinToken(`preguntas/${ publicacionDataId }`, {}, 'GET');

        //console.log(body);

        if (body.ok){
            let questionsArray: QuestionsDataType[] = [];
            for (let question of body.res){
                questionsArray.push({
                    idPregunta: question._id,
                    estadoPregunta: question.estado,
                    pregunta: question.pregunta,
                    respuesta: question.respuesta,
                    idPublicacion: question.publicacionId,
                    //nombreVendedor: question.userId.nombre,
                    //idVendedor: question.userId._id
                });
            }
            dispatchLocalPublication( loadQuestionsData({ preguntas: questionsArray }))
        } else {
            dispatchLocalPublication( noQuestionsData({ messageQuestions: body.msg }))
        }
}
const prepareQuestionsData = (): PublicationDataActions => {
    return {
        type: 'PrepareQuestionsData',
    }
}
const loadQuestionsData = (QuestionsData: QuestionsDataPayload): PublicationDataActions => {
    return {
        type: 'LoadQuestionsData',
        payload: QuestionsData
    }
}
const noQuestionsData = (noQuestionsData: NoQuestionsDataPayload): PublicationDataActions => {
    return {
        type: 'NoQuestionsData',
        payload: noQuestionsData
    }
}

type createQuestionParameter = { /*stateLocalPublication: PublicationDataReducerType,*/ dispatchLocalPublication: React.Dispatch<PublicationDataActions>
                                 publicationId: string | null, question: string | null };
export const startCreateQuestion = async (createQuestionData: createQuestionParameter) => {

        const { /*stateLocalPublication,*/ dispatchLocalPublication, publicationId, question } = createQuestionData;

        const body = await fetchToken(`preguntas`, { 
            publicationId: publicationId,
            question: question,
        }, 'POST');

        //console.log(body);

        if ( body.ok ) {
            dispatchLocalPublication(receiveMessageQuestion({ receiveMessageQuestion: 'Pregunta enviada correctamente' }))
        } else {
            dispatchLocalPublication(receiveMessageErrorQuestion({ receiveMessageErrorQuestion: body.msg }))
        }
}
const receiveMessageQuestion = (receiveMessageQuestionData: ReceiveMessagePayload): PublicationDataActions => {
    return {
        type: 'ReceiveMessage',
        payload: receiveMessageQuestionData
    }
}
const receiveMessageErrorQuestion = (receiveMessageErrorQuestionData: ReceiveMessageErrorPayload): PublicationDataActions => {
    return {
        type: 'ReceiveErrorMessage',
        payload: receiveMessageErrorQuestionData
    }
}
export const clearQuestionError = (): PublicationDataActions  => {
    return {
        type: 'ClearQuestionError'
    }    
}

export const openModalQuestion = (): PublicationDataActions  => {
    return {
        type: 'OpenModalQuestion'
    }    
}
export const closeModalQuestion = (): PublicationDataActions  => {
    return {
        type: 'CloseModalQuestion'
    }    
}
export const openCreateQuestionModal = (): PublicationDataActions => {
    return {
        type: 'OpenCreateQuestionModal',
    }
}
export const closeCreateQuestionModal = (): PublicationDataActions => {
    return {
        type: 'CloseCreateQuestionModal'
    }
}

type startContactSellerPublicationParameter = { /*stateLocalPublication: PublicationDataReducerType,*/ dispatchLocalPublication: React.Dispatch<PublicationDataActions>
                                                parametro: string };
export const startContactSellerPublication = async (startContactSellerPublication: startContactSellerPublicationParameter) => {

    const { dispatchLocalPublication, parametro } = startContactSellerPublication;

    const body = await fetchToken('test/pruebaTokenError', {parametro}, 'POST');

    //console.log(body);

    if (body.ok) {
        
    } else {
        dispatchLocalPublication( messageContactSellerPublicationModal({
            messageContactSellerPublication: body.msg
        }));
    }
}
const messageContactSellerPublicationModal = ( messageContactSellerPublicationData: ContactSellerPublicationPayload ) : PublicationDataActions => {
    return {
        type: 'MessageContactSellerPublicationModal',
        payload: messageContactSellerPublicationData
    }
}
export const openContactSellerModal = (): PublicationDataActions => {
    return {
        type: 'OpenContactSellerModal',
    }
}
export const closeContactSellerModal = (): PublicationDataActions => {
    return {
        type: 'CloseContactSellerModal'
    }
}

type startReportPublicationParameter = { /*stateLocalPublication: PublicationDataReducerType,*/ dispatchLocalPublication: React.Dispatch<PublicationDataActions>
                                         parametro: string };
export const startReportPublication = async (startReportPublication: startReportPublicationParameter) => {

    const { dispatchLocalPublication, parametro } = startReportPublication;

    const body = await fetchToken('test/pruebaTokenError', {parametro}, 'POST');

    //console.log(body);

    if (body.ok) {
        
    } else {
        dispatchLocalPublication( messageReportPublicationModal({
            messageReportPublication: body.msg
        }));
    }
}
const messageReportPublicationModal = ( messageReportPublicationData: ReportPublicationPayload ) : PublicationDataActions => {
    return {
        type: 'MessageReportPublicationModal',
        payload: messageReportPublicationData
    }
}
export const openReportPublicationModal = (): PublicationDataActions => {
    return {
        type: 'OpenReportPublicationModal',
    }
}
export const closeReportPublicationModal = (): PublicationDataActions => {
    return {
        type: 'CloseReportPublicationModal'
    }
}

export const openImagesZoomCarousel = (): PublicationDataActions => {
 
    return {
        type: 'OpenImagesZoomCarousel'
    }
}
export const setImagesZoomCarousel = (clicPublicationPayload: ClicPublicationPayload): PublicationDataActions => {
    return {
        type: 'SetImagesZoomCarousel',
        payload : clicPublicationPayload
    }
}
export const closeImagesZoomCarousel = (): PublicationDataActions => {
    return {
        type: 'CloseImagesZoomCarousel'
    }
}

//CREAMOS LAS ACCIONES DEL REDUCER
type startUpdateHistoryPublicationParameter = { /*stateLocalPublication: PublicationDataReducerType,*/ dispatchLocalPublication: React.Dispatch<PublicationDataActions>
                                                publicacionId: string };
export const startUpdateHistoryPublication = async (startUpdatePublicationData: startUpdateHistoryPublicationParameter) => {

        const { dispatchLocalPublication, publicacionId } = startUpdatePublicationData;

        dispatchLocalPublication(preparePublicationData());

        const body = await fetchToken('publicaciones/historial', {publicacionId}, 'POST');

        //console.log(body);
}