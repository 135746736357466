import NumberFormat from "react-number-format";
import React from 'react';

export function formatoMoneda (value:any):String {
    return (typeof(value)=='number' ? Number(((Math.round(Number(value)*100)/100)).toFixed(2)).toLocaleString('es-MX',{ style: 'currency', currency: 'MXN'}) : 'Valor no monetario')    
}


export const formatoMonedaInput = React.forwardRef(function NumberFormatCustom(props: any, ref) {
    const { onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        prefix="$ "
        placeholder="$ 0.00"
        thousandSeparator
        decimalScale={2}
        fixedDecimalScale        
        // isNumericString
      />
    );
  });
