
import { Alert, Box, Button, Dialog, IconButton, TextField, Typography, useMediaQuery } from "@mui/material"
import { useContext, useEffect, useState } from "react";
import SVG from 'react-inlinesvg';
import { AccountContext } from "../../../context/AccountContext";
import { closeEditEstablecimiento } from "../../../reducers/AccountReducer";
import validator from 'validator';

export const EditarEstablecimiento = () => {

    const responsive = useMediaQuery('(max-width:300px)');

    const { accountReducerState, dispatch } = useContext(AccountContext);
    const { /*name, lastName, mensajeEditar*/ editarEstablecimiento, establecimiento, mensajeEditar } = accountReducerState;

    type editFormName = {
      nombreEstablecimiento: string,
    }
    const initialFormValues : editFormName = {
      nombreEstablecimiento: ''
    }
    const [ formValues, setFormValues ] = useState(initialFormValues);
    const { nombreEstablecimiento } = formValues;

    useEffect(() => {
      setFormValues({
        nombreEstablecimiento: establecimiento,
      })
    }, [ editarEstablecimiento, establecimiento ]);

    const handleEditInputChange = ({target}:any) => {
      setFormValues({
          ...formValues,
          [target.name]: target.value
      });
    }

    const [nombreEstablecimientoError, setNombreEstablecimientoError] = useState(false);
    const [nombreEstablecimientoTextError, setNombreEstablecimientoTextError] = useState('');

    const handleChangeEstablecimiento = () => {
      let error = false;
      if (!nombreEstablecimiento) {
        setNombreEstablecimientoError(true);
        setNombreEstablecimientoTextError('Escriba el nombre');
        error = true;
      } else {
        if (!validator.isLength(nombreEstablecimiento,{min: 2})) {
          setNombreEstablecimientoError(true);
          setNombreEstablecimientoTextError('Ingrese más valores');
          error=true;
        } else { 
          setNombreEstablecimientoError(false);
          setNombreEstablecimientoTextError('');
        }
      }
      if (!error) {
          //dispatch(startEditName({dispatch: dispatch, nombre: nombre, apellido: apellidos}))
          dispatch (closeEditEstablecimiento());
      }
    }

    const handleClickCloseEditEstablecimientoModal = () => {
      setNombreEstablecimientoError(false)
      setNombreEstablecimientoTextError('');
      dispatch (closeEditEstablecimiento());
    }

  return (
    <Dialog
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={editarEstablecimiento}
      //onClose={handleClickCloseEditEstablecimientoModal}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            maxWidth: "600px",
          },
        },
      }}
    >
      <Box sx={{ backgroundColor: "rgb(0, 93, 199)", color: "white", p: (responsive ? 2 : 3), display: "flex" }}>
        <Typography variant='h5'>Editar Establecimiento</Typography>
        <IconButton
          aria-label="close"
          onClick={handleClickCloseEditEstablecimientoModal}
          sx={{
            position: 'absolute',
            right: 18
          }}
        >
          <SVG src={process.env.REACT_APP_ICONSERVER+'dz-error'} width={20} fill="white" />
        </IconButton>
      </Box>
      <Box sx={{ p: (responsive ? "20px 20px 0px 20px" : "30px 30px 0px 30px") }}>
        <Typography fontSize={(responsive ? "15px" : "16px")}>
          <b>¿Quieres cambiar el nombre de tu establecimiento?</b>
        </Typography>
        <br/>
        <p>
          <li>Al editar, asegúrate que estén correctos tus datos</li>
        </p>
      </Box>
      <Box sx={{ p: (responsive ? "10px 20px 0px 20px" : "20px 30px 0px 30px") }}>
        <TextField
          id="nombreEstablecimiento" margin="dense" type="text" fullWidth variant="outlined"
          value={ nombreEstablecimiento } name='nombreEstablecimiento' onChange={ handleEditInputChange } //onChange={ ({target}) => onChange( target.value, 'nombre') }
          error={ nombreEstablecimientoError } label={nombreEstablecimientoTextError ? nombreEstablecimientoTextError : 'Nombre'}
        />
        <Typography sx={{ mt: 2 }}>
          Una vez corroborado tus datos, guarda los cambios.
        </Typography>
      </Box>
      {
        mensajeEditar && <Alert severity="error" sx={{ marginTop: 2 }}> {mensajeEditar} </Alert>
      }
      <Box sx={{ display: "flex", justifyContent: "center", p: (responsive ? 2 : 3) }}>
        <Box textAlign={(responsive ? 'center' : 'unset')}>
          <Button
            color="warning" sx={{ marginRight: (responsive ? 0 : 1) }} size="large"
            onClick={handleClickCloseEditEstablecimientoModal}
          >
            Cancelar
          </Button>
          <Button
            variant="contained" size="large" sx={{ marginLeft: (responsive ? 0 : 1) }}
            onClick={()=>{handleChangeEstablecimiento()}}
          >
            Guardar
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}