import "./styles/yesnoModal.scss"

import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../store/store';
import { Dialog, Button, Typography, Box } from '@mui/material';
import { startCloseYesNoDialog } from '../reducers/dialogsReducer';

export const YesNoDialogModal= () => {

    //LEER INFORMACION DE REDUX
    const storeDialogs = (state: RootState ) => state.dialogs;
    const { yesnoShowDialog, yesnoTitle, yesnoContent } = useSelector(storeDialogs);
    const dispatchRedux = useDispatch<AppDispatch>();

    const handleClose = (yesnoSelection: boolean) => {
        dispatchRedux<any>(startCloseYesNoDialog({yesnoSelection}));
    }

    return (
      <Dialog
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={yesnoShowDialog}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              maxWidth: "1200px",
            },
          },
        }}
      >
        <Box sx={{ backgroundColor: "#ff6700", color: "white", p: 3, display: "flex" }}>
          <Typography variant='h5'>{yesnoTitle}</Typography>
        </Box>
        <Box sx={{ p: "25px 20px 10px 20px" }}>
          <Typography sx={{ fontSize: "18px" }}>{yesnoContent}</Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", p: 2 }} >
          <Box>
            <Button
              variant="contained" color="warning" sx={{ marginRight: 1 }} size="large"
              onClick={() => { handleClose(false) }}
            >
              No
            </Button>
            <Button
              variant="contained" size="large" sx={{ marginLeft: 1 }}
              onClick={() => { handleClose(true) }}
            >
              Si
            </Button>
          </Box>
        </Box>
      </Dialog>
      
    );
}