import Modal from 'react-modal';
import { RootState, AppDispatch } from '../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { messagesIds, closeMessage, modalStyles } from '../../reducers/modalReducer';
import { Alert } from './Alert';

//import { clearAlerts, clearNotifications, desactivationMessage } from '../../reducers/navbarReducer';
import SVG from 'react-inlinesvg';

//Configuracion del estilo del componente Modal
const styleMessage : modalStyles = {
    overlay: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(21, 34, 56, 0.4)'
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: '',
        marginRight: '-50%',
        transform: 'translate(-50%, -60%)'
    }
};

//enviamos el id del documento principal html 
Modal.setAppElement('#root');

export const ShowMessage = () => {

    // console.log('Se renderizo Submodal');
   
    //LEER VARIABLES DE REDUX
    const storeDarkMode = (state: RootState ) => state.darkmode;
    const { darkMode } = useSelector(storeDarkMode);
    const storeModal = (state: RootState) => state.modal;
    const { showMessage } = useSelector(storeModal);
    const { modaltype, title } = useSelector(storeModal);

    //ESCRIBIR EN EL STORE DE REDUX
    const dispatchRedux = useDispatch<AppDispatch>();  

    //EVALUAMOS EL TIPO DE MODAL A MOSTRAR
    let componentJSX: JSX.Element = <></>;
    let iconJSX: JSX.Element = <></>;
    switch (modaltype) {
        case messagesIds.Alert:
            componentJSX = <Alert />
            iconJSX = <SVG src={process.env.REACT_APP_ICONSERVER+'dz-alert-circle'} height={35} fill="currentColor"/>
        break;
        default:
            componentJSX = 
                <h1 className="modal_main">
                    Sin contenido
                </h1>        
    }

    //ONCLOSE DEL MODAL
    const handleClose = () => {
        //EJECUTAMOS FUNCIONES ANTES DE CERRAR
        switch (modaltype) {
            case messagesIds.Alert:
                // dispatchRedux<any>(desactivationMessage());
            break;    
        }
        //cerramos el modal
        dispatchRedux(closeMessage());
    }
    
    return (
        <>
            < Modal
                //closeTimeoutMS={200}
                isOpen={ showMessage }          
                onRequestClose={ handleClose }
                style={ styleMessage }
            > 
                <div className={ 
                (darkMode) 
                    ? 'modal_main modal_dark_theme' 
                    : 'modal_main modal_light_theme'
                }>
                    <div className="modal_title ">
                        <div className="modal_titleicon">
                            { iconJSX }
                            <p>{ title }</p>
                        </div>
                        <div>
                            <button 
                                type="button" 
                                className={ 
                                    (darkMode) 
                                    ? 'btn-close btn-close-white' 
                                    : 'btn-close'
                                }
                                aria-label="Close"
                                onClick={ handleClose }
                            />    
                        </div>
                    </div>
                    { componentJSX }  
                </div>    
            </Modal>
        </>
    )
}