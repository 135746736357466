
import { Alert, Box, Button, Dialog, IconButton, Typography } from "@mui/material"
import { useContext } from "react";
import { SalesContext } from "../context/SalesContext";
import { closeEditarPubModal, startEditPublication } from "../reducers/SalesReducer";

import SVG from 'react-inlinesvg';

export const EditarPublicaModal = () => {

    //LEER EL REDUCER LOCAL
    const { salesReducerState, dispatchSales } = useContext(SalesContext); 
    const { editarPubModal, mensajeAccion } = salesReducerState;

  return (
    <Dialog
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={editarPubModal}
      onClose={()=>{dispatchSales(closeEditarPubModal())}}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            maxWidth: "1200px",
          },
        },
      }}
    >
      <Box sx={{ backgroundColor: "rgb(0, 93, 199)", color: "white", p: 3, display: "flex" }}>
        <Typography variant='h5'>Editar Publicacion</Typography>
        <IconButton
          aria-label="close"
          onClick={()=>{dispatchSales(closeEditarPubModal())}}
          sx={{
            position: 'absolute',
            right: 18
          }}
        >
          <SVG src={process.env.REACT_APP_ICONSERVER+'dz-error'} width={20} fill="white" />
        </IconButton>
      </Box>
      <Box sx={{ p: 2, width: 300 }}>
        Datos de la publicacion
      </Box>
      {
        mensajeAccion && <Alert severity="error"> {mensajeAccion} </Alert>
      }
      <Box sx={{ display: "flex", justifyContent: "center", p: 2 }} >
        <Box>
          <Button
            color="warning" sx={{ marginRight: 1 }} size="large"
            onClick={()=>{dispatchSales(closeEditarPubModal())}}
          >
            Cancelar
          </Button>
          <Button
            variant="contained" size="large" sx={{ marginLeft: 1 }}
            onClick={()=>{dispatchSales(startEditPublication({dispatchLocalSales: dispatchSales, parametro: 'Editar'}))}}
          >
            Aceptar
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}