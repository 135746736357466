import { Redirect, Route, RouteProps } from 'react-router';
import { RootState } from '../store/store';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';

export type ProtectedRouteProps = {
  isAuthenticated: boolean,
  authenticationPath: string,
  redirectPath: string,
} & RouteProps;

export default function ProtectedRoute({isAuthenticated, authenticationPath, redirectPath, ...routeProps}: ProtectedRouteProps) {

   //LEER VARIABLES DE REDUX
   const storeAuth = (state: RootState ) => state.auth;
   const { checking } = useSelector(storeAuth);
 
   if (checking) {
     return (<div className='public_route_container_cargando'><div className='public_route_cargando'><CircularProgress color="warning"/> Cargando...</div></div>)
   } else {
     if(!isAuthenticated) {
         return <Redirect to={{ pathname: isAuthenticated ? redirectPath : authenticationPath }} />;
         } else {
         return <Route {...routeProps} />;
     }
   }


  //  if(isAuthenticated) {
  //   return <Route {...routeProps} />;
  // } else {
  //   return <Redirect to={{ pathname: isAuthenticated ? redirectPath : authenticationPath }} />;
  // }



};