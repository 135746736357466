import { useCallback, useEffect, useState } from 'react';
import { Socket, io } from 'socket.io-client';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../store/store';
import { startLoadAlerts, startLoadMessages, startLoadNotifications, startLoadNavbar } from '../reducers/navbarReducer';

export type socketTypes = 'Alert' | 'Message' | 'Notification' | 'Hotel' | 'Motel' | 'Restaurante';

export const useSocket = ( serverPath: string ) => {

    //ESCRIBIR EN EL STORE DE REDUX
    const dispatchRedux = useDispatch<AppDispatch>();

    // const socket: Socket = useMemo(() => io( serverPath, {transports: ['websocket']} ), [ serverPath ] );
    const [ online, setOnline ] = useState <boolean> (false);
    const [ socket, setSocket ] = useState <Socket | undefined> (undefined);

    const token = localStorage.getItem('token') || ''; 

    const conectarSocket = useCallback(() => {
            setSocket(io(serverPath, { 
                transports: ['websocket'],
                autoConnect: true,
                forceNew: true,
                query: {
                    'token': token
                }
            }));  
        },
        [serverPath, token],
    )

    const desconectarSocket = useCallback(() => {
            socket?.disconnect();
            setSocket(undefined); 
        },
        [socket],
    ) 

    const enviarSocket = (payload: socketTypes) => {
        socket?.emit('Updating',payload)
    }

    useEffect(() => { 
        setOnline( socket?.connected! );

        socket?.on('connect', () => {
            setOnline(true);
        });

        socket?.on('disconnect', () => {
            setOnline(false);
        });
    }, [socket])

    useEffect(() => {
        online ? console.log('Socket Conectado') : console.log('Socket Desconectado');
        if (online) {
            console.log('Cargando Alertas Mensajes Notificaciones');
            dispatchRedux<any>(startLoadNavbar());
        }
    }, [online, dispatchRedux])

    useEffect(() => {
        socket?.on('Updating', async (payload: socketTypes ) => {
            console.log(payload);
            switch (payload) {
                case 'Alert':
                    await dispatchRedux<any>(startLoadAlerts());
                break;
                case 'Message':
                    dispatchRedux<any>(startLoadMessages());
                break;
                case 'Notification':
                    dispatchRedux<any>(startLoadNotifications());
                break;
            }
        })
    }, [socket, dispatchRedux])

    return {
        socket,
        online,
        conectarSocket,
        desconectarSocket,
        enviarSocket

    }
}