import { Box } from "@mui/material"
import "./styles/shopping.scss"
//import { RootState } from '../../../../store/store';
//import { useSelector } from 'react-redux';
//import { useContext, useEffect, useState } from 'react';
//import { startLoadShopping, showModalShopping } from './reducers/ShoppingReducer';
//import { ShoppingContext } from './context/ShoppingContext';
//import { CircularProgress, Button, CardMedia, InputBase, Paper, IconButton, Menu, MenuItem } from '@mui/material';
//import { BuyModal } from './BuyModal';

//import SVG from 'react-inlinesvg';

export const MyShopping = () => {

    //CARGAR COMPRAS DEL USUARIO
    //LEER INFORMACION DE REDUX
    //const storeAuth = (state: RootState ) => state.auth;
    //const { id } = useSelector(storeAuth);
    //LEER EL REDUCER LOCAL
    //const { shoppingReducerState, dispatch } = useContext(ShoppingContext); 
    //const { shopping } = shoppingReducerState; 
    //cargar los datos del usuario a travez del reducer
    /*useEffect(() => {
        startLoadShopping({id, dispatch});
    }, [id, dispatch])*/

    // funciones para el submenu de "Opciones"
    //const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    //const open = Boolean(anchorEl);
    /*const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
      setAnchorEl(null);
    };*/

    /*const filtros = [
      { key: 'a', filtro: 'Todas mis compras' },
      { key: 'b', filtro: 'Este mes' },
      { key: 'c', filtro: 'El mes pasado' },
      { key: 'd', filtro: 'Hace 6 meses' },
      { key: 'e', filtro: 'Este año' },
      { key: 'f', filtro: 'Años anteriores' },
    ];*/

    return (
      <>
        {/*<BuyModal/> {
          shoppingReducerState.loading ? (
            <div className="shopping_wait"> 
              <CircularProgress color="warning"/>Cargando...
            </div>) : (            
            <div className="shopping_main">
              <div className="shopping_menu">
                <div className="shopping_titulo_principal">
                  Mis Compras
                </div>
                <div className="shopping_search">
                  <div className="shopping_search_input">
                    <Paper
                      component="form"
                      sx={{ p: '2px 4px'}}
                    >
                      <IconButton aria-label="buscar">
                        <SVG src={process.env.REACT_APP_ICONSERVER+'dz-search'} width={30}/>
                      </IconButton>
                      <InputBase
                        sx={{ ml: 1, flex: 2 }}
                        placeholder="Buscar"
                      />
                    </Paper>
                  </div>
                  <div className="shooping_search_button">
                    <Button variant="outlined" onClick={handleClick} endIcon={<SVG src={process.env.REACT_APP_ICONSERVER+'dz-angle-down'}
                      width={20} fill="rgb(0, 93, 199)"/>} sx={{ textTransform: 'none' }}
                    >
                      Filtrar
                    </Button>
                    <Menu
                      id="opciones"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleCloseMenu}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      {filtros.map((opcion) => (
                        <MenuItem
                          key={opcion.key}
                          onClick={handleCloseMenu}
                        >
                          {opcion.filtro}
                        </MenuItem>
                      ))}
                    </Menu>
                  </div>
                  <div className="shopping_search_filter">
                    Todas mis compras
                  </div>
                  <div className="shooping_search_numberShopping">
                    { shopping?.length } compras
                  </div>
                </div>
              </div>
              <div className="shopping_section">
                {
                shopping ? (
                  shopping.map((buy)=> (
                    <div className="shopping_format" key={ buy.id }>
                      <div className="shopping_image">
                        <CardMedia
                          component="img"
                          image="./assets/carro-6.png"
                          alt="car"
                          className="shopping_image_style"
                        />
                      </div>
                      <div className="shopping_datos">
                        <div className="shopping_datos_nombre_producto">
                          Ford Explorer 2015 (partes)
                        </div>
                        Vendedor: { buy.venedor }
                        <div className="shopping_datos_precio">
                          $ { buy.total }
                        </div>
                        <div className="shopping_datos_estado">
                          <div>
                            {
                              ( function() {
                                switch ( buy.estado ) {
                                  case 'Entregado':
                                    return <SVG src={process.env.REACT_APP_ICONSERVER+'dz-home'} width={35} fill="green"/>
                                  case 'Por llegar':
                                    return <SVG src={process.env.REACT_APP_ICONSERVER+'dz-shipping'} width={35} fill="rgb(0, 89, 255)"/>
                                  case 'Pendiente de envio':
                                    return <SVG src={process.env.REACT_APP_ICONSERVER+'dz-package'} width={35} fill="rgb(255, 145, 0)"/>
                                }
                              })()
                            }
                          </div>
                          <div>
                            {buy.estado}
                          </div>
                        </div>
                        <div className="shooping_button">
                          <Button color="warning" onClick={()=>{dispatch(showModalShopping(buy))}} sx={{ textTransform: 'none' }}>Ver Compra</Button>
                        </div>
                      </div>
                    </div>
                ))) :
                (
                  <div className="shopping_format">
                    <p>Aún no has generado ninguna compra.</p>
                    <Button variant="contained" color="warning">Buscar Refaccion</Button>
                  </div>
                )
              }
              </div>
            </div>
          )
        }*/}

        <div className="ventas_publications_container">
          <div className="ventas_proximo_container">
            <svg x="0px" y="0px" viewBox="0 0 500 400" className="ventas_icon_soon">
              <path d="M438.7,227.6l36-154.9c2.7-11.7-6.2-22.9-18.2-22.9H149.6l-8.2-36.4C139.6,5.6,132.7,0,124.7,0H43.8c-10.5,0-19,8.5-19,19
                v12.9c0,10.5,8.5,19,19,19h54.8l55,267.3c-14.1,7.8-23.4,23.1-22.5,40.5c1.2,22.4,19.5,40.4,41.8,41.3c24.9,0.9,45.3-18.9,45.3-43.6
                c0-11.8-4.7-22.5-12.3-30.4H368c-8.2,8.4-13,20.1-12.3,32.9c1.3,22.3,19.6,40.2,41.8,41c24.9,0.9,45.3-18.9,45.3-43.6
                c0-17.2-10-32.1-24.5-39.2l4.3-18.7c2.7-11.7-6.2-22.9-18.2-22.9H195.8l-7.7-33.5l232.4,0C429.2,242.1,436.8,236.1,438.7,227.6z
                M422.2,96.3L415,131h-50.7l7.1-34.7H422.2z M327.2,96.3l-7,34.7h-56.8l-6.9-34.7H327.2z M210.4,96.3l7.1,34.7h-50.7l-7.1-34.7
                H210.4z M179.8,194.6l-7.1-34.7h50.6l7.1,34.7H179.8z M276,194.6l-6.9-34.7h45.3l-7,34.7H276z M351.4,194.6l7.1-34.7h50.6l-7.1,34.7
                H351.4z"/>
            </svg>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <div> Mis Compras <br/> <p>Proximamente</p> </div>
            </Box>
          </div>
        </div>

      </>
    )
}