import { createContext, useReducer, useState } from 'react';
import { PrincipalUsers } from '../PrincipalUsers';
import { initialUsersState, usersReducer, UsersReducerType } from '../reducer/UsersReducer';

//DEFINIMOS UN CONTEXT PARA MANEJAR VALORES DE PRINCIPAL USERS
type principalUsersContextType = {
    findUsersModal: boolean,
    setFindUsersModal: React.Dispatch<any>,
    usersReducerState: UsersReducerType,
    dispatchUser: React.Dispatch<any>
}
const principalUsersContextDefaultValues: principalUsersContextType = {
    findUsersModal: false,
    setFindUsersModal: () => null,
    usersReducerState: initialUsersState,
    dispatchUser: () => null
};

export const PrincipalUsersContext = createContext<principalUsersContextType>(principalUsersContextDefaultValues);

//creamos el Functional Component
export const ContextUsers = () => {

    //REDUCER DEL COMPONENTE
    const [usersReducerState, dispatchUser] = useReducer(usersReducer, initialUsersState)

    const [findUsersModal, setFindUsersModal] = useState(false);

    return (
        <PrincipalUsersContext.Provider value={{ findUsersModal, setFindUsersModal, usersReducerState, dispatchUser }}>
            <PrincipalUsers />
        </PrincipalUsersContext.Provider>
    )
}