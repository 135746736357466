import "./styles/sales.scss"
import { styled } from '@mui/material/styles';
import MuiContainer from '@mui/material/Container';
import { AppDispatch, RootState } from '../../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect, useState } from 'react';
import { MenuContext, SalesContext } from './context/SalesContext';
import { CircularProgress, useMediaQuery } from '@mui/material';
import { SalesModal } from './SaleModal';

import { PackagesModal } from '../../../../modals/PackagesModal';
import {  startLoadBusiness } from '../../../../reducers/businessReducer';
import { NewPublicationModal } from "./NewPublicationModal";
import { PublicationModal } from "./PublicationModal";
import { CarModal } from "./CarModal";

import SVG from 'react-inlinesvg'; 
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import List from '@mui/material/List';
import { startLoadSales } from './reducers/SalesReducer';
import { withStyles } from "@material-ui/core";

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0.5, 1.5),
}));

const Container = styled(MuiContainer)(
  ({ theme }) => ({
      width: 200,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
      }),
      overflowX: 'hidden',
  }),
); 

const StyledListItem = withStyles({
  root: {
    "&$selected": {
      backgroundColor: "#ff6700",
      color: "white",
      "& .MuiListItemIcon-root": {
        color: "white",
        fill: "white"
      }
    },
    "&$selected:hover": {
      backgroundColor: "#ff6700",
      color: "white",
      "& .MuiListItemIcon-root": {
        color: "white"
      }
    },
    "&:hover": {
      backgroundColor: "#ff66003f",
      "& .MuiListItemIcon-root": {
        color: "white"
      }
    }
  },
  selected: {}
})(ListItemButton);

const menu = ['Mi plan','Mis publicaciones', 'Mis ventas'];

export const MySales = () => {
   
    //HOOKS DE CONTROL DE MENU
    const [open, setOpen] = useState(true);
    const handleDrawerClose = () => {
      open ? setOpen(false) : setOpen(true);
    };
    //MENU RESPONSIVE
    const responsive = useMediaQuery('(max-width:900px)');
    const responsive2 = useMediaQuery('(max-width:300px)');
    useEffect(() => {
        responsive ? setOpen(false) : setOpen(true);
    }, [responsive])

    //LEER EL CONTEXT PARA CAMBIAR EL COMPONENTE DEL MENU
    const { componentJSX, indexComponent, setIndexComponent } = useContext( MenuContext );
    const handleClick = (index: number) => {
        setIndexComponent(index);
    }

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [])

    //CARGAR DATOS DE NEGOCIO
    const dispatchRedux = useDispatch<AppDispatch>();
    useEffect(() => {
        dispatchRedux<any>(startLoadBusiness());
    }, [dispatchRedux])

    //CARGAR VENTAS DEL USUARIO
    //LEER INFORMACION DE REDUX
    const storeBusiness = (state: RootState ) => state.business;
    const { plan, reload } = useSelector(storeBusiness);
    const storeAuth = (state: RootState ) => state.auth;
    const { id, rol } = useSelector(storeAuth);

    useEffect(() => {
      if (rol === "VENDEDOR") {
        setIndexComponent(1);
      }
    }, [rol, setIndexComponent])

    //LEER EL REDUCER LOCAL
    const { salesReducerState, dispatchSales } = useContext(SalesContext);
    const { loading, reloadPublications } = salesReducerState;
    //CARGAR LAS PUBLICACIONES AL INICIAR
    useEffect(() => {
        if (plan!=='BASICO'){
            startLoadSales({id, dispatchSales});
        }
    }, [id, plan, dispatchSales])
    
    //RECARGAR LAS PUBLICACIONES 
    useEffect(() => {
        if (reloadPublications){ 
            startLoadSales({id, dispatchSales});
            dispatchRedux<any>(startLoadBusiness());
            //console.log('Cargando publicaciones nuevamente2');
        }
    }, [id, reloadPublications, dispatchSales, dispatchRedux])
    
    //VOLVER A CARGAR PAGINA SI SE CAMBIA EL PAQUETE ELEGIDO
    useEffect(() => {
        if (reload) {
            dispatchRedux<any>(startLoadBusiness());
        } 
    }, [reload, dispatchRedux])

    return (
      <>
        <PackagesModal/>
        <SalesModal/> 
        <NewPublicationModal/>
        <PublicationModal/>
        <CarModal/>
        {
          loading ? ( 
          <div className="myquestions_wait"> 
            <CircularProgress color="warning"/>Cargando...
          </div>) : (
            <Box sx={{ display: 'flex' }}>
              <CssBaseline />
              <Container disableGutters maxWidth={false} 
              sx={{ width: open ? 250 : responsive2 ? 50 : 60 }}
              className='myaccount_menu_border'
            >
              <DrawerHeader>
                <IconButton onClick={handleDrawerClose}>
                  {open ? <ChevronLeftIcon/> : <ChevronRightIcon />}
                </IconButton>
              </DrawerHeader>
              <Divider />
              <List>
                {menu.map((text, index) => (
                  <ListItem key={text} disablePadding sx={{ display: 'block' }}>
                    <StyledListItem
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                        
                      }}
                      onClick = {()=> { handleClick(index) } }
                      selected={indexComponent === index}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : 'auto',
                          justifyContent: 'center',
                        }}
                      >
                        {
                          function() {
                            switch (index) {
                              case 0 : return <Box sx={{ width: "26px", height: "26px", display: "flex", alignItems: "center" }}>
                                                <SVG src={process.env.REACT_APP_ICONSERVER+'dz-box'} width={26}/>
                                              </Box>
                              case 1 : return <Box sx={{ width: "26px", height: "26px", display: "flex", alignItems: "center" }}>
                                                <SVG src={process.env.REACT_APP_ICONSERVER+'dz-clipboard-list'} width={26}/>
                                              </Box>
                              case 2 : return <Box sx={{ width: "26px", height: "26px", display: "flex", alignItems: "center" }}>
                                                <SVG src={process.env.REACT_APP_ICONSERVER+'dz-deposit-money'} width={26}/>
                                              </Box>
                            }
                          } ()
                        }
                      </ListItemIcon>
                      <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                    </StyledListItem>
                  </ListItem>
                ))}
              </List>
              <Divider />
              </Container>
              <Box component="main" sx={{ flexGrow: 1 }}>
                { componentJSX }
              </Box>
            </Box> 
          )
        }
     </>
    )
}
