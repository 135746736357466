import { AppThunk } from '../store/store';
import { fetchFormDataToken, fetchToken } from '../helpers/fetch';

//DEFINIMOS LOS TIPOS PARA DEL REDUCER

//definimos la estructura del reducer
interface RegisterState {

    showModal: boolean,
    packageId: string,

    establecimiento: string,
    establecimientoError: string | null,
    fportada: string,
    fportadaError: string | null,
    flogo: string,
    flogoError: string | null,

    rfc: string,
    rfcError: string | null,

    fine: string,
    finepages: number | null,
    finepage: number,
    fineError: string | null,

    frfc: string,
    frfcpages: number | null,
    frfcpage: number,
    frfcError: string | null,
    
    encargado: string,
    encargadoError: string | null,
    contacto: string,
    contactoError: string | null,
    contacto2: string,
    contacto2Error: string | null,

    registerEstablishment: boolean, //indica si ya se actualizaron el establecimiento
    registerEstablishmentSending: boolean, //indica si se esta haciendo fetch para la actualizacion del establecimiento
    registerEstablishmentError: string | null, //indica si el fetch respondio con error

    registerRfc: boolean, //indica si ya se actualizaron el rfc
    registerRfcSending: boolean, //indica si se esta haciendo fetch para la actualizacion del rfc
    registerRfcError: string | null, //indica si el fetch respondio con error

    registerContact: boolean, //indica si ya se actualizaron el encargado y el numero de contacto
    registerContactSending: boolean, //indica si se esta haciendo fetch para la actualizacion del encargado y el numero de contacto
    registerContactError: string | null, //indica si el fetch respondio con error

    registerActivateSeller: boolean, //indica si ya se actualizaron el encargado y el numero de contacto
    registerActivateSellerSending: boolean, //indica si se esta haciendo fetch para la actualizacion del encargado y el numero de contacto
    registerActivateSellerError: string | null, //indica si el fetch respondio con error

    terminosCondicionesSale: boolean,
    avisoPrivacidadSale: boolean

};
//definimos el valor inicial
const initialState: RegisterState = {

    showModal: false,
    packageId: '',

    establecimiento: '',
    establecimientoError: null,
    fportada: '',
    fportadaError: null,
    flogo: '',
    flogoError: null,
    
    rfc: '',
    rfcError: null,

    fine: '',
    finepages: null,
    finepage: 1,
    fineError: null,    

    frfc: '',
    frfcpages: null,
    frfcpage: 1,
    frfcError: null,

    encargado: '',
    encargadoError: null,
    contacto: '',
    contactoError: null,
    contacto2: '',
    contacto2Error: null,

    registerEstablishment: false,
    registerEstablishmentSending: false,
    registerEstablishmentError: null,

    registerRfc: false,
    registerRfcSending: false,
    registerRfcError: null,

    registerContact: false,
    registerContactSending: false,
    registerContactError: null,

    registerActivateSeller: false,
    registerActivateSellerSending: false,
    registerActivateSellerError: null,

    terminosCondicionesSale: false,
    avisoPrivacidadSale: false

}

//definimos los types del reducer
type OnChangePayload = {
    field: keyof RegisterState,
    value: any
}
type OnErrorPayload = {
    errorField: keyof RegisterState,
    label: string
}
type OnCleanErrorPayload = {
    errorField: keyof RegisterState 
}
type UploadFilePayload = {
    field: keyof RegisterState,
    errorField : keyof RegisterState,
    file: string
}
type prepareUploadPayload = {
    field: keyof RegisterState,
    errorField : keyof RegisterState,
}
type pdfLoadSuccessPayload = {
    field: keyof RegisterState,
    pages: number
}

type UpdateFailedPayload = {
    label: string
};

type ShowModalPayload = {
    packageId: string
};

type RegisterActions = 
                        { type: 'OnChange', payload: OnChangePayload } |
                        { type: 'OnError', payload: OnErrorPayload } |
                        { type: 'OnCleanError', payload: OnCleanErrorPayload } |
                        { type: 'PrepareUpload', payload: prepareUploadPayload } |
                        { type: 'UploadFile', payload: UploadFilePayload } | 
                        { type: 'PdfLoadSuccess', payload: pdfLoadSuccessPayload } | 
                        { type: 'ShowModalRegister', payload: ShowModalPayload } | 
                        { type: 'CloseModalRegister' } |
                        { type: 'LogoSending' } |
                        { type: 'IneSending' } |
                        { type: 'EncargadoSending' } |

                        { type: 'PrepareFetchEstablishment' } |
                        { type: 'UpdateEstablishment' } |
                        { type: 'FetchEstablishmentFailed', payload: UpdateFailedPayload } |
                        { type: 'ClearEstablishmentError' } |

                        { type: 'PrepareFetchRfc' } |
                        { type: 'UpdateRfc' } |
                        { type: 'FetchRfcFailed', payload: UpdateFailedPayload } |
                        { type: 'ClearRfcError' } |

                        { type: 'PrepareFetchContact' } |
                        { type: 'UpdateContact' } |
                        { type: 'FetchContactFailed', payload: UpdateFailedPayload } |
                        { type: 'ClearContactError' } |

                        { type: 'PrepareFetchActivateSeller' } |
                        { type: 'UpdateActivateSeller' } |
                        { type: 'FetchActivateSellerFailed', payload: UpdateFailedPayload } |
                        { type: 'ClearActivateSellerError' } |

                        { type: 'AbrirTerminosCondicionesSale' } |
                        { type: 'CerrarTerminosCondicionesSale' } |
                        { type: 'AbrirAvisoPrivacidadSale' } |
                        { type: 'CerrarAvisoPrivacidadSale' } |

                        { type: 'CloseCleanRegister' };

//definimos el reducer
export const registerReducer = (state: RegisterState = initialState, action: RegisterActions): RegisterState => {
    switch (action.type) {
        case 'OnChange' : return {
            ...state,
            [action.payload.field]: action.payload.value
        }
        case 'OnError' : return {
            ...state,
            [action.payload.errorField]: action.payload.label
        }
        case 'OnCleanError' : return {
            ...state,
            [action.payload.errorField]: null
        }
        case 'PrepareUpload' : return {
            ...state,
            [action.payload.field]: null,
            [action.payload.errorField]: null         
        }
        case 'UploadFile' : return {
            ...state,
            [action.payload.field]: action.payload.file
        }
        case 'PdfLoadSuccess' : return {
            ...state,
            [action.payload.field]: action.payload.pages
        }
        case 'ShowModalRegister' : return {
            ...state,
            showModal: true,
            packageId: action.payload.packageId
        }        
        case 'CloseModalRegister' : return {
            ...state,
            showModal: false,
            packageId: ''
        }

        case 'PrepareFetchEstablishment' : return {
            ...state,
            registerEstablishment: false,
            registerEstablishmentError: null,
            registerEstablishmentSending: true,
        }
        case 'UpdateEstablishment' : return {
            ...state,
            registerEstablishment: true,
            registerEstablishmentSending: false
        }
        case 'FetchEstablishmentFailed' : return {
            ...state,
            registerEstablishmentError: action.payload.label,
            registerEstablishmentSending: false
        }
        case 'ClearEstablishmentError' : return {
            ...state,
            registerEstablishment: false,
            registerEstablishmentError: null,
        }        
        
        case 'PrepareFetchRfc' : return {
            ...state,
            registerRfc: false,
            registerRfcError: null,
            registerRfcSending: true,
        }
        case 'UpdateRfc' : return {
            ...state,
            registerRfc: true,
            registerRfcSending: false
        }
        case 'FetchRfcFailed' : return {
            ...state,
            registerRfcError: action.payload.label,
            registerRfcSending: false
        }
        case 'ClearRfcError' : return {
            ...state,
            registerRfc: false,
            registerRfcError: null,
        }

        case 'PrepareFetchContact' : return {
            ...state,
            registerContact: false,
            registerContactError: null,
            registerContactSending: true,
        }
        case 'UpdateContact' : return {
            ...state,
            registerContact: true,
            registerContactSending: false
        }
        case 'FetchContactFailed' : return {
            ...state,
            registerContactError: action.payload.label,
            registerContactSending: false
        }
        case 'ClearContactError' : return {
            ...state,
            registerContact: false,
            registerContactError: null,
        }

        case 'PrepareFetchActivateSeller' : return {
            ...state,
            registerActivateSeller: false,
            registerActivateSellerError: null,
            registerActivateSellerSending: true,
        }
        case 'UpdateActivateSeller' : return {
            ...state,
            registerActivateSeller: true,
            registerActivateSellerSending: false
        }
        case 'FetchActivateSellerFailed' : return {
            ...state,
            registerActivateSellerError: action.payload.label,
            registerActivateSellerSending: false
        }
        case 'ClearActivateSellerError' : return {
            ...state,
            registerActivateSeller: false,
            registerActivateSellerError: null,
        }

        case 'CloseCleanRegister' : return {
            ...state,
            establecimiento: '',
            establecimientoError: null,
            fportada: '',
            fportadaError: null,
            flogo: '',
            flogoError: null,
            fine: '',
            fineError: null,
            rfc: '',
            rfcError: null,
            frfc: '',
            frfcError: null,
            encargado: '',
            encargadoError: null,
            contacto: '',
            contactoError: null,
            contacto2: '',
            contacto2Error: null,
        }

        case 'AbrirTerminosCondicionesSale':
            return {
                ...state,
                terminosCondicionesSale: true
            } 
        case 'CerrarTerminosCondicionesSale':
            return {
                ...state,
                terminosCondicionesSale: false
            } 
        case 'AbrirAvisoPrivacidadSale':
            return {
                ...state,
                avisoPrivacidadSale: true
            } 
        case 'CerrarAvisoPrivacidadSale':
            return {
                ...state,
                avisoPrivacidadSale: false
            }

        default: return state;
    }
}

//METODO ON CHANGE GENERAL
export const onChange = (onChangeData: OnChangePayload): RegisterActions => {
    return {
        type: 'OnChange',
        payload: onChangeData
    }
}
//METODO SET ERROR GENERAL
export const setError = (onErrorData: OnErrorPayload): RegisterActions => {
    return {
        type: 'OnError',
        payload: onErrorData
    }
}
//METODO CLEAN ERROR GENERAL
export const cleanError = (onCleanErrorData: OnCleanErrorPayload): RegisterActions => {
    return {
        type: 'OnCleanError',
        payload: onCleanErrorData
    }
}

//METODO UPLOAD PHOTO GENERAL
//enum para asociar la variable del reducer con su nombre en mongo
export enum fileTypes  { 
    flogo = "fotoLogo", 
    fportada = "fotoPortada",
    fine = "fotoIne",
    frfc = "fotoRfc"
}
type UploadFileParameter = { field: keyof RegisterState, errorField : keyof RegisterState, file: File }
export const startUploadPhoto = (uploadFileData: UploadFileParameter) : AppThunk => {
    return async (dispatch, getState) => {

        const {field, errorField, file} = uploadFileData;

        dispatch(prepareUpload({field, errorField}));

        const userId = getState().auth.id;

        //obteniendo el nombre del campo mongo a partir de su valor en el reducer
        const fieldString = field.toString();
        const index = Object.keys(fileTypes).indexOf(fieldString);
        const tipo = Object.values(fileTypes)[index];

        const body = await fetchFormDataToken(`usuarios/foto`, { 
            userId: userId,
            tipo: tipo
        }, file );

        //console.log(body);

        if (body.ok){
            dispatch(uploadFile({ field, errorField, file: body.res.fotoUpload }));
            // dispatch(uploadFile({ field, errorField, file: body.res.url }));
        } else {
            dispatch(setError({ errorField, label: body.msg}))
        }
    }
}
export const startUploadPhotoPdf = (uploadFileData: UploadFileParameter) : AppThunk => {
    return async (dispatch, getState) => {

        const {field, errorField, file} = uploadFileData;

        dispatch(prepareUpload({field, errorField}));

        const userId = getState().auth.id;

        //obteniendo el nombre del campo mongo a partir de su valor en el reducer
        const fieldString = field.toString();
        const index = Object.keys(fileTypes).indexOf(fieldString);
        const tipo = Object.values(fileTypes)[index];

        const body = await fetchFormDataToken(`usuarios/fotoPdf`, { 
            userId: userId,
            tipo: tipo
        }, file );

        //console.log(body);

        if (body.ok){
            dispatch(uploadFile({ field, errorField, file: body.res.fotoUpload }));
            // dispatch(uploadFile({ field, errorField, file: body.res.url }));
        } else {
            dispatch(setError({ errorField, label: body.msg}))
        }
    }
}
const prepareUpload = (prepareUploadData: prepareUploadPayload): RegisterActions  => {
    return {
        type: 'PrepareUpload',
        payload: prepareUploadData
    }    
}
const uploadFile = (uploadFileData: UploadFilePayload): RegisterActions  => {
    return {
        type: 'UploadFile',
        payload: uploadFileData
    }    
}

//METODO PDF LOADSUCESS GENERAL
type PdfLoadSucessParameter = { field: keyof RegisterState, pages: number }
export const startPdfLoadSuccess = (uploadFileData: PdfLoadSucessParameter) : AppThunk => {
    return async (dispatch) => {

        //console.log(uploadFileData);
        const {field, pages} = uploadFileData;
        dispatch(pdfLoadSuccess({field , pages }));

    }
}
const pdfLoadSuccess = (pdfLoadSuccessData: pdfLoadSuccessPayload): RegisterActions  => {
    return {
        type: 'PdfLoadSuccess',
        payload: pdfLoadSuccessData
    }    
}

export const showModalRegister = (showModalData: ShowModalPayload): RegisterActions => {
    return {
        type: 'ShowModalRegister',
        payload: showModalData 
    }
} 
export const closeModalRegister = (): RegisterActions => {
    return {
        type: 'CloseModalRegister' 
    }
}

export const updateLogoSending = (): RegisterActions => {
    return {
        type: 'LogoSending' 
    }
}
export const updateIneSending = (): RegisterActions => {
    return {
        type: 'IneSending' 
    }
}
export const updateEncargadoSending = (): RegisterActions => {
    return {
        type: 'EncargadoSending' 
    }
}

export const startUserRegister = () : AppThunk => {
    return async (dispatch, getState) => {
        //console.log('abriendo modal');
    }
} 


//ACTUALIZANDO NOMBRE DEL ESTABLECIMIENTO
export const startUpdateEstablishment = (): AppThunk => {
    return async (dispatch, getState) => {

        dispatch(prepareFetchEstablishment());

        const body = await fetchToken(`usuarios/establecimiento`, { 
            userId: getState().auth.id,
            establecimiento: getState().register.establecimiento
        }, 'PUT');

        //console.log(body);

        if (body.ok){
            dispatch(updateEstablishment());
        } else {
            dispatch(fetchEstablishmentFailed({ label: body.msg}))
        }
    }
}
const prepareFetchEstablishment = (): RegisterActions => {
    return {
        type: 'PrepareFetchEstablishment'
    }
}
const updateEstablishment = (): RegisterActions  => {
    return {
        type: 'UpdateEstablishment'
    }    
}
const fetchEstablishmentFailed = (updateEstablishmentFailedData: UpdateFailedPayload): RegisterActions => {
    return {
        type: 'FetchEstablishmentFailed',
        payload: updateEstablishmentFailedData
    }
}
export const clearEstablishmentError = (): RegisterActions  => {
    return {
        type: 'ClearEstablishmentError'
    }    
}

//ACTUALIZANDO NOMBRE DEL RFC
export const startUpdateRfc = (): AppThunk => {
    return async (dispatch, getState) => {

        dispatch(prepareFetchRfc());

        const body = await fetchToken(`usuarios/rfc`, {
            userId: getState().auth.id,
            rfc: getState().register.rfc.toUpperCase()
        }, 'PUT');

        //console.log(body);

        if (body.ok){
            dispatch(updateRfc());
        } else {
            dispatch(fetchRfcFailed({ label: body.msg}))
        }
    }
}
const prepareFetchRfc = (): RegisterActions => {
    return {
        type: 'PrepareFetchRfc'
    }
}
const updateRfc = (): RegisterActions  => {
    return {
        type: 'UpdateRfc'
    }    
}
const fetchRfcFailed = (updateRfcFailedData: UpdateFailedPayload): RegisterActions => {
    return {
        type: 'FetchRfcFailed',
        payload: updateRfcFailedData
    }
}
export const clearRfcError = (): RegisterActions  => {
    return {
        type: 'ClearRfcError'
    }    
}

//ACTUALIZANDO NOMBRE DEL ENCARGADO Y EL NUM. DE CONTACTO
export const startUpdateContact = (): AppThunk => {
    return async (dispatch, getState) => {

        dispatch(prepareFetchContact());

        const body = await fetchToken(`usuarios/contacto`, {
            userId: getState().auth.id,
            encargado: getState().register.encargado,
            contacto: getState().register.contacto
        }, 'PUT');

        //console.log(body);

        if (body.ok){
            dispatch(updateContact());
        } else {
            dispatch(fetchContactFailed({ label: body.msg}))
        }
    }
}
const prepareFetchContact = (): RegisterActions => {
    return {
        type: 'PrepareFetchContact'
    }
}
const updateContact = (): RegisterActions  => {
    return {
        type: 'UpdateContact'
    }    
}
const fetchContactFailed = (updateContactFailedData: UpdateFailedPayload): RegisterActions => {
    return {
        type: 'FetchContactFailed',
        payload: updateContactFailedData
    }
}
export const clearContactError = (): RegisterActions  => {
    return {
        type: 'ClearContactError'
    }    
}
//ACTUALIZANDO EL ESTADO DE VENDEDOR
export const startUpdateActivateSeller = (): AppThunk => {
    return async (dispatch, getState) => {

        dispatch(prepareFetchActivateSeller());

        const body = await fetchToken(`usuarios/activarVendedor`, {
            userId: getState().auth.id
        }, 'PUT');

        //console.log(body);

        if (body.ok){
            dispatch(updateActivateSeller());
        } else {
            dispatch(fetchActivateSellerFailed({ label: body.msg }))
        }
    }
}
const prepareFetchActivateSeller = (): RegisterActions => {
    return {
        type: 'PrepareFetchActivateSeller'
    }
}
const updateActivateSeller = (): RegisterActions  => {
    return {
        type: 'UpdateActivateSeller'
    }    
}
const fetchActivateSellerFailed = (updateActivateSellerFailedData: UpdateFailedPayload): RegisterActions => {
    return {
        type: 'FetchActivateSellerFailed',
        payload: updateActivateSellerFailedData
    }
}
export const clearActivateSellerError = (): RegisterActions  => {
    return {
        type: 'ClearActivateSellerError'
    }    
}
export const closeCleanRegister = (): RegisterActions  => {
    return {
        type: 'CloseCleanRegister'
    }    
}

export const abrirTerminosCondicionesSaleModal = (): RegisterActions => {
    return {
        type: 'AbrirTerminosCondicionesSale',
    }
}
export const cerrarTerminosCondicionesSaleModal = (): RegisterActions => {
    return {
        type: 'CerrarTerminosCondicionesSale',
    }
}
export const abrirAvisoPrivacidadSaleModal = (): RegisterActions => {
    return {
        type: 'AbrirAvisoPrivacidadSale'
    }
}
export const cerrarAvisoPrivacidadSaleModal = (): RegisterActions => {
    return {
        type: 'CerrarAvisoPrivacidadSale'
    }
}