import "./styles/planVentas.scss"

import { RootState, AppDispatch } from '../../../../../../store/store';
import { useSelector, useDispatch } from 'react-redux';
import SVG from 'react-inlinesvg';
import { Box, Button, Link } from '@mui/material';
import { startLoadPackages, showModalPackage } from '../../../../../../reducers/businessReducer';
import { useEffect } from 'react';
import { startCheking } from '../../../../../../reducers/authReducer';

export const PlanVentas = () => { 

    //LEER INFORMACION DE REDUX
		const dispatchRedux = useDispatch<AppDispatch>();  
    const storeBusiness = (state: RootState ) => state.business;
		const { plan, autosActivos, autosLimite, reload } = useSelector(storeBusiness);

    const storeAuth = (state: RootState ) => state.auth;
    const { invitacionVendedor } = useSelector(storeAuth);
			
    //MOSTRAR PAQUETES DE USUARIO
    const handlePlanClick = async () => {
      await dispatchRedux<any>(startLoadPackages({packageId: 'a'}));
			dispatchRedux(showModalPackage());
		}

		//LEER EL REDUCER LOCAL
		//const { dispatchSales } = useContext(SalesContext); 

    //GENERAR NUEVA VENTA
	  /*const handleNuevaClick = () => {
		    dispatchSales(showModalNewPub());
    }*/

    useEffect(() => {
      const token = localStorage.getItem('token') || '';
      if (reload) {
        dispatchRedux<any>(startCheking(token));
      }
    }, [reload, dispatchRedux])


  return (
    <div className="sales_plan_container">
      <div className="sales_plan_format">
        {
          ( plan === 'BASICO' ) ? (
            <div className="plan_format">
              <div className="plan_icono">
                <SVG src={process.env.REACT_APP_ICONSERVER+'dz-plan'} height="100%"/>
              </div>
              <div className="plan_titulo">
                Plan de ventas
              </div>
              <div className="plan_dato1">
                <p>Actualmente tienes un plan: <b className="plan_dato1_color">{ plan }</b></p>
              </div>
              <div></div>
              <div className="plan_dato2">
                <p>
                  <b>¿Quieres publicar algo en AutosXPartes.com?</b>
                  <br />
                  Cambia tu plan para más beneficios
                </p>
              </div>
              <div className="plan_opcion">
                {
                  invitacionVendedor === false ?
                  <Box sx={{ marginTop: 1 }}>
                    <Link underline="hover" href="/contactUser" >Ponte en contacto con nosotros aquí</Link>
                  </Box>
                  :
                  <Box sx={{ textAlign: "center" }}>
                    <Button
                      onClick={ () => {handlePlanClick()} }
                      variant="contained"
                      sx={{ textTransform: 'none' }}
                    >
                      Cambiar mi plan
                    </Button>
                  </Box>
                }
              </div>
            </div>
          ) : (
            <div className="plan_format">
              <div className="plan_icono">
                {
                  ( function() {
                    switch (  plan  ) {
                      case 'VENDEDOR':
                        return <SVG src={process.env.REACT_APP_ICONSERVER+'dz-box'} height="100%"/>
                      case 'PROFESIONAL':
                        return <SVG src={process.env.REACT_APP_ICONSERVER+'dz-box-premium'} height="100%"/>
                      case 'PREMIUM':
                        return <SVG src={process.env.REACT_APP_ICONSERVER+'dz-box-prof'} height="100%"/>
                    }
                  })()
                }
              </div>
              <div className="plan_titulo">
                Plan de ventas
              </div>
              <div className="plan_dato1">
                <p>Actualmente tienes un plan: <b className="plan_dato1_color">{ plan }</b></p>
              </div>
              <div></div>
              <div className="plan_dato2">
                <div>
                  <p className="sales_section_subtitle">
                    Publicaciones Activas: <b>{ autosActivos }</b>
                    <br/>
                    Publicaciones Disponibles: <b>{ (autosLimite < 0) ? 'Ilimitadas' : (autosLimite === 0) ? 'No incluido' : autosLimite-autosActivos }</b>
                  </p>
                  <p>
                    <b>¿Quieres más beneficios en AutosXPartes.com?</b>
                    <br />
                    Puedes cambiar tu plan
                  </p>
                </div>
              </div>
              <div className="plan_opcion">
                <Box sx={{ textAlign: "center" }}>
                  <Button
                    onClick={ () => {handlePlanClick()} }
                    variant="contained"
                    sx={{ textTransform: 'none' }}
                  >
                    Cambiar mi plan
                  </Button>
                </Box>
              </div>
            </div>
          )
        }
      </div>
    </div>            
  )
}