
import { fetchSinToken, fetchToken } from "../../../helpers/fetch"

//DEFINIMOS LOS TIPOS PARA DEL REDUCER
type UltimasVisitas = {
    idPublicacion: string,
    icono: string | undefined,
    marca: string | number, 
    marcaId: string,
    modelo: string,
    modeloId: string,
    anio: number,
    anioInicial: number | null,
    anioFinal: number | null,
    version: string | undefined,
    descripcion: string | undefined,
    motor: string | undefined,
    transmision: string | undefined,
    fotoPublicacion: string | undefined,
    tipo: string | undefined,
    precio: number | string | null | undefined
}
type AsociadosUsuario = {
    idAsociado: string,
    nombre: string,
    apellidos: string,
    portada: string,
    logo: string,
    establecimiento: string
}
export interface UsersReducerType {
    carouselLastVisit: UltimasVisitas[] | undefined,
    carouselLastPublications: UltimasVisitas[] | undefined,
    carouselAssociatesUsers: AsociadosUsuario[] | undefined
}

//DEFINIMOS EL VALOR INICIAL DEL REDUCER
export const initialUsersState: UsersReducerType = {
    carouselLastVisit: undefined,
    carouselLastPublications:  undefined,
    carouselAssociatesUsers: undefined
}

type LoadLastVisitPayload = { ultimasVisitasUsuarios: UltimasVisitas[] };
type LoadPublicationsUserPayload = { publicacionesUsuarios: UltimasVisitas[] };
type LoadAssociatesUsersHomePayload = { asociadosUsuarios: AsociadosUsuario[] };

type UsersActions = { type: 'ClearLastVisitUsers' } |
                    { type: 'ClearPublicationsUsers' } |
                    { type: 'ClearAssociatesUsers' } |
                    { type: 'LoadLastVisitUsers', payload: LoadLastVisitPayload } |
                    { type: 'LoadPublicationsUsers', payload: LoadPublicationsUserPayload } |
                    { type: 'LoadAssociatesUsers', payload: LoadAssociatesUsersHomePayload } ;

//DEFINIMOS LAS ACCIONES DEL REDUCER
export const usersReducer = (state: UsersReducerType = initialUsersState, action: UsersActions) : UsersReducerType => {
    switch (action.type) {
        case 'ClearLastVisitUsers' : return {
            ...state,
            ...initialUsersState
        }
        case 'ClearAssociatesUsers' : return {
            ...state,
            ...initialUsersState
        }
        case 'LoadLastVisitUsers' : return { 
            ...state, 
            carouselLastVisit: action.payload.ultimasVisitasUsuarios,
        }
        case 'ClearPublicationsUsers' : return {
            ...state,
            ...initialUsersState
        }
        case 'LoadPublicationsUsers' : return { 
            ...state, 
            carouselLastPublications: action.payload.publicacionesUsuarios,
        }
        case 'LoadAssociatesUsers' : return { 
            ...state, 
            carouselAssociatesUsers: action.payload.asociadosUsuarios,
        }
        
        default: return state;
    }
}

//CARGAR LAS PUBLICACIONES EN USUARIOS
type LoadLastVisitUsersParameter = { dispatchUser: React.Dispatch<UsersActions> };
export const startLoadLastVisitUsers = async (loadLastVisitUsersData: LoadLastVisitUsersParameter) => {

    const {dispatchUser} = loadLastVisitUsersData;
    
    dispatchUser (clearLastVisitUsers());

    let body = await fetchToken(`publicaciones/historial`,{},'GET');

    //console.log(body);

    if (body.ok){
        //Cambiando formato a respuesta del servidor
        let lastVisits: UltimasVisitas[] = [];
        for (let lastVisit of body.res){
            lastVisits.push({
                idPublicacion: lastVisit._id,
                icono: lastVisit.marca[0].icono,
                marca: lastVisit.marca[0].marca, 
                marcaId: lastVisit.marcaId,
                modelo: lastVisit.modelo[0].modelo,
                modeloId: lastVisit.modeloId,
                anio: lastVisit.anio,
                anioInicial: lastVisit.modelo[0].anioInicial,
                anioFinal: lastVisit.modelo[0].anioFinal,
                version: lastVisit.version,
                descripcion: lastVisit.descripcion,
                motor: lastVisit.motor,
                transmision: lastVisit.transmision,
                fotoPublicacion: lastVisit.fotoPublicacion,
                tipo: lastVisit.tipo,
                precio: lastVisit.precio
            });
        }
        dispatchUser(loadLastVisitUsers({ ultimasVisitasUsuarios: lastVisits }));
    }
}
const loadLastVisitUsers = (LastVisitUsersData: LoadLastVisitPayload): UsersActions  => {
    return {
        type: 'LoadLastVisitUsers',
        payload: LastVisitUsersData
    }    
}
const clearLastVisitUsers = (): UsersActions  => {
    return {
        type: 'ClearLastVisitUsers'
    }    
}

//CARGAR LAS PUBLICACIONES EN USUARIOS
type LoadPublicationsUserParameter = { dispatchUser: React.Dispatch<UsersActions> };
export const startLoadPublicationsUser = async (loadPublicationsUserData: LoadPublicationsUserParameter) => {

    const {dispatchUser} = loadPublicationsUserData;
    
    dispatchUser (clearPublicationsUser());

    let body = await fetchSinToken(`publicaciones/home`,{},'GET');

    //console.log(body);

    if (body.ok){
        //Cambiando formato a respuesta del servidor
        let publications: UltimasVisitas[] = [];
        for (let publication of body.res){
            publications.push({
                idPublicacion: publication._id,
                icono: publication.marcaId.icono,
                marca: publication.marcaId.marca, 
                marcaId: publication.marcaId._id,
                modelo: publication.modeloId.modelo,
                modeloId: publication.modeloId._id,
                anio: publication.anio,
                anioInicial: publication.anioInicial,
                anioFinal: publication.anioFinal,
                version: publication.version,
                descripcion: publication.descripcion,
                motor: publication.motor,
                transmision: publication.transmision,
                fotoPublicacion: publication.fotoPublicacion,
                tipo: publication.tipo,
                precio: publication.precio
            });
        }
        dispatchUser(loadPublicationsUser({ publicacionesUsuarios: publications }));
    }
}
const loadPublicationsUser = (publicationsUserData: LoadPublicationsUserPayload): UsersActions  => {
    return {
        type: 'LoadPublicationsUsers',
        payload: publicationsUserData
    }    
}
const clearPublicationsUser = (): UsersActions  => {
    return {
        type: 'ClearPublicationsUsers'
    }    
}

//CARGAR LAS PUBLICACIONES EN HOME
type LoadAssociatesUsersParameter = { dispatchUser: React.Dispatch<UsersActions> };
export const startLoadAssociatesUsers = async (loadPublicationsUsersData: LoadAssociatesUsersParameter) => {

    const {dispatchUser} = loadPublicationsUsersData;
    
    dispatchUser (clearAssociatesUsers());

    let body = await fetchSinToken(`usuarios/home`,{},'GET');

    //console.log(body);

    if (body.ok){
        //Cambiando formato a respuesta del servidor
        let associatesUsers: AsociadosUsuario[] = [];
        for (let associates of body.res){
            associatesUsers.push({
                idAsociado: associates._id,
                nombre: associates.nombre,
                apellidos: associates.apellidos,
                portada: associates.fotoPortada,
                logo: associates.fotoLogo,
                establecimiento: associates.establecimiento
            });
        }
        dispatchUser(loadAssociatesUsers({ asociadosUsuarios: associatesUsers }));
    }
}
const loadAssociatesUsers = (associatesUsersData: LoadAssociatesUsersHomePayload): UsersActions  => {
    return {
        type: 'LoadAssociatesUsers',
        payload: associatesUsersData
    }
}
const clearAssociatesUsers = (): UsersActions  => {
    return {
        type: 'ClearAssociatesUsers'
    }
}