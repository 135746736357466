import { useSelector } from "react-redux";
import SVG from 'react-inlinesvg';
import { RootState } from '../../../../store/store';
import dayjs from 'dayjs';


export const Notifications = () => {

    // console.log('Se renderizo Notifications');

    //LEER VARIABLES DE REDUX
    const storeDarkMode = (state: RootState ) => state.darkmode;
    const { darkMode } = useSelector(storeDarkMode);
    const storeNavbar = (state: RootState) => state.navbar;
    const { notifications } = useSelector(storeNavbar);

    //ONCLICK DE ITEM
    const handleItemClick = (id: string) => {
        
    } 
    return (
        <>
        {
            (notifications[0].key === 0)
            ? (
                <div className="navbar_modal"></div>
            )
            : ( 
                notifications.map((notification) => (
                    <div 
                        key={ notification.key }
                        id={ notification.id }
                        className={(darkMode) 
                            ? 'navbar_modal_grid navbar_modal_grid_dark' 
                            : 'navbar_modal_grid navbar_modal_grid_light'
                        }
                        onClick = { (e) => { handleItemClick(e.currentTarget.id) } }
                    >
                        <div className={ (notification.active) ? 'navbar_modal_icon navbar_modal_icon_green' : 'navbar_modal_icon' }>
                            {/* <i className="fas fa-bell"></i> */}
                            <SVG src={process.env.REACT_APP_ICONSERVER+'dz-bell'} height={22} fill="currentColor"/>
                        </div>
                        <div className="navbar_modal_text">{ notification.text }</div>
                        <div className={ (notification.active) ? 'navbar_modal_active navbar_modal_active_green' : 'navbar_modal_active' }/>
                        <div
                            className={(darkMode) 
                                ? 'navbar_modal_date navbar_modal_date_dark' 
                                : 'navbar_modal_date navbar_modal_date_light'
                            } 
                        >
                            { dayjs(notification.date).fromNow() }
                        </div>
                    </div>                                      
                ))
            )
        }
        </>
    )
}
