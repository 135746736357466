
import { Box, Button, Card, CardMedia, CircularProgress, Dialog, IconButton, Typography } from "@mui/material"
import { useContext, useEffect, useState } from "react";
import { QuestionsContext } from "./context/QuestionsContext";
import { closeModalQuestionPublic } from "./reducers/QuestionsReducer";

import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import SVG from 'react-inlinesvg';

import Slider from "react-slick";

import { useHistory } from "react-router-dom";

export const QuestionPublicationModal = () => {

  const history = useHistory();

  const settings = {
    dots: true,
    dotsClass: "slick-dots custom-indicator",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    variableWidth: false,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          dots: false
        }
      }
    ]
  };

  const { questionsReducerState, dispatchQuestion } = useContext(QuestionsContext); 
  const { modalQuestionPublic, publicationInfo } = questionsReducerState;

  const [imagenes, setImagenes] = useState<string[]>([]);
    useEffect(() => {
      let fotos: string[] = [];
      if (publicationInfo) {
        publicationInfo.fotoPublicacion && fotos.push(publicationInfo.fotoPublicacion);
        publicationInfo.fotoIzquierda && fotos.push(publicationInfo.fotoIzquierda);
        publicationInfo.fotoDerecha && fotos.push(publicationInfo.fotoDerecha);
        publicationInfo.fotoFrontal && fotos.push(publicationInfo.fotoFrontal);
        publicationInfo.fotoPosterior && fotos.push(publicationInfo.fotoPosterior);
        publicationInfo.fotoMotor && fotos.push(publicationInfo.fotoMotor);
        publicationInfo.fotoInterior && fotos.push(publicationInfo.fotoInterior);
        setImagenes(fotos);
      }
    }, [publicationInfo]);

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }:any) => (
    <SVG
      src={process.env.REACT_APP_ICONSERVER+'dz-angle-left'}
      className={ "slick-prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")}
      aria-disabled={currentSlide === 0 ? true : false}
      {...props}
    />
  );

  const SlickArrowRight = ({ currentSlide, slideCount, ...props }: any) => (
    <SVG
      src={process.env.REACT_APP_ICONSERVER+'dz-angle-right'}
      className={ "slick-next slick-arrow" + (currentSlide === slideCount - 1 ? "slick-disabled" : "" )} 
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      {...props}
    />
  );

  const handleHrefPublicacion = (publicacion: any) => {
    history.push(`/findUser/publication/${publicacion}`);
  }

  return (
    <div>
      {/*<Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        open={modalQuestionPublic}
        onClose={()=>{dispatchQuestion(closeModalQuestionPublic())}}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              maxWidth: "1200px",
            },
          },
        }}
      >
        <Box sx={{ backgroundColor: "black", color: "white", p: 3, display: "flex" }}>
          <Typography variant='h5'>Publicación</Typography>
          <IconButton
            aria-label="close"
            onClick={()=>{dispatchQuestion(closeModalQuestionPublic())}}
            sx={{
              position: 'absolute',
              right: 18
            }}
          >
            <SVG src={process.env.REACT_APP_ICONSERVER+'dz-error'} width={20} fill="white" />
          </IconButton>
        </Box>
        <div className="sale_modal_container">
          { publicationInfo ?
            <div className="sale_modal_format">
            
              <div className="sale_modal_images">
                { imagenes.length === 0 ?
                  <Box sx={{ height: "500px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <CircularProgress size={30} sx={{ mr: 1 }} /><Typography variant="h5">Cargando</Typography>
                  </Box>
                  :
                  <Slider
                    {...settings}
                    customPaging = {(i) => {
                      return <div>
                        <img src={process.env.REACT_APP_PHOTOSERVER+imagenes[i]} alt="" className="slider-dots" />
                      </div>
                    }}
                    prevArrow={ <SlickArrowLeft /> }
                    nextArrow={ <SlickArrowRight /> }
                    className="sale_modal_images_slider"
                  >
                  { imagenes.map((imagen, i) => (
                      <div key={i}>
                        <Card variant="outlined" sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                          <Box>
                            <CardMedia
                              component="img"
                              image={process.env.REACT_APP_PHOTOSERVER+imagen}
                              alt=""
                              className="sale_modal_images_carrusel"
                            />
                          </Box>
                        </Card>
                      </div>
                    ))
                  }
                </Slider>
                }
              </div>

              <div className="sales_modal_titulo_principal">
                <SVG src={publicationInfo.icon ? process.env.REACT_APP_ICONSERVER+publicationInfo.icon : process.env.REACT_APP_ICONSERVER+'dz-start'} width={50}/>
                <p className="sales_modal_titulo_style"><b>{ publicationInfo?.modelo } { publicationInfo?.anio }</b></p>
              </div>

              <div className="sales_modal_datos">
                <div>
                  <p><b>Version:</b> <br/>{ publicationInfo.version ? publicationInfo.version : 'Sin dato' }</p>
                  <p><b>Transmicion:</b> <br/> { publicationInfo?.transmision ? publicationInfo?.transmision : 'Sin dato' }</p>
                </div>
                <div>
                  <p><b>Motor:</b> <br/> { publicationInfo?.motor ? publicationInfo?.motor : 'Sin dato' }</p>
                  <p><b>Compatibilidad:</b> <br/>Del { publicationInfo?.anioInicial ? publicationInfo?.anioInicial : '0000' } al { publicationInfo?.anioFinal ? publicationInfo?.anioFinal : '0000' }</p>
                </div>
              </div>

              <div className="sales_modal_descripcion">
                <b>Descripcion:</b> { publicationInfo.descripcion ? publicationInfo.descripcion : 'Sin descripción' }
              </div>

              <div className="sales_modal_estado">
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    { ( function() {
                      switch ( publicationInfo?.estado ) {
                        case 'Activa':
                          return <SVG src={process.env.REACT_APP_ICONSERVER+'dz-play'} fill="green" className="sales_modal_icon"/>
                        case 'Incompleta':
                          return <SVG src={process.env.REACT_APP_ICONSERVER+'dz-play-disabled'} fill="red" className="sales_modal_icon"/>
                        case 'Pendiente':
                          return <SVG src={process.env.REACT_APP_ICONSERVER+'dz-pause'} fill="rgb(255, 145, 0)" className="sales_modal_icon"/>
                        }
                      })()
                    }
                  </Box>
                  <div>
                    <p><b>Publicacion:</b> { publicationInfo?.estado }</p>
                  </div>
                </Box>
              </div>
            </div>
            :
            <Box sx={{ width: "900px", height: "500px", display: "flex", alignItems: "center", justifyContent: "center" }}>
              <CircularProgress size={30} sx={{ mr: 1 }} /><Typography variant="h5">Cargando</Typography>
            </Box>
          }
        </div>
      </Dialog>*/}


        <Dialog
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          open={modalQuestionPublic}
          onClose={()=>{dispatchQuestion(closeModalQuestionPublic())}}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                maxHeight: '95vh',
                maxWidth: '90vw',
              },
            },
          }}
        >
          <Box sx={{ backgroundColor: "black", color: "white", p: 3, display: "flex" }}>
            <Typography variant='h5'>Detalles de tu publicación</Typography>
            <IconButton
              aria-label="close"
              onClick={()=>{dispatchQuestion(closeModalQuestionPublic())}}
              sx={{
                position: 'absolute',
                right: 18
              }}
            >
              <SVG src={process.env.REACT_APP_ICONSERVER+'dz-error'} width={20} fill="white" />
            </IconButton>
          </Box>
          <div className="sale_modal_container">
            { publicationInfo ?
              <div className="sale_modal_format">

                <div className="publicacion_info_imagenes">
                  <div className="publicacion_info_images_format">
                    {
                      imagenes.length === 0 ?
                      <Box sx={{ height: "500px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <CircularProgress size={30} sx={{ mr: 1 }} /><Typography variant="h5">Cargando</Typography>
                      </Box>
                      :
                      <Slider
                        {...settings}
                        customPaging = {(i) => {
                          return <div>
                            <img src={process.env.REACT_APP_PHOTOSERVER+imagenes[i]} alt="" className="slider-dots" />
                          </div>
                        }}
                        prevArrow={ <SlickArrowLeft /> }
                        nextArrow={ <SlickArrowRight /> }
                        className="publicacion_info_slider"
                      >
                        {
                          imagenes.map((imagen, i) => (
                            <div key={i}>
                              <Card variant="outlined" sx={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}>
                                <Box>
                                  <CardMedia
                                    component="img"
                                    image={process.env.REACT_APP_PHOTOSERVER+imagen}
                                    alt=""
                                    className="publicacion_images_carrusel"
                                  />
                                </Box>
                              </Card>
                            </div>
                          ))
                        }
                      </Slider>
                    }
                  </div>
                </div>

                <div className="sales_modal_titulo_principal">
                  <div style={{ width: "100%" }}>
                    <Typography sx={{ fontSize: 14, mb: 3 }} color="text.secondary">
                      Autopartes
                    </Typography>
                    <Box sx={{ display: "flex", mb: 3 }}>
                      <div className="publicacion_info_container_icon">
                        <SVG src={publicationInfo.icon ? process.env.REACT_APP_ICONSERVER+publicationInfo.icon : '' } fill="currentColor" className="publicacion_info_icon"/>
                      </div>
                      <p className="sales_modal_titulo_style"><b>{ publicationInfo?.modelo } { publicationInfo?.anio }</b></p>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        { ( function() {
                          switch ( publicationInfo?.estado ) {
                            case 'Activa':
                              return <div style={{ width: "30px", height: "30px", borderRadius: "50%", background: "#00c853" }}/>
                            case 'Pausada':
                              return <div style={{ width: "30px", height: "30px", borderRadius: "50%", background: "black" }}/>
                            case 'Incompleta':
                              return <div style={{ width: "30px", height: "30px", borderRadius: "50%", background: "#ef233c" }}/>
                            case 'Pendiente':
                              return <div style={{ width: "30px", height: "30px", borderRadius: "50%", background: "#fca311" }}/>
                            }
                          })()
                        }
                      </Box>
                      <Box sx={{ ml: 2 }}>
                        <p><b>Publicacion:</b> { publicationInfo?.estado }</p>
                      </Box>
                    </Box>
                        
                    <Button
                      variant="contained"
                      color="success"
                      fullWidth startIcon={<ZoomOutMapIcon />}
                      sx={{ textTransform: 'none', mb: 3 }}
                      onClick={() => handleHrefPublicacion(publicationInfo.idPublicacion)}
                    >
                      Ver publicación completa
                    </Button>

                  </div>
                </div>

                <div className="sales_modal_caracteristicas">
                  <div className="publicacion_info_subtitulo">
                    Características principales
                  </div>
                  <div className="publicacion_info_caracteristicas_format">
                    <div>Marca:</div>
                    <div>{publicationInfo?.marca}</div>
                    <div>Modelo:</div>
                    <div>{publicationInfo?.modelo}</div>
                    <div>Año:</div>
                    <div>{publicationInfo?.anio}</div>
                    <div>Compatibilidad:</div>
                    <div>{publicationInfo?.anioInicial ? `Del ${publicationInfo.anioInicial} al ${publicationInfo.anioFinal}` : 'Sin dato'}</div>
                    <div>Transmisión:</div>
                    <div>{publicationInfo?.transmision ? publicationInfo.transmision : 'Sin dato'}</div>
                    <div>Motor:</div>
                    <div>{publicationInfo?.motor ? publicationInfo.motor : 'Sin dato'}</div>
                    <div>Version:</div>
                    <div>{publicationInfo?.version ? publicationInfo.version : 'Sin dato'}</div>
                  </div>
                </div>

                <div className="sales_modal_descripcion">
                  <div className="publicacion_info_subtitulo">
                    Descripción
                  </div>
                  <div className="publicacion_info_descripcion_size">
                    {publicationInfo?.descripcion ? publicationInfo.descripcion : 'El vendedor no incluyo una descripción'}
                  </div>
                </div>
              </div>
              :
              <Box sx={{ width: "900px", height: "500px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <CircularProgress size={30} sx={{ mr: 1 }} /><Typography variant="h5">Cargando</Typography>
              </Box>
            }
          </div>
        </Dialog>


    </div>
  )
}