import { useDispatch, useSelector } from 'react-redux';
import { setTitle } from '../../../../reducers/navbarReducer';
import { AppDispatch, RootState } from '../../../../store/store';
import { setComponent, setItemSelected, SubItemType } from '../../../../reducers/sidebarReducer';


export const SidebarSubItems = (props: { sidebarSubItems: SubItemType[], itemArrow: boolean, id: string }) => {

    //LEER EL STORE DE REDUX
    const storeSidebar = (state: RootState ) => state.sidebar;
    const { itemSelected } = useSelector(storeSidebar)

    //ESCRIBIR EN EL STORE DE REDUX
    const dispatchRedux = useDispatch<AppDispatch>();

    //MARCAR ITEMS SELECCIONADOS DEL SIDEBAR
    const handleItemClick = (id: string, title: string) => {
        //TO-DO ONCLICK ITEM
        dispatchRedux(setTitle({title: title}))
        //guardamos el estado del item seleccionado
        dispatchRedux(setItemSelected({id: id})); 
        //mostramos el componente seleccionado
        dispatchRedux(setComponent({component: id}))
    }
 
    //NO MOSTRAR ELEMENTOS NO DESPLEGADOS
    const mostrarItem: string = (props.itemArrow)? 'sidebar_subitem_show' : 'sidebar_subitem_hide' ;
    
    return (
        <>
        {
            props.sidebarSubItems.map( subitem => (
                <div
                    key={ subitem.key }
                    className="sidebar_item_group"
                >
                    <span></span>
                    <div 
                        key={ subitem.key }
                        id={ subitem.id }
                        title={ subitem.title }
                        className={ (subitem.id === itemSelected) 
                            ? 'sidebar_row sidebar_row_selected' 
                            : 'sidebar_row' 
                        }
                        onClick = { (e) => { handleItemClick(e.currentTarget.id, e.currentTarget.title) } }
                    >
                        <span className={ 'sidebar_bleft ' + mostrarItem }></span>
                        <span
                            className={ 'sidebar_subitem ' + mostrarItem }
                        >
                            <p>{ subitem.title }</p>
                        </span>
                        <span className={ 'sidebar_bright ' + mostrarItem }></span>
                    </div>   
                    <span></span>
                </div>    
            ))
        }
        </>
    )
}