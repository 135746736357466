
import { Avatar, Box, Button, Card, CardContent, CardMedia, CircularProgress, Dialog, DialogContent, Link, List,
         Typography, Divider, DialogContentText, TextField, IconButton, Alert, AlertTitle, useMediaQuery } from '@mui/material';

import { useContext, useEffect, useState ,useRef} from 'react';
import { useParams } from 'react-router-dom';
import { PublicationDataContext } from './context/ContextPublicationData';
import { cleanError, clearQuestionError, closeContactSellerModal,
         closeCreateQuestionModal, closeReportPublicationModal, onChange, 
         /*openContactSellerModal,*/ openCreateQuestionModal, openImagesZoomCarousel, setImagesZoomCarousel,
         /*openReportPublicationModal,*/ setError, startContactSellerPublication, startCreateQuestion, 
         startReportPublication, startUpdatePublicationData, startUpdateQuestionsData, startUpdateHistoryPublication
       } from './reducers/PublicationDataReducer';
import './styles/publicacion-info-styles.scss'
import SVG from 'react-inlinesvg';
import Slider from "react-slick";
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import validator from 'validator';

import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';
import { ImageZoomCarouselModal } from './modals/ImageZoomCarouselModal';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

export const PublicacionInfoScreen = () => {

    useEffect(() => {
      window.scrollTo(0, 0)
    }, []);
    const sliderRef =  useRef<Slider>(null);
    const { idPublicacion } = useParams<{ idPublicacion: string }>();

    const { publicationDataReducerState, dispatchPublicationData } = useContext(PublicationDataContext);
    const { datosPublicacion,
            datosVendedor,
            publicationMessage,
            preguntasPublicacion,
            pregunta,
            preguntaError,
            receiveMessage,
            receiveMessageError,
            openModalCreateQuestion,
            openModalContactSeller,
            openModalReportPublication,
            mensajePublica,
            indexCarousel
          } = publicationDataReducerState;

    const storeAuth = (state: RootState ) => state.auth;
    const { login } = useSelector(storeAuth);

    useEffect(() => {
      dispatchPublicationData(startUpdatePublicationData({ stateLocalPublication: publicationDataReducerState, dispatchLocalPublication: dispatchPublicationData,
                                                           publicacionDataId: idPublicacion }));
    }, [idPublicacion, dispatchPublicationData]);

    useEffect(() => {
      dispatchPublicationData(startUpdateHistoryPublication({dispatchLocalPublication: dispatchPublicationData, publicacionId: idPublicacion}))
    }, [idPublicacion, dispatchPublicationData])
    

    useEffect(() => {
      dispatchPublicationData(startUpdateQuestionsData({ stateLocalPublication: publicationDataReducerState, dispatchLocalPublication: dispatchPublicationData,
                                                         publicacionDataId: idPublicacion }));
    }, [idPublicacion, dispatchPublicationData]);


    useEffect(() => {
    if (sliderRef!==null && sliderRef.current !== null){
    sliderRef.current?.slickGoTo(indexCarousel as number);
    }
    }, [indexCarousel, dispatchPublicationData]);


    const settings = {
      dots: true,
      dotsClass: "slick-dots custom-indicator",
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      responsive: [
        {
          breakpoint: 1000,
          settings: {
            dots: false
          }
        }
      ]
    };

  //EVALUANDO LA CANTIDAD DE FOTOS DE LA PUBLICACION
  const [imagenes, setImagenes] = useState<string[]>([]);
  useEffect(() => {
    let fotos: string[] = [];
    if (datosPublicacion) {
      datosPublicacion.fotoPublicacion && fotos.push(datosPublicacion.fotoPublicacion);
      datosPublicacion.fotoIzquierda && fotos.push(datosPublicacion.fotoIzquierda);
      datosPublicacion.fotoDerecha && fotos.push(datosPublicacion.fotoDerecha);
      datosPublicacion.fotoFrontal && fotos.push(datosPublicacion.fotoFrontal);
      datosPublicacion.fotoPosterior && fotos.push(datosPublicacion.fotoPosterior);
      datosPublicacion.fotoMotor && fotos.push(datosPublicacion.fotoMotor);
      datosPublicacion.fotoInterior && fotos.push(datosPublicacion.fotoInterior);
      setImagenes(fotos);
    }
  }, [datosPublicacion]);

    const SlickArrowLeft = ({ currentSlide, slideCount, ...props }:any) => (
      <SVG
        src={process.env.REACT_APP_ICONSERVER+'dz-angle-left'}
        className={ "slick-prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")}
        aria-disabled={currentSlide === 0 ? true : false}
        {...props}
      />
    );

    const SlickArrowRight = ({ currentSlide, slideCount, ...props }: any) => (
      <SVG
        src={process.env.REACT_APP_ICONSERVER+'dz-angle-right'}
        className={ "slick-next slick-arrow" + (currentSlide === slideCount - 1 ? "slick-disabled" : "" )} 
        aria-disabled={currentSlide === slideCount - 1 ? true : false}
        {...props}
      />
    );

    const regresarPagina = () => {
      window.history.go(-1);
    }

    const [botonPreguntar, setBotonPreguntar] = useState(false);
    const [botonPreguntar2, setBotonPreguntar2] = useState(false);
    const [botonChatWhatsapp, setBotonChatWhatsapp] = useState(false);
    //const [botonContactar, setBotonContactar] = useState(false);
    //const [botonReportar, setBotonReportar] = useState(false);

    const handleClickOpenModalPreguntar = () => {
      if ( login === true ) {
        dispatchPublicationData(openCreateQuestionModal());
      } else {
        setBotonPreguntar(!botonPreguntar);
      }
    };
    const handleClickOpenModalPreguntar2 = () => {
      if ( login === true ) {
        dispatchPublicationData(openCreateQuestionModal());
      } else {
        setBotonPreguntar2(!botonPreguntar2);
      }
    };
    /*const handleClickOpenModalContactar = () => {
      if ( login === true ) {
        dispatchPublicationData(openContactSellerModal());
      } else {
        setBotonContactar(!botonContactar);
      }
    };*/
    /*const handleClickOpenModalReportar = () => {
      if ( login === true ) {
        dispatchPublicationData(openReportPublicationModal());
      } else {
        setBotonReportar(!botonReportar);
      }
    };*/

    const handleCreateQuestion = () => {
      let error = false;
      if (!pregunta) {
        dispatchPublicationData(setError({ errorField: 'preguntaError', label: 'Escriba una pregunta' }));
        error = true;
      } else {
        if (!validator.isLength(pregunta,{min: 4})) {
            dispatchPublicationData(setError({ errorField: 'preguntaError', label: 'Requerimos mas valores' }));
            error = true;
        } else {
            dispatchPublicationData(cleanError({ errorField: 'preguntaError' }))
        }
      }
      if (!error) {
        //si no hay error creamos una publicacion nueva
          dispatchPublicationData(startCreateQuestion({ dispatchLocalPublication:dispatchPublicationData, publicationId: idPublicacion, question: pregunta }));
          dispatchPublicationData(clearQuestionError());
          dispatchPublicationData(closeCreateQuestionModal());
      }
    }

  const preguntasContestadas = preguntasPublicacion?.filter((answer => answer.estadoPregunta === 'Finalizada')) // TRAER TODAS LAS RESPUESTADAS CONTESTADAS

  const responsive = useMediaQuery('(max-width:800px)');
  const responsive2 = useMediaQuery('(max-width:300px)');

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  });

  return (
    <div>
      { datosPublicacion === null ?
        <Box sx={{ height: "90vh", display: "flex", alignItems: "center", justifyContent: "center"  }}>
          <div>
            <Box sx={{ fontSize: "24px", color: "red", minWidth: "200px", textAlign: "center"  }}>
              {publicationMessage}
            </Box>
            <Box sx={{ textAlign: "center" }}>
              <Button onClick={regresarPagina} variant="contained" sx={{ mt: 2 }}>
                Regresar
              </Button>
            </Box>
          </div>
        </Box>
        :
        <>
          <Box sx={{ backgroundColor: "#004e98", padding: (responsive2 ? "20px 30px 20px 30px" : "30px 45px 30px 45px") }}>
            <Box sx={{ margin: "auto", maxWidth: "1350px" }}> 
              <Typography color="white" sx={{ fontSize: (responsive ? "15px" : "16px") }}>Busqueda: {datosPublicacion.marca} {datosPublicacion.modelo} {datosPublicacion.anio}</Typography>
            </Box>
          </Box>
          <Box sx={{ margin: "auto", maxWidth: "1350px", p: (responsive2 ? "20px 30px 20px 30px" : "25px 45px 25px 45px") }}>
            <b>Publicación:</b> {idPublicacion}
          </Box>
          <div className="publicacion_info_container">

            <div className="publicacion_info_imagenes">
              <div className="publicacion_info_images_format">
                {
                  imagenes.length === 0 ?
                  <Box sx={{ height: "500px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <CircularProgress size={30} sx={{ mr: 1 }} /><Typography variant="h5">Cargando</Typography>
                  </Box>
                  :
                  <Slider
                    {...settings} ref={sliderRef}
                    customPaging = {(i) => {
                      return <div>
                        <img src={process.env.REACT_APP_PHOTOSERVER+imagenes[i]} alt="" className="slider-dots" />
                      </div>
                    }}
                    afterChange = {(i) => {
                      dispatchPublicationData(setImagesZoomCarousel({clicID : i}));
                    }}
                    prevArrow={ <SlickArrowLeft /> }
                    nextArrow={ <SlickArrowRight /> }
                    className="publicacion_info_slider"
                  >
                    {
                      imagenes.map((imagen, i) => (
                        <div key={i} onClick={()=>{dispatchPublicationData(openImagesZoomCarousel())}}>
                          <Card variant="outlined" sx={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}>
                            <Box>
                              <CardMedia
                                component="img"
                                image={process.env.REACT_APP_PHOTOSERVER+imagen}
                                alt=""
                                className="publicacion_images_carrusel"
                              />
                            </Box>
                          </Card>
                        </div>
                      ))
                    }
                  </Slider>
                }
              </div>
            </div>

            <div className="publicacion_info_titulo">
              <Card variant="outlined" className="publicacion_info_titulo_card">
                <CardContent >
                  <Typography sx={{ fontSize: 14, mb: 3 }} color="text.secondary">
                    Autopartes
                  </Typography>
                  <Box sx={{ display: "flex" }}>
                    <div className="publicacion_info_container_icon">
                      <SVG src={datosPublicacion?.icon ? process.env.REACT_APP_ICONSERVER+datosPublicacion?.icon : '' } fill="currentColor" className="publicacion_info_icon"/>
                    </div>
                    <div className="publicacion_info_marca">{datosPublicacion?.marca} {datosPublicacion?.modelo} {datosPublicacion?.anio}</div>
                  </Box>
                  {
                    datosPublicacion.precio ?
                    <Box sx={{ mt: 2 }}>
                      <Typography variant='h4'><b>Precio:</b> {formatter.format(parseFloat(datosPublicacion.precio.toString()))}</Typography>
                    </Box>
                    :
                    null
                  }
                  {
                    datosPublicacion.estado === "Pausada" ?
                    <Alert severity="warning"><AlertTitle>Publicación Pausada</AlertTitle></Alert>
                    :
                    <Button variant="contained" color="warning" size="large" fullWidth sx={{ textTransform: "none", mt: 3 }} onClick={handleClickOpenModalPreguntar}>
                      Preguntar
                    </Button>
                  }
                  { receiveMessage && <Alert variant="filled" severity="success" sx={{ marginTop: 2 }}><AlertTitle>{ receiveMessage }</AlertTitle></Alert> }
                  { receiveMessageError && <Alert severity="error" sx={{ marginTop: 2 }}><AlertTitle>{ receiveMessageError }</AlertTitle></Alert> }
                  { botonPreguntar &&
                    <Alert severity="error">
                      <AlertTitle>Para hacer una pregunta sobre esta publicación, es necesario que ingreses a tu cuenta.</AlertTitle>
                      <Button variant="contained" sx={{ marginTop: "8px", textTransform: "none" }}
                        size="large" color="warning" href="/login"
                      >Entrar a mi cuenta</Button>
                    </Alert>
                  }
                  {/*<Button variant="contained" size="large" fullWidth sx={{ textTransform: "none", mt: 3 }} onClick={handleClickOpenModalContactar}>
                    Contactar
                  </Button>*/}
                  {/* botonContactar &&
                    <Alert severity="error">
                      <AlertTitle>Para contactar con el vendedor, es necesario que ingreses a tu cuenta.</AlertTitle>
                      <Button variant="contained" sx={{ marginTop: "8px", textTransform: "none" }}
                        size="large" color="warning" href="/login"
                      >Entrar a mi cuenta</Button>
                    </Alert>
                  */}
                  <Typography sx={{ mt: 3 }}>¿Hay algún problema con esta publicación?</Typography>
                  <Link /*onClick={handleClickOpenModalReportar}*/ underline="hover" sx={{ cursor: "pointer" }} href={(login ? "/contactUser" : "/contact")}>
                    Contáctanos por favor
                  </Link>
                  {/* botonReportar &&
                    <Box sx={{ marginTop: 2 }}>
                      <Alert severity="error">
                        <AlertTitle>Para reportar esta publicación, es necesario que ingreses a tu cuenta.</AlertTitle>
                        <Button variant="contained" sx={{ marginTop: "8px", textTransform: "none" }}
                          size="large" color="warning" href="/login"
                        >Entrar a mi cuenta</Button>
                      </Alert>
                    </Box>
                  */}
                </CardContent>
              </Card>
            </div>

            <div className="publicacion_info_caracteristicas">
              <div className="publicacion_info_subtitulo">
                Características principales
              </div>
              <div className="publicacion_info_caracteristicas_format">
                <div>Marca:</div>
                <div>{datosPublicacion?.marca}</div>
                <div>Modelo:</div>
                <div>{datosPublicacion?.modelo}</div>
                <div>Año:</div>
                <div>{datosPublicacion?.anio}</div>
                <div>Compatibilidad:</div>
                <div>{datosPublicacion?.anioInicial ? `Del ${datosPublicacion.anioInicial} al ${datosPublicacion.anioFinal}` : 'Sin dato'}</div>
                <div>Transmisión:</div>
                <div>{datosPublicacion?.transmision ? datosPublicacion.transmision : 'Sin dato'}</div>
                <div>Motor:</div>
                <div>{datosPublicacion?.motor ? datosPublicacion.motor : 'Sin dato'}</div>
                <div>Version:</div>
                <div>{datosPublicacion?.version ? datosPublicacion.version : 'Sin dato'}</div>
              </div>
            </div>

            <div className="publicacion_info_seller">
            { login ?
              <>
                <div className="publicacion_info_subtitulo">
                  Información del vendedor
                </div>
                <Card variant="outlined" sx={{ p: "20px 20px 20px 20px" }}>
                    <Box sx={{ marginBottom: 2 }}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <svg x="0px" y="0px" viewBox="0 0 50 50" fill="rgb(0, 93, 199)" style={{ height: "50px" }}>
                          <g>
                            <path d="M16.8,49.5c0.7-1.8,1.3-3.7,2-5.5c1.1-3.1,2.2-6.1,3.3-9.2c0.1-0.3,0.1-0.5-0.2-0.7c-0.3-0.3-0.6-0.6-1-0.9
                              c-0.2-0.1-0.5-0.2-0.7-0.2c-0.6,0.1-1.1,0.2-1.7,0.3c-1.6,0.3-2.9-0.2-4-1.4c-0.2-0.2-0.3-0.4-0.5-0.7c-1,2.6-1.9,5.2-2.8,7.8
                              c-0.9,2.6-1.9,5.2-2.9,7.9c2-1,3.8-1.9,5.7-2.9c0.8,2,1.7,3.9,2.5,5.9C16.7,49.7,16.8,49.6,16.8,49.5z"/>
                            <path d="M26,8.4c-4.7-0.5-8.9,2.8-9.4,7.5c-0.5,4.7,2.8,8.9,7.4,9.4c4.7,0.5,8.9-2.8,9.4-7.4C34,13.1,30.7,8.9,26,8.4z M23.5,21.1
                              c-1.1-1.1-2.3-2.2-3.4-3.3c0.5-0.5,1-1,1.5-1.6c0.6,0.6,1.2,1.2,1.8,1.8c1.7-1.8,3.3-3.5,5-5.2c0.5,0.5,1,1,1.6,1.5
                              C27.9,16.6,25.7,18.8,23.5,21.1z"/>
                            <path d="M33.4,49.9c0.9-2,1.7-3.9,2.5-5.9c1.9,1,3.8,1.9,5.7,2.9c-1.9-5.3-3.8-10.5-5.7-15.6c-0.4,0.4-0.7,0.8-1.1,1.2
                              c-1.2,1-2.5,1.2-3.9,0.8c-1.1-0.4-2-0.3-2.8,0.7c-0.3,0.4-0.4,0.6-0.3,1c1.5,4,3,8.1,4.4,12.2C32.7,48,33,48.9,33.4,49.9z"/>
                            <path d="M40,13.9c-0.6-0.6-0.7-1.4-0.6-2.2c0.1-0.5,0.2-1.1,0.3-1.6c0.3-1.3-0.3-2.4-1.6-2.8c-0.5-0.2-1-0.3-1.5-0.5
                              c-0.8-0.3-1.4-0.8-1.6-1.6c-0.2-0.5-0.3-1-0.5-1.5c-0.4-1.2-1.5-1.9-2.8-1.6c-0.6,0.1-1.1,0.2-1.7,0.4c-0.7,0.2-1.4,0-1.9-0.4
                              c-0.5-0.4-1-0.8-1.4-1.3c-1-0.9-2.3-0.9-3.3,0c-0.4,0.4-0.8,0.7-1.2,1.1c-0.6,0.6-1.3,0.8-2.2,0.6c-0.7-0.2-1.5-0.3-2-0.4
                              c-1.3,0-2.1,0.6-2.4,1.6c-0.2,0.5-0.3,1-0.5,1.5c-0.3,0.9-0.9,1.5-1.8,1.8c-0.5,0.1-1,0.3-1.5,0.5C10.6,7.8,10,8.9,10.2,10
                              c0.1,0.6,0.2,1.1,0.4,1.7c0.2,0.9,0,1.6-0.6,2.3c-0.4,0.5-0.9,0.9-1.3,1.4c-0.7,0.8-0.7,2,0,2.8c0.4,0.5,0.8,1,1.3,1.4
                              c0.6,0.6,0.8,1.4,0.6,2.2c-0.1,0.6-0.2,1.1-0.4,1.7c-0.2,1.2,0.4,2.3,1.5,2.7c0.5,0.2,1,0.3,1.5,0.5c0.8,0.3,1.4,0.8,1.7,1.6
                              c0.2,0.6,0.3,1.1,0.5,1.7c0.4,1.2,1.5,1.8,2.8,1.5c0.6-0.1,1.1-0.2,1.7-0.4c0.8-0.2,1.5,0,2.1,0.6c0.4,0.4,0.8,0.7,1.2,1.1
                              c1,0.9,2.3,1,3.3,0c0.4-0.3,0.8-0.7,1.2-1.1c0.6-0.6,1.3-0.8,2.2-0.6c0.5,0.1,1.1,0.2,1.6,0.3c1.3,0.3,2.4-0.3,2.8-1.6
                              c0.2-0.6,0.4-1.1,0.6-1.7c0.3-0.7,0.8-1.2,1.5-1.4c0.6-0.2,1.1-0.4,1.7-0.6c1.2-0.4,1.8-1.5,1.5-2.7c-0.1-0.5-0.2-1.1-0.3-1.6
                              c-0.2-0.9,0-1.7,0.6-2.4c0.4-0.4,0.7-0.8,1.1-1.2c0.8-1,0.8-2.3,0-3.2C40.7,14.8,40.3,14.4,40,13.9z M25,27.1
                              c-5.7,0-10.3-4.6-10.3-10.3c0-5.7,4.6-10.3,10.3-10.3s10.3,4.6,10.3,10.3C35.3,22.5,30.7,27.1,25,27.1z"/>
                          </g>
                        </svg>
                        <Typography sx={{ fontSize: (responsive2 ? "18px" : "21px"), color: "#ff6700" }}>
                          <b>Vendedor certificado</b>
                        </Typography>
                      </div>
                    </Box>
                    <CardMedia
                      component="img"
                      image={datosVendedor?.fotoPortada ? process.env.REACT_APP_USERSERVER+datosVendedor?.fotoPortada : '/assets/sin-foto.png'}
                      alt="imagen-portada"
                      height={ (responsive2 ? 120 : 180) }
                    />
                    <Box sx={{ display: "flex", mt: 2 }}>
                      <Avatar
                        alt="imagen" sx={{ width: (!responsive ? 100 : responsive2 ? 50 : 60), height: (!responsive ? 100 : responsive2 ? 50 : 60), mr: 2, borderColor: "black", borderStyle: "solid", borderWidth: "1px" }}
                        src={datosVendedor?.fotoLogo ? process.env.REACT_APP_USERSERVER+datosVendedor?.fotoLogo : '/assets/sin-foto.png'}
                      />
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <div>
                          <Typography sx={{ fontSize: (responsive ? "18px" : "24px"), mb: 1 }}> <b>{datosVendedor?.establecimiento}</b> </Typography>
                          <Typography sx={{ mb: 1 }}> {datosVendedor?.nombre} {datosVendedor?.apellidos} </Typography>
                          <Typography>Núm. de Contacto: <b>{datosVendedor?.telefono}</b></Typography>
                        </div>
                      </Box>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                      <Button
                        variant="contained"
                        startIcon={<WhatsAppIcon sx={{ height: (responsive2 ? "25" : "30px"), width: (responsive2 ? "25" : "30px") }}/>}
                        size="large"
                        target="_blank"
                        fullWidth={(responsive ? true : false)}
                        href={(`https://wa.me/52${datosVendedor?.telefono}?text=Hola,%20encontré%20tu%20publicación%20en%20autosXpartes.com,%20estoy%20interesado%20en%20tu%20${datosPublicacion.marca}%20${datosPublicacion.modelo}%20${datosPublicacion.anio}`)}
                        sx={{
                          textTransform: "none",
                          backgroundColor: "#00C853",
                          fontSize: (responsive2 ? "15px":"18px"),
                          ":hover": {
                            backgroundColor: "#00C853"
                          }
                        }}
                      >
                        Chat en WhatsApp
                      </Button>
                    </Box>
                </Card>
              </>
              :
              <Card variant="outlined" sx={{ p: 1 }}>
                <CardContent>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Avatar
                      alt="imagen" sx={{ width: (!responsive ? 100 : responsive2 ? 40 : 50), height: (!responsive ? 100 : responsive2 ? 40 : 50), mr: 2 }}
                      src={process.env.REACT_APP_ICONSERVER+'dz-user-circle'}
                    />
                    <div>
                      <Typography sx={{ fontSize: (responsive2 ? "15px" : "18px") /*mb: 2.5*/ }}>
                        Para ver los datos del vendedor es necesario que inicies sesión en tu cuenta
                      </Typography>
                    </div>
                  </Box>
                </CardContent>
                <Box sx={{ p: "0px 10px 10px 10px" }}>
                  <Button
                    variant="contained"
                    startIcon={<WhatsAppIcon sx={{ height: "30px", width: "30px" }}/>}
                    size="large"
                    fullWidth={(responsive ? true : false)}
                    onClick={() => setBotonChatWhatsapp(!botonChatWhatsapp)}
                    sx={{
                      textTransform: "none",
                      backgroundColor: "#00C853",
                      fontSize: (responsive2 ? "15px":"18px"),
                      ":hover": {
                        backgroundColor: "#00C853"
                      }
                    }}
                  >
                    Chat en WhatsApp
                  </Button>
                  { botonChatWhatsapp &&
                    <Alert severity="error">
                      <AlertTitle>Para contactar al vendedor de este producto, es necesario que ingreses a tu cuenta.</AlertTitle>
                      <Button variant="contained" sx={{ marginTop: "8px", textTransform: "none" }}
                        size="large" color="warning" href="/login"
                      >Entrar a mi cuenta</Button>
                    </Alert>
                  }
                </Box>
              </Card>
              }
            </div>
            
            <div className="publicacion_info_description">
              <div className="publicacion_info_subtitulo">
                Descripción
              </div>
              <Card variant="outlined" sx={{ p: "25px", minHeight: "200px" }}>
                <div className="publicacion_info_descripcion_size">
                  {
                  datosPublicacion?.descripcion ? 
                 ( login ?
                  datosPublicacion.descripcion 
                  : "Para ver la descripción es necesario que inicies sesión en tu cuenta")
                  : "El vendedor no incluyo una descripción"
                    
                }
                
                  </div>
              </Card>
            </div>
            
            
            <div className="publicacion_info_preguntas">
              <div className="publicacion_info_subtitulo">
                Preguntas
              </div>
              { login ?
                <Card variant="outlined" sx={{ p: "0px 10px 10px 10px" }}>
                  { preguntasContestadas?.length === 0 ?
                      <Box sx={{ padding: "20px" }}>
                        <Typography variant='h6'>
                          {
                            datosPublicacion.estado === "Pausada" ?
                            'No se realizaron preguntas sobre esta publicación'
                            :
                            'Aún no se han hecho preguntas sobre esta publicación'
                          }
                        </Typography>
                      </Box>
                      :
                      <List>
                        { preguntasContestadas?.map((pregunta) => (
                          <div key={pregunta.idPregunta}>
                            <Box sx={{ p: "20px 5px 20px 5px" }}>
                              <Box sx={{ display: "flex" }}>
                                <Box sx={{ width: "30px", height: "30px" }}>
                                  <SVG src={process.env.REACT_APP_ICONSERVER+'dz-question' } width={30} fill="rgb(0, 93, 199)"/>
                                </Box>
                                <Box sx={{ pl: "15px" }}>
                                  <Box>
                                    <Typography><b>{ pregunta.pregunta }</b></Typography>
                                  </Box>
                                  <Box sx={{ p: "0px 20px 0px 20px" }}>
                                    <Typography>--{ pregunta.respuesta }</Typography>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                            <Divider variant="middle" sx={{ borderWidth: "1px" }} />
                          </div>
                        ))}
                      </List>
                  }
                </Card>
                :
                <Card variant="outlined" sx={{ p: 1 }}>
                  <CardContent>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Avatar
                        alt="imagen" sx={{ width: (!responsive ? 70 : responsive2 ? 40 : 50), height: (!responsive ? 70 : responsive2 ? 40 : 50), mr: 2 }}
                        src={process.env.REACT_APP_ICONSERVER+'dz-question'}
                      />
                      <div>
                        <Typography sx={{ fontSize: (responsive2 ? "15px" : "18px") }}>
                          Para ver las preguntas de esta publicación, es necesario que inicies sesión en tu cuenta
                        </Typography>
                      </div>
                    </Box>
                  </CardContent>
                </Card>
              }
              {
                datosPublicacion.estado === "Pausada" ?
                null
                :
                <Box sx={{ textAlign: (responsive ? "center" : "none") }}>
                  <Button variant="contained" color="warning" size="large" fullWidth={(responsive ? true : false)} sx={{ textTransform: "none", mt: 3 }} onClick={handleClickOpenModalPreguntar2}>
                    Hacer una pregunta
                  </Button>
                </Box>
              }
              { receiveMessage && <Alert variant="filled" severity="success" sx={{ marginTop: 2 }}><AlertTitle>{ receiveMessage }</AlertTitle></Alert> }
              { botonPreguntar2 &&
                <Box sx={{ marginTop: 2 }}>
                  <Alert severity="error">
                    <AlertTitle>Para hacer una pregunta sobre esta publicación, es necesario que ingreses a tu cuenta.</AlertTitle>
                    <Button variant="contained" sx={{ marginTop: "8px", textTransform: "none" }}
                      size="large" color="warning" href="/login"
                    >Entrar a mi cuenta</Button>
                  </Alert>
                </Box>
              }
            </div>
          </div>
        </>
      }

      <ImageZoomCarouselModal />

      <Dialog
        open={openModalCreateQuestion}
        //onClose={() => dispatchPublicationData(closeCreateQuestionModal())}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              maxWidth: "500px",
            },
          },
        }}
      >
        <div>
          <Box sx={{ backgroundColor: "black", color: "white", p: 3, display: "flex" }}>
            <Typography variant='h5'>Preguntar sobre la publicación</Typography>
            <IconButton
              aria-label="close"
              onClick={() => dispatchPublicationData(closeCreateQuestionModal())}
              sx={{
                position: 'absolute',
                right: 18
              }}
            >
              <SVG src={process.env.REACT_APP_ICONSERVER+'dz-error'} width={20} fill="white" />
            </IconButton>
          </Box>
          <DialogContent>
            <DialogContentText>
              Recuerda preguntar de la manera mas correcta, sin ofender o escribir malas palabras al vendedor.
            </DialogContentText>

            <TextField
              id="pregunta" value={ pregunta } error={ preguntaError ? true : false }
              label={preguntaError ? preguntaError : 'Escribe tu pregunta'} sx={{ mb: 1 }} fullWidth
              onChange={ ({target}) => dispatchPublicationData( onChange({ field: 'pregunta', value: target.value })) }
              variant="outlined" margin="dense" autoComplete='off'
            />
            <Box sx={{ display: "flex", justifyContent: "center", pt: 1 }} >
              <Box>
                <Button
                  onClick={() => dispatchPublicationData(closeCreateQuestionModal())}
                  color="warning" sx={{ marginRight: 1 }}
                  size="large" startIcon={<DeleteIcon />}
                >
                  Cancelar
                </Button>
                <Button
                  onClick={() => handleCreateQuestion()}
                  variant="contained" size="large"
                  sx={{ marginLeft: 1 }} endIcon={<SendIcon />}
                >
                  Enviar
                </Button>
              </Box>
            </Box>
          </DialogContent>
        </div>
      </Dialog>

      <Dialog
        open={openModalContactSeller}
        onClose={() => dispatchPublicationData(closeContactSellerModal())}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              maxWidth: "500px",
            },
          },
        }}
      >
        <Box sx={{ backgroundColor: "black", color: "white", p: 3, display: "flex" }}>
          <Typography variant='h5'>Contactar con el vendedor</Typography>
          <IconButton
            aria-label="close"
            onClick={() => dispatchPublicationData(closeContactSellerModal())}
            sx={{
              position: 'absolute',
              right: 18
            }}
          >
            <SVG src={process.env.REACT_APP_ICONSERVER+'dz-error'} width={20} fill="white" />
          </IconButton>
        </Box>
        <Box sx={{ p: 3, width: 400 }}>
          Contactar
        </Box>
        {
          mensajePublica && <Alert severity="error"> {mensajePublica} </Alert>
        }
        <Box sx={{ display: "flex", justifyContent: "center", p: "0px 20px 20px 20px" }} >
          <Box>
            <Button
              onClick={() => dispatchPublicationData(closeContactSellerModal())}
              color="warning" sx={{ marginRight: 1 }}
              size="large"
            >
              Cancelar
            </Button>
            <Button
              onClick={() => dispatchPublicationData(startContactSellerPublication({ dispatchLocalPublication: dispatchPublicationData, parametro: 'Contactar' }))}
              variant="contained" size="large"
              sx={{ marginLeft: 1 }}
            >
              Aceptar
            </Button>
          </Box>
        </Box>
      </Dialog>

      <Dialog
        open={openModalReportPublication}
        onClose={() => dispatchPublicationData(closeReportPublicationModal())}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              maxWidth: "500px",
            },
          },
        }}
      >
        <Box sx={{ backgroundColor: "#ff1717", color: "white", p: 3, display: "flex" }}>
          <Typography variant='h5'>Reportar Publicación</Typography>
          <IconButton
            aria-label="close"
            onClick={() => dispatchPublicationData(closeReportPublicationModal())}
            sx={{
              position: 'absolute',
              right: 18
            }}
          >
            <SVG src={process.env.REACT_APP_ICONSERVER+'dz-error'} width={20} fill="white" />
          </IconButton>
        </Box>
        <Box sx={{ p: 3, width: 400 }}>
          Avisanos
        </Box>
        {
          mensajePublica && <Alert severity="error"> {mensajePublica} </Alert>
        }
        <Box sx={{ display: "flex", justifyContent: "center", p: "0px 20px 20px 20px" }} >
          <Box>
            <Button
              onClick={() => dispatchPublicationData(closeReportPublicationModal())}
              color="warning" sx={{ marginRight: 1 }}
              size="large"
            >
              Cancelar
            </Button>
            <Button
              onClick={() => dispatchPublicationData(startReportPublication({ dispatchLocalPublication: dispatchPublicationData, parametro: 'Reportar' }))}
              variant="contained" size="large"
              sx={{ marginLeft: 1 }}
            >
              Aceptar
            </Button>
          </Box>
        </Box>
      </Dialog>

    </div>
  )
}