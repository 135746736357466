
import "./styles/preguntas.scss"
import { useContext, useEffect } from 'react';
import { MenuContext, QuestionsContext } from '../../context/QuestionsContext';
import { Box, Button, CardMedia, CircularProgress, Pagination, Typography, useMediaQuery } from '@mui/material';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import SVG from 'react-inlinesvg';
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../store/store";
import { /*showModalQuestionPublic,*/ startLoadAnswers, /*startUpdatePublicationQuestionInfo*/ } from "../../reducers/QuestionsReducer";
import { isNumeric } from "../../../../../../helpers/checkValues";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { paginate } from "../../../../../../helpers/arrays";
import { EliminarPreguntaModal } from "./modals/EliminarPreguntaModal";

//TOTAL DE RESULTADOS POR PAGINA
const resuladosPorPagina = 6;

export const Preguntas = () => {

    // PARAMETRO PARA NAVEGAR HACIA OTRO COMPONENTE (HREF O LINK)
    const history = useHistory();

    let location = useLocation();

    const responsive = useMediaQuery('(max-width:400px)');

    //LEER EL REDUCER LOCAL
    const { questionsReducerState, dispatchQuestion } = useContext(QuestionsContext); 
    //const { questions, countQuestions, respuesta, respuestaError } = questionsReducerState;
    const { countQuestions, answers, /*respuesta, respuestaError*/ } = questionsReducerState;

    const storeAuth = (state: RootState ) => state.auth;
    const { id } = useSelector(storeAuth);

    /*useEffect(() => {
        dispatchQuestion(startLoadQuestions({ dispatchLocalQuestions: dispatchQuestion, idUser: id }));
    }, [id, dispatchQuestion]);*/

    useEffect(() => {
      dispatchQuestion(startLoadAnswers({ dispatchLocalQuestions: dispatchQuestion, idUser: id }));
    }, [id, dispatchQuestion]);

    //console.log(answers);

    const preguntasAscendente = answers?.slice().reverse();

    /*const handleClickPublicationInfoModal = (idPublicacion: any) => {
        dispatchQuestion(startUpdatePublicationQuestionInfo({ dispatchLocalQuestions: dispatchQuestion, publicacionInfoId: idPublicacion }));
        dispatchQuestion(showModalQuestionPublic());
    }*/

    const handlePaginationChange = (event: React.ChangeEvent<unknown>, value: number) => {
        history.push(`/questions/${value}`);
        window.scrollTo(0, 0);
    };

    // SACAMOS LOS PARAMETROS DE LA DIRECCION URL 
    const { questionPage } = useParams<{ questionPage: string }>();

    const handleHrefPublicacion = (publicacion: any) => {
      history.push(`/findUser/publication/${publicacion}`);
    }

    const { setIndexComponent } = useContext( MenuContext );

    if (location.pathname === '/response') {
      history.push('/questions/1');
      setIndexComponent(1);
    }

    return (
      <div className="preguntas_main">
        <div className="preguntas_titulo">
          Mis preguntas
        </div>
        <div className="preguntas_section">
          {
            !preguntasAscendente ?
            <Box sx={{ width: "100%", height: "600px", display: "flex", alignItems: "center", justifyContent: "center" }}>
              <CircularProgress size={30} sx={{ mr: 1 }} /><Typography variant="h5">Cargando</Typography>
            </Box>
            :
            preguntasAscendente.length === 0 ?
            <Box sx={{ width: "100%", height: (responsive ? "400px" : "600px"), display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Box sx={{ width: 100, height: 80 }}>
                <SVG src={ process.env.REACT_APP_ICONSERVER+'dz-edit' } width={(responsive ? 60 : 100)} fill="#ff6700"/>
              </Box>
              <Box sx={{ maxWidth: 400 }}>
                <Typography variant={(responsive ? "h6" : "h5")} color="rgb(0, 93, 199)"><b>Aún no has realizado preguntas</b></Typography>
              </Box>
            </Box>
            :
            paginate(preguntasAscendente , resuladosPorPagina, isNumeric(questionPage) ? Number(questionPage) : 1 ).map( (question) =>                             

              <div className="preguntas_format" key={question.idPregunta}>

                <div className="preguntas_format_imagen">
                  <CardMedia
                    component="img"
                    image={ question.fotoPublicacion ? process.env.REACT_APP_PHOTOSERVER+question.fotoPublicacion : './assets/sin-foto.png' }
                    alt="carro"
                    className="preguntas_imagen_height"
                  />
                </div>
                <div className="preguntas_format_publicacion">
                  {question.marca} {question.modelo} {question.anio}
                </div>
                <div className="preguntas_format_boton">
                  <Button
                    startIcon={<BorderColorIcon />}
                    color="success" variant="contained"
                    onClick={() => handleHrefPublicacion(question.idPublicacion)}
                    sx={{ textTransform: 'none' }}
                  >
                    Ver publicación
                  </Button>
                </div>
                <div className="preguntas_format_pregunta">
                  <b>Pregunta:</b> { question.pregunta }
                </div>
                <div className="preguntas_format_respuesta">
                  <Box>
                    { ( function() {
                      switch ( question.estadoPregunta ) {
                        case 'Finalizada':
                          return <Box sx={{ display: "flex", alignItems: "center" }}><p><b>Respuesta:</b> { question.respuesta }</p><p className="estado_pregunta_color_verde"><b>Respondida</b></p></Box>
                        case 'Pendiente':
                          return <p className="estado_pregunta_color_amarillo"><b>En espera de respuesta</b></p>
                        case 'Reportada':
                          return <Box sx={{ display: "flex" }}><p><b>Respuesta:</b> { question.respuesta }</p><p className="estado_pregunta_color_rojo"><b>{ `Tu pregunta fue ${question.estadoPregunta}` }</b></p></Box>
                        }
                      })()
                    }
                  </Box>
                </div>
                <div className="preguntas_format_opciones">
                  
                </div>

              </div>
            )
          }
          <Box sx={{ display: "flex", justifyContent: "center" }}>  {/* COMPONENTE DE LA PAGINACION DE LAS PUBLICACIONES */}
            <Pagination
              count={countQuestions ? Math.ceil(countQuestions/resuladosPorPagina) : 1} 
              page={ isNumeric(questionPage) ? Number(questionPage) : 1 } 
              defaultPage={1} 
              color="primary" 
              size="large" 
              onChange={ handlePaginationChange }
            />
          </Box>
        </div>

        <EliminarPreguntaModal/>

        {/*<Dialog
          open={modalContestar}
          onClose={handleCloseModalContestar}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                maxWidth: "500px",
              },
            },
          }}
        >
          <div>
            <Box sx={{ backgroundColor: "black", color: "white", p: 3, display: "flex" }}>
              <Typography variant='h5'>Responder pregunta</Typography>
              <IconButton
                aria-label="close"
                onClick={handleCloseModalContestar}
                sx={{
                  position: 'absolute',
                  right: 18
                }}
              >
                <SVG src={process.env.REACT_APP_ICONSERVER+'dz-error'} width={20} fill="white" />
              </IconButton>
            </Box>
            <DialogContent>
              <DialogContentText>
                Recuerda responder de la manera mas correcta, sin ofender o escribir malas palabras.
              </DialogContentText>

              <Box sx={{ display: "flex", justifyContent: "center", mb: 1, mt: 1 }}>
                  <Typography variant="h5" color="rgb(0, 93, 199)">{pregunta}</Typography>
              </Box>

              <TextField
                id="pregunta" value={ respuesta } error={ respuestaError ? true : false }
                label={respuestaError ? respuestaError : 'Escribir respuesta'} sx={{ mb: 1 }} fullWidth
                onChange={ ({target}) => dispatchQuestion( onChange({ field: 'respuesta', value: target.value })) }
                variant="outlined" margin="dense" autoComplete='off'
              />

              <Box sx={{ display: "flex", justifyContent: "center", pt: 1 }} >
                <Box>
                  <Button
                    color="warning" sx={{ marginRight: 1 }}
                    size="large" startIcon={<DeleteIcon />}
                    onClick={handleCloseModalContestar}
                  >
                    Cancelar
                  </Button>
                  <Button
                    variant="contained" size="large"
                    sx={{ marginLeft: 1 }} endIcon={<SendIcon />}
                    onClick={() => handleAnswerQuestion()}
                  >
                    Enviar
                  </Button>
                </Box>
              </Box>

            </DialogContent>
          </div>
        </Dialog>*/}
      </div>
    )
}
