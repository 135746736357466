
import "./styles/packagesModal.scss"
import { Box, Button, Dialog, IconButton, Typography, useMediaQuery } from '@mui/material';

import SVG from 'react-inlinesvg';
//import { formatoMoneda } from '../helpers/formats';
import { RootState, AppDispatch } from '../store/store';
import { useSelector, useDispatch } from 'react-redux';
import { closeModalPackage, startChangePackage } from '../reducers/businessReducer';
import { showModalRegister } from "../reducers/registerReducer";
import { RegisterSaleModal } from "./RegisterSaleModal";

export const PackagesModal = () => {

    const responsive = useMediaQuery('(max-width:300px)');

    //LEER INFORMACION DE REDUX
    const storeBusiness = (state: RootState) => state.business;
    const { showmodal, userPackages } = useSelector(storeBusiness);
    const storeAuth = (state: RootState) => state.auth;
    const { registroVendedor } = useSelector(storeAuth);

    const dispatchRedux = useDispatch<AppDispatch>();

    //APLICAR EL PAQUETE SELECCIONADO
    const handleClick = async (id: string, paquete: string) => {
        //validar si ya tiene registro de vendedor
        if ( registroVendedor ) {
          await dispatchRedux<any>(startChangePackage({packageId: id}));
        } else {
          if (paquete==='BASICO') {
            await dispatchRedux<any>(startChangePackage({packageId: id}));
          } else {
              await dispatchRedux<any>(showModalRegister({packageId: id})); 
          }
        }
        dispatchRedux(closeModalPackage());
    }

    return (
      <>
      <RegisterSaleModal/>
        <Dialog
          open={showmodal}
          onClose={()=>{dispatchRedux(closeModalPackage())}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                maxWidth: "1000px",
              },
            },
          }}
        >
          {
            userPackages ? (
              <>
                <Box sx={{ backgroundColor: "black", color: "white", p: (responsive ? 2.5 : 3), display: "flex" }}>
                  <Typography variant='h5'>Nuestros Paquetes</Typography>
                  <IconButton
                    aria-label="close"
                    onClick={()=>{dispatchRedux(closeModalPackage())}}
                    sx={{
                      position: 'absolute',
                      right: 18
                    }}
                  >
                    <SVG src={process.env.REACT_APP_ICONSERVER+'dz-error'} width={20} fill="white" />
                  </IconButton>
                </Box>
                <div className="package_modal_container">
                  <div className="package_modal_format">
                    {
                      userPackages.map((pack) => (
                        <div className="package_modal_card" key={ pack.id }>
                          <div className="package_modal_card_titulo">
                            <p className="package_modal_card_plan_titulo">{ pack?.paquete} </p>
                          </div>
                          <div className="package_modal_card_icono">
                            {
                              <SVG src={process.env.REACT_APP_ICONSERVER+pack?.icono} className="package_modal_icon_plan"/>
                            }
                          </div>
                          <div className="package_modal_card_dato1">
                            <p>{/* pack?.descripcion */}
                              {
                                pack.paquete === 'BASICO'
                                ?
                                  pack.descripcion
                                :
                                  'Proximamente'
                              }
                            </p>
                          </div>
                          <div className="package_modal_card_dato2">
                            <p><b>Precio:</b>  {/* pack?.precio > 0 ? formatoMoneda(pack?.precio) : 'Gratis'*/}Gratis </p>
                          </div>
                          <div className="package_modal_card_opcion">
                            {
                              (pack?.paquete === 'VENDEDOR' || pack?.paquete === 'PREMIUM') ?
                              <Button disabled fullWidth variant="contained">Proximamente</Button> :
                              <Button variant="contained" fullWidth color="warning" onClick={()=>{ handleClick(pack.id, pack.paquete) }}>Seleccionar</Button> 
                            }
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </div>
              </>
            ) : (
              <Box sx={{ p: "20px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <SVG src={process.env.REACT_APP_ICONSERVER+'dz-cancel'} fill="red" width={60} />
                <Typography variant="h6" sx={{ paddingLeft: "10px" }}>Los paquetes de usuario no estan disponibles.</Typography>
              </Box>
            )
          }
        </Dialog>
      </>
    );
}