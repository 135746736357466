
import { createContext, useReducer } from 'react';
import { PublicacionInfoScreen } from '../PublicacionInfoScreen';
import { initialPublicationDataState, publicationDataReducer, PublicationDataReducerType } from '../reducers/PublicationDataReducer';

//DEFINIMOS UN CONTEXT PARA CARGAR INFORMACION DEL SERVIDOR
type publicationDataContextType = {
    publicationDataReducerState: PublicationDataReducerType,
    dispatchPublicationData: React.Dispatch<any>,
}
const publicationDataContextDefaultValues: publicationDataContextType = {
    publicationDataReducerState: initialPublicationDataState,
    dispatchPublicationData: () => null
};
export const PublicationDataContext = createContext<publicationDataContextType>(publicationDataContextDefaultValues);

//creamos el Functional Component
export const ContextPublicationData = () => {

    //REDUCER DEL COMPONENTE
    const [publicationDataReducerState, dispatchPublicationData] = useReducer(publicationDataReducer, initialPublicationDataState)

    return (
        <PublicationDataContext.Provider value = {{ publicationDataReducerState, dispatchPublicationData }}>
            <PublicacionInfoScreen />
        </PublicationDataContext.Provider>
    )
}