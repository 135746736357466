
import { useEffect, useState, createContext, useReducer } from 'react';
import { MyAccount} from '../MyAccount';
import { NotFound } from '../../../../notfound/NotFound';
import { Cuenta } from '../components/miCuenta/Cuenta';
import { Perfil } from '../components/miPerfil/Perfil';
import { Plan } from '../components/miPlan/Plan';
import { Direcciones } from '../components/misDirecciones/Direcciones';
import { Tarjetas } from '../components/misTarjetas/Tarjetas';
import { AccountReducerType, initialAccountState, accountReducer } from '../reducers/AccountReducer';


//DEFINIMOS UN CONTEXT PARA CARGAR INFORMACION DEL SERVIDOR
type accountContextType = {
    accountReducerState: AccountReducerType,
    dispatch: React.Dispatch<any>,
}
const accountContextDefaultValues: accountContextType = {
    accountReducerState: initialAccountState,
    dispatch: () => null
};
export const AccountContext = createContext<accountContextType>(accountContextDefaultValues);


//DEFINIMOS UN CONTEXT PARA NAVEGAR ENTRE COMPONENTES
type menuContextType = {
    componentJSX: JSX.Element,
    setIndexComponent: any,
    indexComponent: any
}
const menuContextDefaultValues: menuContextType = {
    componentJSX: <NotFound/>,
    setIndexComponent: undefined,
    indexComponent: undefined
};
export const MenuContext = createContext<menuContextType>(menuContextDefaultValues);


//creamos el Functional Component
export const ContextAccount = () => {

    //REDUCER DEL COMPONENTE
    const [accountReducerState, dispatch] = useReducer(accountReducer, initialAccountState)

    //NAVEGACION DE COMPONENTES
    const [componentJSX, setComponentJSX] = useState<JSX.Element>(<NotFound/>);
    const [indexComponent, setIndexComponent] = useState(0);
    useEffect(() => {
        switch (indexComponent) {
            case 0: setComponentJSX(<Cuenta />); break;
            case 1: setComponentJSX(<Perfil />); break;
            case 2: setComponentJSX(<Plan />); break;
            case 3: setComponentJSX(<Direcciones/>); break;
            case 4: setComponentJSX(<Tarjetas />); break;          
            default: setComponentJSX(<NotFound />); break;
        }
    }, [indexComponent]) 

    return (

        <AccountContext.Provider value = {{ accountReducerState, dispatch }}>
            <MenuContext.Provider value = {{ componentJSX, setIndexComponent, indexComponent }}>
                <MyAccount />
            </MenuContext.Provider>
        </AccountContext.Provider>

    )
}