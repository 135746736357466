import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";

export const Icon = () => {
      //LEER EL MODO OSCURO DE REDUX
    //leemos el Store general de Redux
    const storeDarkMode = (state: RootState ) => state.darkmode;
    //destructurando el state de Redux obtenemos el valor del reducer que buscamos
    const { darkMode } = useSelector(storeDarkMode);
    
    return (
      <>
      { 
          ( darkMode ) 
          ? (
            <svg viewBox="0 0 22 20">
              <path
                d="M20.5 18.9h-4.4L11 6.3l-3.7 9.1h3.9v3.5H1.5L9 1.1h3.9l7.6 17.8z"
                fill="red"
              />
            </svg> 
          ) : ( 
            <svg viewBox="0 0 22 20">
            <path
              d="M20.5 18.9h-4.4L11 6.3l-3.7 9.1h3.9v3.5H1.5L9 1.1h3.9l7.6 17.8z"
              fill="#005adc"
            />
          </svg>      
          )
      }
      </>
    );
}


export const Logo = () => {

    /*
    //LEER EL MODO OSCURO DE REDUX
    //leemos el Store general de Redux
    const storeDarkMode = (state: RootState ) => state.darkmode;
    //destructurando el state de Redux obtenemos el valor del reducer que buscamos
    const { darkMode } = useSelector(storeDarkMode);
    //leemos el Store general de Redux
    
    return (
        <>
        { 
            ( darkMode ) 
            ? (
              <svg viewBox="0 0 22 20">
                <path
                  d="M20.5 18.9h-4.4L11 6.3l-3.7 9.1h3.9v3.5H1.5L9 1.1h3.9l7.6 17.8z"
                  fill="red"
                />
              </svg> 
            ) : ( 
              <svg viewBox="0 0 22 20">
              <path
                d="M20.5 18.9h-4.4L11 6.3l-3.7 9.1h3.9v3.5H1.5L9 1.1h3.9l7.6 17.8z"
                fill="#005adc"
              />
            </svg>      
            )
        }
        </>
    );
    */

   return (
    <>
    { 
        <svg viewBox="0 0 22 20">
          <path
            d="M20.5 18.9h-4.4L11 6.3l-3.7 9.1h3.9v3.5H1.5L9 1.1h3.9l7.6 17.8z"
            fill="#005adc"
          />
        </svg>        
    }
    </>
);
}