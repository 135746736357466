
import { Principal } from '../Principal';

//creamos el Functional Component
export const ContextAdmin = () => {


    return (
        <Principal />

    )
    
} 
