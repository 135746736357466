/*********************************************************************************************
Titulo:  SIDEBAR
Tipo:    Componente complemento
Nivel:   COMPONENTE Nivel 3  Basico, Redux, Context
Dependencias Componentes:
         principal
         navbar
Dependencias Redux:
         darkModeReducer.tsx
         sidebarReducer.tsx
         navbarReducer.tsx
Creador: Emmanuel Ordaz Alvarez 
Dazteck Todos los derechos reservados, prohibida su copia, distribucion o uso no autorizado
*********************************************************************************************/

import { useDispatch, useSelector } from 'react-redux';
import { useContext, useRef } from 'react';
import SVG from 'react-inlinesvg';
import { AppDispatch, RootState } from '../../../../store/store';
import { startLogo } from '../../../../reducers/navbarReducer';
import { SidebarItems } from './SidebarItems';
import { setSidebarWidth, setArrow } from '../../../../reducers/sidebarReducer';
import { Tooltip } from '@mui/material';
import { SocketContext } from '../../../../context/SocketContext';

import "./styles/sidebar.scss"

export const Sidebar = () => {
    
    //LEER EL STORE DE REDUX
    const storeDarkMode = (state: RootState ) => state.darkmode;
    const { darkMode } =  useSelector(storeDarkMode);
    const storeSidebar = (state: RootState ) => state.sidebar;
    const { sidebarItems } = useSelector(storeSidebar)
    const storeNavbar = (state: RootState ) => state.navbar;
    const { smallLogo } = useSelector(storeNavbar);
    
    //ESCRIBIR EN EL STORE DE REDUX
    const dispatchRedux = useDispatch<AppDispatch>();

    //LEER CONTEXT PRINCIPAL
    const { online } = useContext(SocketContext);

    //ANIMAR EL ANCHO DE LA SIDEBAR
    const { sidebarWidth, sidebarInitialWidth } = useSelector(storeSidebar);   
    const showSidebar = useRef<boolean>(true);
    const handleOnClick = () => {
        if (sidebarWidth === sidebarInitialWidth){
            showSidebar.current = false;
            dispatchRedux(setSidebarWidth({width: 40}));
            dispatchRedux<any>(startLogo({smallLogo: true}));
            //si se hace pequeña enrollar todos los menus desplegados
            sidebarItems.map(item => ( 
                dispatchRedux(setArrow({id: item.id, state: false}))
            ));
        } else {
            showSidebar.current = true;     
            dispatchRedux(setSidebarWidth({width: sidebarInitialWidth}));
            dispatchRedux<any>(startLogo({smallLogo: false})); 
        }       
    }  
    const sidebarStyles = {
        "--sidebarwidth": `${sidebarWidth}px`
    } as React.CSSProperties;

    //DEVOLVEMOS EL COMPONENTE JSX
    return (
        <div className={ 
                (darkMode) 
                    ? 'sidebar sidebar_dark_theme' 
                    : 'sidebar sidebar_light_theme'
                }>
            <div className={ 
                (darkMode) 
                    ? 'sidebar_main sidebar_dark_theme' 
                    : 'sidebar_main sidebar_light_theme'
                }
            >
                <div 
                    className={ 
                        (showSidebar.current)
                        ? 'sidebar_menu sidebar_menu_show'
                        : 'sidebar_menu sidebar_menu_hide' 
                    } 
                    style={ sidebarStyles }
                >
                    <div className={(darkMode)?'sidebar_hr sidebar_hr_dark':'sidebar_hr sidebar_hr_light'}><hr /></div> 
                    <SidebarItems 
                        sidebarItems = { sidebarItems }
                        logoPequeno = { smallLogo }
                    />
                    <div className={(darkMode)?'sidebar_hr sidebar_hr_dark':'sidebar_hr sidebar_hr_light'}><hr /></div> 
                </div>    
                <Tooltip title={(showSidebar.current)?'Cerrar Menu':'Ampliar Menu'} placement="right" arrow>
                    <div className="sidebar_action">
                        <div className={
                            (darkMode)
                            ? 'sidebar_button_dark_theme'
                            : 'sidebar_button_light_theme'
                        }                            
                        onClick={ () => handleOnClick() }
                        >
                            <div className="sidebar_icon">
                                {
                                // className=
                                //     (showSidebar.current)
                                //     ? 'sidebar_icon fas fa-chevron-left'
                                //     : 'sidebar_icon fas fa-chevron-right'
                                // 
                                    (showSidebar.current)
                                    ?<SVG src={process.env.REACT_APP_ICONSERVER+'dz-angle-left'} height={16} fill="currentColor"/>
                                    :<SVG src={process.env.REACT_APP_ICONSERVER+'dz-angle-right'} height={16} fill="currentColor"/>

                                }
                            </div>
                        </div>
                    </div>
                </Tooltip>
            </div>
            <div className="sidebar_notification">
                <div className={ 
                        (showSidebar.current)
                        ? 'sidebar_notification_online sidebar_menu_show'
                        : 'sidebar_notification_online sidebar_menu_hide' 
                    } 
                    style={ sidebarStyles }
                >
                    <div className={(online) ? 'sidebar_notification_icon sidebar_icon_on' : 'sidebar_notification_icon sidebar_icon_off'}>
                        <SVG src={process.env.REACT_APP_ICONSERVER+'dz-check-circle'} height={14} fill="currentColor"/>
                    </div>
                    <div className="sidebar_notification_text">
                        <p>
                            {(online) ? 'Servidor en linea' : 'Servidor desconectado'}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        
    )

}
