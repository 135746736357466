import { useDispatch, useSelector } from 'react-redux';
import SVG from 'react-inlinesvg';
import { setTitle } from '../../../../reducers/navbarReducer';
import { AppDispatch, RootState } from '../../../../store/store';
import { SidebarSubItems } from './SidebarSubItems';  
import { setComponent, setItemSelected, changeArrow, ItemType } from '../../../../reducers/sidebarReducer';


//definimos estructura para los items del sidebar
export const SidebarItems = (props: { sidebarItems: ItemType[], logoPequeno: boolean }) => {

    //LEER EL STORE DE REDUX
    const storeSidebar = (state: RootState ) => state.sidebar;
    const { itemSelected } = useSelector(storeSidebar)

    //ESCRIBIR EN EL STORE DE REDUX
    const dispatchRedux = useDispatch<AppDispatch>();

    const handleItemClick = (id: string, title: string) => {
        //enviamos el titulo al navbar
        dispatchRedux(setTitle({title: title})) 
        //guardamos el estado del item seleccionado
        dispatchRedux(setItemSelected({id: id})); 
        //desplegar solo si el menu es amplio
        if (!props.logoPequeno) {
            dispatchRedux(changeArrow({ id: id }));
        } 
        //mostramos el componente seleccionado
        dispatchRedux(setComponent({component: id}))
    }

    return (
        <>
            {
                props.sidebarItems.map( item => (
                    <div
                        key={item.key}
                        className="sidebar_item_group animate__animated animate__bounceInLeft"
                    >
                        <div 
                            key={item.key}
                            id={item.id}
                            title={item.title}
                            className={ (item.id === itemSelected) 
                                ? 'sidebar_row sidebar_row_selected'
                                : 'sidebar_row' 
                            }
                            onClick = { (e) => { handleItemClick(e.currentTarget.id, e.currentTarget.title) } }
                        >
                            <span className={
                                ( props.logoPequeno ) ? 'sidebar_b' : 'sidebar_bleft'
                            }
                            >
                                {/* <i className={ item.icon }></i> */}
                                <SVG src={process.env.REACT_APP_ICONSERVER+item.icon} height={17} fill="currentColor"/>                            
                            </span>
                            <span className="sidebar_item"><p>{item.title}</p></span>
                            <span></span>
                            <span className="sidebar_bright ">
                            {
                                (item.subitem.length > 0)
                                    && ((item.subitem.length > 0) &&                                     
                                        ((item.arrow)
                                        ?<SVG src={process.env.REACT_APP_ICONSERVER+'dz-angle-down'} height={16} fill="currentColor"/>
                                        :<SVG src={process.env.REACT_APP_ICONSERVER+'dz-angle-right'} height={16} fill="currentColor"/>                                           
                                    )
                                )      
                                    // && <i className = {
                                    //     (item.arrow) 
                                    //         ? 'sidebar_arrow_down fas fa-chevron-down'
                                    //         : 'sidebar_arrow_right fas fa-chevron-right'
                                    // }
                                    // ></i>                                                           
                            }
                            </span>                 
                        </div>       
                        {
                            (item.subitem.length > 0)
                            && <SidebarSubItems  
                                sidebarSubItems={ item.subitem }
                                itemArrow={ item.arrow } 
                                id={ item.id }
                            />
                        }

                    </div>
                ))
            }
        </>
    )
}