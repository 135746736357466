
import { Alert, Box, Button, Dialog, IconButton, InputAdornment, Step, StepContent, StepLabel, Stepper, styled, TextField, Typography, useMediaQuery } from "@mui/material"
import { useContext, useEffect, useState } from "react";
import { AccountContext } from "../../../context/AccountContext";
import { closeEditPhone, onChange, startCodeNewTelephone, startEditTelephone } from "../../../reducers/AccountReducer";
import validator from 'validator';
import InputMask from 'react-input-mask';

import SVG from 'react-inlinesvg';

const PhoneInput = styled( TextField ) `input[type='number']::-webkit-inner-spin-button, input[type='number']::-webkit-outer-spin-button { display: none; }`;

const steps = [
  { label: 'Ingresa el nuevo número teléfonico', description: 'Recuerda revisar con cuidado el número telefónico'},
  { label: 'Ingresa el codigo de validación', description: 'Se envio un codigo de validación al número teléfonico que ingresaste' },
];

export const EditarTelefono = () => {

  const responsive = useMediaQuery('(max-width:300px)');

  const [activeStep, setActiveStep] = useState(0);

  const { accountReducerState, dispatch } = useContext(AccountContext);
  const { phone, mensajeEditar, editarTelefonoModal, codigo, sendCodeNewTelephone } = accountReducerState;

  type editFormPhone = {
    telefono: string,
  }
  const initialFormValues : editFormPhone = {
    telefono: ''
  }
  const [formValues, setFormValues] = useState(initialFormValues);
  const { telefono } = formValues;

  useEffect(() => {
      setFormValues({
        telefono: phone.toString()
      })
  }, [ editarTelefonoModal, phone ]);

  const handleEditInputChange = ({target}:any) => {
    setFormValues({
        ...formValues,
        [target.name]: target.value
    });
  }

  const [telefonoError, setTelefonoError] = useState(false);
  const [telefonoTextError, setTelefonoTextError] = useState('');
  const [telephoneSending, setTelephoneSending] = useState(false);

  const [codigoError, setCodigoError] = useState(false);
  const [codigoTextError, setCodigoTextError] = useState('');
  const [codeSending, setCodeSending] = useState(false);

  const handleClickClosePhoneModal = () => {
    setTelefonoError(false)
    setTelefonoTextError('');
    setCodigoError(false);
    setCodigoTextError('');
    dispatch (closeEditPhone());
  }

  //VALIDACIONES BOTON SIGUIENTE
  const handleNext = async (index: number) => {
    let error = false;
    switch (index) {
        case 0:
          if (!telefono) {
            setTelefonoError(true);
            setTelefonoTextError('Escriba un número teléfonico');
            error = true;
          } else {
            if (!validator.isLength(telefono,{min: 10, max:10})) {
              setTelefonoError(true);
              setTelefonoTextError('El numero telefonico es incorrecto');
              error=true;
            } else { 
              setTelefonoError(false);
              setTelefonoTextError('');
            }
          }
          if (!error) {
              dispatch(startEditTelephone({dispatch: dispatch, telefono: telefono}))
          }
        break;
        case 1:
            if (validator.isLength(codigo,{min: 9, max: 9})){
              setCodigoError(false);
              setCodigoTextError('');
              //EJECUCION DE FETCH
              dispatch(startCodeNewTelephone({dispatch: dispatch, telefono: parseInt(telefono), codigo: codigo}))
          } else {
              //hooks de control de error de input
              setCodigoError(true);
              setCodigoTextError('El codigo de verificacion no es valido');
          }
        break;
      }
  };

  //validacion de email 
  useEffect(() => {
    if (sendCodeNewTelephone) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } 
  }, [sendCodeNewTelephone])

  //VALIDANDO FUNCION DEL BOTON ATRAS
  const handleBack = (index: number) => {
    //console.log('Back'+index);
    switch (index) {
        case 0:
            setTelefonoError(false);
            setTelephoneSending(false);
            setTelefonoTextError('');               
        break;
        case 1:
            setCodigoError(false);
            setCodeSending(false);
            setCodigoTextError('');               
        break;
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Dialog
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={editarTelefonoModal}
      //onClose={handleClickClosePhoneModal}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            maxWidth: "600px",
          },
        },
      }}
    >
      <Box sx={{ backgroundColor: "rgb(0, 93, 199)", color: "white", p: (responsive ? 2 : 3), display: "flex" }}>
        <Typography variant={(responsive ? 'h6' : 'h5')}>Modificar el número telefónico</Typography>
        <IconButton
          aria-label="close"
          onClick={handleClickClosePhoneModal}
          sx={{
            position: 'absolute',
            right: 18
          }}
        >
          <SVG src={process.env.REACT_APP_ICONSERVER+'dz-error'} width={20} fill="white" />
        </IconButton>
      </Box>

      <Box sx={{ p: (responsive ? 2 : 4) }}>
        <Stepper nonLinear activeStep={activeStep} orientation='vertical'>
          {steps.map((step, index) => (
            <Step key={step.label}>
              {/* SUBTITULOS */}
              <StepLabel>
                <Typography fontSize={(responsive ? "17px" : "20px")}>
                  <b>{step.label}</b>
                </Typography>
              </StepLabel>
              <StepContent>                                    
                <Typography fontSize={(responsive ? "15px" : "16px")}>{step.description}</Typography>
                <Box sx={{ mt: 2, width: '100%' }}>
                  {/* COMPONENTES */} {                                            
                    function() {                                     
                      switch (index) {
                        case 0 : return <>
                          <Box>
                            <Alert sx={{ mb: 2 }} severity="warning"><b>NOTA:</b> Solo se permiten pocos cambios del número telefónico</Alert>
                            <PhoneInput
                              id="telefono" value={ telefono } error={ telefonoError } label={telefonoTextError ? telefonoTextError : 'Teléfono'}
                              onChange={handleEditInputChange} fullWidth name="telefono"
                              variant="outlined" margin="dense" type="number"
                              InputProps={{
                                startAdornment: (responsive ? null : <InputAdornment position="start">MX (+52)</InputAdornment>)
                              }}
                            />
                          </Box>
                          {
                            mensajeEditar && <Alert severity="error" sx={{ marginTop: 2 }}> {mensajeEditar} </Alert>
                          }
                        </>
                        case 1 : return <>
                          <Box>
                            <InputMask
                                mask="****-****"
                                value={ codigo }
                                onChange={ ({target}) => dispatch( onChange({ field: 'codigo', value: target.value })) }
                            >
                                {() => <TextField
                                    fullWidth id="outlined-basic" label={codigoTextError ? codigoTextError : 'Codigo de Validación'}
                                    variant="outlined" margin="dense" error={ codigoError }
                                    inputProps={{ style: { textTransform: "uppercase" } }}
                                />}
                            </InputMask>
                          </Box>
                          {
                            mensajeEditar && <Alert severity="error" sx={{ marginTop: 2 }}> {mensajeEditar} </Alert>
                          }
                        </>
                      }
                    }()                                                
                  }
                  {/* CONTROLES */}
                  <div>
                    { function() {
                      switch (index) {
                        case 0: return <>
                          { telephoneSending
                            ? <Button disabled variant="contained" sx={{ mt: 2, mr: 1 }}>Enviando...</Button>
                            : <Button color="warning" variant="contained" onClick={ () => { handleNext(index) }} sx={{ mt: 2, mr: 1 }}>Siguiente</Button>
                          }
                        </>
                        case 1: return <>
                          { codeSending
                            ? <Button disabled variant="contained" sx={{ mt: 2, mr: 1 }}>Actualizando...</Button>
                            : <Button color="warning" variant="contained" onClick={ () => { handleNext(index) }} sx={{ mt: 2, mr: 1 }}>Aceptar</Button>
                          }
                        </>                                                
                        }
                      }()                                                                                        
                    }
                    { function() { 
                      switch (index) {
                        case 0: return null
                        case 1: return <Button onClick={ () => { handleBack(index) }} sx={{ mt: 2, mr: 1 }}>Atrás</Button>
                        }
                      }()
                    }
                  </div>
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>

    </Dialog>
  )
}
