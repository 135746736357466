
import { Alert, Box, Button, Dialog, IconButton, InputAdornment, Step, StepContent, StepLabel, Stepper, Typography, styled, TextField, useMediaQuery } from "@mui/material"
import { useContext, useEffect, useState } from "react";
import SVG from 'react-inlinesvg';
import { AccountContext } from "../../../context/AccountContext";
import { closeEditTelefonoLocal, onChange } from "../../../reducers/AccountReducer";
import InputMask from 'react-input-mask';
import validator from 'validator';

const PhoneInput = styled( TextField ) `input[type='number']::-webkit-inner-spin-button, input[type='number']::-webkit-outer-spin-button { display: none; }`;

const steps = [
  { label: 'Ingresa el nuevo número de contacto', description: 'Recuerda revisar con cuidado el número telefónico'},
  { label: 'Ingresa el codigo de validación', description: 'Se envio un codigo de validación al número teléfonico que ingresaste' },
];

export const EditarTelefonoLocal = () => {

    const responsive = useMediaQuery('(max-width:300px)');

    const [activeStep, setActiveStep] = useState(0);

    const { accountReducerState, dispatch } = useContext(AccountContext);
    const { /*name, lastName, mensajeEditar*/ editarTelefonoLocal, numContacto,        codigo, sendCodeNewTelephone, mensajeEditar } = accountReducerState;

    type editFormPhone = {
      telefonoLocal: number | string | null,
    }
    const initialFormValues : editFormPhone = {
      telefonoLocal: ''
    }
    const [formValues, setFormValues] = useState(initialFormValues);
    const { telefonoLocal } = formValues;

    useEffect(() => {
        setFormValues({
          telefonoLocal: numContacto/*.toString()*/
        })
    }, [ editarTelefonoLocal, numContacto ]);

    const handleEditInputChange = ({target}:any) => {
      setFormValues({
          ...formValues,
          [target.name]: target.value
      });
    }

    const [telefonoLocalError, setTelefonoLocalError] = useState(false);
    const [telefonoLocalTextError, setTelefonoLocalTextError] = useState('');
    const [localTelephoneSending, setLocalTelephoneSending] = useState(false);

    const [codigoError, setCodigoError] = useState(false);
    const [codigoTextError, setCodigoTextError] = useState('');
    const [codeSending, setCodeSending] = useState(false);

    const handleClickCloseLocalPhoneModal = () => {
      setTelefonoLocalError(false)
      setTelefonoLocalTextError('');
      setCodigoError(false);
      setCodigoTextError('');
      dispatch (closeEditTelefonoLocal());
    }

    //VALIDACIONES BOTON SIGUIENTE
    const handleNext = async (index: number) => {
      let error = false;
      switch (index) {
          case 0:
            if (!telefonoLocal) {
              setTelefonoLocalError(true);
              setTelefonoLocalTextError('Escriba un número teléfonico');
              error = true;
            } else {
              if (!validator.isLength(telefonoLocal.toString(),{min: 10, max:10})) {
                setTelefonoLocalError(true);
                setTelefonoLocalTextError('El numero telefonico es incorrecto');
                error=true;
              } else { 
                setTelefonoLocalError(false);
                setTelefonoLocalTextError('');
              }
            }
            if (!error) {
                //dispatch(startEditTelephone({dispatch: dispatch, telefono: telefono}))
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
          break;
          case 1:
              if (validator.isLength(codigo,{min: 9, max: 9})){
                setCodigoError(false);
                setCodigoTextError('');
                //EJECUCION DE FETCH
                //dispatch(startCodeNewTelephone({dispatch: dispatch, telefono: parseInt(telefono), codigo: codigo}))
                dispatch (closeEditTelefonoLocal());
            } else {
                //hooks de control de error de input
                setCodigoError(true);
                setCodigoTextError('El codigo de verificacion no es valido');
                
            }
          break;
        }
    };

    //validacion de email 
    useEffect(() => {
      if (sendCodeNewTelephone) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } 
    }, [sendCodeNewTelephone])

  //VALIDANDO FUNCION DEL BOTON ATRAS
  const handleBack = (index: number) => {
    //console.log('Back'+index);
    switch (index) {
        case 0:
            setTelefonoLocalError(false);
            setLocalTelephoneSending(false);
            setTelefonoLocalTextError('');               
        break;
        case 1:
            setCodigoError(false);
            setCodeSending(false);
            setCodigoTextError('');               
        break;
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
};

  return (
    <Dialog
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={editarTelefonoLocal}
      //onClose={handleClickCloseLocalPhoneModal}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            maxWidth: "600px",
          },
        },
      }}
    >
      <Box sx={{ backgroundColor: "rgb(0, 93, 199)", color: "white", p: (responsive ? 2 : 3), display: "flex" }}>
        <Typography variant='h5'>Editar Teléfono de Contacto</Typography>
        <IconButton
          aria-label="close"
          onClick={handleClickCloseLocalPhoneModal}
          sx={{
            position: 'absolute',
            right: 18
          }}
        >
          <SVG src={process.env.REACT_APP_ICONSERVER+'dz-error'} width={20} fill="white" />
        </IconButton>
      </Box>
      <Box sx={{ p: (responsive ? 2 : 4) }}>
        <Stepper nonLinear activeStep={activeStep} orientation='vertical'>
          {steps.map((step, index) => (
            <Step key={step.label}>
              {/* SUBTITULOS */}
              <StepLabel>
                <Typography fontSize={(responsive ? "17px" : "20px")}>
                  <b>{step.label}</b>
                </Typography>
              </StepLabel>
              <StepContent>                                    
                <Typography fontSize={(responsive ? "15px" : "16px")}>{step.description}</Typography>
                <Box sx={{ mt: 2, width: '100%' }}>
                  {/* COMPONENTES */} {                                            
                    function() {                                     
                      switch (index) {
                        case 0 : return <>
                          <Box>
                            <Alert sx={{ mb: 2 }} severity="warning"><b>NOTA:</b> Solo se permiten pocos cambios del número telefónico</Alert>
                            <PhoneInput
                              id="telefonoLocal" value={ telefonoLocal } error={ telefonoLocalError } label={telefonoLocalTextError ? telefonoLocalTextError : 'Teléfono'}
                              onChange={handleEditInputChange} fullWidth name="telefonoLocal"
                              variant="outlined" margin="dense" type="number"
                              InputProps={{
                                startAdornment: (responsive ? null : <InputAdornment position="start">MX (+52)</InputAdornment>)
                              }}
                            />
                          </Box>
                          {
                            mensajeEditar && <Alert severity="error" sx={{ marginTop: 2 }}> {mensajeEditar} </Alert>
                          }
                        </>
                        case 1 : return <>
                          <Box>
                            <InputMask
                                mask="****-****"
                                value={ codigo }
                                onChange={ ({target}) => dispatch( onChange({ field: 'codigo', value: target.value })) }
                            >
                                {() => <TextField
                                    fullWidth id="outlined-basic" label={codigoTextError ? codigoTextError : 'Codigo de Validación'}
                                    variant="outlined" margin="dense" error={ codigoError }
                                    inputProps={{ style: { textTransform: "uppercase" } }}
                                />}
                            </InputMask>
                          </Box>
                          {
                            mensajeEditar && <Alert severity="error" sx={{ marginTop: 2 }}> {mensajeEditar} </Alert>
                          }
                        </>
                      }
                    }()                                                
                  }
                  {/* CONTROLES */}
                  <div>
                    { function() {
                      switch (index) {
                        case 0: return <>
                          { localTelephoneSending
                            ? <Button disabled variant="contained" sx={{ mt: 2, mr: 1 }}>Enviando...</Button>
                            : <Button color="warning" variant="contained" onClick={ () => { handleNext(index) }} sx={{ mt: 2, mr: 1 }}>Siguiente</Button>
                          }
                        </>
                        case 1: return <>
                          { codeSending
                            ? <Button disabled variant="contained" sx={{ mt: 2, mr: 1 }}>Actualizando...</Button>
                            : <Button color="warning" variant="contained" onClick={ () => { handleNext(index) }} sx={{ mt: 2, mr: 1 }}>Aceptar</Button>
                          }
                        </>                                                
                        }
                      }()                                                                                        
                    }
                    { function() { 
                      switch (index) {
                        case 0: return null
                        case 1: return <Button onClick={ () => { handleBack(index) }} sx={{ mt: 2, mr: 1 }}>Atrás</Button>
                        }
                      }()
                    }
                  </div>
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>
    </Dialog>
  )
}