import { createContext, useReducer } from 'react';
import { MyShopping } from '../MyShopping';
import { ShoppingReducerType, initialShoppingState, shoppingReducer } from '../reducers/ShoppingReducer';


//DEFINIMOS UN CONTEXT PARA CARGAR INFORMACION DEL SERVIDOR
type shoppingContextType = {
    shoppingReducerState: ShoppingReducerType,
    dispatch: React.Dispatch<any>,
}
const shoppingContextDefaultValues: shoppingContextType = {
    shoppingReducerState: initialShoppingState,
    dispatch: () => null
};
export const ShoppingContext = createContext<shoppingContextType>(shoppingContextDefaultValues);


//creamos el Functional Component
export const ContextShopping = () => {


    //REDUCER DEL COMPONENTE
    const [shoppingReducerState, dispatch] = useReducer(shoppingReducer, initialShoppingState)


    return (
        <ShoppingContext.Provider value = {{ shoppingReducerState, dispatch }}>
            <MyShopping />
        </ShoppingContext.Provider>
    )
} 
