
import { Box, Link, Typography, useMediaQuery } from "@mui/material"

export const AvisoPrivacidadScreen = () => {

    const responsive = useMediaQuery('(max-width:500px)');
    const responsive2 = useMediaQuery('(max-width:400px)');

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Box sx={{ maxWidth: "1300px", p: (!responsive ? "60px 60px 80px 60px" : responsive2 ? "20px" : "30px") }}>
        <Typography variant={(responsive2 ? "h5" : "h3")} sx={{ fontWeight: 500, color: "rgb(0, 93, 199)", mb: (responsive2 ? 2 : 4) }}>Aviso de Privacidad</Typography>
          <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
            <b>AUTOSXPARTESPUNTOCOM S.A. DE C.V.</b>, es una persona moral constituida legalmente bajo el
            RFC “<b>AUT220412DW1</b>”, con correo electrónico: <b>contacto@autosxpartes.com</b>.
            Nuestra principal actividad se basa en servicios de plataformas tecnológicas intermediarias, en
            las cuáles se enajenan bienes y se prestan servicios a través de internet. Para lo que será
            necesario recabar tus datos personales para las finalidades señaladas a continuación.
          </Typography>

          <div className="aviso_privacidad_contenedores">
            <div className="aviso_privacidad_subtitulos">¿Para qué utilizamos tus datos personales?</div>
            <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
              ¡Tu privacidad es nuestra prioridad! Es por eso que en <b>AUTOSXPARTESPUNTOCOM</b> tratamos y
              protegemos tus datos personales cumpliendo estrictamente con la <b>LFPDPPP (Ley Federal de
              Protección de Datos Personales en Posesión de Particulares)</b>.
            </Typography><br/>
            <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
              Debes saber que recopilamos y tratamos tu información personal, siempre con tu
              conocimiento, cada vez que utilizas nuestros servicios, y especialmente en las siguientes
              ocasiones:
            </Typography><br/>
            <div>
              <ul>
                <li><Typography fontSize={(responsive2 ? "13px" : "16px" )}><b>I)</b> cuando formulas consultas o nos escribes a través de nuestros formularios de contacto;</Typography></li>
                <li><Typography fontSize={(responsive2 ? "13px" : "16px" )}><b>II)</b> cuando te registras en nuestro sitio web para crear tu área de cliente;</Typography></li> 
                <li><Typography fontSize={(responsive2 ? "13px" : "16px" )}><b>III)</b> cuando te suscribes a nuestro boletín informativo;</Typography></li>
                <li><Typography fontSize={(responsive2 ? "13px" : "16px" )}><b>IV)</b> cuando navegas por nuestro sitio web;</Typography></li>
                <li><Typography fontSize={(responsive2 ? "13px" : "16px" )}><b>V)</b> cuando realizas pedidos para adquirir o contratar nuestros productos o servicios;</Typography></li>
                <li><Typography fontSize={(responsive2 ? "13px" : "16px" )}><b>VI)</b> cuando interactúas a través de nuestros perfiles en las redes sociales;</Typography></li>
                <li><Typography fontSize={(responsive2 ? "13px" : "16px" )}><b>VII)</b> cuando nos escribes por correo electrónico u otras aplicaciones de mensajería; y</Typography></li>
                <li><Typography fontSize={(responsive2 ? "13px" : "16px" )}><b>VIII)</b> cuando respondes a nuestros mensajes, cuestionarios o encuestas.</Typography></li>
              </ul>
            </div><br/>
            <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
              Recuerda que, cuando te pidamos que llenes tus datos personales en cualquiera de los
              formularios disponibles en nuestro sitio web, algunos campos estarán marcados
              como obligatorios. Se trata de datos que necesitamos para poder prestarte el servicio o darte
              acceso a la funcionalidad en cuestión. Eres libre de facilitarnos esos datos o no, pero en caso
              de no hacerlo ten en cuenta que probablemente no puedas disfrutar de esos servicios o
              funcionalidades.
            </Typography><br/>
            <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
              En todo caso, tu consentimiento debe ser libre, especifico, informado e inequívoco, y debes
              otorgarlo mediante una acción positiva y afirmativa, sin ningún tipo de presión y sabiendo que
              estás consintiendo. En <b>AUTOSXPARTESPUNTOCOM</b> nos preocupamos por la transparencia,
              por eso al solicitar tu consentimiento a través de los diversos formularios, incluiremos un
              enlace a la presente Política de Privacidad y una casilla que deberás marcar para consentir el
              tratamiento en cuestión. Te recomendamos leerla completa y con atención, antes de prestar
              tu consentimiento. Mantendremos un registro de todos los consentimientos que hayas
              prestado.
            </Typography><br/>
            <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
              Todos los datos personales que nos facilites deberán ser verdaderos, exactos, completos y
              actualizados, y deberás responsabilizarte de cualquier daño o perjuicio, directo o indirecto,
              que pudiera ocasionarse como consecuencia del incumplimiento de tal obligación.
            </Typography>
          </div>

          <div className="aviso_privacidad_contenedores">
            <div className="aviso_privacidad_subtitulos">Con que finalidad recabamos tus datos personales</div>
            <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
              En <b>AUTOSXPARTESPUNTOCOM</b>, tratamos la información que nos facilites con diferentes
              finalidades según las funcionalidades de las que hagas uso.
            </Typography><br/>
            <div>
              <ol>
                <li>
                  <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
                    Trataremos los datos personales que nos facilites a traves del formulario de
                    registro para la gestion de usuarios de nuestro sitio web y para crear tu cuenta
                    o perfil personal de ususario, con el cual tendrás acceso a todas las
                    funcionalidades reservadas para usuarios registrados, como consultar tus
                    pedidos, y adquirir, ofrecer o vender productos en nuestra tienda en línea sin
                    tener que introducir de nuevo tus datos de identificación.
                  </Typography>
                </li><br/>
                <li>
                  <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
                    También podemos tratarlos para realizar análisis estadísticos sobre el uso que le
                    das a nuestra plataforma digital, con el fin de optimizar nuestro contenido y
                    mejorar tu experiencia en la página. Y si lo aceptas, tambien los usaremos para
                    enviarte información comercial sobre nuestros servicios que puedan ser de tu
                    interés. Podremos enviarte dicha información a través de distintos medios
                    (correo electrónico, WhatsApp, Facebook, Messenger, redes sociales, etc.).
                  </Typography>
                </li><br/>
                <li>
                  <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
                    Para realizar el cumplimiento de nuestras obligaciones legales, contables,
                    administrativas y fiscales, así como nuestros intereses legitimos, incluyendo
                    medidas de la normatividad antifraude durante la contratación.
                  </Typography>
                </li><br/>
                <li>
                  <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
                    Los datos personales que nos proporciones a lo largo del proceso de servicio en
                    nuestra plataforma en línea los utilizaremos para tramitar, enviar y facturar los
                    productos adquiridos y/o los servicios prestados, para informarte sobre
                    el seguimiento y la entrega del pedido, así como para gestionar
                    posibles devoluciones y reembolsos.
                  </Typography>
                </li>
              </ol>
            </div>
          </div>

          <div className="aviso_privacidad_contenedores">
            <div className="aviso_privacidad_subtitulos">Transferencia de datos</div>
            <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
              Al aceptar el uso de cokies, el usuario esta dando su consentimiento para la tansferencia de
              sus datos personales a los servicios de la plataforma, si el usuario no acepta el uso de cokies y
              transferencia de datos no podra hacer uso de la plataforma.
            </Typography><br/>
            <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
              Todos los datos que nos hayas proporcionado serán tratados por <b>AUTOSXPARTESPUNTOCOM</b>
              con la maxima confidencialidad. En algunas ocasiones, para cumplir con las finalidades
              indicadas, sera necesario compartir tus datos personales con prestadores de servicios o
              colaboradores externos que desempeñan funciones específicas para
              <b>AUTOSXPARTESPUNTOCOM</b> y que son necesarias para brindarte el servicio al que esta
              dedicada la empresa (entidades financieras o bancarias responsables de los movimientos de
              pago, empresas de transporte o logística, gestores contables o administrativos, consultores
              externos, abogados y auditores, proveedores, paqueterias, etc.). Estas empresas trataran tus
              datos personales bajo las instrucciones y acuerdos firmados con <b>AUTOSXPARTESPUNTOCOM</b>.
            </Typography>
          </div>

          <div className="aviso_privacidad_contenedores">
            <div className="aviso_privacidad_subtitulos">¿Quién es el responsable del tratamiento de tus datos personales?</div>
            <br/>
            <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
              Identidad: <b>AUTOSXPARTESPUNTOCOM S.A. de C.V. / AUT220412DW1</b>
            </Typography>
            <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
              Teléfono: <b>427 176 58 86</b>
            </Typography>
            <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
              Correo electrónico: <b>contacto@autosxpartes.com</b>
            </Typography>
          </div>

          <div className="aviso_privacidad_contenedores">
            <div className="aviso_privacidad_subtitulos">Plazo de conservación de tus datos personales</div>
            <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
              Los datos que nos proporciones a traves del formulario de contacto, seran conservados
              durante el tiempo necesario para atender y resolver tu consulta o solicitud, cumpliendo así
              con la finalidad correspondiente, es decir, mientras sigan siendo pertinentes en relación con
              los fines para los que fueron recopilados.
            </Typography><br/>
            <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
              Los datos que nos proporciones durante el proceso de compra/venta o en el marco de la
              contratacion de nuestros servicios, seran conservados durante el tiempo necesario para
              gestionar el servicio, incluyendo su facturación, envio y entrega del producto, posibles
              devoluciones y servicios posteriores.
            </Typography><br/>
            <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
              Los datos de tu perfil personal los conservaremos mientras sigas siendo un usuario registrado
              (es decir hasta que te des de baja en nuestras plataformas).
            </Typography>
          </div>

          <div className="aviso_privacidad_contenedores">
            <div className="aviso_privacidad_subtitulos">Legitimación para el tratamiento de tus datos</div>
            <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
              En cada formulario bajo el que recabemos tus datos personales, se te indicara la base legal que
              nos permite tratarlos.
            </Typography><br/>
            <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
              Se haran bajo la base de tu <b>CONSENTIMIENTO</b> los siguientes tratamientos que
              <b>AUTOSXPARTESPUNTOCOM</b> desarrolle:
            </Typography><br/>
            <div>
              <ol type="a">
                <li>
                  <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
                    Responder a tus consultas, mensajes o solicitudes de información enviados a traves de
                    nuestras vías y formularios de contacto;
                  </Typography>
                </li>
                <li>
                  <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
                    Darte de alta como usuario registrado en nuestra página web, crear tu cuenta o perfil
                    personal y permitirte utilizar todas las funcionalidades que ponemos a disposición de
                    los usuarios registrados;
                  </Typography>
                </li>
                <li>
                  <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
                    Gestionar tu suscripción a nuestro boletín informativo para mantenerte informado de
                    nuestras novedades y promociones;
                  </Typography>
                </li>
                <li>
                  <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
                    Gestionar tu participacion en concursos, sorteos, encuestas y actividades
                    promocionales;
                  </Typography>
                </li>
                <li>
                  <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
                    Gestionar los datos personales recogidos por las cokies para analizar el uso que se hace
                    del portal y de todas sus funcionalidades, ofrecerte contenido personalizado, medir las
                    visitas recibidas y realizar analisis respecto de los hábitos de acceso y la actividad
                    desarrollada por los usuarios.
                  </Typography>
                </li>
              </ol>
            </div><br/>
            <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
              Se haran bajo la base de un <b>CONTRATO</b> y por <b>OBLIGACIÓN LEGAL</b> los siguientes tratamientos
              que <b>AUTOSXPARTESPUNTOCOM</b> desarrolle:
            </Typography><br/>
            <div>
              <ol type="a">
                <li>
                  <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
                    Gestionar tus pedidos, devoluciones y reembolsos;
                  </Typography>
                </li>
                <li>
                  <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
                    Enviarte informacion por cualquier medio, relacionado con tus pedidos, incluido el
                    servicio postventa;
                  </Typography>
                </li>
                <li>
                  <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
                    Llevar nuestra contabilidad y cumplir con nuestras obligaciones legales;
                  </Typography>
                </li>
                <li>
                  <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
                    Adoptar medidas antifraude para la protección de tus intereses durante la
                    contratación de nuestros servicios.
                  </Typography>
                </li>
              </ol>
            </div>
          </div>

          <div className="aviso_privacidad_contenedores">
            <div className="aviso_privacidad_subtitulos">Limitacion, revocación, cancelación, u oposición del uso de datos personales</div>
            <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
              En cualquier momento que lo desees, podras limitar, o bien, revocar tu consentimiento,
              poniendote en contacto con nosotros e indicando para que tratamientos o finalidades desear
              revocar el consentimiento. Puedes hacerlo desde el botón de acceso que se encuentra en el
              inicio de la plataforma “<b>Mis datos</b>” o bien escribiendonos al departamento de datos, vía
              correo electrónico a <b>contacto@autosxpartes.com</b>. Es posible que te pidamos copia de tu
              documento de identidad oficial, para verificar que eres tu. Ten en cuenta que la revocación de
              tu consentimiento no afecta la legalidad del tratamiento de uso de datos anteriores a dicha
              revocación, así como tampoco al tratamiento de datos que podamos realizar sobre bases
              legales necesarias.
            </Typography>
          </div>

          <div className="aviso_privacidad_contenedores">
            <div className="aviso_privacidad_subtitulos">¿Cuáles son tus derechos cuando nos facilitas tus datos?</div>
            <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
              Nuestro departamento de manejo de datos personales sera el encargado de la proteccion y
              tratameinto de tus datos, es tu derecho solicitar el cambio o revocación del permiso que
              otorgaste para tus datos.
            </Typography><br/>
            <div>
              <ol>
                <li>
                  <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
                    <b>Derecho de accceso</b>: Como titular, tienes derechos a acceder a tus datos personales,
                    asi como conocer el Aviso de Privacidad al que esta sujeto el tratamiento.
                  </Typography>
                </li><br/>
                <li>
                  <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
                    <b>Derecho de rectificación</b>: Tienes derecho a obtener la rectificación de los datos
                    personales, cuando sean inexactos o incompletos. Por ejemplo en caso de que hubiera
                    errores ortográficos, cambios en los datos recogidos, etc. En caso de que hubieramos
                    compartido tus datos con terceros, les indicaremos a que los rectifiquen en el sentido
                    en que nos hayas indicado, salvo que resulte imposible. En su caso, tambien te
                    comunicaremos a qué terceros hemos facilitado tus datos personales inexactos o
                    incompletos.
                  </Typography>
                </li><br/>
                <li>
                  <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
                    <b>Derecho de cancelación</b>: La cancelación de datos personales dará lugar a un periodo de
                    bloqueo tras el cual se procederá a la supresión del dato. <b>AUTOSXPARTESPUNTOCOM</b>
                    podrá conservarlos exclusivamente para efectos de las responsabilidades nacidas del
                    tratamiento. El periodo de bloqueo será equivalente al plazo de prescripción de las
                    acciones derivadas de la relación jurídica que funda el tratamiento en los términos de
                    la Ley aplicable en la materia. Te avisaremos una vez cancelado el dato. Cuando los
                    datos personales hubiesen sido transmitidos con anterioridad a la fecha de
                    rectificación o cancelación y sigan siendo tratados por terceros, haremos de su
                    conocimiento dicha solicitud de rectificación o cancelación, para que proceda a
                    efectuarla también.
                  </Typography>
                </li><br/>
                <li>
                  <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
                    <b>Derecho de oposición</b>: Tienes derecho en todo momento a oponerte al tratamiento de
                    tus datos. De resultar procedente, atenderemos tu solicitud de oposición y dejaremos
                    de tratar tus datos personales, salvo que existieran motivos legitimos que prevalezcan
                    sobre los intereses, derechos y libertades que hubieras aducido, o para la formulación
                    o la defensa de reclamaciones.
                  </Typography>
                </li>
              </ol>
            </div>
          </div>

          <div className="aviso_privacidad_contenedores">
            <div className="aviso_privacidad_subtitulos">¿Cómo puedes ejercitar tus derechos?</div>
            <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
              En la página web de <b>AUTOSXPARTESPUNTOCOM</b>, tenemos formularios a tu disposición para
              que puedas ejercitar todos tus derechos antes mencionados, de forma rápida, gratuita y
              sencilla. Solo tienes que solicitarnos el formulario en cuestión y devolverlnóslo completado a
              través de nuestra dirección de correo electrónico <b>contacto@autosxpartes.com</b> adjuntando una copia de tu documento
              nacional de identificación vigente. Una vez recibida tu solicitud, obtendras una respuesta en el
              plazo máximo de quince días.
            </Typography>
          </div>

          <div className="aviso_privacidad_contenedores">
            <div className="aviso_privacidad_subtitulos">Modificaciones a la presente Política de Privacidad</div>
            <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
              <b>AUTOSXPARTESPUNTOCOM</b> se reserva el derecho a modificar, en cualquier momento, los
              términos de la presente Política de Privacidad, En caso de hacerlo, te lo notificaremos a traves
              del correo electrónico que nos hayas proporcionado, para que puedas revisar y valorar los
              cambios y, en su caso, retirar tu consentimiento si lo consideras necesario. Te recomendamos
              que consultes el contenido de nuestra <b>Política de Privacidad</b> de vez en cuando, en caso de que
              hubiera cambios menores.
            </Typography>
          </div>

          <Typography variant={(responsive2 ? "h6" : "h5" )} sx={{ mt: (responsive2 ? 4 : 6), textAlign: "center" }}><b>POLÍTICA DE COOKIES</b></Typography>

          <div className="aviso_privacidad_contenedores">
            <div className="aviso_privacidad_subtitulos">¿Qué son las cookies?</div>
            <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
              Una cookie es un fichero que se descarga en tu ordenador al acceder a determinadas páginas
              web. Las cookies permiten a una página web, entre otras cosas, almacenar y recuperar
              información sobre los hábitos de navegación de un usuario o de su equipo, facilitando que
              dicho sitio web pueda ofrecerle opciones personalizadas la próxima vez que lo visite.
              Dependiendo de la información que contengan y de la forma en que utilice su equipo, las
              cookies pueden utilizarse para reconocer al usuario, para analizar el tráfico y con fines
              publicitarios y de marketing.
            </Typography><br/>
            <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
              La mayoría de sitios web utiliza cookies y éstas no te identifican ni son dañinas para tu
              ordenador o dispositivo móvil. De hecho, las cookies facilitan el uso y la navegación por una
              página web y son esenciales para el funcionamiento de internet, gracias a las innumerables
              ventajas que aportan en la prestación de servicios interactivos, como por ejemplo cuando se
              utilizan para gestionar tu sesión de usuario (reduciendo el número de veces que tienes que
              incluir tu contraseña), para mejorar los servicios ofrecidos, o para adecuar los contenidos de
              un sitio web a tus preferencias.
            </Typography><br/>
            <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
              Por lo general, el usuario puede comprobar o modificar el tipo de cookies que acepta a través
              de la configuración de su navegador, tal y como mostraremos más adelante. Tú decides si
              permites o no las cookies, pero si decides no permitirlas, debes bloquearlas en tu navegador.
            </Typography>
          </div>

          <div className="aviso_privacidad_contenedores">
            <div className="aviso_privacidad_subtitulos">¿Qué tipos de cookies utiliza esta página web?</div>
            <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
              Los sitios de <b>AUTOSXPARTESPUNTOCOM</b> utilizan cookies propias y de terceros.
            </Typography><br/>
            <div>
              <ul>
                <li>
                  <Typography sx={{ fontSize: (responsive2 ? "18px" : "20px"), fontWeight: 500 }}>¿Cómo puedes eliminar o desactivar las cookies?</Typography>
                  <Link fontSize={(responsive2 ? "13px" : "16px" )} href="https://support.google.com/chrome/answer/95647?hl=es" target="_blank" underline="hover">https://support.google.com/...</Link><br/>
                  <Link fontSize={(responsive2 ? "13px" : "16px" )} href="https://support.apple.com/es-es/HT201265" target="_blank" underline="hover">https://support.apple.com/...</Link>
                </li>
                <li>
                  <Typography sx={{ fontSize: (responsive2 ? "18px" : "20px"), fontWeight: 500, mb: 1 }}>Actualizaciones y cambios en la Política de Cookies</Typography>
                  <Typography fontSize={(responsive2 ? "13px" : "16px" )}>
                    <b>AUTOSXPARTESPUNTOCOM</b> puede modificar esta Política de Cookies en función de nuevas
                    exigencias legislativas, reglamentarias.
                    Cuando se produzcan cambios significativos en esta Política de Cookies se comunicará al
                    usuario mediante un aviso informativo en el sitio web.
                    Si deseas más información sobre qué uso hacemos de las cookies que utilizamos en el sitio
                    web, puedes enviarnos un correo a <b>contacto@autosxpartes.com</b>.
                  </Typography>
                </li>
              </ul>
            </div>
          </div>
        </Box>
      </Box>
  )
}