import { AppThunk } from '../store/store';
import { fetchSinToken, fetchToken } from '../helpers/fetch';
import { ModoOscuro } from './darkModeReducer';
import { clearNavbar } from './navbarReducer';
import { clearSidebar } from './sidebarReducer';

//definimos la estructura del reducer
interface AuthState {
    checking: boolean,
    login: boolean,
    loading: boolean,
    id: number | null,
    username: string | null,
    idrol: number | null,
    rol: string | null,
    msg: string | null,
    darkmode: boolean,
    name: string | null,
    lastName: string | null,
    email: string | null,
    phone: string | null,
    registroVendedor: boolean,
    invitacionVendedor: boolean
};
//definimos el valor inicial
const initialState: AuthState = {
    checking: true,
    login: false,
    loading: false,
    id: null,
    username: '',
    idrol: null,
    rol: null,
    msg: null,
    darkmode: false,
    name: null,
    lastName: null,
    email: null,
    phone: null,
    registroVendedor: false,
    invitacionVendedor: false
}
//definimos los types del reducer
type StartLoginPayload = { usuario: string, password: string };
type LoginPayload = { id: number, username: string, idrol: number, rol: string, msg: string, 
                      darkmode: boolean, name: string, email: string, phone: string, lastName: string, registroVendedor: boolean, invitacionVendedor: boolean };
type MessagePayload = { msg: string };
type RedirectPayload = { path: string };
type AuthAction = { type: 'logout' } 
                | { type: 'login', payload: LoginPayload }
                | { type: 'message', payload: MessagePayload }
                | { type: 'redirect', payload: RedirectPayload }
                ;

//definimos el reducer
export const authReducer = (state: AuthState = initialState, action: AuthAction): AuthState => {
    switch (action.type) {
        case 'message':
            return {
                ...state,
                login: false,
                checking: false,
                loading: false,
                id: null,
                username: '',
                idrol: null,
                rol: null,
                ...action.payload
            }
        case 'login':
            return {
                ...state,
                login: true,
                checking: false,
                loading: true,
                ...action.payload
            }
        case 'logout':
            return {
                ...state,
                checking: false,
                login: false,
                loading: false,
                id: null,
                username: '',
                idrol: null,
                rol: null,
                msg: null
            }      
        default:
            return state;
    }
}

//definimos los actions asincronos del reducer
export const startLogin = (startLoginData: StartLoginPayload): AppThunk => {
    return async (dispatch) => {
        const body = await fetchSinToken('auth/login', startLoginData, 'POST');
        //console.log(body);
        if (body.ok){
            localStorage.setItem('token', body.res.token);
            localStorage.setItem('token-init-date', new Date().getTime().toString());
            dispatch(login({
                id: body.res.id,
                username: body.res.user,
                idrol: body.res.idRol,
                rol: body.res.rol,
                msg: body.msg,
                darkmode: body.res.darkmode,
                name: body.res.name,
                email: body.res.email,
                phone: body.res.phone,
                lastName: body.res.lastName,
                registroVendedor: body.res.registroVendedor,
                invitacionVendedor: body.res.invitacionVendedor
            }));
        } else {
            dispatch(message({
                msg: body.msg
            }))
        }
    }
}

export const startCheking = (token: string): AppThunk => {
    return async (dispatch) => {
        if (token.length > 0) {
            const body = await fetchToken('auth/validate',{},'GET');
            //console.log(body);
            if (body.ok){
                try {
                    localStorage.setItem('token', body.res.token);
                    localStorage.setItem('token-init-date', new Date().getTime().toString());
                } catch (err) {
                    dispatch(message({
                        msg: 'Error al almacenar el localStorage'
                    }))
                    return;
                }
                dispatch(login({
                    id: body.res.id,
                    username: body.res.user,
                    idrol: body.res.idRol,
                    rol: body.res.rol,
                    msg: body.msg,
                    darkmode: body.res.darkmode,
                    name: body.res.name,
                    email: body.res.email,
                    phone: body.res.phone,
                    lastName: body.res.lastName,
                    registroVendedor: body.res.registroVendedor,
                    invitacionVendedor: body.res.invitacionVendedor
                }));
            } else {
                dispatch(message({
                    msg: ''
                }))
            }
        } else {
            dispatch(message({
                msg: ''
            }))            
        }
    }
}

const login = (loginData: LoginPayload): AuthAction  => {

    return {
        type: 'login',
        payload: loginData
    }    
}

export const startLogout = (): AppThunk => {
    return async (dispatch) => {
        try {
            localStorage.clear();
        } catch (err) {
            dispatch(message({
                msg: 'Error al limpiar localStorage'
            }))
            return;
        }
        //quitando modo oscuro
        dispatch(ModoOscuro({darkmode: false}));
        //quitando notificaciones
        dispatch(clearNavbar());
        //limpiando sidebar 
        dispatch(clearSidebar());
        //quitando datos de usuario
        dispatch(logout());
    }
}

const logout = (): AuthAction  => {
    return {
        type: 'logout'
    }    
}

const message = (msgData: MessagePayload): AuthAction  => {
    return {
        type: 'message',
        payload: msgData
    }    
}

export const startLoadUser = (): AppThunk => {
    return async (dispatch, getState) => {
        dispatch(ModoOscuro({darkmode: getState().auth.darkmode})); 
        //dispatch(startLoadNavbar());
    }
}