
import { useContext, useEffect } from 'react';
import "./styles/principalusers.scss";
import Slider from 'react-slick';
import { Avatar, Box, Button, CardMedia, CircularProgress, Typography, useMediaQuery } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import SVG from 'react-inlinesvg';
import { PrincipalUsersContext } from './context/ContextUsers';
import { BuscarUsersFind } from './BuscarUsersFind';
import { startLoadAssociatesUsers, startLoadLastVisitUsers, startLoadPublicationsUser } from './reducer/UsersReducer';
import { useHistory } from 'react-router-dom';
import ReactPlayer from 'react-player';
import InstallPWA from '../../helpers/installPwa';
import { AppDispatch, RootState } from '../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { startSendRequestRefaccion } from '../../reducers/filterReducer';

  const items = [  // CARRUSEL PARA EL SLIDER PRINCIPAL DEL "HOME"
    { 
      key: 'c1', Title: "¡Gracias!", Image: "./assets/slide-user-1.png", Boton: "Comprar", href: "/findUser", visible: true,
      Subtitle: "Ahora tienes acceso a comprar con los mejores y más confiables vendedores."
    },
    { 
      key: 'c2', Title: "¡Registra tu negocio!", Image: "./assets/slide-user-2.png", Boton: "Contactanos", href: "/contactUser", visible: true,
      Subtitle: "Estás interesado en vender?, ponte en contacto con el equipo de AutosXPartes.com para que realicen una visita a tu negocio."
    },
    { 
      key: 'c3', Title: "¡Próximamente!", Image: "./assets/slide-user-3.png", Boton: "Conocer más", href: "/users", visible: false,
      Subtitle: "Pondremos a tu disposición herramientas para poder realizar compras-ventas desde este mismo sitio."
    }
  ];

  const videosUser = [
    { key: 'v1', urlVideo: "https://youtu.be/x5ika6WnDio" },
    { key: 'v2', urlVideo: "https://youtu.be/3cEUA4Ugigg" },
    { key: 'v3', urlVideo: "https://youtu.be/YTyHxrt7dHE" },
    { key: 'v4', urlVideo: "https://youtu.be/Ulzju1uq0qE" },
    { key: 'v5', urlVideo: "https://youtu.be/_dacRf4cRyg" },
    { key: 'v6', urlVideo: "https://youtu.be/AeAVYduPtD0" },
    { key: 'v7', urlVideo: "https://youtu.be/D3j4Z1-fh5Y" }
  ]

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1000,
        settings:{
          slidesToShow: 3,
          slidesToScroll: 3,
          arrows: false
        }
      },
      {
        breakpoint: 810,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: false
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "65px",
          arrows: false
        }
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "50px",
          arrows: false
        }
      },
      {
        breakpoint: 300,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "40px",
          arrows: false
        }
      }
    ],
  };

  const settings1 = { 
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          arrows: false
        }
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "40px",
          arrows: false
        }
      }
    ],
  };

  const settings2 = { 
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
        }
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "20px",
          arrows: false
        }
      }
    ],
  };

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }:any) => (
    <SVG
      src={process.env.REACT_APP_ICONSERVER+'dz-angle-left'}
      className={ "slick-prev slick-arrow" + (currentSlide === 0 ? "slick-disabled" : "")}
      aria-disabled={currentSlide === 0 ? true : false}
      {...props}
    />
  );

  const SlickArrowRight = ({ currentSlide, slideCount, ...props }: any) => (
    <SVG
      src={process.env.REACT_APP_ICONSERVER+'dz-angle-right'}
      className={ "slick-next slick-arrow" + (currentSlide === slideCount - 1 ? "slick-disabled" : "")} 
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      {...props}
    />
  );

export const PrincipalUsers = () => {

    const responsive = useMediaQuery('(max-width:900px)');
    const responsive2 = useMediaQuery('(max-width:600px)');
    const responsive3 = useMediaQuery('(max-width:300px)');

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const storeFilters = (state: RootState ) => state.filters;
    const { asistencia } = useSelector(storeFilters);

    const dispatchRedux = useDispatch<AppDispatch>(); 

    useEffect(() => {
      if(asistencia === true) {
        const marca = localStorage.getItem('marca') || '';
        const modelo = localStorage.getItem('modelo') || '';
        const anio = localStorage.getItem('anio') || '';
        const refaccion = localStorage.getItem('refaccion') || '';
      
        dispatchRedux<any>(startSendRequestRefaccion({ marcaId: marca, modeloId: modelo, anio: parseInt(anio), refaccion: refaccion}));
      }
    }, []);

    // PARAMETRO PARA NAVEGAR HACIA OTRO COMPONENTE (HREF O LINK)
    const history = useHistory();

    //LEER EL REDUCER LOCAL
    const { /*setFindUsersModal,*/ usersReducerState, dispatchUser } = useContext(PrincipalUsersContext); 
    const { carouselLastVisit, carouselLastPublications, carouselAssociatesUsers } = usersReducerState;

    console.log(carouselLastVisit);

    useEffect(() => {
      startLoadLastVisitUsers({dispatchUser: dispatchUser })
    }, [dispatchUser])

    useEffect(() => {
      startLoadPublicationsUser({dispatchUser: dispatchUser })
    }, [dispatchUser])

    useEffect(() => {
      startLoadAssociatesUsers({dispatchUser: dispatchUser })
    }, [dispatchUser])

    const handleHrefPublicacionUser= (card: any) => {
      history.push(`/findUser/publication/${card}`);
    }

    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    });

    return (
      <div className="prinuser_principal">
        <div className="prinuser_carousel">
          <Box sx={{ maxWidth: "1366px", margin: "auto" }}>
            <Carousel navButtonsAlwaysVisible={true} interval={10000}>
              { items.map( (item) =>
                  <div className='prinuser_carousel_container' key={item.key}>
                    <CardMedia className="prinuser_carousel_image" image={item.Image} title={item.Title}/>
                    <div className='prinuser_carousel_text'>
                      {/*<div className='triangulo-equilatero-bottom-left'/>*/}
                      <div className='prinuser_carousel_title'>
                        <div>
                          <div>{item.Title}</div>
                          <p> 
                            {item.Subtitle}
                          </p>
                          {
                            item.visible 
                            ? 
                            <Button
                              variant="contained"
                              size="medium"
                              href={item.href}
                            >
                              {item.Boton}
                            </Button>
                            :
                            null
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
            </Carousel>
          </Box>
        </div>

        <div className="prinuser_instalar_app">
          <InstallPWA />
        </div>

        <div className="prinuser_slider_container">
          <BuscarUsersFind />
          <p className="prinuser_titulos_principales">Últimas visitas</p>

          <Box sx={{ height: 400 }}>
            {
              !carouselLastVisit ?
                <Box sx={{ height: "400px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <CircularProgress size={30} sx={{ mr: 1 }} />
                </Box>
              : carouselLastVisit.length === 0 ?
                <Box sx={{ width: "100%", height: "380px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <Box sx={{ width: 100, height: 80 }}>
                    <SVG src={ process.env.REACT_APP_ICONSERVER+'dz-search' } width={100} fill="#ff6700"/>
                  </Box>
                  <Box sx={{ maxWidth: 400 }}>
                    <Typography variant="h5" color="rgb(0, 93, 199)"><b>Aún no has realizado búsquedas</b></Typography>
                  </Box>
                </Box>
                :
                <Slider
                  {...settings}
                  prevArrow={ <SlickArrowLeft /> }
                  nextArrow={ <SlickArrowRight /> }
                >
                  {
                    carouselLastVisit.map( (ultimaVisita) => 
                      <div key={ultimaVisita.idPublicacion} className="home_carousel_cointainer_publications" onClick={() => handleHrefPublicacionUser(ultimaVisita.idPublicacion)}>
                        <div className="home_carousel_publications">
                          <div className="home_carousel_publication_imagen">
                            <CardMedia
                              component="img"
                              image={ultimaVisita.fotoPublicacion ? process.env.REACT_APP_PHOTOSERVER+ultimaVisita.fotoPublicacion : '/assets/sin-foto.png'}
                              alt={ultimaVisita.modelo}
                              height={(responsive3 ? "180px" : "250px")}
                            />
                          </div>
                          <div className="home_carousel_publication_icon">
                            <Box sx={{ width: (responsive2 ? "25px" : "30px"), height: (responsive2 ? "25px" : "30px") }}>
                              <SVG src={ultimaVisita.icono ? process.env.REACT_APP_ICONSERVER+ultimaVisita.icono : ''} fill="currentColor" height={(responsive2 ? "25px" : "30px")}/>
                            </Box>
                          </div>
                          <div className="home_carousel_publication_title">
                            <Box sx={{ mr: (responsive3 ? 0 : 2), fontSize: (!responsive ? "20px" : responsive3 ? "15px" : "17px"), color: "rgb(0, 93, 199)", fontWeight: 500, textAlign: (responsive3 ? "center" : "start") }} >
                              {ultimaVisita.marca} {ultimaVisita.modelo} {ultimaVisita.anio}
                            </Box>
                          </div>
                          <div className="home_carousel_publication_description">
                            {
                              ultimaVisita.tipo === "Refaccion" ?
                              <Box>
                                <Typography variant='h6'>Refacción</Typography>
                                <Typography variant='h5' color="#ff6700">Precio: <b>{ultimaVisita.precio ? formatter.format(parseFloat(ultimaVisita.precio.toString())) : ''}</b></Typography>
                              </Box>
                              :
                              <>
                                <b>Motor:</b> {ultimaVisita.motor ? ultimaVisita.motor : 'Sin dato'}
                                <br/>
                                <b>Version:</b> {ultimaVisita.version ? ultimaVisita.version : 'Sin dato'}
                                <br/>
                                <b>Transmición:</b> {ultimaVisita.transmision ? ultimaVisita.transmision : 'Sin dato'}
                              </>
                            }
                          </div>
                        </div>
                      </div>
                    )
                  }
                </Slider>
            }
          </Box>
        </div>

        <div className="prinuser_publicaciones">
          <p className="prinuser_titulos_principales">Autos, Motos, Vehiculos Comerciales y más</p>
          {
            !carouselLastPublications ?
            <Box sx={{ height: "400px", display: "flex", alignItems: "center", justifyContent: "center" }}>
              <CircularProgress size={30} sx={{ mr: 1 }} />
            </Box>
            :
            <Slider
              {...settings}
              prevArrow={ <SlickArrowLeft /> }
              nextArrow={ <SlickArrowRight /> }
            >
              {
                carouselLastPublications.map( (publicaciones) => 
                  <div key={publicaciones.idPublicacion} className="home_carousel_cointainer_publications" onClick={() => handleHrefPublicacionUser(publicaciones.idPublicacion)}>
                    <div className="home_carousel_publications">
                      <div className="home_carousel_publication_imagen">
                        <CardMedia
                          component="img"
                          image={publicaciones.fotoPublicacion ? process.env.REACT_APP_PHOTOSERVER+publicaciones.fotoPublicacion : '/assets/sin-foto.png'}
                          alt={publicaciones.modelo}
                          height={(responsive3 ? "180px" : "250px")}
                        />
                      </div>
                      <div className="home_carousel_publication_icon">
                        <Box sx={{ width: (responsive2 ? "25px" : "30px"), height: (responsive2 ? "25px" : "30px") }}>
                          <SVG src={publicaciones.icono ? process.env.REACT_APP_ICONSERVER+publicaciones.icono : '' } fill="currentColor" height={(responsive2 ? "25px" : "30px")}/>
                        </Box>
                      </div>
                      <div className="home_carousel_publication_title">
                        <Box sx={{ mr: (responsive3 ? 0 : 2), fontSize: (!responsive ? "20px" : responsive3 ? "15px" : "17px"), color: "rgb(0, 93, 199)", fontWeight: 500, textAlign: (responsive3 ? "center" : "start") }} >
                          {publicaciones.marca} {publicaciones.modelo} {publicaciones.anio}
                        </Box>
                      </div>
                      <div className="home_carousel_publication_description">
                        {
                          publicaciones.tipo === "Refaccion" ?
                          <Box>
                            <Typography variant='h6'>Refacción</Typography>
                            <Typography variant='h5' color="#ff6700">Precio: <b>{publicaciones.precio ? formatter.format(parseFloat(publicaciones.precio.toString())) : ''}</b></Typography>
                          </Box>
                          :
                          <>
                            <b>Motor:</b> {publicaciones.motor ? publicaciones.motor : 'Sin dato'}
                            <br/>
                            <b>Version:</b> {publicaciones.version ? publicaciones.version : 'Sin dato'}
                            <br/>
                            <b>Transmición:</b> {publicaciones.transmision ? publicaciones.transmision : 'Sin dato'}
                          </>
                        }
                      </div>
                    </div>
                  </div>
                )
              }
            </Slider>
          }
        </div>

        <div className="prinuser_ofertas_container">
          <div className="prinuser_ofertas_format">
          <p className="prinuser_titulos_principales">Conoce a nuestros asociados</p>  {/* SLIDER DE LOS ASOCIADOS */}
            {
              !carouselAssociatesUsers ?
              <Box sx={{ height: "500px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <CircularProgress size={30} sx={{ mr: 1 }} /><Typography variant="h5">Cargando...</Typography>
              </Box>
              :
              <Slider
                {...settings1}
                prevArrow={ <SlickArrowLeft /> }
                nextArrow={ <SlickArrowRight /> }
              >
                {
                  carouselAssociatesUsers.map( (asociados) => 
                  <Box key={asociados.idAsociado}>
                    <div className="home_asociados_fotos_container">
                      <CardMedia
                        component="img"
                        image={asociados.portada ? process.env.REACT_APP_USERSERVER+asociados.portada : './assets/sin-foto.png'}
                        alt="imagenes"
                        height={(responsive ? "250px" : "300px" )}
                      />
                      <div className="home_asociados_fotos_logo">
                        <Avatar
                          alt="imagen"
                          src={asociados.logo ? process.env.REACT_APP_USERSERVER+asociados.logo : process.env.REACT_APP_ICONSERVER+'dz-user-circle'}
                          sx={{ width: (!responsive ? 120 : responsive2 ? 60 : 80 ), height: (!responsive ? 120 : responsive2 ? 60 : 80 ), borderColor: "black", borderStyle: "solid", borderWidth: "3px" }}
                        />
                      </div>
                      <Box className="home_asociados_fotos_texto">
                        <Typography sx={{ p: 1, color: "white", fontSize: (!responsive ? "20px" : responsive2 ? "14px" : "16px" ), fontWeight: 500 }} >
                          {asociados.establecimiento}
                        </Typography>
                      </Box>
                    </div>
                  </Box>
                  )
                }
              </Slider>
            }
          </div>
        </div>

        <div className="prinuser_videos">
          <div className="home_videos">
            <p className="prinuser_titulos_principales">Visita nuestros canales de información</p>  {/* SLIDER DE LOS VIDEOS */}
            <div>
              <Slider
                {...settings2}
                prevArrow={ <SlickArrowLeft /> }
                nextArrow={ <SlickArrowRight /> }
              >
                {
                  videosUser.map( (videos) =>
                  <div key={videos.key}>
                    <CardMedia sx={{ paddingLeft: "8px", paddingRight: "8px" }}>
                      <ReactPlayer width="100%" height={(responsive ? "250px" : "300px" )} url={videos.urlVideo}/>
                    </CardMedia>
                  </div>
                  )
                }
              </Slider>
            </div>
          </div>
        </div>
      </div>
    )
}