import { fetchToken } from '../../../../../helpers/fetch';
//DEFINIMOS LOS TIPOS PARA DEL REDUCER
type ShoppingBuy = {
    id: string,
    venedor: string, 
    total: number,
    estado: string  
}
//DEFINIMOS LA ESTRUCTURA DEL REDUCER
export interface ShoppingReducerType {
    total: number,
    inicio: number,
    fin: number,
    pagina: number,
    salto: number,
    shopping: ShoppingBuy[] | undefined,
    modal: boolean,
    buymodal: ShoppingBuy | undefined,
    loading: boolean
}
//DEFINIMOS EL VALOR INICIAL DEL REDUCER
export const initialShoppingState: ShoppingReducerType = {
    total: 0,
    inicio: 0,
    fin: 0,
    pagina: 0,
    salto: 10,
    shopping: undefined,
    modal: false,
    buymodal: undefined,
    loading: false
}
//DEFINIMOS LOS PAYLOAD DE LOS ACTIONS
type LoadShoppingPayload = {     
    total: number,
    inicio: number,
    fin: number,
    pagina: number,
    shopping: ShoppingBuy[]
}

//DEFINIMOS LOS ACTIONS DEL REDUCER
type ShoppingActions =  { type: 'ClearShopping' } |
                        { type: 'LoadShopping', payload: LoadShoppingPayload } |
                        { type: 'ShowModal', payload: ShoppingBuy } | 
                        { type: 'CloseModal' }
                        ;

//DEFINIMOS LAS ACCIONES DEL REDUCER
export const shoppingReducer = (state: ShoppingReducerType = initialShoppingState, action: ShoppingActions) : ShoppingReducerType => {
    switch (action.type) {
        case 'ClearShopping' : return {
            ...state,
            ...initialShoppingState,
            loading: true
        }
        case 'LoadShopping' : return { 
            ...state, 
            ...action.payload,
            loading: false
        }
        case 'ShowModal' : return {
            ...state,
            modal: true,
            buymodal: action.payload
        }
        case 'CloseModal' : return {
            ...state,
            modal: false,
            buymodal: undefined
        }
        default: return state;
    }
}

const shoppings: ShoppingBuy[] = [
    {
        id: 'a',
        venedor: 'ALEX YONKE', 
        total: 50999,
        estado: 'Por llegar'
    },
    {
        id: 'b',
        venedor: 'EL CHAPARRO',
        total: 359,
        estado: 'Pendiente de envio'
    },
    {
        id: 'c',
        venedor: 'CHATARRAS DANIEL', 
        total: 12980,
        estado: 'Entregado'  
    },
    {
        id: 'd',
        venedor: 'CHATARRAS El CHINO', 
        total: 289,
        estado: 'Entregado'  
    },
    {
        id: 'e',
        venedor: 'CHATARRAS El CHUY', 
        total: 670,
        estado: 'Por llegar'  
    },
    {
        id: 'f',
        venedor: 'CHATARRAS El CHAVO', 
        total: 45700,
        estado: 'Pendiente de envio'  
    },
];

//CREAMOS LAS ACCIONES DEL REDUCER

//CARGAR DATOS DE LA COMPRA
type LoadShoppingParameter = { id: number | null, dispatch: React.Dispatch<ShoppingActions> };
export const startLoadShopping = async (loadShoppingData: LoadShoppingParameter) => {

    const {id, dispatch} = loadShoppingData;
    
    dispatch (clearShopping());

    const body = await fetchToken(`usuarios/cuenta/${id}`,{},'GET');
    if (body.ok){
        dispatch ( loadShopping({
            total: 3,
            inicio: 1,
            fin: 3,
            pagina: 1,
            shopping: shoppings
        }));
    } 
}
const clearShopping = (): ShoppingActions  => {
    return {
        type: 'ClearShopping'
    }    
}
const loadShopping = (buyData: LoadShoppingPayload): ShoppingActions  => {
    return {
        type: 'LoadShopping',
        payload: buyData
    }    
}

//MOSTAR Y OCULTAR MODAL
export const showModalShopping = (showModalData: ShoppingBuy): ShoppingActions => {
    return {
        type: 'ShowModal',
        payload: showModalData
    }
}
export const closeModalShopping = (): ShoppingActions => {
    return {
        type: 'CloseModal'
    }
} 