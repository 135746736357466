
import { Alert, Box, Button, Dialog, IconButton, TextField, Typography, useMediaQuery } from "@mui/material"
import { useContext, useEffect, useState } from "react";
import { AccountContext } from "../../../context/AccountContext";
import { closeEditNames, startEditName } from "../../../reducers/AccountReducer";
import validator from 'validator';

import SVG from 'react-inlinesvg';

export const EditarNombre = () => {

    const responsive = useMediaQuery('(max-width:300px)');

    const { accountReducerState, dispatch } = useContext(AccountContext);
    const { name, lastName, mensajeEditar, editarNombresModal } = accountReducerState;

    type editFormName = {
      nombre: string,
      apellidos: string
    }
    const initialFormValues : editFormName = {
      nombre: '',
      apellidos: ''
    }
    const [ formValues, setFormValues ] = useState(initialFormValues);
    const { nombre, apellidos } = formValues;

    useEffect(() => {
        setFormValues({
          nombre: name,
          apellidos: lastName
        })
    }, [ editarNombresModal, name, lastName ]);

    const handleEditInputChange = ({target}:any) => {
      setFormValues({
          ...formValues,
          [target.name]: target.value
      });
    }

    const handleClickCloseNameModal = () => {
      setNombreError(false)
      setNombreTextError('');
      setApellidosError(false)
      setApellidosTextError('');
      dispatch (closeEditNames());
    }

    const [nombreError, setNombreError] = useState(false);
    const [nombreTextError, setNombreTextError] = useState('');
    const [apellidosError, setApellidosError] = useState(false);
    const [apellidosTextError, setApellidosTextError] = useState('');

    const handleChangeNameLastName = () => {
      let error = false;
      if (!nombre) {
        setNombreError(true);
        setNombreTextError('Escriba el nombre');
        error = true;
      } else {
        if (!validator.isLength(nombre,{min: 4})) {
          setNombreError(true);
          setNombreTextError('Ingrese más valores');
          error=true;
        } else { 
          setNombreError(false);
          setNombreTextError('');
        }
      }
      if (!apellidos) {
        setApellidosError(true);
        setApellidosTextError('Escriba sus apellidos');
        error = true;
      } else {
          if (!validator.isLength(apellidos,{min: 4})) {
            setApellidosError(true);
            setApellidosTextError('Ingrese más valores');
            error=true;
          } else { 
            setApellidosError(false);
            setApellidosTextError('');
          }
      }
      if (!error) {
          dispatch(startEditName({dispatch: dispatch, nombre: nombre, apellido: apellidos}))
      }
    }

  return (
    <Dialog
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={editarNombresModal}
      //onClose={handleClickCloseNameModal}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            maxWidth: "600px",
          },
        },
      }}
    >
      <Box sx={{ backgroundColor: "rgb(0, 93, 199)", color: "white", p: (responsive ? 2 : 3), display: "flex" }}>
        <Typography variant={(responsive ? 'h6' : 'h5')}>Editar nombre y apellidos</Typography>
        <IconButton
          aria-label="close"
          onClick={handleClickCloseNameModal}
          sx={{
            position: 'absolute',
            right: 18
          }}
        >
          <SVG src={process.env.REACT_APP_ICONSERVER+'dz-error'} width={20} fill="white" />
        </IconButton>
      </Box>
      
      <Box sx={{ p: (responsive ? "20px 20px 0px 20px" : "30px 30px 0px 30px") }}>
        <Typography fontSize={(responsive ? "15px" : "16px")}>
          <b>¿Quieres cambiar tu nombre y apellidos?</b>
        </Typography>
        <br/>
        <p>
          <li>Al editar, asegúrate que estén correctos tus datos</li>
        </p>
      </Box>
      <Box sx={{ p: (responsive ? "10px 20px 0px 20px" : "20px 30px 0px 30px") }}>
        <TextField
          id="name" margin="dense" type="text" fullWidth variant="outlined" inputProps={{ style: { textTransform: "uppercase" } }}
          value={ nombre } name='nombre' onChange={ handleEditInputChange } //onChange={ ({target}) => onChange( target.value, 'nombre') }
          error={ nombreError } label={nombreTextError ? nombreTextError : 'Nombre'}
        />
        <TextField
          id="name" margin="dense" type="text" fullWidth variant="outlined" inputProps={{ style: { textTransform: "uppercase" } }}
          value={ apellidos } name='apellidos' onChange={ handleEditInputChange } //onChange={ ({target}) => onChange( target.value, 'apellidos') }
          error={ apellidosError } label={apellidosTextError ? apellidosTextError : 'Apellidos'}
        />
        <Typography sx={{ mt: 2 }}>
          Una vez corroborado tus datos, guarda los cambios.
        </Typography>
      </Box>
      {
        mensajeEditar && <Alert severity="error" sx={{ marginTop: 2 }}> {mensajeEditar} </Alert>
      }
      <Box sx={{ display: "flex", justifyContent: "center", p: (responsive ? 2 : 3) }}>
        <Box textAlign={(responsive ? 'center' : 'unset')}>
          <Button
            color="warning" sx={{ marginRight: (responsive ? 0 : 1) }} size="large"
            onClick={handleClickCloseNameModal}
          >
            Cancelar
          </Button>
          <Button
            variant="contained" size="large" sx={{ marginLeft: (responsive ? 0 : 1) }}
            onClick={()=>{handleChangeNameLastName()}}
          >
            Guardar
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}