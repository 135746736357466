
import "./styles/buscar-refaccion.scss"
import { Autocomplete, Button, Drawer, InputAdornment, TextField, Typography, useMediaQuery, Box, Pagination, CardMedia, CircularProgress, ListItemButton, ListItemIcon, ListItemText, Collapse, List, Checkbox, Alert, AlertTitle } from "@mui/material"
import SVG from 'react-inlinesvg';
import { useContext, useEffect, useState } from "react";
import { Brand, cleanAsistenciaError, cleanError, Model, onChange, setClearAnio, setClearBrand, setClearModel, setError, startLoadFiltersSinToken, startSelectAnio, startSelectBrand, startSelectModel, startSendRequestRefaccion } from "../../reducers/filterReducer";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import MuiContainer from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useHistory, useParams } from "react-router-dom";
import { startUpdateSearch } from "./reducers/FindReducer";
import { FindsContext } from "./context/ContextFind";
import { paginate } from "../../helpers/arrays";
import { isNumeric } from "../../helpers/checkValues";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import validator from 'validator';

  const Container = styled(MuiContainer)(  // ESTILOS PARA EL COMPONENTE DE CONTAINER PARA EL SIDEBAR DE FILTROS
    ({ theme }) => ({
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
      }),
      overflowX: 'hidden',
    }),
  );

  //TOTAL DE RESULTADOS POR PAGINA
  const resuladosPorPagina = 12;

export const BuscarRefaccionScreen = () => {

    // EFECTO PARA QUE LA PAGINA EMPIECE DESDE ARRIBA CUANDO SE ACTUALIZE
    useEffect(() => {
      window.scrollTo(0, 0)
    }, []);

    const responsive = useMediaQuery('(max-width: 1000px)'); 
    const responsive2 = useMediaQuery('(max-width: 300px)');

    // PARAMETRO PARA NAVEGAR HACIA OTRO COMPONENTE (HREF O LINK)
    const history = useHistory();

    //HOOK PARA CONTROLAR INPUTS
    const buscarActivo = true;

    //LEER EL REDUCER LOCAL
    const { findsReducerState, dispatchFind } = useContext(FindsContext);  
    const { resultados, totalPublicaciones, resultadosMensaje, 
            brandSearch, modelSearch, anioSearch } = findsReducerState;

    //LEER EL REDUCER REDUX
    const storeFilters = (state: RootState ) => state.filters;
    const { load, brands, availableModels, availableAnios, 
            brandTextError, modelTextError, anioTextError,
            brand, model, anio, buscarRefaccion, buscarRefaccionError,
            envioFinalizado, sendRequestRefaccionSending,
            sendRequestRefaccionCorrect, sendRequestRefaccionError
          } = useSelector(storeFilters);

    const storeAuth = (state: RootState ) => state.auth;
    const { login } = useSelector(storeAuth);

    // Cargar los filtros si no han sido cargados
    const dispatchRedux = useDispatch<AppDispatch>();
    useEffect(() => {
      if (buscarActivo && !load) {
          //console.log('Cargando filtros');
          dispatchRedux<any>(startLoadFiltersSinToken());
      }
    }, [load, buscarActivo, dispatchRedux]);

  //LISTAS
    //Marcas
    const [selectedBrand, setSelectedBrand] = useState<Brand | null>(null);
    const [iconBrand, setIconBrand] = useState<string>('');
    useEffect(() => {
      if (buscarActivo) {
        if (selectedBrand) {
            // console.log('Marca seleccionada:');
            clearBrand();
            setIconBrand(selectedBrand.icono);
            dispatchRedux<any>(startSelectBrand({selectedBrand}));
        } else {
          if (buscarActivo) { //solo si se esta mostrando el modal
              // console.log('Limpiando la marca del reducer');
              clearBrand();
              dispatchRedux<any>(setClearBrand());
          }
        }
      }
    }, [selectedBrand, buscarActivo, dispatchRedux])
    //Modelos
    const [selectedModel, setSelectedModel] = useState<Model | null>(null);
    useEffect(() => {
      if (buscarActivo) {
        if (selectedModel) {
            // console.log('Modelo seleccionado');
            clearModel();
            dispatchRedux<any>(startSelectModel({selectedModel}));
        } else {
          if (buscarActivo) { //solo si se esta mostrando el modal
              // console.log('Limpiando el modelo del reducer');
              clearModel();
              dispatchRedux<any>(setClearModel());
          }
        }
      }
    }, [selectedModel, buscarActivo, dispatchRedux])
    //Anios
    const [selectedAnio, setSelectedAnio] = useState<number | null>(null);
    useEffect(() => {
      if (buscarActivo) {
        if (selectedAnio) {
            // console.log('Anio seleccionado');
            dispatchRedux<any>(startSelectAnio({selectedAnio}));          
        } else {
          if (buscarActivo) { //solo si se esta mostrando el modal
              // console.log('Limpiando el anio del reducer');
              dispatchRedux<any>(setClearAnio()); 
          }
        }
      }
    }, [selectedAnio, buscarActivo, dispatchRedux])

    //METODOS DE LIMPIEZA
    const clearBrand = () => {
      setIconBrand('');
      setSelectedModel(null);
      setSelectedAnio(null);
    }
    const clearModel = () => {
        setSelectedAnio(null);
    }

    //FUNCION PARA LLENAR LISTAS SI ESTAS VIENEN YA CON UN VALOR SELECCIONADO
    const [marcaAutollenada, setMarcaAutollenada] = useState(false);
    const [modeloAutomatico, setModeloAutomatico] = useState<Model | null>(null);
    const [modeloAutollenado, setModeloAutollenado] = useState(false);
    const [anioAutomatico, setAnioAutomatico] = useState<number | null>(null)
    const [anioAutollenado, setAnioAutollenado] = useState(false);

    const llenarLista = (marca:Brand, modelo:Model, anio:number) => {
      setSelectedBrand(marca);
      setMarcaAutollenada(true);
      setModeloAutomatico(modelo);
      setAnioAutomatico(anio);
    }

    useEffect(() => {
      //console.log('nuevos modelos disponibles');
      if (marcaAutollenada) {
          //console.log('autollenado MODELOS por el usuario');
          setMarcaAutollenada(false);
          setSelectedModel(modeloAutomatico);
          setModeloAutollenado(true);
      } else {/*console.log('llenado automatico')*/}
    }, [availableModels, marcaAutollenada, modeloAutomatico])

    useEffect(() => {
      //console.log('nuevos anios disponibles');
      if (modeloAutollenado) {
          //console.log('autollenado ANIOS');
          setModeloAutollenado(false);
          setSelectedAnio(anioAutomatico);
          setAnioAutollenado(true);
      } else {}
    }, [availableAnios, modeloAutollenado, anioAutomatico])
        
    useEffect(() => {
        //console.log('añio llenado automaticamente')
        if (anioAutollenado) {
          //console.log('llenado todos los inputs')
          setBusquedaEjecutada(true);
          setAnioAutollenado(false);
        }
    }, [anioAutollenado]);

    const [busquedaEjecutada, setBusquedaEjecutada ] = useState(false);
    
    // FUNCION AL EJECUTAR CLICK SOBRE EL BOTON DE "BUSCAR" LAS PUBLICACIONES
    const handleClickFind = () => {
        setBusquedaEjecutada(false);
        console.log(brand)
        console.log(model)
        console.log(anio)
        if (brand !== null && model !== null && anio !== null ) {
          if (login) {
            history.push(`/findUser/${JSON.stringify(brand)}/${JSON.stringify(model)}/${anio}/1`);
          } else {
            history.push(`/find/${JSON.stringify(brand)}/${JSON.stringify(model)}/${anio}/1`);
          }
          Search();
        }
    }

    const Search = () => {
      const marcaSearch: Brand | null  = brand ? brand : null;
      const modeloSearch: Model | null = model ? model : null;
      const anioSearch: number = anio ? anio : 0;

      dispatchFind(startUpdateSearch({ stateLocal: findsReducerState, dispatchLocal: dispatchFind, 
                                      marca: marcaSearch, modelo: modeloSearch, anio: anioSearch }));
      setBusquedaEjecutada(true);
      dispatchRedux<any>(cleanAsistenciaError());
    }

    useEffect(() => {
      if (busquedaEjecutada) {
        Search();
      }
    }, [busquedaEjecutada])

    // SACAMOS LOS PARAMETROS DE LA DIRECCION URL 
    const { marcaParam, modeloParam, anioParam, paginaParam } = useParams<{ marcaParam: string, modeloParam: string, anioParam: string, paginaParam: string }>();

    useEffect(() => {
      if ( marcaParam == null || modeloParam == null || anioParam == null ) {} else {
        llenarLista(JSON.parse(marcaParam), JSON.parse(modeloParam), parseInt(anioParam));
        setOpenFilterMarcas(true);
        setOpenFilterModelos(true);
        setOpenFilterAnios(true);
      }
    }, [marcaParam, modeloParam, anioParam]);

    const handleHrefPublicacion = (tarjeta: any) => {
      if (login) {
        history.push(`/findUser/publication/${tarjeta}`);
      } else {
        history.push(`/find/publication/${tarjeta}`);
      }
    }

    const handlePaginationChange = (event: React.ChangeEvent<unknown>, value: number) => {
        if (login) {
          history.push(`/findUser/${JSON.stringify(brandSearch)}/${JSON.stringify(modelSearch)}/${anioSearch}/${value}`);
        } else {
          history.push(`/find/${JSON.stringify(brandSearch)}/${JSON.stringify(modelSearch)}/${anioSearch}/${value}`);
        }
        window.scrollTo(0, 0);
    };

    //COMPONENTE SUBMENU FILTROS RESPONSIVE
    const [openFilters, setOpenFilters] = useState(true);
    const handleFiltersClose = () => {
        openFilters ? setOpenFilters(false) : setOpenFilters(true);
    };
    
    const [screen, setScreen] = useState(false);
    useEffect(() => {
        responsive ? setScreen(true) : setScreen(false);
        if (responsive === true) { setOpenFilters(false); } else { setOpenFilters(true); }
    }, [responsive]);

    const [openFilterMarcas, setOpenFilterMarcas] = useState(false);
    const handleClickAbrirMarcas = () => { setOpenFilterMarcas(!openFilterMarcas) };
    const handleSelectMarcaFiltro = (marca:any) => {
        setSelectedBrand(marca);
        setOpenFilterModelos(true);
    };

    const [openFilterModelos, setOpenFilterModelos] = useState(false);
    const handleClickAbrirModelos = () => { setOpenFilterModelos(!openFilterModelos) };
    const handleSelectModeloFiltro = (modelo:any) => {
        setSelectedModel(modelo);
        setOpenFilterAnios(true);
        setOpenFilters(false);
        if (login) {
          history.push(`/findUser/${JSON.stringify(brand)}/${JSON.stringify(modelo)}/${null}/1`);
        } else {
          history.push(`/find/${JSON.stringify(brand)}/${JSON.stringify(modelo)}/${null}/1`);
        }
        
        dispatchFind(startUpdateSearch({ stateLocal: findsReducerState, dispatchLocal: dispatchFind, 
                                         marca: brand, modelo: modelo, anio: 0 }));
        dispatchRedux<any>(cleanAsistenciaError());
    };

    const [openFilterAnios, setOpenFilterAnios] = useState(false);
    const handleClickAbrirAnios = () => { setOpenFilterAnios(!openFilterAnios) };
    const handleSelectAnioFiltro = (anios:any) => { 
        setSelectedAnio(anios);
        setOpenFilters(false);
        
        if (login) {
          history.push(`/findUser/${JSON.stringify(brand)}/${JSON.stringify(model)}/${anios}/1`);
        } else {
          history.push(`/find/${JSON.stringify(brand)}/${JSON.stringify(model)}/${anios}/1`);
        }

        dispatchFind(startUpdateSearch({ stateLocal: findsReducerState, dispatchLocal: dispatchFind, 
                                         marca: brand, modelo: model, anio: anios }));
        dispatchRedux<any>(cleanAsistenciaError());
    };
    // COMPONENTE SIDEBAR MEBU FILTROS
    const listaFiltros = (
      <div>
        <Typography variant="h5" sx={{ pb: 3 }}>Filtros</Typography>
        <div>
          <ListItemButton onClick={handleClickAbrirMarcas}>
            <ListItemText primary="Marca" />
            {openFilterMarcas ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openFilterMarcas} timeout="auto" unmountOnExit>
            <List component="div" sx={{ maxHeight: "240px", overflow: "hidden", overflowY: "scroll" }}>
              { brands?.map((marca) => 
                <ListItemButton sx={{ pl: 4 }} key={marca.id} onClick={() => handleSelectMarcaFiltro(marca)}>
                  <ListItemIcon>
                    <SVG src={process.env.REACT_APP_ICONSERVER+marca.icono} width={30} fill="currentColor"/>
                  </ListItemIcon>
                  <ListItemText sx={{ whiteSpace: "pre-line" }} primary={marca.marca}/>
                  <Checkbox
                    checked={brand?.marca === marca.marca}
                    color="warning"
                    size="small"
                  />
                </ListItemButton>
                )
              }
            </List>
          </Collapse>
        </div>
        <div>
          <ListItemButton onClick={handleClickAbrirModelos}>
            <ListItemText primary="Modelos" />
            {openFilterModelos ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openFilterModelos} timeout="auto" unmountOnExit>
            <List component="div" sx={{ maxHeight: "240px", overflow: "hidden", overflowY: "scroll" }}>
              { availableModels ? 
                availableModels.map((modelo) => 
                  <ListItemButton sx={{ pl: 4 }} key={modelo.id} onClick={() => handleSelectModeloFiltro(modelo)}>
                    <ListItemIcon>
                      <SVG src={process.env.REACT_APP_ICONSERVER+iconBrand} width={30} fill="currentColor"/>
                    </ListItemIcon>
                    <ListItemText sx={{ whiteSpace: "pre-line" }} primary={modelo.modelo}/>
                    <Checkbox
                      checked={model?.modelo === modelo.modelo}
                      color="warning"
                      size="small"
                    />
                  </ListItemButton>
                )
                : <ListItemButton sx={{ pl: 4 }}>
                    <ListItemText primary="Debe elegir una marca"/>
                  </ListItemButton>
              }
            </List>
          </Collapse>
        </div>
        <div>
          <ListItemButton onClick={handleClickAbrirAnios}>
            <ListItemText primary="Años" />
            {openFilterAnios ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openFilterAnios} timeout="auto" unmountOnExit>
            <List component="div" sx={{ maxHeight: "240px", overflow: "hidden", overflowY: "scroll" }}>
              { availableAnios ?
                availableAnios.map((anioSelect) => 
                  <ListItemButton sx={{ pl: 4 }} key={anioSelect} onClick={() => handleSelectAnioFiltro(anioSelect)}>
                    <ListItemText primary={anioSelect}/>
                    <Checkbox
                      checked={anio === anioSelect}
                      color="warning"
                      size="small"
                    />
                  </ListItemButton>
                )
                : <ListItemButton sx={{ pl: 4 }}>
                    <ListItemText primary="Debe de haber un modelo"/>
                  </ListItemButton>
              }
            </List>
          </Collapse>
        </div>
      </div>
    );

    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    });

    const handleClicAsistencia = () => {
      let error = false;
      if (!buscarRefaccion) {
        dispatchRedux<any>(setError({ errorField: 'buscarRefaccionError', label: 'Escriba lo que quiere buscar' }));
        error=true;
      } else {
        if (!validator.isLength(buscarRefaccion,{min: 4})) {
            dispatchRedux<any>(setError({ errorField: 'buscarRefaccionError', label: 'Agrege más valores' }));
            error=true;
        } else {
            dispatchRedux<any>(cleanError({ errorField: 'buscarRefaccionError' }));
        }
      }

      if (!error) {
        if(!login) {
          history.push('/login');
        }
        dispatchRedux<any>(startSendRequestRefaccion({ marcaId: brand?.id, modeloId: model?.id, anio: anio, refaccion: buscarRefaccion}));
        //dispatchRedux<any>(startSendRequestRefaccion());
      }
    }

    return (
      <>
        <div className="find_buscador_container">  {/* CONTENEDOR PRINCIPAL DE LA BARRA DE BUSQUEDA */}
          <div className="find_buscador_format">
            <div> {/* FILTRO DE MARCAS */}
              <Autocomplete
                id="selectedBrand" value={selectedBrand} disabled={brands ? false : true}
                onChange={(_event: any, newValue: Brand | null) => { setSelectedBrand(newValue); }}
                isOptionEqualToValue={(option, value) => option.id === value.id || option.icono === value.icono || option.marca === value.marca || option.icono === '' || option.id === '' || option.marca === ''}
                sx={{ background: "white", p: 1 }} options={brands? brands : [{id:'', marca:'', icono:''}]}
                autoHighlight getOptionLabel={(option) => option.marca} autoComplete={true}
                renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    <Box sx={{ width: "30px", height: "30px" }}>
                      <SVG src={process.env.REACT_APP_ICONSERVER+option.icono} width={30} fill="currentColor"/>
                    </Box>
                    <Typography sx={{ marginLeft: 2 }}>{option.marca}</Typography>
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params} error={brandTextError ? true : false} label={brandTextError ? brandTextError : 'Seleccione la marca'}
                    variant="standard" autoComplete='off' placeholder="Ver lista de marcas"
                    inputProps={{...params.inputProps, autoComplete: 'off'}}
                    InputProps={{...params.InputProps, startAdornment: (
                        <InputAdornment position="start">{iconBrand && <SVG src={process.env.REACT_APP_ICONSERVER+iconBrand} width={30}/>}</InputAdornment>
                      )
                    }}
                  />
                )}
              />
            </div>
            <div> {/* FILTRO DE MODELOS */}
              <Autocomplete
                id="selectedModel" value={selectedModel} disabled={availableModels ? false : true}
                onChange={(_event: any, newValue: Model | null) => { setSelectedModel(newValue);}}
                sx={{ background: "white", p: 1 }} autoHighlight getOptionLabel={(option) => option.modelo}
                isOptionEqualToValue={(option, newValue) => option.id === newValue.id}
                options={availableModels ? availableModels : [{id:'', marca:'', modelo:'', anioInicial:0, anioFinal:0}]}
                renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    <Box sx={{ width: "30px", height: "30px" }}>
                      <SVG src={process.env.REACT_APP_ICONSERVER+iconBrand} width={30} fill="currentColor"/>
                    </Box>
                    <Typography sx={{ marginLeft: 2 }}>{option.modelo}</Typography>
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params} error={modelTextError ? true : false} label={modelTextError ? modelTextError : 'Seleccione el modelo'}
                    variant="standard" autoComplete='off' placeholder="Ver lista de modelos"
                    inputProps={{...params.inputProps, autoComplete: 'off'}}
                    InputProps={{...params.InputProps, startAdornment: (
                        <InputAdornment position="start">{iconBrand && <SVG src={process.env.REACT_APP_ICONSERVER+iconBrand} width={30}/>}</InputAdornment>
                      )
                    }}
                  />
                )}
              />
            </div>
            <div> {/* FILTRO DE ANIOS */}
              <Autocomplete
                id="selectedAnio" value={selectedAnio || null} disabled={availableAnios? false : true}
                onChange={(_event: any, newValue: number | null) => { setSelectedAnio(newValue); }}
                sx={{ background: "white", p: 1 }} options={availableAnios ? availableAnios : []}
                getOptionLabel={(option) => option.toString()} autoHighlight
                isOptionEqualToValue={(option, newValue) => option === newValue}
                renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    {option}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params} error={anioTextError ? true : false} label={anioTextError ? anioTextError : 'Seleccione el año'}
                    variant="standard" autoComplete='off' placeholder="Ver lista de años"
                    inputProps={{...params.inputProps, autoComplete: 'off'}}
                  />
                )}
              />
            </div>
            <Button variant="contained" color="warning" size="large" fullWidth onClick={handleClickFind}>Buscar</Button>
          </div>
        </div>

        { screen && <Box sx={{ pt: 3, pl: 2 }}><Button onClick={handleFiltersClose}> <ChevronRightIcon /> Mostrar Filtros </Button></Box>}

        <div className="find_publicaciones_container">  {/* CONTENEDOR DE LAS PUBLICACIONES DE LA BUSQUEDA Y LOS FILTROS */}
          <Box>  {/* CONTENEDOR PARA LOS FILTROS DE BUSQUEDA */}
            { screen ? 
              <Drawer sx={{ '& .MuiDrawer-paper': { width: 250, p: 2 } }} open={openFilters} onClose={() => (setOpenFilters(false))}>
                {listaFiltros}
              </Drawer> :
              <Container sx={{ width: 330 }}>{listaFiltros}</Container>
            }
          </Box>

          <Box sx={{ width: "100%" }}>  {/* CONTENEDOR PARA LAS TARJETAS DE LAS PUBLICACIONES */}
            <div>
              { !busquedaEjecutada     // EVALUAMOS SI SE HA EJECUTADO LA BUSQUEDA CON EL BOTON O ATRAVEZ DE UN AUTOLLENADO
                ? 
                  <div className="find_publicaciones_sin_resultados_cargando">  {/* SI NO SE HA EJECUTADO LA BUSQUEDA APARECERA LO SIG */}
                    <Box sx={{ display: "flex", maxWidth: "500px" }}>
                      <Box sx={{ width: "90px", height: "70px", display: "flex", alignItems: "center" }}>
                        <SVG src={process.env.REACT_APP_ICONSERVER+'dz-search'} width={80} fill="#ff6700"/>
                      </Box>
                      <Typography sx={{ fontSize:( responsive2 ? "18px" : "24px")}}>Para realizar su busqueda, debe llenar los parametros del buscador</Typography>
                    </Box>
                  </div>
                : (totalPublicaciones === 0) // SI SE EJECUTO LA BUSQUEDA, SE VA EVALUAR SI LA BISQUEDA TIENE RESULTADOS O NO TIENE RESULTADOS
                  ? resultadosMensaje ?
                    <div className="find_publicaciones_sin_resultados_cargando">  {/* SI NO TIENE RESULTADOS DE BUSQUEDA, VA ARROJAR UN MENSAJE */}
                      <Box sx={{ maxWidth: "500px" }}>
                        <Box sx={{ display: "flex" }}>
                          <SVG src={process.env.REACT_APP_ICONSERVER+'dz-error'} width={60} fill="red"/>
                          <Typography fontSize={22}>{ resultadosMensaje }</Typography>
                        </Box>
                        <div className="find_publicaciones_sin_resultados_busqueda">
                          <div><Typography sx={{ pb: 1 }} variant="h6">¿No encontraste lo que buscas?</Typography></div>
                          <div>
                            <Typography sx={{ pb: 1 }}>¿Que refacción necesitas?</Typography>
                            <TextField
                              id="buscarRefacion" variant="outlined" type="text" size="small" fullWidth
                              placeholder={`Ej. Salpicadera, Motor, Cofre, Rines, etc...`}
                              value={ buscarRefaccion || "" } error={ buscarRefaccionError ? true : false }
                              label={ buscarRefaccionError ? buscarRefaccionError : '' } sx={{ mb: 1, mt: "25px", width: "100%" }}
                              onChange={ ({target}) => dispatchRedux( onChange({ field: 'buscarRefaccion', value: target.value })) }
                            />
                          </div>
                          <div className="find_publicaciones_sin_resultados_datos">
                            <div>Marca</div>
                            <div>
                              <TextField size="small" disabled fullWidth id="outlined-basic" value={ brandSearch?.marca } variant="outlined" />
                            </div>
                            <div>Modelo</div>
                            <div>
                              <TextField size="small" disabled fullWidth id="outlined-basic" value={ modelSearch?.modelo } variant="outlined" />
                            </div>
                            <div>Año</div>
                            <div>
                              <TextField size="small" disabled fullWidth id="outlined-basic" value={ anioSearch } variant="outlined" />
                            </div>
                          </div>
                          <div>
                            {
                              envioFinalizado
                              ? <Button disabled variant="contained">Enviado</Button>
                              : ( sendRequestRefaccionSending
                                  ? <Button disabled variant="contained" endIcon={<CircularProgress color="inherit" size="1.5rem" />}>Enviando...</Button>
                                  : <Button variant="contained" onClick={handleClicAsistencia}>Enviar solicitud</Button>
                                )
                            }
                            { sendRequestRefaccionCorrect && <Alert severity="success" sx={{ marginTop: 2 }}><AlertTitle>{ sendRequestRefaccionCorrect }</AlertTitle></Alert> }
                            { sendRequestRefaccionError && <Alert severity="error" sx={{ marginTop: 2 }}><AlertTitle>{ sendRequestRefaccionError }</AlertTitle></Alert> }
                          </div>
                          <div>
                            <Typography sx={{ pt: 2 }}>Enviaremos tu solicitud a nuestros vendedores para encontrar lo que estas buscando</Typography>
                          </div>
                        </div>
                      </Box>
                    </div>
                    :
                    <Box sx={{ height: "650px", display: "flex", alignItems: "center", justifyContent: "center" }}>  {/* SI HAY RESULTADOS VA A TENER UN LETRERO DE CARGA */}
                      <CircularProgress color="warning" size={30} sx={{ mr: 1 }} /><Typography variant="h5">Cargando</Typography>
                    </Box>                      
                  : !resultados
                    ?
                    <Box sx={{ height: "650px", display: "flex", alignItems: "center", justifyContent: "center" }}>  {/* SI SE TARDA EN CARGAR LAS PUBLICACIONES, VA APARECER UN LETRERO DE CARGA */}
                      <CircularProgress color="warning" size={30} sx={{ mr: 1 }} /><Typography variant="h5">Cargando</Typography>
                    </Box>
                    : 
                    <>
                      <div className="find_publicaciones_format"> {/* SI YA ESTAN LAS PUBLICACIONES, SE VAN A MOSTRAR EN PANTALLA */}
                        { paginate(resultados, resuladosPorPagina, isNumeric(paginaParam) ? Number(paginaParam) : 1 ).map( (card) =>                             
                            <div key={card.id} onClick={() => handleHrefPublicacion(card.id)}>
                              <div className="find_publicacion_card">
                                <Box sx={{ backgroundColor: (card.tipo === "Auto" ? "#ff6700" : "rgb(0, 93, 199)" ), color: "white", height: "45px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                  <Typography variant="h6">{card.tipo}</Typography>
                                </Box>
                                <CardMedia
                                  component="img"
                                  image={card.fotoPublicacion ? process.env.REACT_APP_PHOTOSERVER+card.fotoPublicacion : '/assets/sin-foto.png'}
                                  alt={card.modelo}
                                  className="find_publicacion_card_image"
                                />
                                <Box sx={{ p: "20px 10px 20px 10px" }}>
                                  <div className="find_publicacion_card_title">
                                    <SVG src={card.icono ? process.env.REACT_APP_ICONSERVER+card.icono : '' } fill="currentColor" height={30}/>
                                    {card.marca} {card.modelo} {card.anio}
                                  </div>
                                  {
                                    card.tipo === "Auto" ?
                                    <div className="find_publicacion_card_descripcion">
                                      <b>Motor:</b> {card.motor ? card.motor : 'Sin dato'}
                                      <br/>
                                      <b>Version:</b> {card.vesion ? card.vesion : 'Sin dato'}
                                      <br/>
                                      <b>Transmición:</b> {card.transmision ? card.transmision : 'Sin dato'}
                                    </div>
                                    :
                                    <Box>
                                      <Typography variant='h5'><b>Precio:</b> {formatter.format(parseFloat(card.precio.toString()))}</Typography>
                                    </Box>
                                  }
                                </Box>
                              </div>
                            </div>                                       
                        )}
                      </div>
                      <Box sx={{ display: "flex", justifyContent: "center" }}>  {/* COMPONENTE DE LA PAGINACION DE LAS PUBLICACIONES */}
                        <Pagination 
                          count={totalPublicaciones ? Math.ceil(totalPublicaciones/resuladosPorPagina) : 1} 
                          page={ isNumeric(paginaParam) ? Number(paginaParam) : 1 } 
                          defaultPage={1}color="primary" size="large" onChange={ handlePaginationChange }
                        />
                      </Box>
                      <div className="find_publicaciones_sin_resultados_cargando2">
                        <div className="find_publicaciones_sin_resultados_busqueda">
                          <div><Typography sx={{ pb: 1 }} variant="h6">¿No encontraste lo que buscas?</Typography></div>
                          <div>
                            <Typography sx={{ pb: 1 }}>¿Que refacción necesitas?</Typography>
                            <TextField
                              id="buscarRefacion" variant="outlined" type="text" size="small" fullWidth
                              placeholder={`Ej. Salpicadera, Motor, Cofre, Rines, etc...`}
                              value={ buscarRefaccion || "" } error={ buscarRefaccionError ? true : false }
                              label={ buscarRefaccionError ? buscarRefaccionError : '' } sx={{ mb: 1, mt: "25px", width: "100%" }}
                              onChange={ ({target}) => dispatchRedux( onChange({ field: 'buscarRefaccion', value: target.value })) }
                            />
                          </div>
                          <div className="find_publicaciones_sin_resultados_datos">
                            <div>Marca</div>
                            <div>
                              <TextField size="small" disabled fullWidth id="outlined-basic" value={ brandSearch?.marca } variant="outlined" />
                            </div>
                            <div>Modelo</div>
                            <div>
                              <TextField size="small" disabled fullWidth id="outlined-basic" value={ modelSearch?.modelo } variant="outlined" />
                            </div>
                            <div>Año</div>
                            <div>
                              <TextField size="small" disabled fullWidth id="outlined-basic" value={ anioSearch } variant="outlined" />
                            </div>
                          </div>
                          <div>
                            {
                              envioFinalizado
                              ? <Button disabled variant="contained">Enviado</Button>
                              : ( sendRequestRefaccionSending
                                  ? <Button disabled variant="contained" endIcon={<CircularProgress color="inherit" size="1.5rem" />}>Enviando...</Button>
                                  : <Button variant="contained" onClick={handleClicAsistencia}>Enviar solicitud</Button>
                                )
                            }
                            { sendRequestRefaccionCorrect && <Alert severity="success" sx={{ marginTop: 2 }}><AlertTitle>{ sendRequestRefaccionCorrect }</AlertTitle></Alert> }
                            { sendRequestRefaccionError && <Alert severity="error" sx={{ marginTop: 2 }}><AlertTitle>{ sendRequestRefaccionError }</AlertTitle></Alert> }
                          </div>
                          <div>
                            <Typography sx={{ pt: 2 }}>Enviaremos tu solicitud a nuestros vendedores para encontrar lo que estas buscando</Typography>
                          </div>
                        </div>
                      </div>
                    </>
              }
            </div>
          </Box>
        </div>
      </>
    )
}