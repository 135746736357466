import React from 'react'
import { useState, useEffect, useLayoutEffect, useContext } from 'react';
import { ColAttributtes } from './Catalogo';
import { CatalogoContext } from './context/CatalogoContext';
import { setVisibles, checkRows, checkRow, selectItem } from './reducers/CatalogoReducer';
import { Tooltip } from '@mui/material';
import SVG from 'react-inlinesvg';
import dayjs from 'dayjs';

type Props = {
    darkMode: boolean,
    sidebarWidth: number,   
    ajusteAutomatico: boolean,
    countList: number,
    rowHeight: number,
    colPosition: ColAttributtes,
    setColPosition: React.Dispatch<React.SetStateAction<ColAttributtes>>,
    setCountList: React.Dispatch<React.SetStateAction<number>>
}

export const List: React.FC<Props> = ({ darkMode, sidebarWidth, ajusteAutomatico, 
                                        countList, rowHeight, colPosition, setColPosition, setCountList }) => {

    //OBTENEMOS EL VALOR DEL QUERY DEL REDUCER
    const { query, dispatch } = useContext(CatalogoContext);     
    
    //ICONOS DE LISTA
    const iconTrue = <div className="catalogo_color_green"><SVG src={process.env.REACT_APP_ICONSERVER+'dz-check'} height={12} fill="currentColor"/></div>
    const iconFalse = <div className="catalogo_color_red"><SVG src={process.env.REACT_APP_ICONSERVER+'dz-error'} height={12} fill="currentColor"/></div>

    //VALIDANDO TAMAÑO DE GRID PARA ADAPTAR LISTA Y NUMERO DE REGISTROS MOSTRADOS
    //calculando el ancho que ocupa la lista
    const [maxWidth, setMaxWidth] = useState<number>(0);
    useEffect(() => {
        setMaxWidth(query.headerList.reduce((counter, header) => (typeof(header.width) == "number" ? counter+header.width : counter), 0));
    }, [query.headerList])
    //tomando valores de ancho y alto del contenedor
    const [widthList, setWidthList] = useState<string>('0px');
    const [gridEight, setGridEight] = useState(0);
    const [gridWidth, setGridWidth] = useState(0);
    useLayoutEffect(() => {
        function updateSize() {
            if (window.innerWidth >= 1024)
                setGridWidth(window.innerWidth - sidebarWidth - 40);
            else 
                setGridWidth(1024 - sidebarWidth - 40);
            setGridEight(window.innerHeight );
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, [sidebarWidth]);
    //validando que regla aplicar de acuerdo a los anchos de la lista y del contenedor
    useEffect(() => {
        if (maxWidth > (gridWidth-6)){
            //console.log('Lista Grande');
            (ajusteAutomatico) ? setWidthList('auto') : setWidthList('0px');
        } else {
            //console.log('Lista Pequeña');
            (ajusteAutomatico) ? setWidthList('calc(100% - 2px)') : setWidthList('0px');
        }
    }, [ajusteAutomatico, gridWidth, maxWidth]);
    //validando la cantidad de registros a mostrar de acuerdo a la altura de la lista
    useEffect(() => {
        const eightList = gridEight - 282; 
       if (gridEight < 768) {
           setCountList( Math.floor((470 - 44) / (rowHeight + 2)));
       } else {
           setCountList( Math.floor((eightList - 44) / (rowHeight + 2)));
       }
    }, [gridEight,rowHeight,setCountList]);
    //limiando check de elementos no visibles
    useEffect(() => {
        dispatch(setVisibles({ rowsVisibles: countList}));
    }, [countList, dispatch]);
    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, field : any) => {
        console.log('MouseDown');
        if (!colPosition.dragging) {
            setColPosition({
                ...colPosition,
                fieldId: field,
                startLeft: e.screenX,
                diffY: e.screenX,
                dragging: true 
            });
        }
    }
    
    //MARCANDO REGISTROS SELECCIONADOS
    const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, field: any, rowid: string) => {
        if (field === "check") {
            //cambiando valor de check en la lista
            dispatch(checkRow({ rowid: rowid }));
        }
        else {
            //cambiando valor para modo edicion 
            dispatch(selectItem(field, rowid));
        }
        // console.log(field+''+rowid);    

    }
    const handleRowCheck = (e: React.ChangeEvent<HTMLInputElement>, rowid: string) => {
    } 

    const [checkItems, setCheckItems] = useState<boolean>(false);
    const handleHeaderCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log('Hola');
        setCheckItems(!checkItems); 
        //cambiando valor de check en la lista
        dispatch(checkRows({ check: !checkItems }));
    } 

    return (
        <div className="catalogo_list" >
            <div 
                className={(darkMode) ? 'catalogo_table catalogo_table_dark' : 'catalogo_table catalogo_table_light'}
                // style={{ 'width' : widthList, '&:hover' : {'overflowX' : (ajusteAutomatico) ? 'hidden' : 'auto2' }} as React.CSSProperties }
                style={{ 'width' : widthList }}
            >
                <div className="catalogo_header">
                {
                    query.headerList.map((header) => (
                        (header.id === 'a') ? undefined :
                        <div
                            key={ header.id+header.id }
                            className="catalogo_header_item"
                            style={ { 'width' : header.width+'px' } } 
                        >
                            <div 
                                className={ (header.id === 'b')
                                            ? 'catalogo_header_check'
                                            : (ajusteAutomatico) ? 'catalogo_header_tittle_disabled' : 'catalogo_header_tittle'
                                          }
                                style= { 
                                    {
                                        '--rowlist' : `${countList*(rowHeight+2)+40}px`,
                                        '--rowleft' : `${colPosition.left}px`
                                    } as React.CSSProperties } 
                                onMouseDown = { (header.id === 'b') ? undefined : (e) => ( handleMouseDown(e,header.field) ) }                                      
                            >
                                { 
                                    (header.id === 'b') 
                                    ? <Tooltip title="Seleccionar todos" placement="top" arrow> 
                                        <input className="catalogo_header_check_input" type="checkbox" checked={ checkItems } onChange={ (e) => (handleHeaderCheck(e)) } /> 
                                      </Tooltip>
                                    : <p> { header.label } </p>
                                }
                            </div>
                        </div>
                    ))
                }
                </div>
                {
                    query.rowsList.slice(0,countList).map((row) => (
                        <div 
                            key={ row._id+row._id }
                            className={(darkMode) 
                                ? (row.check) ? 'catalogo_row catalogo_row_dark_selected catalogo_row_dark_theme' : 'catalogo_row catalogo_row_dark_theme'
                                : (row.check) ? 'catalogo_row catalogo_row_light_selected catalogo_row_light_theme' : 'catalogo_row catalogo_row_light_theme'
                            }
                        >
                        { 
                            query.headerList.map((header) => (
                                (header.id === 'a') ? undefined :
                                <div
                                    key={ header.id+row._id }
                                    className={ (darkMode) 
                                        ? (row[header.field]?.selected) ? 'catalogo_item catalogo_item_selected catalogo_item_dark_theme' : 'catalogo_item catalogo_item_dark_theme' 
                                        : (row[header.field]?.selected) ? 'catalogo_item catalogo_item_selected catalogo_item_light_theme' : 'catalogo_item catalogo_item_light_theme' }
                                    style={ { width : header.width+'px', height : `${rowHeight}px`, textAlign: header.aligment } }  
                                    onClick={ (e) => ( handleClick(e, header.field, row._id) ) }                                 
                                >
                                    {
                                        (header.id === 'b') 
                                        ? <input type="checkbox" checked={ row.check } onChange={ (e) => (handleRowCheck(e,row._id) ) } /> 
                                        : (function() {
                                        switch (header.type) {
                                            case 'string': 
                                                return <p> { row[header.field]?.value } </p>;
                                            case 'boolean':
                                                return  row[header.field]?.value ? (typeof(row[header.field].value)=='boolean') ? ( (row[header.field].value) ? iconTrue : iconFalse ) : 'Valor no booleano' : undefined
                                            case 'integer': 
                                                return <p> { row[header.field]?.value ? (Number.isInteger(row[header.field].value) ? row[header.field].value  : 'Valor no entero') : '' } </p>;
                                            case 'decimal': 
                                                return <p> { row[header.field]?.value ? (typeof(row[header.field].value)=='number' ? (Math.round(Number(row[header.field].value)*100)/100).toFixed(2) : 'Valor no numerico') : '' } </p>;
                                            case 'money': 
                                                return <p> { row[header.field]?.value ? (typeof(row[header.field].value)=='number' ? Number(((Math.round(Number(row[header.field].value)*100)/100)).toFixed(2)).toLocaleString('es-MX',{ style: 'currency', currency: 'MXN'}) : 'Valor no monetario') : '' } </p>;
                                            case 'date':
                                                return <p> { row[header.field]?.value ? (dayjs(row[header.field].value).isValid) ? (dayjs(String(row[header.field]?.value)).format('DD-MMMM-YYYY')) : 'Fecha no valida' : '' } </p>
                                            case 'time':
                                                return <p> { row[header.field]?.value ? (dayjs(String(row[header.field]?.value)).format('HH:mm')+' hrs') : '' } </p>
                                            case 'datetime':
                                                return <p> { row[header.field]?.value ? (dayjs(String(row[header.field]?.value)).format('DD-MMMM-YYYY HH:mm')+' hrs') : '' } </p>
                                            case 'icon':
                                                return row[header.field]?.value ? <div className="catalogo_list_icon"><SVG src={process.env.REACT_APP_ICONSERVER+String(row[header.field]?.value)} height={18} fill="currentColor"/></div> : undefined
                                            case 'picture':
                                                return row[header.field]?.value ? <div className="catalogo_list_picture"><SVG src={process.env.REACT_APP_ICONSERVER+'dz-file-image'} height={18} fill="currentColor"/></div> : undefined
                                            
                                            default: 
                                                return undefined;
                                            }                                            
                                        })() 
                                    }
                                </div>
                            ))
                        }
                        </div>
                    ))
                }
            </div>
        </div>        
    )
}
