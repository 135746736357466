import { useMemo, useState, useEffect, useRef, useReducer, useCallback } from 'react';
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import SVG from 'react-inlinesvg';
import { createTheme, ThemeProvider } from '@mui/material';
import './styles/catalogo.scss';
import { Controller } from './Controller';
import { List } from './List';
import { Summary } from './Summary';
import { CatalogoContext } from './context/CatalogoContext';
import { queryReducer, initialStateReducer, setHeader, loadInitialRows } from './reducers/CatalogoReducer';
import { fetchToken } from '../../../../helpers/fetch';

//DEFINICION DE ATRIBUTOS DE COLUMNAS DEL GRID PARA EFITAR TAMAÑOS
export interface ColAttributtes {
    fieldId:    string,
    startLeft:  number,
    diffY:      number,
    dragging:   boolean,
    left:       number
}
    
export const Catalogo = () => {

    //LEER EL MODO OSCURO DE REDUX
    const storeDarkMode = (state: RootState ) => state.darkmode;
    const { darkMode } = useSelector(storeDarkMode);
    const storeSidebar = (state: RootState ) => state.sidebar;
    //destructurando el state de Redux obtenemos el valor del reducer que buscamos
    const { sidebarWidth, sidebarInitialWidth } = useSelector(storeSidebar);   
    const modoAmplio = useRef<boolean>(false);
    if(sidebarWidth === sidebarInitialWidth){
        modoAmplio.current = false;
    } else {
        modoAmplio.current = true;
    }
    const formStyles = {
        "--sidebarwidth" : `${sidebarWidth + 20}px`
    } as React.CSSProperties;

    //CARGAR EL REDUCER LOCAL
    const [query, dispatch] = useReducer(queryReducer, initialStateReducer)

    //DARKTHEME PARA MATERIAL UI
    const theme = useMemo(() =>
          createTheme({
            palette: {
              mode: darkMode ? 'dark' : 'light'
            },
          }),
        [darkMode],
    );

    //FUNCION PARA CARGAR LISTA
    const StartLoadInitialRows = useCallback(async() => {
        const body = await fetchToken(query.urlFetch,{},'GET');
        if (body.ok){
            dispatch(loadInitialRows( body.res ))
        }
    },[query.urlFetch],)

    //cargar lista del reducer
    useEffect(() => {
        StartLoadInitialRows();
    }, [StartLoadInitialRows])

    //VERIFICANDO POSICION DE MOUSE PARA CAMBIO DE ANCHO DE COLUMNAS
    //estados para cambiar posicion de las columnas
    const [colPosition, setColPosition] = useState<ColAttributtes>({
        fieldId: '',
        startLeft: 0,
        diffY: 0,
        dragging: false,
        left: 0
    })
    const handleMouseUp = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (colPosition.dragging) {
            const difLeft = (colPosition.startLeft-e.screenX)*(-1); 
            const index = query.headerList.findIndex((header => header.field === colPosition.fieldId));
            const width = query.headerList[index].width;
            const newWidth = ((width + difLeft) > 50) ? width + difLeft : 50;
            const newHeaderList = query.headerList.map( header => (header.field === colPosition.fieldId) ? { ...header, width : newWidth } : { ...header } );
            dispatch(setHeader({ headerList: newHeaderList }));

            setColPosition({
                fieldId: '',
                startLeft: 0,
                diffY: 0,
                dragging: false,
                left: 0
            });       
        }
    }
    const handleMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (colPosition.dragging) {
            var left = e.screenX - colPosition.diffY;
            setColPosition({
                ...colPosition,
                left: left
            });
        }
    }

    //ITEMS Y ALTURA DE ITEMS
    const [countList, setCountList] = useState<number>(12);
    const [rowHeight, setRowHeight] = useState<number>(32);

    //CHECK AUTO AJUSTE
    const [ajusteAutomatico, setAjusteAutomatico] = useState<boolean>(false);

    return (
        <CatalogoContext.Provider value={{ query , dispatch }}> 
            <div className={ (darkMode) ? 'catalogo_principal catalogo_dark_theme' : 'catalogo_principal catalogo_light_theme' }
                style={ formStyles }
                onMouseMove = { (e) => ( handleMouseMove(e) ) }
                onMouseUp = { (e) => ( handleMouseUp(e) ) }
                onMouseLeave = { (e) => ( handleMouseUp(e) ) }
            >
                <ThemeProvider theme={theme} >
                <div className={(darkMode) ? 'catalogo_grid catalogo_grid_dark_theme' : 'catalogo_grid catalogo_grid_light_theme'}>
                    <div className="catalogo_title">
                        <div className="catalogo_logo">
                            <SVG src={process.env.REACT_APP_ICONSERVER+query.icon} height={24} fill="currentColor"/>
                        </div>
                        <div className="catalogo_text">
                            <p>Catálogo de { query.title }</p>
                        </div>
                    </div>
                    <Controller 
                        theme = { theme }
                        sidebarWidth = { sidebarWidth } 
                        setAjusteAutomatico = { setAjusteAutomatico }
                        setRowHeight = { setRowHeight }
                    />
                    <List
                        darkMode = { darkMode }
                        sidebarWidth = { sidebarWidth } 
                        ajusteAutomatico = { ajusteAutomatico }
                        countList = { countList }
                        rowHeight = { rowHeight }
                        colPosition = { colPosition }
                        setColPosition = { setColPosition }
                        setCountList = { setCountList }
                    />
                    <Summary
                        countList = { countList }
                    />
                </div>
                </ThemeProvider>
            </div>
        </CatalogoContext.Provider>
    )
}

