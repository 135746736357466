
import { fetchSinToken } from "../../../helpers/fetch";

//DEFINIMOS LOS TIPOS PARA DEL REDUCER
type Publicaciones = {
    idPublicacion: string,
    icono: string | undefined,
    marca: string, 
    marcaId: string,
    modelo: string,
    modeloId: string,
    anio: number,
    anioInicial: number | null,
    anioFinal: number | null,
    version: string | undefined,
    descripcion: string | undefined,
    motor: string | undefined,
    transmision: string | undefined,
    fotoPublicacion: string | undefined,
    tipo: string | undefined,
    precio: number | string | null | undefined
}
type Asociados = {
    idAsociado: string,
    nombre: string,
    apellidos: string,
    portada: string,
    logo: string,
    establecimiento: string
}
//DEFINIMOS LA ESTRUCTURA DEL REDUCER
export interface HomeReducerType {
    carouselPublications: Publicaciones[] | undefined,
    carouselAssociates: Asociados[] | undefined
}
//DEFINIMOS EL VALOR INICIAL DEL REDUCER
export const initialHomeState: HomeReducerType = {
    carouselPublications: undefined,
    carouselAssociates: undefined
}

type LoadPublicationsHomePayload = { publicacionesHome: Publicaciones[] };
type LoadAssociatesHomePayload = { asociadosHome: Asociados[] };

type HomeActions = { type: 'ClearPublicationsHome' } |
                   { type: 'ClearAssociatesHome' } |
                   { type: 'LoadPublicationsHome', payload: LoadPublicationsHomePayload } |
                   { type: 'LoadAssociatesHome', payload: LoadAssociatesHomePayload } ;

//DEFINIMOS LAS ACCIONES DEL REDUCER
export const homeReducer = (state: HomeReducerType = initialHomeState, action: HomeActions) : HomeReducerType => {
    switch (action.type) {
        case 'ClearPublicationsHome' : return {
            ...state,
            ...initialHomeState
        }
        case 'ClearAssociatesHome' : return {
            ...state,
            ...initialHomeState
        }
        case 'LoadPublicationsHome' : return { 
            ...state, 
            carouselPublications: action.payload.publicacionesHome,
        }
        case 'LoadAssociatesHome' : return { 
            ...state, 
            carouselAssociates: action.payload.asociadosHome,
        }
        
        default: return state;
    }
}

//CARGAR LAS PUBLICACIONES EN HOME
type LoadPublicationsHomeParameter = { dispatchHome: React.Dispatch<HomeActions> };
export const startLoadPublicationsHome = async (loadPublicationsHomeData: LoadPublicationsHomeParameter) => {

    const {dispatchHome} = loadPublicationsHomeData;
    
    dispatchHome (clearPublicationsHome());

    let body = await fetchSinToken(`publicaciones/home`,{},'GET');

    console.log(body);

    if (body.ok){
        //Cambiando formato a respuesta del servidor
        let publications: Publicaciones[] = [];
        for (let publication of body.res){
            publications.push({
                idPublicacion: publication._id,
                icono: publication.marcaId.icono,
                marca: publication.marcaId.marca, 
                marcaId: publication.marcaId._id,
                modelo: publication.modeloId.modelo,
                modeloId: publication.modeloId._id,
                anio: publication.anio,
                anioInicial: publication.anioInicial,
                anioFinal: publication.anioFinal,
                version: publication.version,
                descripcion: publication.descripcion,
                motor: publication.motor,
                transmision: publication.transmision,
                fotoPublicacion: publication.fotoPublicacion,
                tipo: publication.tipo,
                precio: publication.precio
            });
        }
        dispatchHome(loadPublicationsHome({ publicacionesHome: publications }));
    }
}
const loadPublicationsHome = (publicationsHomeData: LoadPublicationsHomePayload): HomeActions  => {
    return {
        type: 'LoadPublicationsHome',
        payload: publicationsHomeData
    }    
}
const clearPublicationsHome = (): HomeActions  => {
    return {
        type: 'ClearPublicationsHome'
    }    
}

//CARGAR LAS PUBLICACIONES EN HOME
type LoadAssociatesHomeParameter = { dispatchHome: React.Dispatch<HomeActions> };
export const startLoadAssociatesHome = async (loadPublicationsHomeData: LoadAssociatesHomeParameter) => {

    const {dispatchHome} = loadPublicationsHomeData;
    
    dispatchHome (clearAssociatesHome());

    let body = await fetchSinToken(`usuarios/home`,{},'GET');

    //console.log(body);

    if (body.ok){
        //Cambiando formato a respuesta del servidor
        let associates: Asociados[] = [];
        for (let associate of body.res){
            associates.push({
                idAsociado: associate._id,
                nombre: associate.nombre,
                apellidos: associate.apellidos,
                portada: associate.fotoPortada,
                logo: associate.fotoLogo,
                establecimiento: associate.establecimiento
            });
        }
        dispatchHome(loadAssociatesHome({ asociadosHome: associates }));
    }
}
const loadAssociatesHome = (associatesHomeData: LoadAssociatesHomePayload): HomeActions  => {
    return {
        type: 'LoadAssociatesHome',
        payload: associatesHomeData
    }
}
const clearAssociatesHome = (): HomeActions  => {
    return {
        type: 'ClearAssociatesHome'
    }
}