import "./styles/okModal.scss"

import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../store/store';
import { Dialog, Box, Typography, Button } from '@mui/material';
import { startCloseOkDialog } from '../reducers/dialogsReducer';


export const OkDialogModal= () => {

    //LEER INFORMACION DE REDUX
    const storeDialogs = (state: RootState ) => state.dialogs;
    const { okShowDialog, okTitle, okContent } = useSelector(storeDialogs);
    const dispatchRedux = useDispatch<AppDispatch>();

    const handleClose = () => {
        dispatchRedux<any>(startCloseOkDialog());
    }

    return (
      <Dialog
        open={okShowDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              maxWidth: "600px",
            },
          },
        }}
      >

        <Box sx={{ backgroundColor: "#ff6700", color: "white", p: 3, display: "flex" }}>
          <Typography variant='h5'>{okTitle}</Typography>
        </Box>
        
        <Box sx={{ p: "20px 20px 0px 20px" }}>
          <Typography sx={{ textAlign: "center" }} variant="h6">{okContent}</Typography>
        </Box>

        <Box sx={{ p: 2, display: "flex", justifyContent: "center" }} >
          <Button
            variant="contained" size="large" sx={{ marginLeft: 1 }}
            onClick={handleClose}
          >
            Ok
          </Button>
        </Box>

      </Dialog>      
    );
}