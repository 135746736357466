
// COMPONENTE DE CARRUSEL PARA LAS PUBLICACIONES DE LOS AUTOS Y REFACCIONES

import Slider from 'react-slick';
import { Box, CardMedia, CircularProgress, Typography, useMediaQuery } from '@mui/material';
import SVG from 'react-inlinesvg';
import { useContext, useEffect } from 'react';
import { HomeContext } from './context/ContextHome';
import { startLoadPublicationsHome } from './reducer/HomeReducer';
import { useHistory } from 'react-router-dom';

export const HomeSliderCardsPublicaciones = () => {

    // PARAMETRO PARA NAVEGAR HACIA OTRO COMPONENTE (HREF O LINK)
    const history = useHistory();

    //LEER EL REDUCER LOCAL
    const { homeReducerState, dispatchHome } = useContext(HomeContext); 
    const { carouselPublications } = homeReducerState;
    
    useEffect(() => {
      startLoadPublicationsHome({dispatchHome: dispatchHome })
    }, [dispatchHome])

    const responsive = useMediaQuery('(max-width:900px)');
    const responsive2 = useMediaQuery('(max-width:600px)');
    const responsive3 = useMediaQuery('(max-width:300px)');

    const settings = {  // PARAMETROS PRINCIPALES ( RESPONSIVE ) PARA EL CARRUSEL DE LAS PUBLICACIONES DE AUTOS Y REFACCIONES
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1000,
          settings:{
            slidesToShow: 3,
            slidesToScroll: 3,
            arrows: false
          }
        },
        {
          breakpoint: 810,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            arrows: false
          }
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: "65px",
            arrows: false
          }
        },
        {
          breakpoint: 400,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: "50px",
            arrows: false
          }
        }
      ],
    };

    const SlickArrowLeft = ({ currentSlide, slideCount, ...props }:any) => (
      <SVG
        src={process.env.REACT_APP_ICONSERVER+'dz-angle-left'}
        className={ "slick-prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")}
        aria-disabled={currentSlide === 0 ? true : false}
        {...props}
      />
    );
  
    const SlickArrowRight = ({ currentSlide, slideCount, ...props }: any) => (
      <SVG
        src={process.env.REACT_APP_ICONSERVER+'dz-angle-right'}
        className={ "slick-next slick-arrow" + (currentSlide === slideCount - 1 ? " slick-disabled" : "")} 
        aria-disabled={currentSlide === slideCount - 1 ? true : false}
        {...props}
      />
    );

    const handleHrefPublicacionHome= (card: any) => {
        history.push(`/find/publication/${card}`);
    }

    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    });

    return (
      <Box /*sx={{ height: 400 }}*/>
        {
          !carouselPublications ?
          <Box sx={{ height: "400px", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <CircularProgress size={30} sx={{ mr: 1 }} />
          </Box>
          :
          <Slider
            {...settings}
            prevArrow={ <SlickArrowLeft /> }
            nextArrow={ <SlickArrowRight /> }
          >
            {
              carouselPublications.map( (publicaciones) => 
                <div key={publicaciones.idPublicacion} className="home_carousel_cointainer_publications" onClick={() => handleHrefPublicacionHome(publicaciones.idPublicacion)}>
                  <div className="home_carousel_publications">
                    <div className="home_carousel_publication_imagen">
                      <CardMedia
                        component="img"
                        image={publicaciones.fotoPublicacion ? process.env.REACT_APP_PHOTOSERVER+publicaciones.fotoPublicacion : '/assets/sin-foto.png'}
                        alt={publicaciones.modelo}
                        height={(responsive3 ? "180px" : "250px")}
                      />
                    </div>
                    <div className="home_carousel_publication_icon">
                      <Box sx={{ width: (responsive2 ? "25px" : "30px"), height: (responsive2 ? "25px" : "30px") }}>
                        <SVG src={publicaciones.icono ? process.env.REACT_APP_ICONSERVER+publicaciones.icono : '' } fill="currentColor" height={(responsive2 ? "25px" : "30px")}/>
                      </Box>
                    </div>
                    <div className="home_carousel_publication_title">
                      <Box sx={{ mr: (responsive3 ? 0 : 2), fontSize: (!responsive ? "20px" : responsive3 ? "15px" : "17px"), color: "rgb(0, 93, 199)", fontWeight: 500, textAlign: (responsive3 ? "center" : "start") }}  >
                        {publicaciones.marca} {publicaciones.modelo} {publicaciones.anio}
                      </Box>
                    </div>
                    <div className="home_carousel_publication_description">
                      {
                        publicaciones.tipo === "Refaccion" ?
                        <Box>
                          <Typography variant='h6'>Refacción</Typography>
                          <Typography variant='h5' color="#ff6700">Precio: <b>{publicaciones.precio ? formatter.format(parseFloat(publicaciones.precio.toString())) : ''}</b></Typography>
                        </Box>
                        :
                        <>
                          <b>Motor:</b> {publicaciones.motor ? publicaciones.motor : 'Sin dato'}
                          <br/>
                          <b>Version:</b> {publicaciones.version ? publicaciones.version : 'Sin dato'}
                          <br/>
                          <b>Transmición:</b> {publicaciones.transmision ? publicaciones.transmision : 'Sin dato'}
                        </>
                      }
                    </div>
                  </div>
                </div>
              )
            }
          </Slider>
        }
      </Box>
    )
}