import { Autocomplete, Box, Button, InputAdornment, TextField, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
//import { PrincipalUsersContext } from "./context/ContextUsers";
import { Brand, Model, setClearAnio, setClearBrand,
         setClearErrorAnio, setClearErrorBrand, setClearErrorModel,
         setClearModel, setErrorAnio, setErrorBrand, setErrorModel,
         startLoadFiltersSinToken, startSelectAnio, startSelectBrand, startSelectModel
       } from "../../reducers/filterReducer";
//import CloseIcon from '@mui/icons-material/Close';
import SVG from 'react-inlinesvg';
import { useMediaQuery } from "@material-ui/core";

export const BuscarUsersFind = () => {

    //const { findUsersModal, setFindUsersModal } = useContext(PrincipalUsersContext);

    const findUsersModal = true;

    const history = useHistory();

    const resposive = useMediaQuery('(max-width:500px)')

  //------------------------------------------------------------------FILTROS DE MARCA Y MODELO
    //LEER INFORMACION DE REDUX
    //Cargar datos de filtros de autos
    const storeFilters = (state: RootState ) => state.filters;
    const { load, brands, availableModels, availableAnios,
            brandTextError, modelTextError, anioTextError,
            brandTextLabel, modelTextLabel, anioTextLabel,
            brand, model, anio
          } = useSelector(storeFilters);
    //console.log(load);
    // Cargar los filtros si no han sido cargados
    const dispatchRedux = useDispatch<AppDispatch>();
    useEffect(() => {
      if (findUsersModal && !load) {
          // console.log('Cargando filtros');
          dispatchRedux<any>(startLoadFiltersSinToken());
          //console.log('cargando filtros');
      }
    }, [load, findUsersModal, dispatchRedux]);

  //LISTAS
    //Marcas
    const [selectedBrand, setSelectedBrand] = useState<Brand | null>(null);
    const [iconBrand, setIconBrand] = useState<string>('');
    useEffect(() => {
      if (findUsersModal) {
        if (selectedBrand) {
            // console.log('Marca seleccionada:');
            clearBrand(); 
            setIconBrand(selectedBrand.icono);
            dispatchRedux<any>(startSelectBrand({selectedBrand}));          
        } else {
          if (findUsersModal) { //solo si se esta mostrando el modal
            // console.log('Limpiando la marca del reducer');
            clearBrand();              
            dispatchRedux<any>(setClearBrand()); 
          }
        }
      }
    }, [selectedBrand, findUsersModal, dispatchRedux])
    //Modelos
    const [selectedModel, setSelectedModel] = useState<Model | null>(null);
    useEffect(() => {
      if (findUsersModal) {
        if (selectedModel) {
            // console.log('Modelo seleccionado');
            clearModel();  
            dispatchRedux<any>(startSelectModel({selectedModel}));          
        } else {
          if (findUsersModal) { //solo si se esta mostrando el modal
              // console.log('Limpiando el modelo del reducer');
              clearModel();  
              dispatchRedux<any>(setClearModel()); 
          }
        }
      }
    }, [selectedModel, findUsersModal, dispatchRedux])
    //Anios
    const [selectedAnio, setSelectedAnio] = useState<number | null>(null);
    useEffect(() => {
      if (findUsersModal) {
        if (selectedAnio) {
            // console.log('Anio seleccionado');
            dispatchRedux<any>(startSelectAnio({selectedAnio}));          
        } else {
          if (findUsersModal) { //solo si se esta mostrando el modal
              // console.log('Limpiando el anio del reducer');
              clearAnio();  
              dispatchRedux<any>(setClearAnio()); 
          }
        }
      }
    }, [selectedAnio, findUsersModal, dispatchRedux])

  //METODOS DE LIMPIEZA
  const clearBrand = () => {
    setIconBrand('');
    setSelectedModel(null);
    setSelectedAnio(null);
  }
  const clearModel = () => {
      setSelectedAnio(null);
  }
  const clearAnio = () => {

  }
  /*const handleClose = () => {
      // console.log('Limpiando los filtros al cerrar el modal');
      setFindUsersModal(false);
      clearBrand();          
      setSelectedBrand(null);    
      dispatchRedux<any>(setClearBrand());
  }*/

  //const [hreFind, setHreFind] = useState('/');

  const handleParams = () => {
    let error = false;
    if (!brand) {
        dispatchRedux<any>(setErrorBrand({label: 'Debe indicar la marca del automovil'})); 
        error=true;
    } else { dispatchRedux<any>(setClearErrorBrand()) }
    if (!model) {
        dispatchRedux<any>(setErrorModel({label: 'Debe indicar el modelo del automovil'})); 
        error=true;
    } else { dispatchRedux<any>(setClearErrorModel()) }
    if (!anio) {
        dispatchRedux<any>(setErrorAnio({label: 'Debe indicar el año del automovil'})); 
        error=true;
      } else { dispatchRedux<any>(setClearErrorAnio()) }
    if (!error) {
        history.push(`/findUser/${JSON.stringify(brand)}/${JSON.stringify(model)}/${anio}/${1}`)
        //dispatchRedux<any>(startUpdateSearch());
        //console.log('no hay errores');
    } else {
        //console.log('hay errores');
    }
  }

  return (
    <div style={{textAlign: "center"}}>
      {/*<Dialog
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={findUsersModal}
        onClose={handleClose}
      >*/}
        <Typography sx={{ flex: 1 }} variant="h5" component="div">
          ¿Que es lo que buscas?
        </Typography>

        <div className="prinuser_buscar_modal_content">
          <Box sx={{ width: 800 }}>
            {/* FILTRO DE MARCAS */}
            <Autocomplete
              id="selectedBrand" value={selectedBrand} disabled={brands ? false : true} sx={{ mb: 2 }}
              onChange={(_event: any, newValue: Brand | null) => {
                setSelectedBrand(newValue);
              }}
              options={brands ? brands : [{id:'', marca:'', icono:''}]}
              autoHighlight getOptionLabel={(option) => option.marca}
              renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                  <Box sx={{ width: "30px", height: "30px" }}>
                    <SVG src={process.env.REACT_APP_ICONSERVER+option.icono} width={30}/>
                  </Box>
                  <Typography sx={{ marginLeft: 2 }}>{option.marca}</Typography>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params} error={brandTextError? true : false}
                  label={brandTextError ? brandTextLabel : 'Marca'} placeholder="Ver lista de marcas"
                  InputProps={{
                    ...params.InputProps, // disable autocomplete and autofill                                            
                    startAdornment: (
                      <InputAdornment position="start">                                                                                      
                        {brand && <SVG src={process.env.REACT_APP_ICONSERVER+brand.icono} width={30}/>}
                      </InputAdornment>
                    )                                                                                                                                                                                    
                  }}
                />
              )}
            />
            {/* FILTRO DE MODELOS */}
            <Autocomplete
              id="selectedModel" value={selectedModel} disabled={availableModels ? false : true} sx={{ mb: 2 }}
              onChange={(_event: any, newValue: Model | null) => {
                setSelectedModel(newValue);
              }}
              options={availableModels ? availableModels : [{id:'',marca:'',modelo:'',anioInicial:0,anioFinal:0}]}
              autoHighlight getOptionLabel={(option) => option.modelo}
              renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                  <SVG src={process.env.REACT_APP_ICONSERVER+iconBrand} width={30} fill="currentColor"/>
                  <Typography sx={{ marginLeft: 2 }}>{option.modelo}</Typography>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={modelTextError ? true : false}
                  label={modelTextError ? modelTextLabel : 'Modelo'} placeholder="Ver lista de modelos"
                  InputProps={{
                    ...params.InputProps, // disable autocomplete and autofill
                    startAdornment: (
                      <InputAdornment position="start">
                        {brand && <SVG src={process.env.REACT_APP_ICONSERVER+brand.icono} width={30}/>}
                      </InputAdornment>
                    )
                  }}
                />
              )}                                  
            />    
            {/* FILTRO DE ANIOS */}
            <Autocomplete
              id="selectedAnio" value={selectedAnio} disabled={availableAnios ? false : true} sx={{ mb: 2 }}
              onChange={(_event: any, newValue: number | null) => {
                setSelectedAnio(newValue);
              }}
              options={availableAnios ? availableAnios : []}
              autoHighlight getOptionLabel={(option) => option.toString()}
              renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                  {option}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={anioTextError ? true : false}
                  label={anioTextError ? anioTextLabel : 'Año'}
                  inputProps={{
                    ...params.inputProps, autoComplete: 'new-password', // disable autocomplete and autofill
                  }}
                />
              )}
            />

            <Button 
              variant="contained" 
              color="warning"
              fullWidth
              onClick={() => handleParams()}
              size="large"
              sx={{ fontSize: (resposive ? "16px" : "21px"), mt: 1 }}
            >
              Busca tu autoparte
            </Button>
          </Box>
        </div>
      {/*</Dialog>*/}
    </div>
  )
}