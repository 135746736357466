
const baseURL = process.env.REACT_APP_SERVER;
type serverMethod = 'GET' | 'POST' | 'PUT' | 'LOCK' | 'UNLOCK' | 'DELETE';
export interface ServerResponse {
    status: number,
    ok: boolean,
    msg: string,
    count?: number,
    res?: any | null,
    err?: any | null, 
    sql?: any | null
}

export const fetchSinToken = ( endpoint: string, data: Object, method: serverMethod = 'GET') => {
    return new Promise <ServerResponse> ( (resolve) => {
        const url = `${ baseURL }/api/${ endpoint }`;
        if (method === 'GET'){
            fetch(url).then((response) => {
                resolve (response.json());
            }).catch((err) => {
                resolve ({
                    status: 500,
                    ok: false,
                    msg: 'No hay conexion con el servidor',
                    err
                })
            })
        } else {
            fetch(url, {
                method, 
                headers: {'Content-type': 'application/json'}, 
                body: JSON.stringify(data) }
                ).then((response) => {
                    resolve (response.json());
            }).catch((err) => {
                resolve ({
                    status: 500,
                    ok: false,
                    msg: 'No hay conexion con el servidor',
                    err
                })
            })
        }
    
    });
}

export const fetchToken = ( endpoint: string, data: Object, method: serverMethod = 'GET') => {
    return new Promise <ServerResponse> ( (resolve) => {
        const url = `${ baseURL }/api/${ endpoint }`;
        const token = localStorage.getItem('token') || '';
        if (method === 'GET'){
            fetch(url, {  
                method,
                headers:{
                    'token': token
                }
            }).then((response) => {
                resolve (response.json());
            }).catch((err) => {
                resolve ({
                    status: 500,
                    ok: false,
                    msg: 'No hay conexion con el servidor',
                    err
                })
            })
        } else {
            fetch(url, {
                method, 
                headers: {
                    'Content-type': 'application/json',
                    'token': token                    
                }, 
                body: JSON.stringify(data) }
                ).then((response) => {
                    resolve (response.json());
            }).catch((err) => {
                resolve ({
                    status: 500,
                    ok: false,
                    msg: 'No hay conexion con el servidor',
                    err
                })
            })
        }
    
    });
}

export const fetchFormDataToken = ( endpoint: string, data: Object, file: File ) => {
    return new Promise <ServerResponse> ( (resolve) => {
        const url = `${ baseURL }/api/${ endpoint }`;
        const token = localStorage.getItem('token') || '';
        const formData = new FormData();
        formData.append('archivo',file);
        Object.entries(data).forEach(([key, value])=> { 
            formData.append(key,value);
        })

        fetch(url, {
            method: 'POST', 
            headers: {
                'token': token                    
            }, 
            body: formData }
            ).then((response) => {
                resolve (response.json());
        }).catch((err) => {
            resolve ({
                status: 500,
                ok: false,
                msg: 'No hay conexion con el servidor',
                err
            })
        })        
    
    });
}