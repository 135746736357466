//importamos createStore,applyMiidleware de Redux 
//npm install --save react-redux redux
import { Action, AnyAction, applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from 'redux-devtools-extension';
//importamos redux-thunk para trabajar los middleware de Redux
//npm install --save redux-thunk
import thunk, { ThunkDispatch } from "redux-thunk";
//importamos los reducers
import { navbarReducer } from '../reducers/navbarReducer';
import { modalReducer } from '../reducers/modalReducer';
import { darkModeReducer } from '../reducers/darkModeReducer';
import { sidebarReducer } from '../reducers/sidebarReducer';
import { authReducer } from '../reducers/authReducer';
import { businessReducer } from '../reducers/businessReducer';
import { filterReducer } from '../reducers/filterReducer';
import { dialogsReducer } from '../reducers/dialogsReducer';
import { registerReducer } from "../reducers/registerReducer";
import { contactReducer } from "../components/screens/reducers/ContactReducer";

//creamos la lista de reducers del Store
const rootReducer = combineReducers({
    auth: authReducer,
    darkmode: darkModeReducer,
    sidebar: sidebarReducer,
    navbar: navbarReducer,
    modal: modalReducer,
    business: businessReducer,
    filters: filterReducer,
    dialogs: dialogsReducer,
    register: registerReducer,
    contact: contactReducer
})

//creamos el composeEnhancer para la configuracion de Redux
//https://github.com/zalmoxisus/redux-devtools-extension#usage
const composeEnhancers = composeWithDevTools(applyMiddleware(thunk));

//creamos el store de Redux
export const store = createStore(
    //enviamos los reducers que va a trabajar
    rootReducer,
    //enviamos la herramienta de middleware y los parametros para las herramientas de desarrollo
    composeEnhancers
)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export type ThunkAction<
  R, // Return type of the thunk function
  S, // state type used by getState
  E, // any "extra argument" injected into the thunk
  A extends Action // known types of actions that can be dispatched
> = (dispatch: ThunkDispatch<S, E, A>, getState: () => S, extraArgument: E) => R;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>