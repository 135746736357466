
import { useEffect, useState, createContext, useReducer } from 'react'; 
import { NotFound } from '../../../../notfound/NotFound';
import { QuestionsReducerType, initialQuestionsState, questionsReducer } from '../reducers/QuestionsReducer';
import { MyQuestions } from '../MyQuestions';
import { Preguntas } from '../components/misPreguntas/Preguntas';
import { Respuestas } from '../components/misRespuestas/Respuestas';


//DEFINIMOS UN CONTEXT PARA CARGAR INFORMACION DEL SERVIDOR
type questionsContextType = {
    questionsReducerState: QuestionsReducerType,
    dispatchQuestion: React.Dispatch<any>,
}
const questionsContextDefaultValues: questionsContextType = {
    questionsReducerState: initialQuestionsState,
    dispatchQuestion: () => null
};
export const QuestionsContext = createContext<questionsContextType>(questionsContextDefaultValues);

//DEFINIMOS UN CONTEXT PARA NAVEGAR ENTRE COMPONENTES
type menuContextType = {
    componentJSX: JSX.Element,
    setIndexComponent: any,
    indexComponent: any
} 
const menuContextDefaultValues: menuContextType = {
    componentJSX: <NotFound/>,
    setIndexComponent: undefined,
    indexComponent: undefined
};
export const MenuContext = createContext<menuContextType>(menuContextDefaultValues);

//creamos el Functional Component
export const ContextQuestions = () => {

    //REDUCER DEL COMPONENTE
    const [questionsReducerState, dispatchQuestion] = useReducer(questionsReducer, initialQuestionsState)

    //NAVEGACION DE COMPONENTES
    const [componentJSX, setComponentJSX] = useState<JSX.Element>(<NotFound/>);
    const [indexComponent, setIndexComponent] = useState(0);
    useEffect(() => {
        switch (indexComponent) {
            case 0: setComponentJSX(<Preguntas />); break;       
            case 1: setComponentJSX(<Respuestas />); break;       
            default: setComponentJSX(<NotFound />); break;
        }
    }, [indexComponent])

    return (
            <QuestionsContext.Provider value = {{ questionsReducerState, dispatchQuestion }}>
                <MenuContext.Provider value = {{ componentJSX, setIndexComponent, indexComponent }}>
                    <MyQuestions />
                </MenuContext.Provider>
            </QuestionsContext.Provider>
    )
} 
