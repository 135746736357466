import FileResizer from 'react-image-file-resizer';

export const resizeFile = (file : File) => new Promise(resolve => {
    console.log(file.name);
    let extension;
    if (file.name.endsWith(".gif") || file.name.endsWith(".GIF") ){
        extension="";
    }else{
        extension="jpg";
    }
    console.log(extension);

    FileResizer.imageFileResizer(file, 1000, 1000, extension, 100, 0,
    uri => {
        resolve(uri);
    },
    'file'
    );
});